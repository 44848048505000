import {
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { KeycloakService } from 'keycloak-angular';
import { NGXLogger } from 'ngx-logger';
import { CheckboxValidator } from '../../../lib/form-fields';
import { PasswordValidator } from '../../../lib/form-fields/form-field-password/password-validator';
import { RegisterService } from '../../../services';
import {
  FormValidationErrorResponse,
  RegisterInput,
} from '../../../types';

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RegisterFormComponent implements OnInit {
  @Input() registerInput!: RegisterInput;
  form!: UntypedFormGroup;
  registrationSuccessful = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private keycloak: KeycloakService,
    private logger: NGXLogger,
    private service: RegisterService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: new UntypedFormControl({
        value: this.registerInput.email,
        disabled: true,
      }), // view only
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      password: [
        '',
        [Validators.required, PasswordValidator.syntax],
      ],
      passwordRepeat: [''],
      confirmedGDPR: [false, [CheckboxValidator.checked]],
    });
    this.passwordRepeatControl.setValidators([
      Validators.required,
      PasswordValidator.sameAsPassword(
        this.passwordControl
      ),
    ]);
  }

  get emailControl(): UntypedFormControl {
    return this.form.get('email') as UntypedFormControl;
  }

  get firstnameControl(): UntypedFormControl {
    return this.form.get('firstname') as UntypedFormControl;
  }

  get lastnameControl(): UntypedFormControl {
    return this.form.get('lastname') as UntypedFormControl;
  }

  get passwordControl(): UntypedFormControl {
    return this.form.get('password') as UntypedFormControl;
  }

  get passwordRepeatControl(): UntypedFormControl {
    return this.form.get(
      'passwordRepeat'
    ) as UntypedFormControl;
  }

  get confirmedGDPRControl(): UntypedFormControl {
    return this.form.get(
      'confirmedGDPR'
    ) as UntypedFormControl;
  }

  submit() {
    this.form.markAllAsTouched();
    const values = this.form.value;
    this.logger.debug('save', { values });
    if (this.form.valid) {
      this.service
        .register(
          {
            firstname: this.firstnameControl.value,
            lastname: this.lastnameControl.value,
            password: this.passwordControl.value,
            confirmedGDPR: this.confirmedGDPRControl.value,
          },
          this.registerInput.token
        )
        .then(() => this.success())
        .catch((errors: FormValidationErrorResponse) =>
          this.fail(errors)
        );
    }
  }

  login() {
    this.keycloak.login({
      redirectUri: `${window.location.origin}/home`,
    });
  }

  private success() {
    this.registrationSuccessful = true;
    setTimeout(() => this.login(), 1500);
  }

  private fail(errors: FormValidationErrorResponse) {
    this.logger.error(
      'RegisterFormComponent:handleErrors',
      {
        errors,
      }
    );
    errors.forEach((error) => {
      const field = this.form.get(
        error.field
      ) as UntypedFormControl;
      field.setErrors({ custom: error.message });
    });
  }
}
