import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarRef,
  TextOnlySnackBar,
} from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(
    private snackbar: MatSnackBar,
    private translate: TranslateService
  ) {}

  /**
   *
   * @param translationKey - The message that would be displayed on the snack bar
   * @param actionBtn - The title of the action button (optional).
   * @param duration - Snack bar display duration in ms. If the value is falsy or a negative number, the snack bar will not dismiss (default value: 3 s.).
   */
  showSnackBar(
    translationKey: string,
    actionBtn = '',
    duration = 3000 // Default value
  ): void {
    this.translate
      .get([translationKey, actionBtn])
      .subscribe((translated) => {
        this.show(
          translated[translationKey],
          translated[actionBtn],
          duration
        );
      });
  }

  /**
   * Show the given snack bar component with the given data.
   *
   * @template T
   * @template D
   * @param {ComponentType<T>} component - The component to be opened
   * @param {D} data - The data to be passed to the component (optional).
   * @param {number} duration - Snack bar display duration in ms. If the value is falsy or a negative number, the snack bar will not dismiss (default value: 3 s.).
   * @param {string | string[]} panelClass - The CSS class(es) (optional).
   */

  showSnackBarFromComponent<T, D>(
    component: ComponentType<T>,
    data?: D,
    duration: number = 3000, // Default value
    panelClass: string | string[] = ''
  ): void {
    const config: MatSnackBarConfig<D> =
      new MatSnackBarConfig<D>();
    config.duration = duration;
    config.panelClass = panelClass;
    config.data = data;
    this.snackbar.openFromComponent(component, config);
  }

  dismiss(): void {
    this.snackbar.dismiss();
  }

  private show(
    message: string,
    actionBtn?: string,
    duration?: number
  ): MatSnackBarRef<TextOnlySnackBar> {
    const config: MatSnackBarConfig =
      new MatSnackBarConfig();
    config.duration = duration;
    return this.snackbar.open(message, actionBtn, config);
  }
}
