import { APP_INITIALIZER } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakOptions } from 'keycloak-angular/lib/core/interfaces/keycloak-options';
import * as Keycloak from 'keycloak-js';
import { environment } from '../../environments/environment';

const config = {
  url: environment.keycloakUrl,
  realm: environment.realm,
  clientId: environment.clientId,
};

const initOptions: Keycloak.KeycloakInitOptions = {
  onLoad: 'check-sso',
  silentCheckSsoRedirectUri:
    window.location.origin +
    '/assets/silent-check-sso.html',
};

const options: KeycloakOptions = {
  config,
  initOptions,
  bearerExcludedUrls: ['/api/system/version'],
};

const initializeKeycloak =
  (keycloak: KeycloakService) => () =>
    keycloak.init(options);

export const KEYCLOAK_PROVIDER = {
  provide: APP_INITIALIZER,
  useFactory: initializeKeycloak,
  multi: true,
  deps: [KeycloakService],
};
