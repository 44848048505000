import { Component, HostBinding } from '@angular/core';
import { ExamCenterService } from '../../services';

@Component({
  selector: 'app-exam-center-form-page',
  templateUrl: './exam-center-form-page.component.html',
  styleUrls: ['./exam-center-form-page.component.scss'],
})
export class ExamCenterFormPageComponent {
  @HostBinding('class.form-page-component')
  hostClass = true;
  constructor(private service: ExamCenterService) {}

  get headline(): string {
    return this.service.formState.item.id > 0
      ? 'label.edit-exam-center'
      : 'label.create-exam-center';
  }
}
