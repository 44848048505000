<form [formGroup]="form">
  <div class="form-content">
    <!-- Question #1 (current university) -->
    <div class="question-block">
      <h4>
        {{
          'booking.sdv.additional-questions-page.universityDetails.question'
            | translate
        }}
        <mat-hint>
          <em>{{
            'booking.sdv.additional-questions-page.universityDetails.explanation'
              | translate
          }}</em>
        </mat-hint>
      </h4>

      <app-form-field-text [field]="universityControl">
      </app-form-field-text>
    </div>

    <!-- Question #2 (current subject) -->
    <div class="question-block">
      <h4>
        {{
          'booking.sdv.additional-questions-page.studiesDetails.subject.question'
            | translate
        }}
        <mat-hint>
          <em>{{
            'booking.sdv.additional-questions-page.studiesDetails.subject.explanation'
              | translate
          }}</em>
        </mat-hint>
      </h4>

      <app-form-field-text [field]="studiesSubjectControl">
      </app-form-field-text>
    </div>

    <!-- Question #3 (last school) -->
    <div fxLayout="column" class="question-block">
      <h4>
        {{
          'booking.sdv.additional-questions-page.schoolDetails.question'
            | translate
        }}
        <mat-hint>
          <em>{{
            'booking.sdv.additional-questions-page.schoolDetails.explanation'
              | translate
          }}</em>
        </mat-hint>
      </h4>

      <app-form-field-text
        fxFlex="grow"
        [field]="schoolNameControl"
        [label]="
          'booking.sdv.additional-questions-page.schoolDetails.label.name'
        "
      >
      </app-form-field-text>

      <div
        fxLayout="column"
        fxLayout.gt-sm="row"
        fxLayoutGap="0"
        fxLayoutGap.gt-sm="2rem"
      >
        <app-form-field-text
          fxFlex="grow"
          [field]="schoolZipControl"
          [label]="
            'booking.sdv.additional-questions-page.schoolDetails.label.zip'
          "
        >
        </app-form-field-text>
        <app-form-field-text
          fxFlex="grow"
          [field]="schoolCityControl"
          [label]="
            'booking.sdv.additional-questions-page.schoolDetails.label.city'
          "
        >
        </app-form-field-text>
      </div>
      <div
        fxLayout="column"
        fxLayout.gt-sm="row"
        fxLayoutGap="0"
        fxLayoutGap.gt-sm="2rem"
      >
        <app-form-field-select
          fxFlex="grow"
          [field]="schoolCountryControl"
          [options]="countryOptions"
          sortBy="translated-label-asc"
          [label]="
            'booking.sdv.additional-questions-page.schoolDetails.label.country'
          "
        ></app-form-field-select>
        <app-form-field-select
          *ngIf="schoolCountryControl.value === 'DEU'"
          fxFlex="grow"
          [field]="schoolStateControl"
          [options]="federalStateOptions"
          [label]="
            'booking.sdv.additional-questions-page.schoolDetails.label.state'
          "
        ></app-form-field-select>
      </div>
    </div>

    <section class="section-bg-contrast">
      <h4>
        <strong>
          {{
            'booking.sdv.additional-questions-page.studentDetails.paragraph-1'
              | translate
          }}</strong
        >
      </h4>

      <!-- Question #4 (current semester) -->
      <div class="question-block">
        <h4>
          {{
            'booking.sdv.additional-questions-page.studiesDetails.term.question'
              | translate
          }}
          <mat-hint>
            <em>{{
              'booking.sdv.additional-questions-page.studiesDetails.term.explanation'
                | translate
            }}</em>
          </mat-hint>
        </h4>

        <app-form-field-text
          [min]="1"
          type="number"
          [field]="studiesTermControl"
        >
        </app-form-field-text>
      </div>

      <!-- Question #5 (final school grade) -->
      <div class="question-block">
        <h4>
          {{
            'booking.sdv.additional-questions-page.studentDetails.finalGrade'
              | translate
          }}
        </h4>
        <app-form-field-text [field]="finalGradeControl">
        </app-form-field-text>
      </div>

      <!-- Question #6 (final German grade) -->
      <div class="question-block">
        <h4>
          {{
            'booking.sdv.additional-questions-page.studentDetails.schoolSubjects.german'
              | translate
          }}
        </h4>
        <div
          fxLayout="column"
          fxLayout.gt-sm="row"
          fxLayoutGap="0"
          fxLayoutGap.gt-sm="2rem"
        >
          <app-form-field-text
            fxFlex="grow"
            [field]="germanGradeControl"
            [label]="
              'booking.sdv.additional-questions-page.studentDetails.schoolSubjects.grade'
            "
          >
          </app-form-field-text>
          <app-form-field-text
            fxFlex="grow"
            [field]="germanPointsControl"
            [label]="
              'booking.sdv.additional-questions-page.studentDetails.schoolSubjects.points'
            "
          >
          </app-form-field-text>
        </div>
      </div>

      <!-- Question #7 (final maths grade) -->
      <div class="question-block">
        <h4>
          {{
            'booking.sdv.additional-questions-page.studentDetails.schoolSubjects.mathematics'
              | translate
          }}
        </h4>
        <div
          fxLayout="column"
          fxLayout.gt-sm="row"
          fxLayoutGap="0"
          fxLayoutGap.gt-sm="2rem"
        >
          <app-form-field-text
            fxFlex="grow"
            [field]="mathematicsGradeControl"
            [label]="
              'booking.sdv.additional-questions-page.studentDetails.schoolSubjects.grade'
            "
          >
          </app-form-field-text>
          <app-form-field-text
            fxFlex="grow"
            [field]="mathematicsPointsControl"
            [label]="
              'booking.sdv.additional-questions-page.studentDetails.schoolSubjects.points'
            "
          >
          </app-form-field-text>
        </div>
      </div>

      <!-- Question #8 (German language level) -->
      <div class="question-block">
        <h4>
          {{
            'booking.sdv.additional-questions-page.studentDetails.germanLanguageLevel.question'
              | translate
          }}
        </h4>
        <app-form-field-select
          [field]="germanLanguageLevelControl"
          [options]="germanLanguageLevelSelectOptions"
          panelClass="max-height-unset"
        >
        </app-form-field-select>
      </div>

      <!-- Question #9 (migrational background) -->
      <div class="question-block">
        <h4>
          {{
            'booking.sdv.additional-questions-page.studentDetails.migrationBackground.question'
              | translate
          }}
          <mat-hint>
            <em>{{
              'booking.sdv.additional-questions-page.studentDetails.migrationBackground.explanation'
                | translate
            }}</em>
          </mat-hint>
        </h4>
        <app-form-field-radio
          [field]="migrationBackgroundControl"
          [options]="migrationBackgroundOptions"
          [color]="'accent'"
        ></app-form-field-radio>
      </div>
    </section>

    <section>
      <!-- Question #10 (how did you find us) -->
      <div class="question-block">
        <h4>
          {{
          'booking.sdv.additional-questions-page.applicantMarketing.question'
            | translate
          }}
          <mat-hint>
            <em>{{
              'booking.sdv.additional-questions-page.applicantMarketing.explanation'
                | translate
              }}</em>
          </mat-hint>
        </h4>

        <!-- University -->
        <div>
          <h5>
            {{
            'booking.sdv.additional-questions-page.applicantMarketing.university.title'
              | translate
            }}
          </h5>
          <app-form-field-checkbox
            [label]="
              'booking.sdv.additional-questions-page.applicantMarketing.university.informational-materials'
            "
            [field]="marketingUniversityInformationalMaterials"
            [breakLines]="true"
          ></app-form-field-checkbox>
          <app-form-field-checkbox
            [label]="
              'booking.sdv.additional-questions-page.applicantMarketing.university.event'
            "
            [field]="marketingUniversityEvent"
            [breakLines]="true"
          ></app-form-field-checkbox>
          <app-form-field-checkbox
            [label]="
              'booking.sdv.additional-questions-page.applicantMarketing.university.social-media'
            "
            [field]="marketingUniversitySocialMedia"
            [breakLines]="true"
          ></app-form-field-checkbox>
          <app-form-field-checkbox
            [label]="
              'booking.sdv.additional-questions-page.applicantMarketing.university.info-mail'
            "
            [field]="marketingUniversityInfoMail"
            [breakLines]="true"
          ></app-form-field-checkbox>
          <app-form-field-checkbox
            [label]="
              'booking.sdv.additional-questions-page.applicantMarketing.university.personal'
            "
            [field]="marketingUniversityPersonal"
            [breakLines]="true"
          ></app-form-field-checkbox>
        </div>

        <!-- School -->
        <div>
          <h5>
            {{
            'booking.sdv.additional-questions-page.applicantMarketing.school.title'
              | translate
            }}
          </h5>
          <app-form-field-checkbox
            [label]="
              'booking.sdv.additional-questions-page.applicantMarketing.school.informational-materials'
            "
            [field]="marketingSchoolInformationalMaterials"
            [breakLines]="true"
          ></app-form-field-checkbox>
          <app-form-field-checkbox
            [label]="
              'booking.sdv.additional-questions-page.applicantMarketing.school.event'
            "
            [field]="marketingSchoolEvent"
            [breakLines]="true"
          ></app-form-field-checkbox>
          <app-form-field-checkbox
            [label]="
              'booking.sdv.additional-questions-page.applicantMarketing.school.personal'
            "
            [field]="marketingSchoolPersonal"
            [breakLines]="true"
          ></app-form-field-checkbox>
        </div>

        <!-- Social Circles -->
        <div>
          <h5>
            {{
            'booking.sdv.additional-questions-page.applicantMarketing.social-circles.title'
              | translate
            }}
          </h5>

          <app-form-field-checkbox
            [label]="
              'booking.sdv.additional-questions-page.applicantMarketing.social-circles.relatives'
            "
            [field]="marketingSocialCirclesRelatives"
            [breakLines]="true"
          ></app-form-field-checkbox>
          <app-form-field-checkbox
            [label]="
              'booking.sdv.additional-questions-page.applicantMarketing.social-circles.friends-non-sponsored'
            "
            [field]="marketingSocialCirclesFriendsNonSponsored"
            [breakLines]="true"
          ></app-form-field-checkbox>
          <app-form-field-checkbox
            [label]="
              'booking.sdv.additional-questions-page.applicantMarketing.social-circles.friends-sponsored'
            "
            [field]="marketingSocialCirclesFriendsSponsored"
            [breakLines]="true"
          ></app-form-field-checkbox>
          <app-form-field-checkbox
            [label]="
              'booking.sdv.additional-questions-page.applicantMarketing.social-circles.friends-in-selection-process'
            "
            [field]="marketingSocialCirclesFriendsInSelectionProcess"
            [breakLines]="true"
          ></app-form-field-checkbox>
        </div>

        <!-- Other --->
        <div>
          <h5>
            {{
            'booking.sdv.additional-questions-page.applicantMarketing.other.title'
              | translate
            }}
          </h5>

          <app-form-field-checkbox
            [label]="
              'booking.sdv.additional-questions-page.applicantMarketing.other.event'
            "
            [field]="marketingOtherEvent"
            [breakLines]="true"
          ></app-form-field-checkbox>
          <app-form-field-checkbox
            [label]="
              'booking.sdv.additional-questions-page.applicantMarketing.other.social-media.sdv'
            "
            [field]="marketingOtherSocialMediaSdV"
            [breakLines]="true"
          ></app-form-field-checkbox>
          <app-form-field-checkbox
            [label]="
              'booking.sdv.additional-questions-page.applicantMarketing.other.homepage.sdv'
            "
            [field]="marketingOtherWebsiteSdV"
            [breakLines]="true"
          ></app-form-field-checkbox>
          <app-form-field-checkbox
            [label]="
              'booking.sdv.additional-questions-page.applicantMarketing.other.social-media.other'
            "
            [field]="marketingOtherSocialMediaOther"
            [breakLines]="true"
          ></app-form-field-checkbox>
          <app-form-field-checkbox
            [label]="
              'booking.sdv.additional-questions-page.applicantMarketing.other.homepage.efellows'
            "
            [field]="marketingOtherWebsiteEFellows"
            [breakLines]="true"
          ></app-form-field-checkbox>
          <app-form-field-checkbox
            [label]="
              'booking.sdv.additional-questions-page.applicantMarketing.other.homepage.arbeiterkind'
            "
            [field]="marketingOtherWebsiteArbeiterkind"
            [breakLines]="true"
          ></app-form-field-checkbox>
          <app-form-field-checkbox
            [label]="
              'booking.sdv.additional-questions-page.applicantMarketing.other.homepage.stipendiumplus'
            "
            [field]="marketingOtherWebsiteStipendiumPlus"
            [breakLines]="true"
          ></app-form-field-checkbox>
          <app-form-field-checkbox
            [label]="
              'booking.sdv.additional-questions-page.applicantMarketing.other.homepage.mystipendium'
            "
            [field]="marketingOtherWebsiteMyStipendium"
            [breakLines]="true"
          ></app-form-field-checkbox>
          <app-form-field-checkbox
            [label]="
              'booking.sdv.additional-questions-page.applicantMarketing.other.homepage.other'
            "
            [field]="marketingOtherWebsiteOther"
            [breakLines]="true"
          ></app-form-field-checkbox>
          <app-form-field-checkbox
            [label]="
              'booking.sdv.additional-questions-page.applicantMarketing.other.newspaper'
            "
            [field]="marketingOtherNewspaper"
            [breakLines]="true"
          ></app-form-field-checkbox>
          <div
            fxLayout="column"
            fxLayout.gt-sm="row"
            fxLayoutGap="0"
            fxLayoutGap.gt-sm="2rem"
            class="marketing-other-other"
          >
            <app-form-field-checkbox
              fxFlex="none"
              [label]="
                'booking.sdv.additional-questions-page.applicantMarketing.other.other'
              "
              [field]="marketingOtherOther"
              [breakLines]="true"
            >
            </app-form-field-checkbox>
            <app-form-field-text
              fxFlex="grow"
              *ngIf="marketingOtherOther.value"
              [field]="marketingOtherOtherText"
            ></app-form-field-text>
          </div>
        </div>
      </div>
    </section>
  </div>

  <mat-card-actions fxLayout fxLayoutAlign="space-between">
    <button
      mat-raised-button
      color="warn"
      (click)="cancelBooking()"
    >
      {{ 'button.cancel' | translate }}
    </button>
    <div>
      <button
        mat-raised-button
        matStepperPrevious
        type="button"
      >
        {{ 'button.back' | translate }}
      </button>
      <button
        mat-raised-button
        color="primary"
        type="button"
        (click)="updateBooking()"
        [disabled]="!form.valid"
      >
        {{ 'button.next' | translate }}
      </button>
    </div>
  </mat-card-actions>
</form>
