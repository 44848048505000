import {
  Component,
  HostBinding,
  ViewChild,
} from '@angular/core';
import { UserTableComponent } from 'src/app/components';
import {
  NotificationService,
  UserService,
} from '../../services';
import { ListFilter } from '../../types';

@Component({
  selector: 'app-user-page',
  templateUrl: './user-page.component.html',
  styleUrls: ['./user-page.component.scss'],
})
export class UserPageComponent {
  @HostBinding('class.table-page-component')
  hostClass = true;

  @ViewChild(UserTableComponent)
  private userTableComponent!: UserTableComponent;

  constructor(
    private userService: UserService,
    private notificationService: NotificationService
  ) {}

  get filter(): ListFilter {
    return this.userService.listState.filter;
  }

  get total(): number {
    return this.userService.listState.response.total;
  }

  get loading(): boolean {
    return this.userService.listState.loading;
  }

  get isDeleteBtn(): boolean {
    return (
      this.userTableComponent?.selection.selected.length > 0
    );
  }

  async deleteUsers(): Promise<void> {
    const userCount =
      this.userTableComponent.selection.selected.length;

    await this.notificationService
      .confirm({
        message:
          userCount == 1
            ? 'label.confirm-delete-user'
            : 'label.confirm-delete-users',
        translationParams: {
          userCount,
        },
      })
      .then((result) => {
        if (result) {
          return this.userTableComponent.deleteUsers();
        } else {
          return Promise.resolve();
        }
      });
    this.load();
  }

  load(): void {
    this.userService.loadList();
  }
}
