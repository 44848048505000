import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { RadioOption } from 'src/app/types/RadioOptions';
import { FormFieldAbstractComponent } from '../form-field-abstract.component';

@Component({
  selector: 'app-form-field-radio',
  templateUrl: './form-field-radio.component.html',
  styleUrls: ['./form-field-radio.component.scss'],
})
export class FormFieldRadioComponent extends FormFieldAbstractComponent {
  @Input() color?: ThemePalette;
  @Input() options!: RadioOption[];
  @Input() radioGroupClass!: string;
  @Output()
  change: EventEmitter<any> = new EventEmitter<any>();

  labelId: string;

  constructor() {
    super();
    this.labelId = `radio-group-label-${
      crypto?.randomUUID
        ? crypto.randomUUID()
        : Math.random()
    }`;
  }
}
