<app-loading-indicator-full *ngIf="showLoadingIndicator"></app-loading-indicator-full>
<app-register-form *ngIf="showForm" [registerInput]="registerInput"></app-register-form>
<mat-card *ngIf="showFailMessage">
  <mat-card-content>
    <p>{{'message.invite-token-validation-fail'|translate}}</p>
  </mat-card-content>
  <mat-card-actions>
    <button routerLink="/landing" mat-raised-button><span class="mdi mdi-arrow-left-thick"></span></button>
  </mat-card-actions>
</mat-card>
