import { Component, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';

export interface ConfirmDialogData {
  title?: string;
  message: string;
  translationParams?: { [key: string]: string | number };
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  constructor(
    public dialog: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData
  ) {}

  get hasTitle(): boolean {
    return String(this.data.title || '').length > 0;
  }

  get title(): string {
    return this.data.title || '';
  }

  get message(): string {
    return this.data.message;
  }

  get translationParams(): {
    [key: string]: string | number;
  } {
    return this.data.translationParams || {};
  }

  cancel(): void {
    this.dialog.close(false);
  }

  confirm(): void {
    this.dialog.close(true);
  }
}
