import { Component, Input } from '@angular/core';
import { FormFieldAbstractComponent } from '../form-field-abstract.component';

@Component({
  selector: 'app-form-field-text',
  templateUrl: './form-field-text.component.html',
  styleUrls: ['./form-field-text.component.scss'],
})
export class FormFieldTextComponent extends FormFieldAbstractComponent {
  @Input() type = 'text';
  @Input() min?: number;
  @Input() max?: number;
}
