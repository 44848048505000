<mat-dialog-content>
  <p class="message-content">
    <span>{{ errorMessage.message | translate }}</span>
    <ng-container
      *ngIf="errorMessage.eMail && errorMessage.end"
    >
      <a
        class="e-mail-link"
        href="mailto:{{ errorMessage.eMail | translate }}"
        >{{ errorMessage.eMail | translate }}</a
      ><span>{{ errorMessage.end | translate }}</span>
    </ng-container>
  </p>

  <div class="button-container flex-row flex-center">
    <mat-icon color="warn">error</mat-icon>
  </div>
</mat-dialog-content>
