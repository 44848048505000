import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TenantId } from 'src/environments/environments.types';

@Component({
  selector: 'app-forgot-password-success-dialog',
  templateUrl:
    './forgot-password-success-dialog.component.html',
  styleUrls: [
    './forgot-password-success-dialog.component.scss',
  ],
})
export class ForgotPasswordSuccessDialogComponent {
  get tenant(): TenantId {
    return environment.tenant;
  }
}
