import {
  Component,
  HostBinding,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import {
  CountryService,
  ExamLocationService,
  NotificationService,
} from 'src/app/services';
import {
  ExamLocation,
  FormValidationErrorResponse,
  RadioOption,
  SelectOption,
  Supervision,
} from 'src/app/types';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'app-exam-location-form',
  templateUrl: './exam-location-form.component.html',
  styleUrls: ['./exam-location-form.component.scss'],
})
export class ExamLocationFormComponent implements OnInit {
  @HostBinding('class.form-component') hostClass = true;
  private form!: UntypedFormGroup;

  get countryOptions(): SelectOption[] {
    return this.countryService.selectOptions;
  }

  supervisionOptions: RadioOption[] = [
    {
      value: Supervision.Testcenter,
      label: 'label.exam-location.supervision.in-person',
    },
    {
      value: Supervision.Proctoring,
      label: 'label.exam-location.supervision.proctoring',
    },
  ];

  constructor(
    private breadcrumbService: BreadcrumbService,
    private countryService: CountryService,
    private formBuilder: UntypedFormBuilder,
    private logger: NGXLogger,
    private note: NotificationService,
    private router: Router,
    private service: ExamLocationService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: [this.item.name, [Validators.required]],
      country: [this.item.country, [Validators.required]],
      hint: [this.item.description],
      supervision: [
        this.item.supervision,
        [Validators.required],
      ],
    });

    this.breadcrumbService.set(
      '@examLocationName',
      this.item.name ? this.item.name : { skip: true }
    );

    this.nameControl.valueChanges.subscribe(
      (locationName) => {
        this.breadcrumbService.set('@examLocationName', {
          label: locationName,
          skip: false,
        });
      }
    );
  }

  get item(): ExamLocation {
    return this.service.formState.item;
  }

  get nameControl(): UntypedFormControl {
    return this.form.get('name') as UntypedFormControl;
  }

  get countryControl(): UntypedFormControl {
    return this.form.get('country') as UntypedFormControl;
  }

  get hintControl(): UntypedFormControl {
    return this.form.get('hint') as UntypedFormControl;
  }

  get supervisionControl(): UntypedFormControl {
    return this.form.get(
      'supervision'
    ) as UntypedFormControl;
  }

  submit(): void {
    this.form.markAllAsTouched();
    const values = this.form.value;
    this.logger.debug('save', { values });
    if (this.form.valid) {
      this.logger.debug('is submit');
      this.item.name = this.nameControl.value;
      this.item.country = this.countryControl.value;
      this.item.description = this.hintControl.value;
      this.item.supervision = this.supervisionControl.value;
      this.service
        .save()
        .then(() => this.success())
        .catch((e: FormValidationErrorResponse) =>
          this.fail(e)
        );
    }
  }

  delete(): void {
    this.note
      .confirm({ message: 'label.confirm-delete' })
      .then((result) => {
        if (result) {
          this.service.delete(this.item.id).then(() => {
            this.router.navigate(['/home/exam-location']);
          });
        }
      });
  }

  private success(): void {
    // TODO message
    this.router.navigate(['/home/exam-location']);
  }

  private fail(errors: FormValidationErrorResponse): void {
    errors.forEach((error) => {
      const field = this.form.get(
        error.field
      ) as UntypedFormControl;
      field.setErrors({ custom: error.message });
    });
  }
}
