import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import {
  ExamCenterService,
  ExamEventService,
  ExamLocationService,
  ExamService,
} from '../services';
import { ExamCenter } from '../types';

@Injectable({
  providedIn: 'root',
})
export class InitExamCenterFormGuard
  implements CanActivate
{
  constructor(
    private service: ExamCenterService,
    private examLocationService: ExamLocationService,
    private examEventService: ExamEventService,
    private examService: ExamService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Promise<boolean>((resolve) => {
      const id = Number(route.paramMap.get('id'));
      this.service.loadById(id).then((item: ExamCenter) => {
        this.service.formState.item = item;

        const promises$: Promise<any>[] = [];
        promises$.push(
          this.examLocationService.loadAllForSelect()
        );
        promises$.push(
          this.examService.loadExamListForSelect()
        );

        this.examEventService.listState.filter.testcentersId =
          item.id;
        this.examEventService.listState.response.items = [];
        this.examEventService.listState.response.total = 0;
        promises$.push(this.examEventService.loadList());

        Promise.all(promises$).then(() => {
          resolve(true);
        });
      });
    });
  }
}
