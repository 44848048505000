import { NgxLoggerLevel } from 'ngx-logger';
import packageInfo from '../../package.json';
import { Environment } from './environments.types';

export const environment: Environment = {
  apiUrl: '',
  clientId: 'boti',
  tenant: 'sdv',
  keycloakUrl: 'https://dev.studienstiftung-test.de/auth',
  logLevel: NgxLoggerLevel.DEBUG,
  production: false,
  realm: 'boti-sdv-dev',
  version: '1.4.3-948',
  mbr: {
    enabled: false,
  },
};
