import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-header-navbar',
  templateUrl: './header-navbar.component.html',
  styleUrls: ['./header-navbar.component.scss'],
})
export class HeaderNavbarComponent {
  @Input() isAdmin!: boolean;
  @Input() containsLinks = true;
}
