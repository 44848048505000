export interface AdditionalQuestionData {
  language?: string;
  universityIds?: number[];
  sendResultsToUniversityIds?: number[];
  finalGrade?: string;
  universityDetails?: UniversityDetails;
  studiesDetails?: StudiesDetails;
  schoolDetails?: SchoolDetails;
  studentDetails?: StudentDetails;
  applicantMarketingDetails?: ApplicantMarketingDetails;
}

export interface UniversityDetails {
  name?: string;
  city?: string;
}

export interface StudiesDetails {
  graduateDegree?: string;
  subject?: string;
  subjectTerm?: number;
  typeOfDegree?: string;
  universityTerm?: number;
}

export interface SchoolDetails {
  name?: string;
  zip?: string;
  city?: string;
  state?: string;
  country?: string;
}

export interface StudentDetails {
  migrationBackground?: boolean;
  germanLanguageLevel?: LanguageLevel;
  schoolSubjects?: {
    german?: SchoolSubjectDetails;
    mathematics?: SchoolSubjectDetails;
  };
}

export enum LanguageLevel {
  A1 = 'A1',
  A2 = 'A2',
  B1 = 'B1',
  B2 = 'B2',
  C1 = 'C1',
  C2 = 'C2',
  NATIVE_SPEAKER = 'NATIVE_SPEAKER',
}

export interface SchoolSubjectDetails {
  grade?: string;
  points?: number;
}

export interface ApplicantMarketingDetails {
  university: {
    /**
     * A notice, poster, or leaflet displayed at the applicants' university.
     */
    informationalMaterials: boolean;
    /**
     * An informational event held at the applicants' university.
     */
    event: boolean;
    /**
     * Information provided on a social media channel of the applicants' university.
     */
    socialMedia: boolean;
    /**
     * A (mass) email by the applicants' university, department, or similar entity.
     */
    infoMail: boolean;
    /**
     * A lecturer or other staff member of the university provided the information personally.
     */
    personal: boolean;
  };
  school: {
    /**
     * A notice or poster displayed at the applicants' school.
     */
    informationalMaterials: boolean;
    /**
     * An informational event held at the applicants' school.
     */
    event: boolean;
    /**
     * A teacher or school management provided the information personally.
     */
    personal: boolean;
  };
  socialCircles: {
    /**
     * Parents or other relatives.
     */
    relatives: boolean;
    /**
     * Friends or fellow student, who is (currently) not being sponsored by SdV.
     */
    friendsNonSponsored: boolean;
    /**
     * Friends or fellow student, who is currently being sponsored by SdV.
     */
    friendsSponsored: boolean;
    /**
     * Friends or fellow student, who has applied for sponsorship by SdV.
     */
    friendsInSelectionProcess: boolean;
  };
  other: {
    /**
     * A university or educational fair.
     */
    event: boolean;
    socialMedia: {
      /**
       * SdV social media channels.
       */
      sdv: boolean;
      /**
       * Other social media channels (not SdV or the applicants' university).
       */
      other: boolean;
    };
    website: {
      /**
       * The SdV web page.
       */
      sdv: boolean;
      /**
       * The web page e-fellows.net.
       */
      eFellows: boolean;
      /**
       * The web page Arbeiterkind.de.
       */
      arbeiterkind: boolean;
      /**
       * The web page studiumplus.de.
       */
      stipendiumPlus: boolean;
      /**
       * The web page mystipendium.de.
       */
      myStipendium: boolean;
      /**
       * A different web page, found while researching scholarships and/or academic studies financing.
       */
      other: boolean;
    };
    /**
     * A newspaper article.
     */
    newspaper: boolean;
    /**
     * A different marketing method, not covered by any other option.
     */
    other?: string;
  };
}
