import { Component, Input } from '@angular/core';
import {
  CountryService,
  GenderService,
} from 'src/app/services';
import { Profile } from 'src/app/types';

@Component({
  selector: 'app-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss'],
})
export class ProfileCardComponent {
  @Input() profile!: Profile;

  constructor(
    private genderService: GenderService,
    private countryService: CountryService
  ) {}

  get genderLabel(): string {
    return (
      this.genderService.selectOptions.find(
        (el) => el.value === this.profile.user.gender
      )?.label ?? ''
    );
  }

  get countryLabel(): string {
    return (
      this.countryService.selectOptions.find(
        (el) => el.value === this.profile.user.country
      )?.label ?? ''
    );
  }
}
