import { Supervision } from './ExamLocation';
import { UtilizationInfo } from './UtilizationInfo';

export interface ExamEvent {
  id: number;
  logoPath: string;
  maximumCapacity: number;
  price: {
    amount: number;
    currency: 'EUR';
  };
  reducedPrice?: {
    amount: number;
    currency: 'EUR';
  };
  registerTimeFrom: string;
  registerTimeTo: string;
  supervision: Supervision;
  templateId: number;
  testarea: string;
  testcentersId: number;
  testeventTime: string;
  testId: number;
  utilizationInfo?: UtilizationInfo;
}
