import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import {
  EmailConfirmResponse,
  RegisterRequest,
  User,
} from '../../types';

@Injectable({
  providedIn: 'root',
})
export class RegisterService {
  constructor(private http: HttpClient) {}

  confirmEmail(
    token: string
  ): Promise<EmailConfirmResponse> {
    return firstValueFrom(
      this.http.get<EmailConfirmResponse>(
        `/api/confirm-email/${token}`
      )
    );
  }

  requestInvite(email: string): Promise<void> {
    return firstValueFrom(
      this.http.post<void>(`/api/email`, { email })
    );
  }

  requestForgotPassword(email: string): Promise<void> {
    return firstValueFrom(
      this.http.post<void>(`api/user/send-reset/email`, {
        email,
      })
    );
  }

  editEmail(email: string, user: User): Promise<void> {
    return firstValueFrom(
      this.http.put<void>(`/api/user/${user.id}/email`, {
        email,
      })
    );
  }

  register(
    data: RegisterRequest,
    token: string
  ): Promise<void> {
    return firstValueFrom(
      this.http.post<void>(`/api/register/${token}`, data)
    );
  }

  checkResetPasswordToken(token: string): Promise<string> {
    return firstValueFrom(
      this.http.get<string>(
        `/api/confirm-reset-email/${token}`
      )
    );
  }

  resetPassword(
    password: string,
    token: string
  ): Promise<any> {
    return firstValueFrom(
      this.http.put<any>(`/api/reset-password/${token}`, {
        password,
      })
    );
  }
}
