import {
  BreakpointObserver,
  Breakpoints,
} from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  sidenavOpened = false;
  isHandset$: Observable<boolean>;

  constructor(
    private breakpointObserver: BreakpointObserver
  ) {
    this.isHandset$ = this.breakpointObserver
      .observe([
        Breakpoints.TabletPortrait,
        Breakpoints.Handset,
      ])
      .pipe(
        map((result) => result.matches),
        shareReplay()
      );
  }

  toggleSideNav() {
    this.sidenavOpened = !this.sidenavOpened;
  }
}
