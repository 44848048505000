import { Component, Input } from '@angular/core';
import { FormFieldAbstractComponent } from '../form-field-abstract.component';

@Component({
  selector: 'app-form-field-textarea',
  templateUrl: './form-field-textarea.component.html',
  styleUrls: ['./form-field-textarea.component.scss'],
})
export class FormFieldTextareaComponent extends FormFieldAbstractComponent {
  @Input()
  type = 'text';
}
