import {
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import Tools from 'src/app/lib/Tools';
import {
  BookingService,
  ExamEventService,
  UserService,
} from 'src/app/services';
import {
  AdditionalBookingQuestionsResponse,
  EventBooking,
  ExamEvent,
  User,
} from 'src/app/types';
import { ExamBookingAddQuestionsUserAnswers } from 'src/app/types/ExamBookingAddQuestionsUserAnswers';
import { environment } from 'src/environments/environment';
import { TenantId } from 'src/environments/environments.types';

@Component({
  selector: 'app-step5-booking-overview',
  templateUrl: './step5-booking-overview.component.html',
  styleUrls: ['./step5-booking-overview.component.scss'],
})
export class Step5BookingOverviewComponent
  implements OnInit
{
  @Output() overviewFormValid: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  addQuestions!: AdditionalBookingQuestionsResponse;
  booking$!: Observable<EventBooking>;
  examEvent!: ExamEvent;
  form!: UntypedFormGroup;
  user!: User;
  userAnswers: ExamBookingAddQuestionsUserAnswers = {
    language: '',
    universityIds: [],
    sendResultsToUniversityIds: [],
    finalGrade: '',
  };

  constructor(
    private bookingService: BookingService,
    private translateService: TranslateService,
    private examEventService: ExamEventService,
    private userService: UserService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.booking$ = this.bookingService.eventBooking$;

    this.user = this.userService.formState.item;

    this.bookingService
      .loadAdditionalBookingQuestions(
        this.bookingService.formState.item.bookingId
      )
      .then((questions) => {
        this.addQuestions = questions;
        this.getUserAnswers();
        this.getExamEvent(
          this.bookingService.formState.item.testEventId
        );
      });

    this.form = this.formBuilder.group({
      confirmedTermsOfService: [
        false,
        Validators.requiredTrue,
      ],
    });

    if (this.tenant === 'sdv') {
      this.confirmedTermsOfServiceControl.clearValidators();
      this.form.updateValueAndValidity();
    }

    this.overviewFormValid.emit(this.form.valid);

    this.form.valueChanges.subscribe((_value) => {
      this.overviewFormValid.emit(this.form.valid);
    });
  }

  get tenant(): TenantId {
    return environment.tenant;
  }

  get confirmedTermsOfServiceControl(): UntypedFormControl {
    return this.form.get(
      'confirmedTermsOfService'
    ) as UntypedFormControl;
  }

  get currentLanguage(): string {
    return this.translateService.currentLang;
  }

  get timeSlot(): Date | undefined {
    if (this.examEvent) {
      return Tools.dateFromIso(
        this.examEvent.testeventTime
      );
    }
    return;
  }

  private getExamEvent(testEventId: number) {
    this.examEventService
      .loadByEventId(testEventId)
      .then((event) => (this.examEvent = event));
  }

  private getUserAnswers() {
    this.booking$.subscribe((booking) => {
      this.userAnswers.universityIds =
        this.addQuestions.universities?.filter((uni) =>
          booking.additionalQuestionData?.universityIds?.includes(
            uni.id
          )
        );

      switch (booking.test.templateName) {
        case 'TM-WISO': {
          this.userAnswers.language =
            booking.additionalQuestionData?.language ?? '';

          this.userAnswers.sendResultsToUniversityIds =
            this.addQuestions.sendResultsToUniversities?.filter(
              (uni) =>
                booking.additionalQuestionData?.sendResultsToUniversityIds?.includes(
                  uni.id
                )
            );
          break;
        }
        case 'PhaST': {
          this.userAnswers.finalGrade =
            booking.additionalQuestionData?.finalGrade;
          break;
        }
        case 'GSAT': {
          this.userAnswers.language =
            booking.additionalQuestionData?.language ?? '';
          break;
        }
        case 'ITB-Business': {
          this.userAnswers.language =
            booking.additionalQuestionData?.language ?? '';
          this.userAnswers.sendResultsToUniversityIds =
            this.addQuestions.sendResultsToUniversities?.filter(
              (uni) =>
                booking.additionalQuestionData?.sendResultsToUniversityIds?.includes(
                  uni.id
                )
            );
          this.userAnswers.finalGrade =
            booking.additionalQuestionData?.finalGrade;
          break;
        }
        case 'ITB-Science':
        case 'ITB-Technology': {
          this.userAnswers.language =
            booking.additionalQuestionData?.language ?? '';
          this.userAnswers.finalGrade =
            booking.additionalQuestionData?.finalGrade;
          break;
        }
      }
    });
  }
}
