<div class="container-content" fxLayout="column">
  <app-exam-center-table-filter></app-exam-center-table-filter>
  <app-exam-center-table></app-exam-center-table>
  <app-paginator
    [filter]="filter"
    [total]="total"
    (filterApply)="load()"
  ></app-paginator>
  <app-loading-indicator-full
    *ngIf="loading"
  ></app-loading-indicator-full>
</div>
