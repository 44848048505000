<div class="container-content" fxLayout="column">
  <app-user-table-filter></app-user-table-filter>
  <app-user-table></app-user-table>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <button
      (click)="deleteUsers()"
      mat-raised-button
      color="accent"
      id="users-delete-button"
      [style.visibility]="
        isDeleteBtn ? 'visible' : 'hidden'
      "
    >
      {{ 'button.delete' | translate }}
    </button>
    <app-paginator
      [filter]="filter"
      [total]="total"
      (filterApply)="load()"
    ></app-paginator>
  </div>
  <app-loading-indicator-full
    *ngIf="loading"
  ></app-loading-indicator-full>
</div>
