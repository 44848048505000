<mat-card-content>
  <p>
    {{ 'hint.invite-email.' + tenant | translate }}
  </p>

  <app-form-field-text
    [field]="emailControl"
    appearance="outline"
    label="label.email"
  ></app-form-field-text>
  <p class="hint-privacy-policy">
    <span>
      {{
        'hint.privacy-policy-part-1.' + tenant | translate
      }}
    </span>

    <a
      class="link"
      (click)="openDialog('privacy-terms')"
      attr.aria-label="{{
        'hint.privacy-policy-part-2-link' | translate
      }}"
      >{{
        'hint.privacy-policy-part-2-link' | translate
      }}</a
    >{{ 'hint.privacy-policy-part-3' | translate }}
  </p>
</mat-card-content>
<mat-dialog-actions>
  <div class="flex-1"></div>
  <button
    (click)="submit()"
    color="primary"
    mat-raised-button
  >
    {{ 'button.get-invite' | translate }}
  </button>
</mat-dialog-actions>
