import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-requirements-check-decline-message',
  templateUrl:
    './requirements-check-decline-message.component.html',
  styleUrls: [
    './requirements-check-decline-message.component.scss',
  ],
})
export class RequirementsCheckDeclineMessageComponent {
  @Input() question!: string;

  showInfoAboutUnfulfilledPrerequisites(): boolean {
    const schools = [
      'dual-studies.university-of-cooperative-education',
      'art-college',
      'conservatory',
    ];
    return !schools.includes(this.question);
  }

  showInfoAboutNonSelfApplication(): boolean {
    const questionsWithoutInfo = [
      'permittedToReceiveBafoeg',
      'studiesPrimarilyInGermany',
      'studiedInEuOrSwitzerland',
      'dual-studies.university-of-cooperative-education',
      'art-college',
      'conservatory',
      'formOfStudies',
      'academicStudiesCompleted',
    ];
    return !questionsWithoutInfo.includes(this.question);
  }
}
