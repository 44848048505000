import {
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { SelectOption } from 'src/app/types';

@Component({
  selector: 'app-step2-event-location-form',
  templateUrl: './step2-event-location-form.component.html',
  styleUrls: ['./step2-event-location-form.component.scss'],
})
export class Step2EventLocationFormComponent
  implements OnInit
{
  @Output() eventLocationFormValid: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  //TODO: Replace with translationKeys
  testFormOptions: SelectOption[] = [
    {
      value: 'testzentrum',
      label: 'Testzentrum',
    },
    {
      value: 'proctoring',
      label: 'Online',
    },
  ];

  private form!: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      testForm: ['', Validators.required],
    });

    // Set timeout to avoid ExpressionChangedAfterItHasBeenCheckedError in ExamBookingFormComponent
    // TODO: Find another way to update the form in the parent component
    setTimeout(() => {
      this.eventLocationFormValid.emit(this.form.valid);
    }, 0);

    this.form.valueChanges.subscribe((_value) => {
      this.eventLocationFormValid.emit(this.form.valid);
    });
  }

  get testForm(): UntypedFormControl {
    return this.form.get('testForm') as UntypedFormControl;
  }
}
