import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { ExamService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class ResetExamFormGuard implements CanActivate {
  constructor(private service: ExamService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Promise<boolean>((resolve) => {
      this.service.formState.item =
        ExamService.factoryItem();

      const promises$: Promise<any>[] = [];

      promises$.push(this.service.loadTemplateOptions());

      Promise.all(promises$).then(() => {
        resolve(true);
      });
    });
  }
}
