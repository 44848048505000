<h4 class="section-bg-contrast">
  {{ 'title.additional-questions' | translate }}
</h4>
<div
  fxLayout="column"
  fxLayout.gt-sm="row"
  fxLayoutGap="0"
  fxLayoutGap.gt-sm="2rem"
>
  <div fxFlex="grow" class="section-block">
    <p>
      <strong>
        {{
          'booking.sdv.additional-questions-page.universityDetails.question'
            | translate
        }}
      </strong>
    </p>
    <p>
      {{
        booking.additionalQuestionData?.universityDetails
          ?.name
      }}
    </p>
  </div>

  <div fxFlex="grow" class="section-block">
    <p>
      <strong>
        {{
          'booking.sdv.additional-questions-page.studiesDetails.subject.question'
            | translate
        }}
      </strong>
    </p>
    <p>
      {{
        booking.additionalQuestionData?.studiesDetails
          ?.subject
      }}
    </p>
  </div>
</div>

<div
  fxLayout="column"
  fxLayout.gt-sm="row"
  fxLayoutGap="0"
  fxLayoutGap.gt-sm="2rem"
>
  <div fxFlex="grow" class="section-block">
    <p>
      <strong>
        {{
          'booking.sdv.additional-questions-page.schoolDetails.question'
            | translate
        }}
      </strong>
    </p>
    <p>
      <span>
        {{
          booking.additionalQuestionData?.schoolDetails
            ?.name
        }}
      </span>
      <br />
      <span>
        {{
          booking.additionalQuestionData?.schoolDetails?.zip
        }}
      </span>
      <span>
        {{
          booking.additionalQuestionData?.schoolDetails
            ?.city
        }}
      </span>
      <br />
      <span>
        {{
          booking.additionalQuestionData?.schoolDetails
            ?.state
        }}
      </span>
      <span>
        {{
          booking.additionalQuestionData?.schoolDetails
            ?.country
        }}
      </span>
    </p>
  </div>
</div>
