import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { KeycloakService } from 'keycloak-angular';
import { DateTime } from 'luxon';
import { Subscription } from 'rxjs';
import {
  AppService,
  LayoutService,
} from 'src/app/services';
import { environment } from 'src/environments/environment';
import { InfoPageDialogComponent } from '../dialogs/info-page-dialog/info-page-dialog.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  tenant!: string;
  isLandingPage!: boolean;
  linkToContactPage!: string;
  yearNow!: string;

  private unsubOnDestroy: Subscription = new Subscription();

  constructor(
    private appService: AppService,
    private dialog: MatDialog,
    private layoutService: LayoutService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private keycloak: KeycloakService
  ) {}

  ngOnInit(): void {
    this.tenant = environment.tenant;

    this.yearNow = DateTime.now().toFormat('yyyy');

    this.setContactLink(this.currentLanguage);

    this.unsubOnDestroy =
      this.translateService.onLangChange.subscribe(
        (value) => {
          this.setContactLink(value.lang);
        }
      );

    this.isLandingPage =
      this.router.url.includes('landing');

    this.activatedRoute.queryParams.subscribe((params) => {
      this.keycloak.isLoggedIn().then((status) => {
        const { showPrivacy } = params;
        const showPrivacyBoolean = showPrivacy === 'true';
        if (showPrivacyBoolean && !status) {
          this.openDialog('privacy-terms');
        }
      });
    });
  }

  openDialog(pageTemplate: string): void {
    this.dialog
      .open(InfoPageDialogComponent, {
        data: pageTemplate,
        autoFocus: false,
      })
      .afterClosed()
      .subscribe(() => {
        this.activatedRoute.queryParams.subscribe(
          (oldParams) => {
            const newParams = {
              ...oldParams,
            };
            newParams.showPrivacy = undefined;

            this.router.navigate([], {
              relativeTo: this.activatedRoute,
              queryParams: newParams,
              queryParamsHandling: 'merge',
            });
          }
        );
      });
  }

  ngOnDestroy(): void {
    this.unsubOnDestroy.unsubscribe();
  }

  get currentLanguage() {
    return this.translateService.currentLang;
  }

  get isHandset$() {
    return this.layoutService.isHandset$;
  }

  get backendVersion() {
    return this.appService.versionBackend;
  }

  get versionFrontend() {
    return this.appService.versionFrontend;
  }

  private setContactLink(language: string) {
    if (language === 'de') {
      this.linkToContactPage =
        'https://itb-academic-tests.org/teilnehmer/teilnehmer-support/';
    } else {
      this.linkToContactPage =
        'https://itb-academic-tests.org/en/participants/support-participants/';
    }
  }
}
