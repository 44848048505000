import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MbrUserService } from '../../../services';

@Component({
  selector: 'app-privacy-terms-page',
  templateUrl: './privacy-terms-page.component.html',
  styleUrls: ['./privacy-terms-page.component.scss'],
})
export class PrivacyTermsPageComponent implements OnInit {
  @Input() showBreadcrumb = true;
  @Input() showMbrSpecificHighlighting = false;
  @Input() showAcceptAndRejectButtons = false;

  tenant!: string;
  private mbrEnabled!: boolean;

  constructor(
    private mbrUserService: MbrUserService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.tenant = environment.tenant;
    this.mbrEnabled = environment.mbr?.enabled ?? false;

    this.route.data.subscribe(
      ({
        showMbrSpecificHighlighting,
        showAcceptAndRejectButtons,
      }) => {
        this.showMbrSpecificHighlighting =
          showMbrSpecificHighlighting ?? false;
        this.showAcceptAndRejectButtons =
          showAcceptAndRejectButtons ?? false;
      }
    );
  }

  get showThirdPartyMbrParagraph(): boolean {
    if (this.tenant !== 'default') {
      return false;
    }
    return this.mbrEnabled;
  }

  async acceptPrivacyTerms(): Promise<void> {
    await this.mbrUserService.requestFirstAndLastNameFromWallet();
    await this.router.navigate([
      '/mbr-registration/retrieve-personal-data',
    ]);
  }

  async rejectPrivacyTerms(): Promise<void> {
    await this.mbrUserService.deleteUserWithIncompleteSetup();
  }
}
