<form [formGroup]="form">
  <div class="form-content">
    <section class="info-section">
      <!-- block: personal -->
      <div class="section-block">
        <div class="form-block">
          <app-form-field-text
            [field]="emailControl"
            label="label.email"
          ></app-form-field-text>
          <app-form-field-text
            [field]="firstnameControl"
            label="label.firstname"
            [required]="
              firstnameControl | hasRequiredValidator
            "
          ></app-form-field-text>
          <app-form-field-text
            [field]="lastnameControl"
            label="label.lastname"
            [required]="
              lastnameControl | hasRequiredValidator
            "
          ></app-form-field-text>
          <app-form-field-text
            [field]="birthNameControl"
            label="label.birthname"
            [required]="
              birthNameControl | hasRequiredValidator
            "
          ></app-form-field-text>
          <app-form-field-select
            [field]="genderControl"
            [options]="genderOptions"
            label="gender.label"
            [required]="
              genderControl | hasRequiredValidator
            "
          ></app-form-field-select>
        </div>
        <div class="form-block">
          <app-form-field-date
            [field]="birthDateControl"
            label="label.birthDate"
            [required]="
              birthDateControl | hasRequiredValidator
            "
          >
          </app-form-field-date>
          <app-form-field-text
            [field]="birthLocationControl"
            label="label.birthLocation"
            [required]="
              birthLocationControl | hasRequiredValidator
            "
          ></app-form-field-text>
          <app-form-field-text
            [field]="phoneControl"
            label="label.phone"
            [required]="phoneControl | hasRequiredValidator"
          ></app-form-field-text>
          <app-form-field-text
            [field]="email2Control"
            label="label.email2"
            [required]="
              email2Control | hasRequiredValidator
            "
          ></app-form-field-text>
        </div>
      </div>

      <!-- block: address -->
      <h4>{{ 'label.address' | translate }}</h4>
      <div class="section-block">
        <div class="form-block">
          <app-form-field-text
            [field]="streetControl"
            label="label.street"
            [required]="
              streetControl | hasRequiredValidator
            "
          ></app-form-field-text>
          <app-form-field-text
            [field]="streetNumberControl"
            label="label.street-number"
            [required]="
              streetNumberControl | hasRequiredValidator
            "
          ></app-form-field-text>
          <app-form-field-text
            [field]="addressAdditionControl"
            label="label.addressAddition"
            [required]="
              addressAdditionControl | hasRequiredValidator
            "
          ></app-form-field-text>
          <app-form-field-text
            [field]="postalCodeControl"
            label="label.postalCode"
            [required]="
              postalCodeControl | hasRequiredValidator
            "
          ></app-form-field-text>
        </div>
        <div class="form-block">
          <app-form-field-text
            [field]="cityControl"
            label="label.city"
            [required]="cityControl | hasRequiredValidator"
          ></app-form-field-text>
          <app-form-field-text
            [field]="stateControl"
            label="label.state"
            [required]="stateControl | hasRequiredValidator"
          ></app-form-field-text>
          <app-form-field-select
            [field]="countryControl"
            [options]="countryOptions"
            sortBy="translated-label-asc"
            label="label.country"
            [required]="
              countryControl | hasRequiredValidator
            "
          ></app-form-field-select>
        </div>
      </div>

      <!-- block: parental address -->
      <ng-container *ngIf="tenant === 'sdv'">
        <div class="form-radio-field">
          <app-form-field-radio
            [field]="parentalAddressesRadioControl"
            [options]="parentalAddressesOptions"
            [color]="'accent'"
            label="userInfo.parental-addresses.radio-btn.label"
          >
          </app-form-field-radio>
        </div>
        <div class="section-block">
          <ng-container
            *ngIf="parentalAddressesRadioControl.value"
            formArrayName="parentalAddresses"
          >
            <ng-container
              *ngFor="
                let parentalAddressFormGroup of parentalAddressesFormArray.controls
              "
            >
              <app-parental-address-form
                [parentalAddressFormGroup]="
                  parentalAddressFormGroup
                "
              ></app-parental-address-form>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </section>

    <!-- block: options -->
    <div class="form-block">
      <div class="options">
        <app-form-field-checkbox
          [field]="roleAdminControl"
          label="label.role-admin"
        ></app-form-field-checkbox>
        <app-form-field-checkbox
          [field]="roleCandidateControl"
          label="label.role-candidate"
        ></app-form-field-checkbox>
        <!-- Not in use yet: BOTI-306
           <div class="btn-edit">
          <button
            *ngIf="!isCreateMode"
            (click)="editEmail()"
            mat-raised-button
          >
            {{ 'label.edit-email' | translate }}
          </button>
        </div> -->
        <div>
          <button
            (click)="resetPassword()"
            mat-raised-button
          >
            {{ 'label.reset-password' | translate }}
          </button>
        </div>
      </div>
      <div class="bookings">
        <div class="bookings-header">
          <h2 class="mat-headline">
            {{ 'title.bookings' | translate }}
          </h2>
          <div class="show-inactive-bookings">
            <mat-checkbox
              [checked]="showDeletedBookings"
              (change)="showDeletedBookings = $event.checked"
            >
              {{ 'label.user-details.bookings.show-inactive' | translate }}
            </mat-checkbox>
          </div>
        </div>
        <ng-container *ngIf="!isLoading">
          <app-dashboard-booking-card
            *ngFor="let booking of filteredBookings"
            [booking]="booking"
            [routerLink]="'booking'"
          ></app-dashboard-booking-card>
        </ng-container>
        <div class="container-relative flex-1">
          <app-loading-indicator-full
            *ngIf="isLoading"
          ></app-loading-indicator-full>
        </div>
      </div>
    </div>
  </div>

  <div class="buttons">
    <button [routerLink]="['/home/user']" mat-raised-button>
      {{ 'button.cancel' | translate }}
    </button>
    <div class="flex-1"></div>
    <button
      (click)="delete()"
      mat-raised-button
      color="warn"
      [disabled]="!canDelete"
    >
      {{ 'button.delete' | translate }}
    </button>
    <button
      (click)="submit()"
      mat-raised-button
      color="accent"
      [disabled]="form.invalid"
    >
      {{ 'button.save' | translate }}
    </button>
  </div>
</form>
