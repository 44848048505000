import { Component, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { AlertDialogData } from '../../../types';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss'],
})
export class AlertDialogComponent {
  constructor(
    public dialog: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AlertDialogData
  ) {}

  close(): void {
    this.dialog.close();
  }

  get hasTitle(): boolean {
    return String(this.data.title || '').length > 0;
  }

  get title(): string {
    return this.data.title as string;
  }

  get message(): string {
    return this.data.message as string;
  }
}
