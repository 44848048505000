<div
  class="page-content-cards container-content"
  *ngIf="!isLoading"
  fxLayout="column"
>
  <xng-breadcrumb [separator]="separatorIconTemplate">
    <ng-container
      *xngBreadcrumbItem="
        let breadcrumb;
        let info = info;
        let first = first
      "
    >
      <mat-icon *ngIf="info">{{ info }}</mat-icon>
      <ng-container *ngIf="!first">{{
        breadcrumb | translate
      }}</ng-container>
    </ng-container>
  </xng-breadcrumb>

  <ng-template #separatorIconTemplate>
    <mat-icon>arrow_right</mat-icon>
  </ng-template>

  <div class="page-block" fxFlexAlign="center">
    <app-dashboard-booking-card
      *ngFor="let booking of bookings"
      [booking]="booking"
      [routerLink]="'/home/bookings/summary'"
    ></app-dashboard-booking-card>
  </div>
</div>

<app-loading-indicator-full
  *ngIf="isLoading"
></app-loading-indicator-full>
