<div class="plain-footer">
  <ul>
    <li class="link-container">
      <a
        *ngIf="!isLandingPage; else imprintDialog"
        routerLink="/home/imprint"
        routerLinkActive="active-link"
        attr.aria-label="{{ 'label.imprint' | translate }}"
      >
        <mat-icon *ngIf="isHandset$ | async"
          >maps_home_work</mat-icon
        >
        <span *ngIf="(isHandset$ | async) === false">{{
          'label.imprint' | translate
        }}</span>
      </a>
      <ng-template #imprintDialog>
        <a
          (click)="openDialog('imprint')"
          attr.aria-label="{{
            'label.imprint' | translate
          }}"
        >
          <mat-icon *ngIf="isHandset$ | async"
            >home</mat-icon
          >
          <span *ngIf="(isHandset$ | async) === false">{{
            'label.imprint' | translate
          }}</span>
        </a>
      </ng-template>
    </li>
    <li class="link-container" *ngIf="tenant === 'default'">
      <a
        *ngIf="!isLandingPage; else termsOfServiceDialog"
        routerLink="/home/terms-of-service"
        routerLinkActive="active-link"
        attr.aria-label="{{
          'label.terms-of-service-short' | translate
        }}"
      >
        <mat-icon *ngIf="isHandset$ | async"
          >menu_book</mat-icon
        >
        <span *ngIf="(isHandset$ | async) === false">{{
          'label.terms-of-service-short' | translate
        }}</span>
      </a>
      <ng-template #termsOfServiceDialog>
        <a
          (click)="openDialog('terms-of-service')"
          attr.aria-label="{{
            'label.terms-of-service-short' | translate
          }}"
        >
          <mat-icon *ngIf="isHandset$ | async"
            >menu_book</mat-icon
          >
          <span *ngIf="(isHandset$ | async) === false">{{
            'label.terms-of-service-short' | translate
          }}</span></a
        >
      </ng-template>
    </li>
    <li class="link-container">
      <a
        *ngIf="!isLandingPage; else privacyTermsDialog"
        routerLink="/home/privacy-terms"
        routerLinkActive="active-link"
        attr.aria-label="{{
          'label.privacy-terms' | translate
        }}"
      >
        <mat-icon *ngIf="isHandset$ | async"
          >policy</mat-icon
        >
        <span *ngIf="(isHandset$ | async) === false">{{
          'label.privacy-terms' | translate
        }}</span>
      </a>
      <ng-template #privacyTermsDialog>
        <a
          (click)="openDialog('privacy-terms')"
          attr.aria-label="{{
            'label.privacy-terms' | translate
          }}"
        >
          <mat-icon *ngIf="isHandset$ | async"
            >policy</mat-icon
          >
          <span *ngIf="(isHandset$ | async) === false">{{
            'label.privacy-terms' | translate
          }}</span></a
        >
      </ng-template>
    </li>
    <li class="link-container">
      <a
        *ngIf="!isLandingPage; else systemRequirements"
        routerLink="/home/system-requirements"
        routerLinkActive="active-link"
        attr.aria-label="{{
          'label.system-requirements' | translate
        }}"
      >
        <mat-icon *ngIf="isHandset$ | async"
          >devices</mat-icon
        >
        <span *ngIf="(isHandset$ | async) === false">{{
          'label.system-requirements' | translate
        }}</span>
      </a>
      <ng-template #systemRequirements>
        <a
          (click)="openDialog('system-requirements')"
          attr.aria-label="{{
            'label.system-requirements' | translate
          }}"
        >
          <mat-icon *ngIf="isHandset$ | async"
            >devices</mat-icon
          >
          <span *ngIf="(isHandset$ | async) === false">{{
            'label.system-requirements' | translate
          }}</span></a
        >
      </ng-template>
    </li>
    <li class="link-container">
      <ng-container [ngSwitch]="tenant">
        <a
          *ngSwitchCase="'default'"
          href="{{ linkToContactPage }}"
          attr.aria-label="{{
            'label.contact' | translate
          }}"
          target="contact-page"
          rel="noopener noreferrer"
        >
          <mat-icon *ngIf="isHandset$ | async"
            >alternate_email</mat-icon
          >
          <span *ngIf="(isHandset$ | async) === false">{{
            'label.contact' | translate
          }}</span>
        </a>
        <ng-container *ngSwitchCase="'sdv'">
          <a
            *ngIf="!isLandingPage; else contactSdv"
            routerLink="/home/contact"
            routerLinkActive="active-link"
            attr.aria-label="{{
              'label.contact' | translate
            }}"
          >
            <mat-icon *ngIf="isHandset$ | async"
              >alternate_email</mat-icon
            >
            <span *ngIf="(isHandset$ | async) === false">{{
              'label.contact' | translate
            }}</span>
          </a>
          <ng-template #contactSdv>
            <a
              (click)="openDialog('contact-sdv')"
              attr.aria-label="{{
                'label.contact' | translate
              }}"
            >
              <mat-icon *ngIf="isHandset$ | async"
                >alternate_email</mat-icon
              >
              <span
                *ngIf="(isHandset$ | async) === false"
                >{{ 'label.contact' | translate }}</span
              ></a
            >
          </ng-template>
        </ng-container>
      </ng-container>
    </li>
  </ul>
  <div
    class="link-container"
    [ngClass]="{
      copyright: (isHandset$ | async) === false,
      'copyright-mobile': isHandset$ | async
    }"
    matTooltip="Backend: {{ backendVersion }},
    Frontend: {{ versionFrontend }}"
    matTooltipClass="tooltip"
  >
    © <span>{{ yearNow }}</span> ITB Consulting
  </div>
</div>
