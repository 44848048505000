<mat-form-field
  [appearance]="appearance"
  [class.disabled]="disabled"
>
  <mat-label *ngIf="hasLabel">{{
    label | translate
  }}</mat-label>
  <mat-select
    [formControl]="field"
    placeholder="{{ placeholder | translate }}"
    [multiple]="multiple"
    (selectionChange)="selectionChange.emit($event)"
  >
    <ng-container
      *ngIf="!isProctoringOption; else withOptionGroups"
    >
      <mat-option
        *ngFor="let option of options"
        [value]="option.value"
      >
        {{ option.label | translate }}
      </mat-option>
    </ng-container>
    <ng-template #withOptionGroups>
      <mat-optgroup
        *ngFor="let group of optionGroups"
        [label]="group.groupName | translate"
      >
        <mat-option
          *ngFor="let option of group.options"
          [value]="option.value"
        >
          {{ option.label | translate }}
        </mat-option>
      </mat-optgroup>
    </ng-template>
  </mat-select>

  <mat-hint *ngIf="hasHint">{{
    hint | translate
  }}</mat-hint>

  <mat-error>{{ error | translate }}</mat-error>
</mat-form-field>
