import { Component, Input } from '@angular/core';
import { FormFieldAbstractComponent } from '../form-field-abstract.component';

@Component({
  selector: 'app-form-field-date',
  templateUrl: './form-field-date.component.html',
  styleUrls: ['./form-field-date.component.scss'],
})
export class FormFieldDateComponent extends FormFieldAbstractComponent {
  @Input()
  minDate?: Date = undefined;

  @Input()
  maxDate?: Date = undefined;

  get hasValue(): boolean {
    return String(this.field.value || '').length > 0;
  }

  clear(): void {
    this.field.setValue(null);
  }
}
