<mat-dialog-content>
  <app-imprint-page
    *ngIf="template === 'imprint'"
    [showBreadcrumb]="false"
  ></app-imprint-page>
  <app-terms-of-service-page
    *ngIf="template === 'terms-of-service'"
    [showBreadcrumb]="false"
  ></app-terms-of-service-page>
  <app-privacy-terms-page
    *ngIf="template === 'privacy-terms'"
    [showBreadcrumb]="false"
  >
  </app-privacy-terms-page>
  <app-system-requirements-page
    *ngIf="template === 'system-requirements'"
    [showBreadcrumb]="false"
  >
  </app-system-requirements-page>
  <app-contact-page
    *ngIf="template === 'contact-sdv'"
    [showBreadcrumb]="false"
  >
  </app-contact-page>
  <app-consent-proctoring-page
    *ngIf="template === 'consent-proctoring-sdv'"
    [showBreadcrumb]="false"
  >
  </app-consent-proctoring-page>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-stroked-button
    mat-dialog-close
    color="primary"
  >
    {{ 'button.close' | translate }}
  </button>
</mat-dialog-actions>
