<mat-card *ngIf="!registrationSuccessful">
  <mat-card-content>
    <app-form-field-text
      [field]="emailControl"
      label="label.email"
    ></app-form-field-text>
    <app-form-field-text
      [field]="firstnameControl"
      label="label.firstname"
    ></app-form-field-text>
    <app-form-field-text
      [field]="lastnameControl"
      label="label.lastname"
    ></app-form-field-text>
    <app-form-field-password
      [field]="passwordControl"
      label="label.password"
    ></app-form-field-password>
    <app-form-field-password
      [field]="passwordRepeatControl"
      label="label.passwordRepeat"
    ></app-form-field-password>
    <app-form-field-checkbox-gdpr
      class="spacerBottom"
      [field]="confirmedGDPRControl"
    ></app-form-field-checkbox-gdpr>
  </mat-card-content>
  <mat-card-actions>
    <div class="buttons">
      <button
        (click)="submit()"
        mat-raised-button
        color="primary"
      >
        {{ 'label.register' | translate }}
      </button>
    </div>
  </mat-card-actions>
</mat-card>
<mat-card *ngIf="registrationSuccessful">
  <mat-card-content>
    <div class="flex-row">
      <div class="flex-1">
        {{ 'message.registration-successful' | translate }}
      </div>
      <button
        class="to-login-button"
        (click)="login()"
        mat-button
      >
        {{ 'button.to-login' | translate }}
      </button>
    </div>
  </mat-card-content>
</mat-card>
