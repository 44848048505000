import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services';
import { User } from '../types';

@Injectable({
  providedIn: 'root',
})
export class CandidateFormGuard implements CanActivate {
  constructor(
    private service: UserService,
    private route: ActivatedRoute
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Promise<boolean>((resolve) => {
      this.route.params.subscribe((params) => {
        const mockUserId = 1; // TODO load by profile of logged in user
        this.service
          .loadById(mockUserId)
          .then((item: User) => {
            this.service.formState.item = item;
            resolve(true);
          });
      });
    });
  }
}
