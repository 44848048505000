import {
  HttpClient,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import {
  BehaviorSubject,
  firstValueFrom,
  Observable,
} from 'rxjs';
import {
  AddBookingDetailsRequest,
  AddBookingDetailsResponse,
  AdditionalBookingQuestionsResponse,
  BookingCancellationInfoResponse,
  BookingCancellationResponse,
  CancellationBookingRequest,
  ChangeExamEventRequest,
  ChangeExamEventResponse,
  CreateBookingResponse,
  DoPaymentRequest,
  EventBooking,
  EventBookingFileInfo,
  EventBookingHistoryEvents,
  FormState,
  InfotextResponse,
  ListFilter,
  ListResponse,
  ListState,
  PrerequisiteCheckResponse,
  PrerequisiteQuestionData,
} from 'src/app/types';

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  listState: ListState<EventBooking, ListFilter> = {
    loading: false,
    response: {
      items: [],
      total: 0,
    },
    filter: {
      search: '',
      page: 0,
      size: 10,
      sort: 'name',
      direction: 'asc',
    },
  };

  formState: FormState<EventBooking> = {
    loading: false,
    item: BookingService.factoryItem(),
  };

  private readonly eventBooking =
    new BehaviorSubject<EventBooking>(
      BookingService.factoryItem()
    );
  readonly eventBooking$: Observable<EventBooking> =
    this.eventBooking.asObservable();

  constructor(
    private logger: NGXLogger,
    private http: HttpClient,
    private translateService: TranslateService
  ) {}

  static factoryItem(): EventBooking {
    return {
      bookingId: 0,
      bookingReference: '',
      deleted: false,
      paymentExpectedUntil: '',
      reservedUntil: '',
      test: {
        id: 0,
        name: 'VERY IMPORTANT TEST',
        templateName: 'PhaST',
        logoPath: '',
      },
      testEventId: 0,
      status: 'open',
      userId: 0,
    };
  }

  loadBookingsList(
    userId: number,
    includes: {
      incompleteBookings?: boolean;
      deletedBookings?: boolean;
    } = {}
  ): Promise<ListResponse<EventBooking>> {
    this.listState.loading = true;

    const url = `api/bookings/user/${userId}`;
    const promise$: Promise<ListResponse<EventBooking>> =
      firstValueFrom(
        this.http.get<ListResponse<EventBooking>>(url, {
          params: {
            includeIncomplete:
              !!includes.incompleteBookings,
            includeDeleted: !!includes.deletedBookings,
          },
        })
      );

    promise$.then(
      (response) => (this.listState.response = response)
    );

    promise$.finally(
      () => (this.listState.loading = false)
    );
    return promise$;
  }

  loadBookingById(
    bookingId: number,
    options?: { loadDeletedBookings: boolean }
  ): Promise<EventBooking> {
    this.listState.loading = true;
    const url = `api/bookings/booking/${bookingId}`;

    let params = new HttpParams();

    if (options?.loadDeletedBookings) {
      params = params.set(
        'showDeleted',
        `${options?.loadDeletedBookings}`
      );
    }

    const promise$: Promise<EventBooking> = firstValueFrom(
      this.http.get<EventBooking>(url, { params })
    );

    promise$.then(
      (response) => (this.formState.item = response)
    );

    promise$.finally(
      () => (this.listState.loading = false)
    );
    return promise$;
  }

  createBooking(): Promise<CreateBookingResponse> {
    this.listState.loading = true;

    const { prerequisiteQuestionId, testEventId, userId } =
      this.formState.item;

    const promise$: Promise<CreateBookingResponse> =
      firstValueFrom(
        this.http.post<CreateBookingResponse>(
          '/api/bookings',
          { prerequisiteQuestionId, testEventId, userId }
        )
      );

    promise$.then((response) => {
      const newBooking = {
        ...this.formState.item,
        ...response,
      };
      this.formState.item = newBooking;
      this.eventBooking.next(newBooking);
    });

    promise$.finally(
      () => (this.listState.loading = false)
    );

    return promise$;
  }

  updateBooking(
    bookingId: number
  ): Promise<AddBookingDetailsResponse> {
    this.listState.loading = true;

    const body: AddBookingDetailsRequest = {
      bookingId: this.formState.item.bookingId,
      userId: this.formState.item.userId,
      testEventId: this.formState.item.testEventId,
      prerequisiteQuestionId:
        this.formState.item.prerequisiteQuestionId,
      priceCalculationData:
        this.formState.item.priceCalculationData,
      additionalQuestionData:
        this.formState.item.additionalQuestionData,
    };

    const promise$: Promise<AddBookingDetailsResponse> =
      firstValueFrom(
        this.http.patch<AddBookingDetailsResponse>(
          `/api/bookings/booking/${bookingId}`,
          body
        )
      );

    promise$.then((response) => {
      const updatedBooking = {
        ...this.formState.item,
        ...response,
      };
      this.formState.item = updatedBooking;
      this.eventBooking.next(updatedBooking);
    });

    promise$.finally(
      () => (this.listState.loading = false)
    );

    return promise$;
  }

  deleteBooking(bookingId: number): Promise<void> {
    return firstValueFrom(
      this.http.delete<void>(
        `/api/bookings/booking/${bookingId}`
      )
    );
  }

  cancellationInfo(
    bookingId: number
  ): Promise<BookingCancellationInfoResponse> {
    this.listState.loading = true;

    const url = `api/bookings/booking/${bookingId}/cancellationInfo`;

    const promise$: Promise<BookingCancellationInfoResponse> =
      firstValueFrom(
        this.http.get<BookingCancellationInfoResponse>(url)
      );

    promise$.finally(
      () => (this.listState.loading = false)
    );

    return promise$;
  }

  cancellationBooking(
    bookingId: number,
    body: CancellationBookingRequest
  ): Promise<BookingCancellationResponse> {
    this.listState.loading = true;
    const url = `api/bookings/booking/${bookingId}/cancellation`;

    const promise$: Promise<BookingCancellationResponse> =
      firstValueFrom(
        this.http.put<BookingCancellationResponse>(
          url,
          body
        )
      );

    promise$.finally(
      () => (this.listState.loading = false)
    );

    return promise$;
  }

  changeTestEvent(
    bookingId: number,
    body: ChangeExamEventRequest
  ) {
    this.listState.loading = true;

    const url = `api/bookings/booking/${bookingId}/testEvent`;

    const promise$ = firstValueFrom(
      this.http.put<ChangeExamEventResponse>(url, body)
    );

    promise$.finally(
      () => (this.listState.loading = false)
    );

    return promise$;
  }

  loadAdditionalBookingQuestions(
    bookingId: number,
    options?: { loadDeletedBookings: boolean }
  ): Promise<AdditionalBookingQuestionsResponse> {
    let params = new HttpParams();

    if (options?.loadDeletedBookings) {
      params = params.set(
        'showDeleted',
        `${options?.loadDeletedBookings}`
      );
    }

    return firstValueFrom(
      this.http.get<AdditionalBookingQuestionsResponse>(
        `/api/bookings/booking/${bookingId}/additionalQuestions`,
        { params }
      )
    );
  }

  doPayment(bookingId: number): Promise<any> {
    this.listState.loading = true;

    const body: DoPaymentRequest = {
      lang: this.currentLanguage,
    };

    const promise$ = firstValueFrom(
      this.http.post<any>(
        `/api/bookings/booking/${bookingId}/payment`,
        body,
        { observe: 'response' }
      )
    );

    promise$
      .then((response) => {
        if (response?.status === (201 || 200)) {
          const redirectLink =
            response.headers.get('location');
          this.logger.debug(
            `Payment for booking ${bookingId} successful, redirecting to ${redirectLink}.`
          );
          if (redirectLink) {
            window.location.href = decodeURI(redirectLink);
          }
        }
      })
      .catch((err) => {
        this.logger.error(
          `Error while initiating payment for booking ${bookingId}.`,
          err
        );
      });

    promise$.finally(
      () => (this.listState.loading = false)
    );

    return promise$;
  }

  loadBookingHistoryEventsByBookingId(
    bookingId: number
  ): Promise<ListResponse<EventBookingHistoryEvents>> {
    return firstValueFrom(
      this.http.get<
        ListResponse<EventBookingHistoryEvents>
      >(`/api/bookings/booking/${bookingId}/history`)
    );
  }

  loadBookingFilesByBookingId(
    bookingId: number
  ): Promise<ListResponse<EventBookingFileInfo>> {
    return firstValueFrom(
      this.http.get<ListResponse<EventBookingFileInfo>>(
        `/api/bookings/booking/${bookingId}/files`
      )
    );
  }

  getBookingFileByFileKey(
    bookingId: number,
    fileKey: string
  ): Promise<HttpResponse<Blob>> {
    const url = `/api/bookings/booking/${bookingId}/files/file`;

    return firstValueFrom(
      this.http.get(url, {
        params: { fileKey },
        observe: 'response',
        responseType: 'blob',
      })
    );
  }

  uploadFileForBooking(
    file: File,
    relativePath: string,
    bookingId: number
  ): Promise<EventBookingFileInfo> {
    const formData = new FormData();
    formData.append('file', file, relativePath);

    const url = `/api/bookings/booking/${bookingId}/files`;
    return firstValueFrom(
      this.http.post<EventBookingFileInfo>(url, formData)
    );
  }

  deleteFileForBooking(
    bookingId: number,
    fileKey: string
  ): Promise<HttpResponse<void>> {
    const url = `/api/bookings/booking/${bookingId}/files/file`;
    return firstValueFrom(
      this.http.delete<void>(url, {
        params: { fileKey },
        observe: 'response',
      })
    );
  }

  checkPrerequisitesForBooking(
    testId: number,
    body: PrerequisiteQuestionData
  ): Promise<PrerequisiteCheckResponse> {
    this.listState.loading = true;

    const url = `api/bookings/test/${testId}/prerequisitesCheck`;

    const promise$: Promise<PrerequisiteCheckResponse> =
      firstValueFrom(
        this.http.post<PrerequisiteCheckResponse>(url, body)
      );

    promise$.then(({ prerequisiteDataId }) => {
      this.formState.item.prerequisiteQuestionId =
        prerequisiteDataId;
    });

    promise$.finally(
      () => (this.listState.loading = false)
    );

    return promise$;
  }

  getInfotextForCompletedBooking(
    bookingId: number
  ): Promise<InfotextResponse> {
    const url = `api/bookings/booking/${bookingId}/infotext`;

    return firstValueFrom(
      this.http.get<InfotextResponse>(url)
    );
  }

  get currentLanguage(): string {
    return this.translateService.currentLang;
  }
}
