<div class="container-content" fxLayout="column">
  <ng-container *ngIf="showBreadcrumb">
    <xng-breadcrumb [separator]="separatorIconTemplate">
      <ng-container
        *xngBreadcrumbItem="
          let breadcrumb;
          let info = info;
          let first = first
        "
      >
        <mat-icon *ngIf="info">{{ info }}</mat-icon>
        <ng-container *ngIf="!first">{{
          breadcrumb | translate
        }}</ng-container>
      </ng-container>
    </xng-breadcrumb>

    <ng-template #separatorIconTemplate>
      <mat-icon>arrow_right</mat-icon>
    </ng-template>
  </ng-container>

  <h1 class="mat-headline">
    {{ 'label.imprint' | translate }}
  </h1>

  <ng-container [ngSwitch]="tenant">
    <div *ngSwitchCase="'default'">
      <ng-container *ngTemplateOutlet="address">
      </ng-container>

      <p>
        {{
          'imprint.default.responsibility.part-1'
            | translate
        }}
        <br />
        {{
          'imprint.default.responsibility.part-2'
            | translate
        }}
        <br />
        {{
          'imprint.default.responsibility.part-3'
            | translate
        }}
      </p>

      <p>
        <b
          >{{ 'imprint.default.ceo.label' | translate }}
          {{ 'imprint.default.ceo.name' | translate }}</b
        >
      </p>

      <p>
        {{ 'imprint.default.legal.part-1' | translate }}
        <br />
        {{ 'imprint.default.legal.part-2' | translate }}
      </p>

      <p>
        {{ 'imprint.default.tax.name' | translate }}
        <br />
        {{ 'imprint.default.tax.number' | translate }}
      </p>
    </div>

    <div *ngSwitchCase="'sdv'">
      <ng-container *ngTemplateOutlet="address">
      </ng-container>

      <p>
        <a
          href="{{
            'imprint.sdv.address.web.link' | translate
          }}"
          target="_blank"
          >{{
            'imprint.sdv.address.web.label' | translate
          }}</a
        >
      </p>
    </div>
  </ng-container>

  <ng-template #address>
    <address>
      <p>
        {{
          'imprint.' + tenant + '.address.name' | translate
        }}
        <br />
        {{
          'imprint.' + tenant + '.address.street-and-number'
            | translate
        }}
        <br />
        {{
          'imprint.' + tenant + '.address.zip-code-and-city'
            | translate
        }}
      </p>

      <p>
        <span>{{
          'imprint.' + tenant + '.address.telephone.label'
            | translate
        }}</span
        >:
        <a
          href="tel:{{
            'imprint.' +
              tenant +
              '.address.telephone.clean-number' | translate
          }}"
          >{{
            'imprint.' +
              tenant +
              '.address.telephone.number' | translate
          }}</a
        >
        <br />
        <span>{{
          'imprint.' + tenant + '.address.telefax.label'
            | translate
        }}</span
        >:
        <span>{{
          'imprint.' + tenant + '.address.telefax.number'
            | translate
        }}</span>
        <br />
        <span>{{
          'imprint.' + tenant + '.address.e-mail.label'
            | translate
        }}</span
        >:
        <a
          href="mailto:{{
            'imprint.' + tenant + '.address.e-mail.address'
              | translate
          }}"
          >{{
            'imprint.' + tenant + '.address.e-mail.address'
              | translate
          }}</a
        >
      </p>
    </address>
  </ng-template>
</div>
