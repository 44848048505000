export type TextFormattingType =
  | 'BOLD'
  | 'ITALIC'
  | 'UNDERLINED';

export interface HeadingResponseBlock {
  type: 'HEADING';
  value: string;
}

export interface TextResponseBlock {
  type: 'TEXT';
  value: string;
  formatting?: TextFormattingType[];
}

export interface LinkResponseBlock {
  type: 'LINK';
  value: string;
  link: string;
  formatting?: TextFormattingType[];
}

export interface LineBreakBlock {
  type: 'LINE_BREAK';
  value: '';
}

export type ResponseBlock =
  | HeadingResponseBlock
  | TextResponseBlock
  | LinkResponseBlock
  | LineBreakBlock;

export interface InfotextResponse {
  blocks?: ResponseBlock[] | null;
}
