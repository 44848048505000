import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AppInitializerService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class MbrPrivacyTermsGuard implements CanActivate {
  constructor(private initService: AppInitializerService) {}

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Promise<boolean>((resolve) =>
      this.initService
        .init()
        .then(() => this.initService.resolveMbrStatus())
        .then((mbrStatus) => {
          resolve(
            !!mbrStatus.setupCompleted &&
              (!mbrStatus.isMbrUser ||
                mbrStatus.confirmedGDPR)
          );
        })
    );
  }
}
