import {
  Component,
  HostBinding,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { BreadcrumbService } from 'xng-breadcrumb';
import {
  ExamCenterService,
  ExamEventService,
  ExamLocationService,
  NotificationService,
} from '../../../services';
import { CountryService } from '../../../services/country/country.service';
import {
  ExamCenter,
  ExamEvent,
  FormValidationErrorResponse,
  SelectOption,
} from '../../../types';

@Component({
  selector: 'app-exam-center-form',
  templateUrl: './exam-center-form.component.html',
  styleUrls: ['./exam-center-form.component.scss'],
})
export class ExamCenterFormComponent implements OnInit {
  @HostBinding('class.form-component') hostClass = true;
  showEventTable = true;

  get examLocationSelectOptions(): SelectOption[] {
    return this.examLocationService.selectOptions;
  }

  private form!: UntypedFormGroup;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private countryService: CountryService,
    private examEventService: ExamEventService,
    private examLocationService: ExamLocationService,
    private formBuilder: UntypedFormBuilder,
    private logger: NGXLogger,
    private note: NotificationService,
    private router: Router,
    private service: ExamCenterService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: [this.item.name, [Validators.required]],
      examLocation: [
        this.item.testareaId,
        [Validators.required],
      ],
      maxCapacity: [
        this.item.maximumCapacity,
        [Validators.required],
      ],
      street: [this.item.street, [Validators.required]],
      streetNumber: [
        this.item.streetNumber,
        [Validators.required],
      ],
      additionalAddressInfo: [
        this.item.additionalAddressInfo,
      ],
      postalCode: [this.item.zip, [Validators.required]],
      city: [this.item.city, [Validators.required]],
      state: [this.item.state, [Validators.required]], //
      provider: [this.item.provider, [Validators.required]],
      country: [this.item.country, [Validators.required]],
      hint: [this.item.additionalInformation],
    });

    this.breadcrumbService.set(
      '@examCenterName',
      this.item.name ? this.item.name : { skip: true }
    );

    this.nameControl.valueChanges.subscribe(
      (centerName) => {
        this.breadcrumbService.set('@examCenterName', {
          label: centerName,
          skip: false,
        });
      }
    );
  }

  get showEventBlock(): boolean {
    return this.item.id > 0;
  }

  get countryOptions(): SelectOption[] {
    return this.countryService.selectOptions;
  }
  get item(): ExamCenter {
    return this.service.formState.item;
  }
  get nameControl(): UntypedFormControl {
    return this.form.get('name') as UntypedFormControl;
  }
  get examLocationControl(): UntypedFormControl {
    return this.form.get(
      'examLocation'
    ) as UntypedFormControl;
  }
  get maxCapacityControl(): UntypedFormControl {
    return this.form.get(
      'maxCapacity'
    ) as UntypedFormControl;
  }
  get streetControl(): UntypedFormControl {
    return this.form.get('street') as UntypedFormControl;
  }
  get streetNumberControl(): UntypedFormControl {
    return this.form.get(
      'streetNumber'
    ) as UntypedFormControl;
  }
  get additionalAddressInfoControl(): UntypedFormControl {
    return this.form.get(
      'additionalAddressInfo'
    ) as UntypedFormControl;
  }
  get postalCodeControl(): UntypedFormControl {
    return this.form.get(
      'postalCode'
    ) as UntypedFormControl;
  }
  get cityControl(): UntypedFormControl {
    return this.form.get('city') as UntypedFormControl;
  }
  get stateControl(): UntypedFormControl {
    return this.form.get('state') as UntypedFormControl;
  }
  get providerControl(): UntypedFormControl {
    return this.form.get('provider') as UntypedFormControl;
  }
  get countryControl(): UntypedFormControl {
    return this.form.get('country') as UntypedFormControl;
  }
  get hintControl(): UntypedFormControl {
    return this.form.get('hint') as UntypedFormControl;
  }

  examEventClicked(examEvent: ExamEvent): void {
    this.examEventService.formState.item = examEvent;
    this.showEventTable = false;
  }

  examEventCreateClicked(): void {
    this.examEventService.formState.item =
      ExamEventService.factoryItem();
    this.showEventTable = false;
  }

  examFormClosed(): void {
    this.examEventService.formState.item =
      ExamEventService.factoryItem();
    this.examEventService.loadList();
    this.showEventTable = true;
  }

  submit(): void {
    this.form.markAllAsTouched();
    const values = this.form.value;
    this.logger.debug('save', { values });
    if (this.form.valid) {
      this.logger.debug('is submit');
      this.item.name = this.nameControl.value;
      this.item.testareaId = this.examLocationControl.value;
      this.item.maximumCapacity =
        this.maxCapacityControl.value;
      this.item.street = this.streetControl.value;
      this.item.streetNumber =
        this.streetNumberControl.value;
      this.item.additionalAddressInfo =
        this.additionalAddressInfoControl.value;
      this.item.zip = this.postalCodeControl.value;
      this.item.city = this.cityControl.value;
      this.item.state = this.stateControl.value;
      this.item.provider = this.providerControl.value;
      this.item.country = this.countryControl.value;
      this.item.additionalInformation =
        this.hintControl.value;
      this.service
        .save()
        .then(() => this.success())
        .catch((e: FormValidationErrorResponse) =>
          this.fail(e)
        );
    }
  }

  delete(): void {
    this.note
      .confirm({ message: 'label.confirm-delete' })
      .then((result) => {
        if (result) {
          this.service.delete(this.item.id).then(() => {
            this.router.navigate(['/home/exam-center']);
          });
        }
      });
  }

  private success(): void {
    // TODO message
    this.router.navigate(['/home/exam-center']);
  }

  private fail(errors: FormValidationErrorResponse): void {
    errors.forEach((error) => {
      const field = this.form.get(
        error.field
      ) as UntypedFormControl;
      field.setErrors({ custom: error.message });
    });
  }
}
