import { Component, HostBinding } from '@angular/core';
import { ExamLocationService } from '../../services';

@Component({
  selector: 'app-exam-location-form-page',
  templateUrl: './exam-location-form-page.component.html',
  styleUrls: ['./exam-location-form-page.component.scss'],
})
export class ExamLocationFormPageComponent {
  @HostBinding('class.form-page-component')
  hostClass = true;
  constructor(private service: ExamLocationService) {}

  get headline(): string {
    return this.service.formState.item.id > 0
      ? 'label.exam-location.edit'
      : 'label.exam-location.create';
  }
}
