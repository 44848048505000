import {
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { TenantId } from '../../../environments/environments.types';
import {
  AppService,
  LayoutService,
  ProfileService,
} from '../../services';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DefaultLayoutComponent {
  constructor(
    private app: AppService,
    private layout: LayoutService,
    private profileService: ProfileService
  ) {}

  toggleSideNav(): void {
    this.layout.toggleSideNav();
  }

  get backendVersion(): string {
    return this.app.versionBackend;
  }

  get versionFrontend(): string {
    return this.app.versionFrontend;
  }

  get isHandset$(): Observable<boolean> {
    return this.layout.isHandset$;
  }

  get sidenavOpened(): boolean {
    return this.layout.sidenavOpened;
  }

  get isAdmin(): boolean {
    return this.profileService.isAdmin;
  }

  get tenant(): TenantId {
    return environment.tenant;
  }
}
