<div class="section-block-bg-contrast">
  <div
    *ngIf="
      booking.additionalQuestionData?.studiesDetails
        ?.universityTerm ||
      booking.additionalQuestionData?.finalGrade
    "
    fxLayout="column"
    fxLayout.gt-sm="row"
    fxLayoutGap="0"
    fxLayoutGap.gt-sm="2rem"
  >
    <div
      *ngIf="booking.additionalQuestionData?.studiesDetails?.universityTerm"
      fxFlex="grow"
      class="section-block"
    >
      <p>
        <strong>
          {{
          'booking.sdv.additional-questions-page.studiesDetails.term.question'
            | translate
          }}
        </strong>
      </p>
      <p>
        {{
        booking.additionalQuestionData?.studiesDetails?.universityTerm
        }}
      </p>
    </div>

    <div
      *ngIf="booking.additionalQuestionData?.finalGrade"
      fxFlex="grow"
      class="section-block"
    >
      <p>
        <strong>
          {{
            'booking.sdv.additional-questions-page.studentDetails.finalGrade'
              | translate
          }}
        </strong>
      </p>
      <p>
        {{ booking.additionalQuestionData?.finalGrade }}
      </p>
    </div>
  </div>

  <div
    *ngIf="
      booking.additionalQuestionData?.studentDetails
        ?.schoolSubjects?.german?.grade ||
      booking.additionalQuestionData?.studentDetails
        ?.schoolSubjects?.german?.points ||
      booking.additionalQuestionData?.studentDetails
        ?.schoolSubjects?.mathematics?.grade ||
      booking.additionalQuestionData?.studentDetails
        ?.schoolSubjects?.mathematics?.points
    "
    fxLayout="column"
    fxLayout.gt-sm="row"
    fxLayoutGap="0"
    fxLayoutGap.gt-sm="2rem"
  >
    <div
      *ngIf="
      booking.additionalQuestionData?.studentDetails
        ?.schoolSubjects?.german?.grade ||
      booking.additionalQuestionData?.studentDetails
        ?.schoolSubjects?.german?.points
    "
      fxFlex="grow"
      class="section-block"
    >
      <p>
        <strong>
          {{
          'booking.sdv.additional-questions-page.studentDetails.schoolSubjects.german'
            | translate
          }}
        </strong>
      </p>

      <div
        fxLayout="column"
        fxLayout.gt-sm="row"
        fxLayoutGap="0"
        fxLayoutGap.gt-sm="2rem"
      >
        <div
          *ngIf="
          booking.additionalQuestionData?.studentDetails
            ?.schoolSubjects?.german?.grade
        "
          fxFlex="grow"
          class="section-block"
        >
        <span>
          {{
          'booking.sdv.additional-questions-page.studentDetails.schoolSubjects.label.grade'
            | translate
          }}:
        </span>
          <span>
          {{
            booking.additionalQuestionData?.studentDetails
              ?.schoolSubjects?.german?.grade
            }}</span
          >
        </div>

        <div
          *ngIf="
          booking.additionalQuestionData?.studentDetails
            ?.schoolSubjects?.german?.points
        "
          fxFlex="grow"
          class="section-block"
        >
        <span
        >{{
          'booking.sdv.additional-questions-page.studentDetails.schoolSubjects.label.points'
            | translate
          }}:</span
        >
          <span>
          {{
            booking.additionalQuestionData?.studentDetails
              ?.schoolSubjects?.german?.points
            }}</span
          >
        </div>
      </div>
    </div>

    <div
      *ngIf="
      booking.additionalQuestionData?.studentDetails
        ?.schoolSubjects?.mathematics?.grade ||
      booking.additionalQuestionData?.studentDetails
        ?.schoolSubjects?.mathematics?.points
    "
      fxFlex="grow"
      class="section-block"
    >
      <p>
        <strong>
          {{
          'booking.sdv.additional-questions-page.studentDetails.schoolSubjects.mathematics'
            | translate
          }}
        </strong>
      </p>

      <div
        fxLayout="column"
        fxLayout.gt-sm="row"
        fxLayoutGap="0"
        fxLayoutGap.gt-sm="2rem"
      >
        <div
          *ngIf="
          booking.additionalQuestionData?.studentDetails
            ?.schoolSubjects?.mathematics?.grade
        "
          fxFlex="grow"
          class="section-block"
        >
        <span>
          {{
          'booking.sdv.additional-questions-page.studentDetails.schoolSubjects.label.grade'
            | translate
          }}:
        </span>
          <span>
          {{
            booking.additionalQuestionData?.studentDetails
              ?.schoolSubjects?.mathematics?.grade
            }}</span
          >
        </div>

        <div
          *ngIf="
          booking.additionalQuestionData?.studentDetails
            ?.schoolSubjects?.mathematics?.points
        "
          fxFlex="grow"
          class="section-block"
        >
        <span>
          {{
          'booking.sdv.additional-questions-page.studentDetails.schoolSubjects.label.points'
            | translate
          }}:
        </span>
          <span>
          {{
            booking.additionalQuestionData?.studentDetails
              ?.schoolSubjects?.mathematics?.points
            }}</span
          >
        </div>
      </div>
    </div>

  </div>

  <div
    fxLayout="column"
    fxLayout.gt-sm="row"
    fxLayoutGap="0"
    fxLayoutGap.gt-sm="2rem"
  >
    <div
      *ngIf="
        booking.additionalQuestionData?.studentDetails
          ?.germanLanguageLevel
      "
      fxFlex="grow"
      class="section-block"
    >
      <p>
        <strong>
          {{
            'booking.sdv.additional-questions-page.studentDetails.germanLanguageLevel.question'
              | translate
          }}
        </strong>
      </p>
      <p>
        {{ getLanguageLevelLabel | translate }}
      </p>
    </div>

    <div
      *ngIf="
        booking.additionalQuestionData?.studentDetails
          ?.migrationBackground !== undefined
      "
      fxFlex="grow"
      class="section-block"
    >
      <p>
        <strong>
          {{
            'booking.sdv.additional-questions-page.studentDetails.migrationBackground.question'
              | translate
          }}
        </strong>
      </p>
      <p>
        {{
          booking.additionalQuestionData?.studentDetails
            ?.migrationBackground
            ? ('booking.sdv.additional-questions-page.studentDetails.migrationBackground.options.yes'
              | translate)
            : ('booking.sdv.additional-questions-page.studentDetails.migrationBackground.options.no'
              | translate)
        }}
      </p>
    </div>
  </div>
</div>
