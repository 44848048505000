<mat-card
  [routerLink]="['/home/registrations/exam', exam.id]"
  mat-ripple
  [matRippleDisabled]="
    !isExamEvents ||
    isBookingExist ||
    isTooLateForBooking ||
    isTooEarlyForBooking
  "
  [class.disabled]="
    !isExamEvents ||
    isBookingExist ||
    isTooLateForBooking ||
    isTooEarlyForBooking
  "
>
  <mat-card-content fxLayoutAlign="space-between">
    <div class="container-text" fxLayout="column">
      <h2>{{ examName }}</h2>
      <ng-container *ngIf="isExamEvents && !isBookingExist">
        <ng-container
          *ngIf="
            !isTooLateForBooking && !isTooEarlyForBooking
          "
        >
          <p
            [innerHTML]="
              'message.booking-register-timespan'
                | translate
                  : {
                      dateStart:
                        registerDateFrom
                        | localizedDate: 'mediumDate',
                      timeStart:
                        registerDateFrom
                        | localizedDate: 'shortTime',
                      dateEnd:
                        registerDateTo
                        | localizedDate: 'mediumDate',
                      timeEnd:
                        registerDateTo
                        | localizedDate: 'shortTime'
                    }
            "
          ></p>
        </ng-container>

        <ng-container *ngIf="isTooLateForBooking">
          <p
            [innerHTML]="
              'message.booking-is-not-possible-too-late'
                | translate
                  : {
                      date:
                        registerDateTo
                        | localizedDate: 'mediumDate',
                      time:
                        registerDateTo
                        | localizedDate: 'shortTime'
                    }
            "
          ></p>
          <div class="hint">
            {{
              'message.booking-is-not-possible'
                | translate
                | uppercase
            }}
          </div>
        </ng-container>

        <ng-container *ngIf="isTooEarlyForBooking">
          <p
            [innerHTML]="
              'message.booking-is-not-possible-too-early'
                | translate
                  : {
                      date:
                        registerDateFrom
                        | localizedDate: 'mediumDate',
                      time:
                        registerDateFrom
                        | localizedDate: 'shortTime'
                    }
            "
          ></p>
          <div class="hint">
            {{
              'message.booking-is-not-possible'
                | translate
                | uppercase
            }}
          </div>
        </ng-container>
      </ng-container>

      <div class="hint" *ngIf="isBookingExist">
        {{
          'message.booking-already-made'
            | translate
            | uppercase
        }}
      </div>

      <div
        class="hint"
        *ngIf="!isBookingExist && !isExamEvents"
      >
        {{
          'message.booking-no-events-available'
            | translate
            | uppercase
        }}
      </div>
    </div>

    <div class="container-img" fxFlexAlign="center">
      <img
        *ngIf="srcLogo"
        [src]="srcLogo"
        [alt]="examName"
      />
    </div>
  </mat-card-content>
</mat-card>
