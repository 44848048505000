import {
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InfoPageDialogComponent } from 'src/app/components';
import { FormFieldAbstractComponent } from 'src/app/lib/form-fields/form-field-abstract.component';

@Component({
  selector: 'app-form-field-checkbox-gdpr',
  templateUrl: './form-field-checkbox-gdpr.component.html',
  styleUrls: ['./form-field-checkbox-gdpr.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormFieldCheckboxGdprComponent extends FormFieldAbstractComponent {
  constructor(private dialog: MatDialog) {
    super();
  }

  preventClick(e: Event) {
    e.preventDefault();
    e.stopPropagation();
  }

  openDialog(pageTemplate: string): void {
    this.dialog.open(InfoPageDialogComponent, {
      data: pageTemplate,
      autoFocus: false,
    });
  }

  get checkBoxLabel(): string {
    return this.label;
  }

  get showError(): boolean {
    return this.field.touched && !this.field.valid;
  }

  get hasHint(): boolean {
    return null !== this.hint && !this.showError;
  }
}
