import { Component, Input } from '@angular/core';
import { EventBooking } from 'src/app/types';

@Component({
  selector: 'app-add-questions-answers-overview',
  templateUrl:
    './add-questions-answers-overview.component.html',
  styleUrls: [
    './add-questions-answers-overview.component.scss',
  ],
})
export class AddQuestionsAnswersOverviewComponent {
  @Input() booking!: EventBooking;
}
