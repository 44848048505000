import {
  AbstractControl,
  UntypedFormControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export class PasswordValidator {
  static syntax(
    control: UntypedFormControl
  ): ValidationErrors | null {
    const value = control.value; // ensure string

    if (/\p{Cc}/gu.test(value)) {
      return {
        custom: 'field.error.password-has-control-chars',
      } as ValidationErrors;
    }

    if (value.length < 8) {
      return {
        custom: 'field.error.password-too-short',
      } as ValidationErrors;
    }

    if (!/\d/.test(value)) {
      return {
        custom: 'field.error.password-has-no-number',
      } as ValidationErrors;
    }
    if (!/[a-z]/.test(value) && !/\p{Ll}/gu.test(value)) {
      return {
        custom:
          'field.error.password-has-no-lowercase-letter',
      } as ValidationErrors;
    }
    if (!/[A-Z]/.test(value) && !/\p{Lu}/gu.test(value)) {
      return {
        custom:
          'field.error.password-has-no-uppercase-letter',
      } as ValidationErrors;
    }

    return null;
  }

  static sameAsPassword(
    passwordControl: UntypedFormControl
  ): ValidatorFn {
    return (
      control: AbstractControl
    ): ValidationErrors | null => {
      if (control.value !== passwordControl.value) {
        return {
          custom: 'field.error.repeat-password',
        } as ValidationErrors;
      }

      return null;
    };
  }
}
