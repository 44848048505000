import { UtilizationInfo } from '../UtilizationInfo';

export interface ChangeExamEventResponse {
  booking: {
    test: {
      id: number;
      name: string;
      logoPath: string;
    };
    bookingId: number;
    userId: number;
    testEventId: number;
    reservedUntil: string;
    additionalQuestionData: {
      universityIds: number[];
      finalGrade: string;
    };
    status: string;
    paymentExpectedUntil: string;
    bookingReference: string;
  };
  oldTestEvent: UtilizationInfo;
  newTestEvent: UtilizationInfo;
}
