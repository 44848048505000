export interface Exam {
  id: number;
  testname: string;
  templateName: string;
  logoPath: string;
  registrationPeriodStart: string;
  registrationPeriodEnd?: string;
  validityPeriodStart: string;
  validityPeriodEnd?: string;
  priceInEuro?: number;
  existingBooking?: {
    bookingReference: string;
    status: string;
    testArea: string;
    testEventTime: string;
  };
  resultReportTemplateId?: string;
  invitationTemplateId?: string;
}
