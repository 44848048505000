import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { TenantId } from '../../../environments/environments.types';
import { LayoutService } from '../../services';

@Component({
  selector: 'app-plain-layout',
  templateUrl: './plain-layout.component.html',
  styleUrls: ['./plain-layout.component.scss'],
})
export class PlainLayoutComponent {
  constructor(private layout: LayoutService) {}

  get isHandset$(): Observable<boolean> {
    return this.layout.isHandset$;
  }

  get tenant(): TenantId {
    return environment.tenant;
  }
}
