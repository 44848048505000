import { Component, Input, OnChanges } from '@angular/core';
import {
  CurrencyMaskConfig,
  CurrencyMaskInputMode,
} from 'ngx-currency';
import { FormFieldAbstractComponent } from '../form-field-abstract.component';

@Component({
  selector: 'app-form-field-monetary-amount',
  templateUrl:
    './form-field-monetary-amount.component.html',
  styleUrls: [
    './form-field-monetary-amount.component.scss',
  ],
})
export class FormFieldMonetaryAmountComponent
  extends FormFieldAbstractComponent
  implements OnChanges
{
  @Input()
  currency = 'EUR';

  @Input()
  decimalPlaces = 2;

  options: CurrencyMaskConfig = {
    align: 'right',
    allowNegative: false,
    allowZero: true,
    decimal: ',',
    precision: this.decimalPlaces,
    prefix: '',
    suffix: ' ' + this.currency,
    thousands: '.',
    nullable: false,
    inputMode: CurrencyMaskInputMode.NATURAL,
  };

  ngOnChanges() {
    this.options = {
      ...this.options,
      suffix: ` ${this.currency}`,
      precision: this.decimalPlaces,
    };
  }
}
