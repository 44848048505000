<form>
  <mat-card class="stepper">
    <mat-stepper
      #stepper
      labelPosition="bottom"
      linear
      (selectionChange)="selectionEvent($event)"
    >
      <mat-step
        [completed]="profileCheckFormValid"
        color="primary"
      >
        <ng-template matStepContent>
          <mat-card-title>{{
            'label.profile-check' | translate
          }}</mat-card-title>
          <app-step1-profile-check-form
            (profileCheckFormValid)="
              setProfileCheckFormValid($event)
            "
            (nextStep)="nextStep($event)"
          ></app-step1-profile-check-form>
        </ng-template>
      </mat-step>

      <mat-step
        *ngIf="hasEventRelevantQuestions"
        [completed]="eventLocationFormValid"
        color="primary"
      >
        <ng-template matStepContent>
          <!-- TODO: The content of this step is not defined yet -->
          <mat-card-title>{{
            'label.test-pre-check' | translate
          }}</mat-card-title>
          <app-step2-event-location-form
            (eventLocationFormValid)="
              setEventLocationFormValid($event)
            "
          ></app-step2-event-location-form>
        </ng-template>
      </mat-step>

      <mat-step
        [completed]="timeSlotFormValid"
        [id]="'test-event-select'"
        color="primary"
      >
        <ng-template matStepContent>
          <mat-card-title>
            {{ 'label.test-event-select' | translate }}
          </mat-card-title>
          <app-step3-test-time-form
            (timeSlotFormValid)="
              setTimeSlotFormValid($event)
            "
            (nextStep)="nextStep($event)"
            [stepId]="stepId"
          ></app-step3-test-time-form>
        </ng-template>
      </mat-step>

      <mat-step
        [completed]="additionalQuestionsFormValid"
        color="primary"
      >
        <ng-template matStepContent>
          <mat-card-title>
            {{ 'title.additional-questions' | translate }}
          </mat-card-title>
          <app-step4-additional-questions-form
            (additionalQuestionsFormValid)="
              setAdditionalQuestionsFormValid($event)
            "
            (nextStep)="nextStep($event)"
          ></app-step4-additional-questions-form>
        </ng-template>
      </mat-step>

      <mat-step
        [completed]="overviewFormValid"
        color="primary"
      >
        <ng-template matStepContent>
          <mat-card-title>
            {{ 'title.summary' | translate }}
          </mat-card-title>
          <app-step5-booking-overview
            (overviewFormValid)="
              setOverviewFormValid($event)
            "
          >
          </app-step5-booking-overview>

          <ng-container
            *ngIf="isHandset$ | async; else desktopLayout"
          >
            <mat-card-actions class="btn-group-mobile">
              <button
                [disabled]="
                  !overviewFormValid || isPayBtnClicked
                "
                mat-raised-button
                color="primary"
                (click)="doPayment()"
                class="btn-pay-now"
              >
                {{ 'button.pay-now' | translate }}
              </button>
              <div>
                <button
                  mat-raised-button
                  matStepperPrevious
                  type="button"
                >
                  {{ 'button.back' | translate }}
                </button>
                <button
                  mat-raised-button
                  color="warn"
                  (click)="deleteBooking()"
                >
                  {{ 'button.cancel' | translate }}
                </button>
              </div>
            </mat-card-actions>
          </ng-container>

          <ng-template #desktopLayout>
            <mat-card-actions
              class="flex-row flex-jc-space-between"
            >
              <div>
                <button
                  mat-raised-button
                  matStepperPrevious
                  type="button"
                >
                  {{ 'button.back' | translate }}
                </button>
                <button
                  mat-raised-button
                  color="warn"
                  (click)="deleteBooking()"
                >
                  {{ 'button.cancel' | translate }}
                </button>
              </div>
              <button
                [disabled]="
                  !overviewFormValid || isPayBtnClicked
                "
                mat-raised-button
                color="accent"
                (click)="doPayment()"
                class="btn-pay-now"
              >
                {{ 'button.pay-now' | translate }}
              </button>
            </mat-card-actions>
          </ng-template>
        </ng-template>
      </mat-step>
    </mat-stepper>
  </mat-card>
</form>
