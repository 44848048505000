import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialsModule } from '../materials/materials.module';
import {
  AlertDialogComponent,
  ConfirmDialogComponent,
  ErrorDialogComponent,
  ErrorHttpDialogComponent,
  SuccessDialogComponent,
} from '../notification';

@NgModule({
  declarations: [
    AlertDialogComponent,
    ConfirmDialogComponent,
    SuccessDialogComponent,
    ErrorDialogComponent,
    ErrorHttpDialogComponent,
  ],
  imports: [
    CommonModule,
    MaterialsModule,
    TranslateModule.forChild({ isolate: false }),
  ],
  exports: [AlertDialogComponent, ConfirmDialogComponent],
})
export class NotificationModule {}
