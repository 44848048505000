import { Injectable, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  RouterStateSnapshot,
  TitleStrategy,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class TemplatePageTitleStrategy
  extends TitleStrategy
  implements OnDestroy
{
  private subscription?: Subscription;

  constructor(
    private readonly title: Title,
    private translateService: TranslateService
  ) {
    super();
  }

  updateTitle(snapshot: RouterStateSnapshot): void {
    this.subscription = this.translateService
      .stream(`title.${environment.tenant}.application`)
      .subscribe((title) => this.title.setTitle(title));
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
