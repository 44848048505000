import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import Tools from 'src/app/lib/Tools';
import {
  BookingService,
  ExamEventService,
  NotificationService,
  UserService,
} from 'src/app/services';
import {
  EventBooking,
  ExamEvent,
  SelectOption,
} from 'src/app/types';
import { environment } from 'src/environments/environment';
import { TenantId } from 'src/environments/environments.types';

interface TimeSlotForm {
  testLocation: string;
  testDate: string;
  testTime: string;
  examEventChoosen: string;
}

@Component({
  selector: 'app-step3-test-time-form',
  templateUrl: './step3-test-time-form.component.html',
  styleUrls: ['./step3-test-time-form.component.scss'],
})
export class Step3TestTimeFormComponent
  implements OnInit, OnChanges
{
  @HostBinding('class.form-component') hostClass = true;
  @Output() timeSlotFormValid: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() nextStep: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Input() stepId!: string;

  form!: UntypedFormGroup;
  isBookingCreated = false;
  optionsDate: SelectOption[] = [];
  optionsLocation: SelectOption[] = [];
  optionsTime: SelectOption[] = [
    {
      label: 'option.morning',
      value: 'morning',
    },
    {
      label: 'option.morning',
      value: 'afternoon',
    },
  ];
  protected testId!: number;

  private examEventsFullList!: ExamEvent[];
  private selectedLocations: string[] = [];
  private selectedDates: string[] = [];
  private selectedTimes: string[] = [];

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected bookingService: BookingService,
    protected examEventService: ExamEventService,
    protected formBuilder: UntypedFormBuilder,
    protected logger: NGXLogger,
    protected notificationService: NotificationService,
    protected userService: UserService
  ) {}

  ngOnChanges(): void {
    if (this.testId && this.stepId === 'testEventSelect') {
      this.loadEvents(this.testId);
    }
  }

  ngOnInit(): void {
    this.testId = Number(
      this.activatedRoute.snapshot.paramMap.get('id')
    );

    this.form = this.formBuilder.group({
      testLocation: [''],
      testDate: [''],
      testTime: [['morning', 'afternoon']],
      examEventChoosen: ['', Validators.required],
    });

    // Set timeout to avoid ExpressionChangedAfterItHasBeenCheckedError in ExamBookingFormComponent
    // TODO: Find another way to update the form in the parent component
    setTimeout(() => {
      this.timeSlotFormValid.emit(this.form.valid);
    }, 0);

    this.form.valueChanges.subscribe(
      (_value: TimeSlotForm) => {
        this.timeSlotFormValid.emit(this.form.valid);
      }
    );

    this.loadEvents(this.testId);
  }

  createBooking(): void {
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      return;
    } else if (
      this.bookingService.formState.item.testEventId ===
        this.examEventChoosen.value &&
      this.isBookingCreated
    ) {
      // This is the case where we:
      // - select an event
      // - navigate forward
      // - navigate back to this step
      // - do NOT select a different event
      // - proceed from this step once again
      // In this case, we don't have to notify the backend to create a new booking.
      this.nextStep.emit(true);
    } else {
      this.bookingService.formState.item =
        BookingService.factoryItem();
      this.booking.userId =
        this.userService.formState.item.id;
      this.booking.testEventId =
        this.examEventChoosen.value;

      this.bookingService
        .createBooking()
        .catch(async (error: HttpErrorResponse) => {
          this.isBookingCreated = false;
          this.nextStep.emit(false);

          if (error.status === 409) {
            // This will happen when the last seat at this specific
            // timeslot in the chosen testarea has been booked
            // since the list of test events was loaded.
            const errorNotice =
              this.notificationService.error(
                `booking.${this.tenant}.error-message.event-overbooked`
              );
            return Promise.all([
              errorNotice,
              this.loadEvents(this.testId),
            ]).finally(() => Promise.reject(error));
          } else {
            return await this.fail(error);
          }
        })
        .then(() => {
          this.isBookingCreated = true;
          this.nextStep.emit(true);
        });
    }
  }

  get booking(): EventBooking {
    return this.bookingService.formState.item;
  }

  get examEventsFiltered(): ExamEvent[] {
    return (this.examEventsFullList || [])
      .filter((event) => this.locationFilter(event))
      .filter((event) => this.dateFilter(event))
      .filter((event) => this.timeFilter(event));
  }

  get examEventChoosen(): UntypedFormControl {
    return this.form.get(
      'examEventChoosen'
    ) as UntypedFormControl;
  }

  get testLocation(): UntypedFormControl {
    return this.form.get(
      'testLocation'
    ) as UntypedFormControl;
  }

  get testDate(): UntypedFormControl {
    return this.form.get('testDate') as UntypedFormControl;
  }

  get testTime(): UntypedFormControl {
    return this.form.get('testTime') as UntypedFormControl;
  }

  get loading(): boolean {
    return this.examEventService.listState.loading;
  }

  protected async loadEvents(
    testId: number
  ): Promise<void> {
    this.examEventService.listState.filter =
      ExamEventService.resetFilter();
    this.examEventService.listState.filter.testId = testId;
    this.examEventService.listState.filter.includeEvent =
      this.bookingService.formState.item.testEventId;

    await this.examEventService.loadList();

    this.examEventService
      .loadByExamId(testId)
      .then((examEvents) => {
        this.examEventsFullList =
          this.fixTestEventTimeFormat(examEvents);

        this.setOptionsDate(this.examEventsFullList);
        this.setOptionsLocation(this.examEventsFullList);
        this.setOptionsTime(this.examEventsFullList);

        //Initial state: all testDates AND testLocations are checked
        this.testDate.patchValue([
          ...this.examEventsFullList.map((examEvent) => {
            const shortDate =
              this.convertDateToShortDateString(
                examEvent.testeventTime
              );
            return shortDate;
          }),
        ]);

        this.testLocation.patchValue([
          ...this.examEventsFullList.map(
            (examEvent) => examEvent.testarea
          ),
        ]);

        //Value Changes
        this.testLocation.valueChanges.subscribe(
          (value) => {
            this.selectedLocations = value;
            this.checkIfChosenEventNotDisplayed(
              this.examEventsFiltered
            );
          }
        );

        this.testDate.valueChanges.subscribe((value) => {
          this.selectedDates = value;
          this.checkIfChosenEventNotDisplayed(
            this.examEventsFiltered
          );
        });

        this.testTime.valueChanges.subscribe((value) => {
          this.selectedTimes = value;
          this.checkIfChosenEventNotDisplayed(
            this.examEventsFiltered
          );
        });

        if (
          this.bookingService.formState.item.testEventId
        ) {
          this.examEventChoosen.setValue(
            this.bookingService.formState.item.testEventId
          );
        }
      });
  }

  private setOptionsLocation(
    examEvents: ExamEvent[]
  ): void {
    this.optionsLocation = [];
    this.selectedLocations = [];
    this.examEventsSort(examEvents).forEach((event) => {
      this.optionsLocation.push({
        value: event.testarea,
        label: event.testarea,
        supervision: event.supervision,
      });
      this.selectedLocations.push(event.testarea);
    });

    //Remove duplicates and sort
    this.optionsLocation = this.removeDuplicatesAndSort(
      this.optionsLocation,
      'asc'
    );
  }

  private setOptionsDate(examEvents: ExamEvent[]): void {
    this.optionsDate = [];
    this.selectedDates = [];
    this.examEventsSort(examEvents).forEach((event) => {
      const shortDate = this.convertDateToShortDateString(
        event.testeventTime
      );
      this.optionsDate.push({
        value: shortDate,
        label: event.testeventTime,
      });
      this.selectedDates.push(shortDate);
    });

    //Remove duplicates and sort
    this.optionsDate = this.removeDuplicatesAndSort(
      this.optionsDate,
      'asc'
    );
  }

  private setOptionsTime(examEvents: ExamEvent[]): void {
    this.optionsTime = [];
    examEvents.forEach((event) => {
      const eventStartHour = new Date(
        event.testeventTime
      ).getHours();
      if (eventStartHour <= 12) {
        this.optionsTime.push({
          label: 'option.morning',
          value: 'morning',
        });
        this.selectedTimes.push('morning');
      } else {
        this.optionsTime.push({
          label: 'option.afternoon',
          value: 'afternoon',
        });
        this.selectedTimes.push('afternoon');
      }
    });

    //Remove duplicates and sort
    this.optionsTime = this.removeDuplicatesAndSort(
      this.optionsTime,
      'desc'
    );
  }

  private locationFilter(event: ExamEvent): boolean {
    return this.selectedLocations.includes(event.testarea);
  }

  private dateFilter(event: ExamEvent): boolean {
    const shortDate = this.convertDateToShortDateString(
      event.testeventTime
    );
    return this.selectedDates.includes(shortDate);
  }

  private timeFilter(event: ExamEvent): boolean {
    const eventStartHour = new Date(
      event.testeventTime
    ).getHours();

    return this.selectedTimes.includes(
      eventStartHour <= 12 ? 'morning' : 'afternoon'
    );
  }

  private fixTestEventTimeFormat(
    events: ExamEvent[]
  ): ExamEvent[] {
    return events.map((event) => {
      if (event.testeventTime) {
        return {
          ...event,
          testeventTime: Tools.dateFromIso(
            event.testeventTime
          ).toString(),
        };
      } else {
        return event;
      }
    });
  }

  private examEventsSort(events: ExamEvent[]): ExamEvent[] {
    return events.sort(
      (a, b) =>
        Date.parse(a.testeventTime) -
        Date.parse(b.testeventTime)
    );
  }

  private removeDuplicatesAndSort(
    array: SelectOption[],
    sort: 'asc' | 'desc'
  ) {
    //TODO: Find another way to remove duplicates
    const sorted = array
      .filter(
        (element, index, orgArray) =>
          index ===
          orgArray.findIndex(
            (el) => el.value === element.value
          )
      )
      .sort((a, b) =>
        (a.value as string).localeCompare(b.value as string)
      );

    if (sort === 'desc') {
      return sorted.reverse();
    } else {
      return sorted;
    }
  }

  private convertDateToShortDateString(
    date: string
  ): string {
    return `${new Date(date).getFullYear()}${new Date(
      date
    ).getMonth()}${new Date(date).getDate()}`;
  }

  private checkIfChosenEventNotDisplayed(
    examEventsFilteredList: ExamEvent[]
  ): void {
    if (
      examEventsFilteredList.some(
        (event) => event.id !== this.examEventChoosen.value
      )
    ) {
      this.examEventChoosen.setValue(null);
    }
  }

  protected fail(
    error: HttpErrorResponse
  ): Promise<[undefined, void]> {
    this.logger.error('create booking failed');

    const errorDialog =
      this.notificationService.httpError(error);
    return Promise.all([
      errorDialog,
      this.loadEvents(this.testId),
    ]).finally(() => Promise.reject(error));
  }

  protected get tenant(): TenantId {
    return environment.tenant;
  }
}
