import {
  UntypedFormControl,
  ValidationErrors,
} from '@angular/forms';

export class CheckboxValidator {
  static checked(
    control: UntypedFormControl
  ): ValidationErrors | null {
    return control.value
      ? null
      : ({ required: true } as ValidationErrors);
  }
}
