import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { BookingService } from 'src/app/services';
import {
  LevelOfEducation,
  ParentalRole,
  PriceCalculationData,
  ReceivesBafoeg,
} from 'src/app/types';

@Component({
  selector: 'app-step4a-consent-proctoring-modal',
  templateUrl:
    './step4a-consent-proctoring-modal.component.html',
  styleUrls: [
    './step4a-consent-proctoring-modal.component.scss',
  ],
})
export class Step4aConsentProctoringModalComponent
  implements OnInit
{
  showBreadcrumb = false;
  form!: FormGroup;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: boolean,
    private dialogRef: MatDialogRef<Step4aConsentProctoringModalComponent>,
    private bookingService: BookingService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      consent: [false, [Validators.requiredTrue]],
    });
  }

  get consentControl(): FormControl {
    return this.form.get('consent') as FormControl;
  }

  nextAndClose(): void {
    const priceCalculationData: PriceCalculationData = {
      dataProcessingForProctoringAccepted:
        this.consentControl.value,
      parentage: [
        {
          type: ParentalRole.MOTHER,
          levelOfEducation: LevelOfEducation.UNDEFINED,
        },
        {
          type: ParentalRole.FATHER,
          levelOfEducation: LevelOfEducation.UNDEFINED,
        },
      ],
      receivesBafoeg: ReceivesBafoeg.UNKNOWN,
    };

    this.bookingService.formState.item = {
      ...this.bookingService.formState.item,
      priceCalculationData,
    };

    this.dialogRef.close({ data: this.form.valid });
  }
}
