export interface CancellationBookingRequest {
  reason?: string;
  refundMethod:
    | 'none'
    | 'manual'
    | 'automatic'
    | 'automatic_default';
  refund?: {
    amount: number;
    currency: string;
  };
}
