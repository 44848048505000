import {
  Component,
  HostBinding,
  OnInit,
} from '@angular/core';
import { DateTime } from 'luxon';
import {
  ExamEventService,
  ExamService,
} from 'src/app/services';
import { Exam } from 'src/app/types';

@Component({
  selector: 'app-my-registrations-page',
  templateUrl: './my-registrations-page.component.html',
  styleUrls: ['./my-registrations-page.component.scss'],
})
export class MyRegistrationsPageComponent
  implements OnInit
{
  @HostBinding('class.default-page-component')
  hostClass = true;
  isLoading = true;

  constructor(
    private examService: ExamService,
    private examEventService: ExamEventService
  ) {}

  async ngOnInit(): Promise<void> {
    this.examEventService.listState.filter =
      ExamEventService.resetFilter();

    await this.examEventService.loadList();
    await this.examService.loadExamList();
    this.isLoading = false;
  }

  get items(): Exam[] {
    const validItems =
      this.examService.listState.response.items.filter(
        ({ validityPeriodStart, validityPeriodEnd }) => {
          const diffToStart = DateTime.fromISO(
            validityPeriodStart
          )
            .diffNow()
            .toMillis();

          const diffToEnd = (
            validityPeriodEnd
              ? DateTime.fromISO(validityPeriodEnd)
              : DateTime.now().plus({ days: 1 })
          )
            .diffNow()
            .toMillis();

          return diffToStart < 0 && diffToEnd > 0;
        }
      );

    return validItems;
  }
}
