import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'localizedDate',
  pure: false,
})
export class LocalizedDatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(
    value: any,
    format: string = 'mediumDate',
    timezone: string = 'Europe/Berlin'
  ): any {
    const timezoneOffset = DateTime.fromJSDate(value)
      .setZone(timezone)
      .toFormat('ZZZ');

    const datePipe: DatePipe = new DatePipe(
      this.translateService.currentLang || 'en'
    );

    return datePipe.transform(
      value,
      format,
      timezoneOffset
    );
  }
}
