import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services';
import { User } from '../types';

@Injectable({
  providedIn: 'root',
})
export class InitUserFormGuard implements CanActivate {
  constructor(private service: UserService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Promise<boolean>((resolve) => {
      const id = Number(route.paramMap.get('id'));
      this.service.loadById(id).then((item: User) => {
        this.service.formState.item = item;
        resolve(true);
      });
    });
  }
}
