import { Injectable } from '@angular/core';
import { SelectOption } from '../../types';

@Injectable({
  providedIn: 'root',
})
export class GenderService {
  // TODO load by API
  selectOptions: SelectOption[] = [
    { value: 'm', label: 'gender.male' },
    { value: 'f', label: 'gender.female' },
    { value: 'd', label: 'gender.divers' },
    { value: 'n', label: 'gender.not-specified' },
  ];
}
