import {
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { environment } from 'src/environments/environment';
import { TenantId } from 'src/environments/environments.types';

@Component({
  selector: 'app-invite-error-dialog',
  templateUrl: './invite-error-dialog.component.html',
  styleUrls: ['./invite-error-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InviteErrorDialogComponent {
  get tenant(): TenantId {
    return environment.tenant;
  }
}
