<h2 mat-dialog-title>
  {{
    'title.test-event-details'
      | translate: { testEventId: examEvent.id }
  }}
</h2>

<div class="flex-row">
  <div class="flex-1 flex-column">
    <h3>{{ 'testevent.details.title' | translate }}</h3>

    <span
      >{{
        'testevent.details.testevent-time' | translate
      }}:</span
    >
    <span
      ><em>{{
        'testevent.details.date-and-time'
          | translate
            : {
                date:
                  examEvent.testeventTime
                  | localizedDate: 'mediumDate',
                time:
                  examEvent.testeventTime
                  | localizedDate: 'shortTime'
              }
      }}</em></span
    >
    <span
      >{{
        'label.registration-period-start' | translate
      }}:</span
    >
    <span
      ><em>{{
        'testevent.details.date-and-time'
          | translate
            : {
                date:
                  examEvent.registerTimeFrom
                  | localizedDate: 'mediumDate',
                time:
                  examEvent.registerTimeFrom
                  | localizedDate: 'shortTime'
              }
      }}</em></span
    >
    <span
      >{{
        'label.registration-period-end' | translate
      }}:</span
    >
    <span
      ><em>{{
        'testevent.details.date-and-time'
          | translate
            : {
                date:
                  examEvent.registerTimeTo
                  | localizedDate: 'mediumDate',
                time:
                  examEvent.registerTimeTo
                  | localizedDate: 'shortTime'
              }
      }}</em></span
    >
    <span>
      {{ 'label.registration-price' | translate }}:
      <em>{{
        examEvent.price.amount
          | currency
            : examEvent.price.currency
            : undefined
            : undefined
            : currentLanguage
      }}</em>
    </span>
    <span *ngIf="exam?.priceInEuro">
      {{ 'label.default-registration-price' | translate }}:
      <em>{{
        exam.priceInEuro
          | currency
            : 'EUR'
            : undefined
            : undefined
            : currentLanguage
      }}</em>
    </span>

    <span
      >{{ 'label.max-capacity' | translate }}:
      <em>{{
        'testevent.details.max-capacity-format'
          | translate
            : { maximumCapacity: examEvent.maximumCapacity }
      }}</em></span
    >
  </div>

  <div class="flex-1 flex-column">
    <h3>{{ 'label.exam-center' | translate }}</h3>

    <span
      ><strong>{{ examCenter.name }}</strong></span
    >
    <span
      >{{ 'label.exam-location.title' | translate }}:
      <em>{{ examEvent.testarea }}</em></span
    >
    <span
      >{{ 'label.provider' | translate }}:
      <em>{{ examCenter.provider }}</em></span
    >
    <div>
      {{
        'testevent.details.testcenter-address' | translate
      }}:
      <address>
        <span
          >{{ examCenter.street }}
          {{ examCenter.streetNumber }}</span
        >
        <span
          >{{ examCenter.zip }} {{ examCenter.city }},
          {{ examCenter.state }}</span
        >
        <span>{{ examCenter.country }}</span>
        <span *ngIf="examCenter?.additionalAddressInfo">{{
          examCenter.additionalAddressInfo
        }}</span>
      </address>
    </div>
    <span *ngIf="examCenter?.additionalInformation"
      >{{ 'label.hint' | translate }}:</span
    >
    <span *ngIf="examCenter?.additionalInformation"
      ><em>{{ examCenter.additionalInformation }}</em></span
    >
  </div>
</div>

<div class="flex-row">
  <div class="flex-1 flex-column">
    <h3>{{ 'label.exams.exam' | translate }}</h3>

    <span
      ><strong>{{ exam.testname }}</strong></span
    >
    <span
      >{{ 'label.test-template' | translate }}:
      <em>{{ exam.templateName }}</em></span
    >

    <span *ngIf="exam?.registrationPeriodStart"
      >{{
        'label.registration-period-start' | translate
      }}:</span
    >
    <span *ngIf="exam?.registrationPeriodStart"
      ><em>{{
        'testevent.details.date-and-time'
          | translate
            : {
                date:
                  exam.registrationPeriodStart
                  | localizedDate: 'mediumDate',
                time:
                  exam.registrationPeriodStart
                  | localizedDate: 'shortTime'
              }
      }}</em></span
    >
    <span *ngIf="exam?.registrationPeriodEnd"
      >{{
        'label.registration-period-end' | translate
      }}:</span
    >
    <span *ngIf="exam?.registrationPeriodEnd"
      ><em>{{
        'testevent.details.date-and-time'
          | translate
            : {
                date:
                  exam.registrationPeriodEnd
                  | localizedDate: 'mediumDate',
                time:
                  exam.registrationPeriodEnd
                  | localizedDate: 'shortTime'
              }
      }}</em></span
    >

    <span
      >{{
        'label.validity-period-start' | translate
      }}:</span
    >
    <span
      ><em>{{
        'testevent.details.date-and-time'
          | translate
            : {
                date:
                  exam.validityPeriodStart
                  | localizedDate: 'mediumDate',
                time:
                  exam.validityPeriodStart
                  | localizedDate: 'shortTime'
              }
      }}</em></span
    >
    <span *ngIf="exam?.validityPeriodEnd"
      >{{ 'label.validity-period-end' | translate }}:</span
    >
    <span *ngIf="exam?.validityPeriodEnd"
      ><em>{{
        'testevent.details.date-and-time'
          | translate
            : {
                date:
                  exam.validityPeriodEnd
                  | localizedDate: 'mediumDate',
                time:
                  exam.validityPeriodEnd
                  | localizedDate: 'shortTime'
              }
      }}</em></span
    >
  </div>

  <div class="flex-1 flex-column">
    <img
      *ngIf="examEvent.logoPath"
      [src]="examEvent.logoPath"
      [alt]="exam.testname"
      class="logo"
    />
  </div>
</div>

<div class="buttons">
  <button mat-dialog-close mat-raised-button>
    {{ 'button.close' | translate }}
  </button>
  <div class="flex-1"></div>
  <button
    [routerLink]="[
      '/home/exam-center',
      examEvent.testcentersId
    ]"
    mat-dialog-close
    mat-raised-button
    color="primary"
  >
    {{ 'button.open-testcenter' | translate }}
  </button>
</div>
