<button [matMenuTriggerFor]="menu" mat-icon-button>
  <mat-icon>person</mat-icon>
</button>
<mat-menu #menu="matMenu" class="profile-button-menu">
  <button [routerLink]="['/profile']" mat-menu-item>
    <mat-icon>person</mat-icon> {{'label.profile'|translate}}
  </button>
  <mat-divider></mat-divider>
  <button (click)="logout()" mat-menu-item>
    <mat-icon>logout</mat-icon> {{'label.logout'|translate}}
  </button>
</mat-menu>
