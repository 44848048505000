import {
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-profile-button',
  templateUrl: './profile-button.component.html',
  styleUrls: ['./profile-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProfileButtonComponent {
  constructor(private keycloak: KeycloakService) {}
  logout() {
    this.keycloak.logout(
      `${window.location.origin}/landing`
    );
  }
}
