import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  HostBinding,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormArray,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DateTime } from 'luxon';
import { NGXLogger } from 'ngx-logger';
import { EditEmailDialogComponent } from 'src/app/components/dialogs';
import { CheckboxValidator } from 'src/app/lib/form-fields';
import {
  CountryService,
  ExamService,
  GenderService,
  NotificationService,
  ProfileService,
  SnackBarService,
  UserService,
} from 'src/app/services';
import {
  ParentalAddressInfo,
  SelectOption,
  User,
} from 'src/app/types';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-step1-profile-check-form',
  templateUrl: './step1-profile-check-form.component.html',
  styleUrls: ['./step1-profile-check-form.component.scss'],
})
export class Step1ProfileCheckFormComponent
  implements OnInit
{
  @HostBinding('class.form-component') hostClass = true;
  @Output() profileCheckFormValid: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() nextStep: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  form!: UntypedFormGroup;
  maxDate!: Date;
  private isSdv = false;

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected countryService: CountryService,
    protected dialog: MatDialog,
    protected examService: ExamService,
    protected formBuilder: UntypedFormBuilder,
    protected genderService: GenderService,
    protected logger: NGXLogger,
    protected notificationService: NotificationService,
    protected profileService: ProfileService,
    protected snackBar: SnackBarService,
    protected userService: UserService
  ) {
    this.isSdv = environment.tenant === 'sdv';
  }

  ngOnInit(): void {
    // Set minimum age: 10 years
    this.maxDate = DateTime.now()
      .minus({ years: 10 })
      .toJSDate();

    this.userService.formState.item =
      this.profileService.profile.user;

    this.form = this.formBuilder.group({
      // block: base
      email: [
        this.item.email,
        [Validators.required, Validators.email],
      ],
      firstname: [
        this.item.firstname,
        [Validators.required],
      ],
      lastname: [this.item.lastname, [Validators.required]],
      birthName: [this.item.birthName],
      confirmedGDPR: [
        this.item.confirmedGDPR,
        [CheckboxValidator.checked],
      ],

      // block: personal
      gender: [this.item.gender, [Validators.required]],
      birthLocation: [this.item.birthplace],
      birthDate: [
        this.item.birthday,
        [Validators.required],
      ],
      phone: [this.item.phoneNumber],
      email2: [this.item.secondEmail, [Validators.email]],

      // block: address
      street: [this.item.street, [Validators.required]],
      streetNumber: [
        this.item.streetNumber,
        [Validators.required],
      ],
      addressAddition: [this.item.additionalAddressInfo],
      postalCode: [this.item.zip, [Validators.required]],
      city: [this.item.city, [Validators.required]],
      state: [this.item.state],
      country: [this.item.country, [Validators.required]],

      // parental Addresses
      parentalAddressesRadio: [
        this.item.parentalAddresses.length > 0
          ? true
          : undefined,
      ],
      parentalAddresses: this.formBuilder.array(
        this.item.parentalAddresses.map((address) =>
          this.createParentalAddressFormGroup(address)
        )
      ),
    });

    this.emailControl.disable();

    const templateName = this.examTemplateName;
    switch (templateName?.toUpperCase()) {
      case 'GSAT':
        this.logger.trace(
          `birth location and phone should not be required because the template is ${templateName}`
        );
        this.birthLocationControl.removeValidators(
          Validators.required
        );
        this.phoneControl.removeValidators(
          Validators.required
        );
        break;
      case 'PHAST':
      case 'ITB-Business':
      case 'ITB-Science':
      case 'ITB-Technology':
      case 'TM-WISO':
      default:
        this.logger.trace(
          `birth location and phone should be required because the template is ${templateName}`
        );
        this.birthLocationControl.addValidators(
          Validators.required
        );
        this.phoneControl.addValidators(
          Validators.required
        );
    }

    // Set timeout to avoid ExpressionChangedAfterItHasBeenCheckedError in ExamBookingFormComponent
    // TODO: Find another way to update the form in the parent component
    setTimeout(() => {
      this.profileCheckFormValid.emit(this.form.valid);
    }, 0);

    this.form.valueChanges.subscribe((_value) => {
      this.profileCheckFormValid.emit(this.form.valid);
    });

    if (this.isSdv) {
      this.parentalAddressesRadioControl.setValidators([
        Validators.required,
      ]);
    } else {
      this.parentalAddressesRadioControl.clearValidators();
    }
  }

  get isCandidate(): boolean {
    return this.userService.formState.item.candidate;
  }

  get item(): User {
    return this.userService.formState.item;
  }

  get genderOptions(): SelectOption[] {
    return this.genderService.selectOptions;
  }

  get countryOptions(): SelectOption[] {
    return this.countryService.selectOptions;
  }

  get examId(): number {
    return Number(
      this.activatedRoute.snapshot.paramMap
        .get('id')
        ?.match(/^\d+/i)
    );
  }

  get examTemplateName(): string {
    const templateName =
      this.examService.listState.response.items.find(
        (exam) => exam.id === this.examId
      )?.templateName;
    return templateName ?? '';
  }

  // block: base
  get emailControl(): UntypedFormControl {
    return this.form.get('email') as UntypedFormControl;
  }
  get firstnameControl(): UntypedFormControl {
    return this.form.get('firstname') as UntypedFormControl;
  }
  get lastnameControl(): UntypedFormControl {
    return this.form.get('lastname') as UntypedFormControl;
  }
  get birthNameControl(): UntypedFormControl {
    return this.form.get('birthName') as UntypedFormControl;
  }
  get confirmedGDPRControl(): UntypedFormControl {
    return this.form.get(
      'confirmedGDPR'
    ) as UntypedFormControl;
  }

  // block: personal
  get genderControl(): UntypedFormControl {
    return this.form.get('gender') as UntypedFormControl;
  }
  get birthLocationControl(): UntypedFormControl {
    return this.form.get(
      'birthLocation'
    ) as UntypedFormControl;
  }
  get birthDateControl(): UntypedFormControl {
    return this.form.get('birthDate') as UntypedFormControl;
  }
  get phoneControl(): UntypedFormControl {
    return this.form.get('phone') as UntypedFormControl;
  }
  get email2Control(): UntypedFormControl {
    return this.form.get('email2') as UntypedFormControl;
  }

  // block: address
  get streetControl(): UntypedFormControl {
    return this.form.get('street') as UntypedFormControl;
  }
  get streetNumberControl(): UntypedFormControl {
    return this.form.get(
      'streetNumber'
    ) as UntypedFormControl;
  }
  get addressAdditionControl(): UntypedFormControl {
    return this.form.get(
      'addressAddition'
    ) as UntypedFormControl;
  }
  get postalCodeControl(): UntypedFormControl {
    return this.form.get(
      'postalCode'
    ) as UntypedFormControl;
  }
  get stateControl(): UntypedFormControl {
    return this.form.get('state') as UntypedFormControl;
  }
  get cityControl(): UntypedFormControl {
    return this.form.get('city') as UntypedFormControl;
  }
  get countryControl(): UntypedFormControl {
    return this.form.get('country') as UntypedFormControl;
  }
  get parentalAddressesRadioControl(): UntypedFormControl {
    return this.form.get(
      'parentalAddressesRadio'
    ) as UntypedFormControl;
  }
  get parentalAddressesFormArray(): FormArray<UntypedFormGroup> {
    return this.form.controls[
      'parentalAddresses'
    ] as FormArray<UntypedFormGroup>;
  }

  submit(): void {
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      return;
    }

    this.userService.formState.item = {
      ...this.userService.formState.item,
      // block: base
      firstname: this.firstnameControl.value,
      lastname: this.lastnameControl.value,
      birthName: this.birthNameControl.value,
      confirmedGDPR: this.confirmedGDPRControl.value,

      // block: personal
      gender: this.genderControl.value,
      birthplace: this.birthLocationControl.value,
      birthday: this.birthdayFormated,
      phoneNumber: this.phoneControl.value,
      secondEmail: this.email2Control.value,

      // block: address
      street: this.streetControl.value,
      streetNumber: this.streetNumberControl.value,
      additionalAddressInfo:
        this.addressAdditionControl.value,
      zip: this.postalCodeControl.value,
      city: this.cityControl.value,
      state: this.stateControl.value,
      country: this.countryControl.value,
      parentalAddresses:
        this.parentalAddressesFormArray.value,
    };

    this.userService
      .save()
      .then(() => {
        this.nextStep.emit(true);
      })
      .catch((error: HttpErrorResponse) => {
        this.nextStep.emit(false);
        this.fail(error);
      });
  }

  get birthdayFormated(): string {
    if (this.birthDateControl.value instanceof Date) {
      const birthday = this.birthDateControl.value;
      return (
        birthday.getFullYear() +
        '-' +
        ('0' + (birthday.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + birthday.getDate()).slice(-2)
      );
    } else {
      return this.birthDateControl.value;
    }
  }

  editEmail() {
    this.dialog.open(EditEmailDialogComponent, {
      width: '450px',
      autoFocus: false,
    });
  }

  private createParentalAddressFormGroup(
    address: ParentalAddressInfo
  ): FormGroup {
    return this.formBuilder.group({
      additionalAddressInfo: [
        address.additionalAddressInfo,
      ],
      city: [address.city],
      country: [address.country],
      state: [address.state],
      street: [address.street],
      streetNumber: [address.streetNumber],
      zip: [address.zip],
    });
  }

  private fail(
    error: HttpErrorResponse
  ): Promise<undefined> {
    this.logger.error(
      'Step1ProfileCheckFormComponent:saveErrors',
      {
        error,
      }
    );

    const errorDialog =
      this.notificationService.httpError(error);
    return errorDialog.finally(() => Promise.reject(error));
  }
}
