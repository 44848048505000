import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import Tools from 'src/app/lib/Tools';
import { ExamEvent, Supervision } from 'src/app/types';

@Component({
  selector: 'app-test-time-card',
  templateUrl: './test-time-card.component.html',
  styleUrls: ['./test-time-card.component.scss'],
})
export class TestTimeCardComponent {
  @Input() control!: UntypedFormControl;
  @Input() examEvent!: ExamEvent;
  @Input() isPriceVisible = true;
  checked = false;
  name = 'timeSlotRadioGroup';

  constructor(private translateService: TranslateService) {}

  get timeSlot(): Date {
    return Tools.dateFromIso(this.examEvent.testeventTime);
  }

  get currentLanguage(): string {
    return this.translateService.currentLang;
  }

  get isProctoring(): boolean {
    return (
      this.examEvent.supervision === Supervision.Proctoring
    );
  }

  chooseTimeSlot(): void {
    this.checked = !this.checked;
    if (!this.checked) {
      this.control.reset();
    }
  }
}
