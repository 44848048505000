<div class="form-content">
  <div class="form-block">
    <app-form-field-text
      [field]="nameControl"
      label="label.name"
    ></app-form-field-text>
    <app-form-field-select
      [field]="countryControl"
      [options]="countryOptions"
      sortBy="translated-label-asc"
      label="label.country"
    ></app-form-field-select>
    <app-form-field-text
      [field]="hintControl"
      label="label.hint"
    ></app-form-field-text>
    <app-form-field-radio
      [field]="supervisionControl"
      [options]="supervisionOptions"
      [color]="'accent'"
    >
    </app-form-field-radio>
  </div>
</div>

<div class="buttons">
  <button
    [routerLink]="['/home/exam-location']"
    mat-raised-button
  >
    {{ 'button.cancel' | translate }}
  </button>
  <div class="flex-1"></div>
  <button
    (click)="delete()"
    mat-raised-button
    color="warn"
  >
    {{ 'button.delete' | translate }}
  </button>
  <button
    (click)="submit()"
    mat-raised-button
    color="accent"
  >
    {{ 'button.save' | translate }}
  </button>
</div>
