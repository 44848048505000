import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AppInitializerServiceMock {
  private initPromise: Promise<void> | null = null;

  init(): Promise<void> {
    if (!this.initPromise) {
      this.initPromise = new Promise<void>((resolve) =>
        resolve()
      );
    }
    return this.initPromise;
  }
}
