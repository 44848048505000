<div class="container-content" fxLayout="column">
  <h1>
    {{ 'message.404.title' | translate }}
  </h1>
  <p>
    {{ 'message.404.text' | translate }}
  </p>

  <div class="image-wrapper">
    <ng-container [ngSwitch]="tenant">
      <img
        *ngSwitchCase="'default'"
        src="https://static.register.itb-academic-tests.org/img/404-panda.png"
        [alt]="'message.404.image-alt' | translate"
      />
      <img
        *ngSwitchCase="'sdv'"
        src="https://static.studienstiftung-test.de/img/404-panda.png"
        [alt]="'message.404.image-alt' | translate"
      />
    </ng-container>
  </div>
</div>
