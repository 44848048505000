<form [formGroup]="form" *ngIf="!loading">
  <div class="form-content">
    <div class="form-block">
      <app-form-field-selection-test-location
        [field]="testLocation"
        [label]="'label.test-event-location' | translate"
        [options]="optionsLocation"
        [multiple]="true"
      >
      </app-form-field-selection-test-location>
    </div>

    <div class="form-block">
      <app-form-field-select
        [field]="testDate"
        [label]="'label.test-event-date' | translate"
        [options]="optionsDate"
        [multiple]="true"
        [localizedDate]="true"
      ></app-form-field-select>
    </div>
    <div class="form-block">
      <app-form-field-select
        [field]="testTime"
        [label]="'label.test-event-time' | translate"
        [options]="optionsTime"
        [multiple]="true"
      ></app-form-field-select>
    </div>
  </div>

  <div class="timeSlots">
    <app-test-time-card
      *ngFor="let examEvent of examEventsFiltered"
      [control]="examEventChoosen"
      [examEvent]="examEvent"
    ></app-test-time-card>
  </div>

  <mat-card-actions>
    <button
      mat-raised-button
      matStepperPrevious
      type="button"
    >
      {{ 'button.back' | translate }}
    </button>
    <button
      mat-raised-button
      color="accent"
      type="button"
      (click)="createBooking()"
      [disabled]="!form.valid"
    >
      {{ 'button.next' | translate }}
    </button>
  </mat-card-actions>
</form>

<app-loading-indicator-full
  *ngIf="loading"
></app-loading-indicator-full>
