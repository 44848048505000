import {
  AfterViewInit,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MatSort,
  MatSortable,
  Sort,
} from '@angular/material/sort';
import { Subscription } from 'rxjs';
import { ExamLocationService } from '../../../services';
import { ExamLocation, ListFilter } from '../../../types';

@Component({
  selector: 'app-exam-location-table',
  templateUrl: './exam-location-table.component.html',
  styleUrls: ['./exam-location-table.component.scss'],
})
export class ExamLocationTableComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild(MatSort, { static: true }) sortTable!: MatSort;

  @HostBinding('class.entity-table-component')
  hostClass = true;
  rowsConfig = ['name'];

  private defaultSortColumnConfig: MatSortable;
  private sortSubcription!: Subscription;

  constructor(private service: ExamLocationService) {
    this.defaultSortColumnConfig = {
      id: this.service.listState.filter.sort,
      start: this.service.listState.filter.direction,
      disableClear: true,
    };
  }

  ngOnInit(): void {
    // Default sort column
    this.sortTable.sort(this.defaultSortColumnConfig);
  }

  ngAfterViewInit(): void {
    this.sortSubcription =
      this.sortTable.sortChange.subscribe(
        (sortColumn: Sort) => {
          this.service.listState.filter.sort =
            sortColumn.active;
          this.service.listState.filter.direction =
            sortColumn.direction as ListFilter['direction'];
          this.service.loadList();
        }
      );
  }

  get items(): ExamLocation[] {
    return this.service.listState.response.items;
  }

  ngOnDestroy(): void {
    this.sortSubcription.unsubscribe();
  }
}
