<form [formGroup]="form">
  <div fxLayout="column" class="form-content">
    <p>
      {{
        'booking.sdv.pricing-page.paragraph-1' | translate
      }}
    </p>
    <p>
      {{
        'booking.sdv.pricing-page.paragraph-2' | translate
      }}
    </p>
    <mat-divider></mat-divider>

    <!-- Question #1 -->
    <mat-label>
      {{
        'booking.sdv.pricing-page.parental-data.label'
          | translate
      }}</mat-label
    >
    <div
      fxLayout="column"
      fxLayoutGap="2rem"
      fxLayoutGap.gt-sm="0"
    >
      <div
        class="select-block"
        fxLayout="column"
        fxLayout.gt-sm="row"
        fxLayoutGap="0"
        fxLayoutGap.gt-sm="2rem"
      >
        <app-form-field-select
          fxFlex="grow"
          [field]="parentOneControl"
          [options]="parentalRoleOptions"
        ></app-form-field-select>
        <app-form-field-select
          fxFlex="grow"
          [field]="parentOneLevelOfEducationControl"
          [options]="parentalEducationLevelOptions"
          (selectionChange)="
            checkIfInformationIsCorrectRequired()
          "
          label="booking.sdv.pricing-page.parental-data.level-of-education.label"
          panelClass="max-height-unset"
        ></app-form-field-select>
      </div>
      <div
        class="select-block"
        fxLayout="column"
        fxLayout.gt-sm="row"
        fxLayoutGap="0"
        fxLayoutGap.gt-sm="2rem"
      >
        <app-form-field-select
          fxFlex="grow"
          [field]="parentTwoControl"
          [options]="parentalRoleOptions"
        ></app-form-field-select>
        <app-form-field-select
          fxFlex="grow"
          [field]="parentTwoLevelOfEducationControl"
          [options]="parentalEducationLevelOptions"
          (selectionChange)="
            checkIfInformationIsCorrectRequired()
          "
          label="booking.sdv.pricing-page.parental-data.level-of-education.label"
          panelClass="max-height-unset"
        ></app-form-field-select>
      </div>
    </div>

    <!-- Question #2 -->
    <app-form-field-radio
      [radioGroupClass]="'column-if-mobile'"
      class="width100"
      [field]="receivesBafoegControl"
      [options]="radioBtnReceivesBafoegOptions"
      [color]="'accent'"
      (change)="onBafoegChange()"
      label="booking.sdv.pricing-page.receives-bafoeg.question"
    >
    </app-form-field-radio>

    <!-- Question #3 -->
    <app-form-field-text
      *ngIf="isReceivesBafoeg"
      [field]="bafoegNumberControl"
      [errorMessages]="{
        pattern:
          'booking.sdv.pricing-page.receives-bafoeg.errorMessage'
      }"
      label="booking.sdv.pricing-page.receives-bafoeg.bafoegNumber"
    >
    </app-form-field-text>

    <!-- Question #3 -->
    <app-form-field-checkbox
      [field]="informationIsCorrectControl"
      label="booking.sdv.pricing-page.information-is-correct-label"
      [disabled]="informationIsCorrectControl.disabled"
    >
    </app-form-field-checkbox>
  </div>

  <mat-card-actions fxLayout fxLayoutAlign="space-between">
    <button
      mat-raised-button
      color="warn"
      (click)="cancelBooking()"
    >
      {{ 'button.cancel' | translate }}
    </button>
    <div>
      <button
        mat-raised-button
        matStepperPrevious
        type="button"
      >
        {{ 'button.back' | translate }}
      </button>
      <button
        mat-raised-button
        color="primary"
        type="button"
        (click)="updateBooking()"
        [disabled]="!form.valid"
      >
        {{ 'button.next' | translate }}
      </button>
    </div>
  </mat-card-actions>
</form>
