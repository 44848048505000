<h2 *ngIf="hasTitle" mat-dialog-title>{{title|translate}}</h2>
<mat-dialog-content>
  <p>{{message | translate: translationParams }}</p>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="flex-1">
    <button (click)="cancel()"
            mat-raised-button>{{'button.cancel'|translate}}</button>
  </div>
  <div>
    <button (click)="confirm()"
            class="mat-primary"
            mat-raised-button>{{'button.confirm'|translate}}</button>
  </div>
</mat-dialog-actions>
