import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'app-profile-edit-page',
  templateUrl: './profile-edit-page.component.html',
  styleUrls: ['./profile-edit-page.component.scss'],
})
export class ProfileEditPageComponent {
  @HostBinding('class.form-page-component')
  hostClass = true;
}
