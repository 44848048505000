<div class="form-content">
  <div class="form-block">
    <app-form-field-text
      [field]="emailControl"
      label="label.email"
    ></app-form-field-text>
    <app-form-field-text
      [field]="firstnameControl"
      label="label.firstname"
    ></app-form-field-text>
    <app-form-field-text
      [field]="lastnameControl"
      label="label.lastname"
    ></app-form-field-text>
    <app-form-field-checkbox-gdpr
      class="spacerBottom"
      [field]="confirmedGDPRControl"
    ></app-form-field-checkbox-gdpr>
  </div>

  <div class="form-block">
    <app-form-field-select
      [field]="genderControl"
      [options]="genderOptions"
      label="gender.label"
    ></app-form-field-select>
    <app-form-field-text
      [field]="birthLocationControl"
      label="label.birthLocation"
    ></app-form-field-text>
    <app-form-field-text
      [field]="birthDateControl"
      label="label.birthDate"
    ></app-form-field-text>
    <app-form-field-text
      [field]="phoneControl"
      label="label.phone"
    ></app-form-field-text>
    <app-form-field-text
      [field]="email2Control"
      label="label.email2"
    ></app-form-field-text>
  </div>

  <div class="form-block">
    <app-form-field-text
      [field]="streetControl"
      label="label.street"
    ></app-form-field-text>
    <app-form-field-text
      [field]="addressAdditionControl"
      label="label.addressAddition"
    ></app-form-field-text>
    <app-form-field-text
      [field]="postalCodeControl"
      label="label.postalCode"
    ></app-form-field-text>
    <app-form-field-text
      [field]="stateControl"
      label="label.state"
    ></app-form-field-text>
    <app-form-field-select
      [field]="countryControl"
      [options]="countryOptions"
      sortBy="translated-label-asc"
      label="label.country"
    ></app-form-field-select>
  </div>
</div>

<div class="buttons">
  <button [routerLink]="['/home']" mat-raised-button>
    {{ 'button.cancel' | translate }}
  </button>
  <div class="flex-1"></div>
  <button
    (click)="submit()"
    mat-raised-button
    color="primary"
  >
    {{ 'button.save' | translate }}
  </button>
</div>
