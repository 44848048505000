<mat-form-field
  [appearance]="appearance"
  [class.disabled]="disabled"
>
  <mat-label *ngIf="hasLabel">{{label|translate}}</mat-label>
  <input [formControl]="field"
         placeholder="{{placeholder|translate}}"
         [type]="type"
         [required]="required"
         [disabled]="disabled"
         matInput/>
  <mat-icon matSuffix
            *ngIf="!showPassword"
            (click)="switchType()"
            class="pointer">visibility</mat-icon>
  <mat-icon matSuffix
            *ngIf="showPassword"
            (click)="switchType()"
            class="pointer">visibility_off</mat-icon>
  <mat-hint *ngIf="hasHint">{{hint|translate}}</mat-hint>
  <mat-error>{{error|translate}}</mat-error>
</mat-form-field>
