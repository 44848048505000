import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { KeycloakService } from 'keycloak-angular';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { TenantId } from '../../../environments/environments.types';
import {
  ForgotPasswordDialogComponent,
  ForgotPasswordSuccessDialogComponent,
  InviteDialogComponent,
  InviteErrorDialogComponent,
  InviteSuccessDialogComponent,
} from '../../components';
import { NotificationService } from '../../services';
import { DialogResult } from '../../types';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent {
  readonly enableMbr: boolean;

  constructor(
    private dialog: MatDialog,
    private note: NotificationService,
    private keycloak: KeycloakService
  ) {
    this.enableMbr = environment.mbr?.enabled ?? false;
  }

  login(): void {
    // Keycloak does not use "location.assign" but rather "location.replace"; so it does not itself create a history
    //  entry to which one could navigate back. For this reason, we're creating one manually.
    window.history.pushState({}, 'Landing Page');
    this.keycloak.login({
      redirectUri: `${window.location.origin}/home`,
    });
  }

  inviteDialog(): void {
    firstValueFrom(
      this.dialog
        .open(InviteDialogComponent, {
          width: '450px',
          autoFocus: false,
        })
        .afterClosed()
    ).then((result) => this.inviteResponse(result));
  }

  forgotPasswordDialog(): void {
    firstValueFrom(
      this.dialog
        .open(ForgotPasswordDialogComponent, {
          width: '450px',
          autoFocus: false,
        })
        .afterClosed()
    ).then((result) => this.forgotPasswordResponse(result));
  }

  get tenant(): TenantId {
    return environment.tenant;
  }

  private inviteResponse(result: DialogResult): void {
    if (result?.success) {
      this.dialog.open(InviteSuccessDialogComponent, {
        width: '450px',
        autoFocus: false,
      });
    } else if (result?.success === false) {
      this.dialog.open(InviteErrorDialogComponent, {
        width: '450px',
        autoFocus: false,
      });
    } else {
      return;
    }
  }

  private forgotPasswordResponse(
    result: DialogResult
  ): void {
    if (result) {
      this.dialog.open(
        ForgotPasswordSuccessDialogComponent,
        {
          width: '450px',
          autoFocus: false,
        }
      );
    }
  }
}
