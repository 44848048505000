<mat-card
  mat-ripple
  *ngIf="booking"
  [routerLink]="[routerLink, booking.bookingId]"
  [class.incomplete-booking]="booking.status === 'canceled' || (booking.status === 'open' && (booking.paymentExpectedUntil || !booking.requestedPayment))"
  [class.deleted-booking]="booking.deleted || booking.status === 'order_canceled'"
>
  <mat-card-content fxLayoutAlign="space-between">
    <div class="container-text" fxLayout="column">
      <h2>{{ booking.test.name }}</h2>
      <p *ngIf="timeSlot">
        <span
          >{{
            'booking.default.overviewCard.format.dayOfWeek'
              | translate
                : {
                    timeSlot:
                      timeSlot | localizedDate: 'EEEE'
                  }
          }}<strong>{{
            'booking.default.overviewCard.format.date'
              | translate
                : {
                    timeSlot:
                      timeSlot | localizedDate: 'mediumDate'
                  }
          }}</strong>
        </span>
        <ng-container *ngIf="examEvent">
          <br />
          <span>{{
            'booking.default.overviewCard.format.area'
              | translate: { testarea: examEvent.testarea }
          }}</span>
        </ng-container>
        <span class="time">{{
          'booking.default.overviewCard.format.time'
            | translate
              : {
                  timeSlot:
                    timeSlot | localizedDate: 'shortTime'
                }
        }}</span>
      </p>
      <p *ngIf="!timeSlot">
        {{ 'testevent.deleted.part-1' | translate }}
        <br/>
        {{ 'testevent.deleted.part-2' | translate }}
      </p>
      <p class="status">
        <mat-card class="mat-elevation-z0">
          <mat-card-content *ngIf="!booking.deleted">
            {{ 'label.payment-status' | translate }}:
            <br/>
            <span>
              <b>{{
              'booking.default.status.' + booking.status
                | lowercase
                | translate
              }}</b>
            </span>
          </mat-card-content>
          <mat-card-content *ngIf="booking.deleted">
            {{ 'label.payment-status' | translate }}:
            <br/>
            <span
            ><b>{{
              'booking.default.status.deleted'
                | lowercase
                | translate
              }}</b></span
            >
            <mat-divider></mat-divider>
            {{ 'label.payment-status-before-deletion' | translate }}:
            <br/>
            <span
            ><b>{{
              'booking.default.status.' + booking.status
                | lowercase
                | translate
              }}</b></span
            >
          </mat-card-content>
        </mat-card>
      </p>
    </div>
    <div class="container-img" fxFlexAlign="center">
      <img
        *ngIf="booking.test?.logoPath"
        [src]="booking.test.logoPath"
        [alt]="booking.test.name"
      />
    </div>
  </mat-card-content>
</mat-card>
