import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import {
  Country,
  ListResponse,
  SelectOption,
} from '../../types';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  selectOptions: SelectOption[] = [];

  constructor(private http: HttpClient) {}

  init(): Promise<ListResponse<Country>> {
    const promise$: Promise<ListResponse<Country>> =
      firstValueFrom(
        this.http.get<ListResponse<Country>>(
          '/api/country/list'
        )
      );

    promise$.then(
      (response) =>
        (this.selectOptions = response.items.map(
          (country) => ({
            value: country.id,
            label: `country.${country.id}`.toLowerCase(),
          })
        ))
    );
    return promise$;
  }
}
