<mat-dialog-content>
  <p>
    {{ 'message.get-invite-fail.0.' + tenant | translate }}
    <a
      href="mailto:{{
          'message.get-invite-fail.1.' + tenant | translate
        }}"
      >{{
          'message.get-invite-fail.1.' + tenant | translate
      }}</a
    >{{ 'message.get-invite-fail.2' | translate }}
  </p>

  <div class="button-container flex-row flex-center">
    <mat-icon color="warn">error</mat-icon>
  </div>
</mat-dialog-content>
