import {
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ExamService } from '../../../services';

@Component({
  selector: 'app-exam-table-filter',
  templateUrl: './exam-table-filter.component.html',
  styleUrls: ['./exam-table-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExamTableFilterComponent
  implements OnInit, OnDestroy
{
  searchControl!: UntypedFormControl;
  private searchSubscription!: Subscription;
  private timeToWaitBeforeUpdateMs = 250;

  constructor(private service: ExamService) {}

  ngOnInit(): void {
    this.searchControl = new UntypedFormControl(
      this.service.listState.filter.search
    );
    this.searchSubscription =
      this.searchControl.valueChanges
        .pipe(debounceTime(this.timeToWaitBeforeUpdateMs))
        .subscribe((value) => {
          this.service.listState.filter.search = value;
          this.reload();
        });
  }

  reload(): void {
    this.service.loadExamList();
  }

  ngOnDestroy(): void {
    this.searchSubscription.unsubscribe();
  }
}
