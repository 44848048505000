<div class="container-content" fxLayout="column">
  <ng-container *ngIf="showBreadcrumb">
    <xng-breadcrumb [separator]="separatorIconTemplate">
      <ng-container
        *xngBreadcrumbItem="
          let breadcrumb;
          let info = info;
          let first = first
        "
      >
        <mat-icon *ngIf="info">{{ info }}</mat-icon>
        <ng-container *ngIf="!first">{{
          breadcrumb | translate
        }}</ng-container>
      </ng-container>
    </xng-breadcrumb>

    <ng-template #separatorIconTemplate>
      <mat-icon>arrow_right</mat-icon>
    </ng-template>
  </ng-container>

  <h1 class="mat-headline">
    {{ 'label.contact' | translate }}
  </h1>

  <p>
    {{ 'contact.sdv.paragraph-1.part-1' | translate }}
    <a
      href="tel:{{
        'contact.sdv.paragraph-1.telephone.clean-number'
          | translate
      }}"
      >{{
        'contact.sdv.paragraph-1.telephone.number'
          | translate
      }}</a
    >
    {{ 'contact.sdv.paragraph-1.part-2' | translate }}
    <a
      href="mailto:{{
        'contact.sdv.paragraph-1.e-mail' | translate
      }}"
      >{{ 'contact.sdv.paragraph-1.e-mail' | translate }}</a
    >
  </p>

  <p>
    {{ 'contact.sdv.paragraph-2.part-1' | translate }}
    <a
      href="{{
        'contact.sdv.paragraph-2.link-1.link' | translate
      }}"
      target="_blank"
      >{{
        'contact.sdv.paragraph-2.link-1.text' | translate
      }}</a
    >
    {{ 'contact.sdv.paragraph-2.part-2' | translate }}
    <a
      href="{{
        'contact.sdv.paragraph-2.link-2.link' | translate
      }}"
      target="_blank"
      >{{
        'contact.sdv.paragraph-2.link-2.text' | translate
      }}</a
    >
    {{ 'contact.sdv.paragraph-2.part-3' | translate }}
  </p>

  <p>
    {{ 'contact.sdv.paragraph-3.part-1' | translate }}
    <a
      href="{{
        'contact.sdv.paragraph-3.link-1.link' | translate
      }}"
      target="_blank"
      >{{
        'contact.sdv.paragraph-3.link-1.text' | translate
      }}</a
    >.
    {{ 'contact.sdv.paragraph-3.part-2' | translate }}
  </p>
</div>
