import {
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { Observable } from 'rxjs';
import { LayoutService } from '../../services';

@Component({
  selector: 'app-mbr-registration-page',
  templateUrl: './mbr-registration-page.component.html',
  styleUrls: ['./mbr-registration-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MbrRegistrationPageComponent {
  constructor(private layout: LayoutService) {}

  get isHandset$(): Observable<boolean> {
    return this.layout.isHandset$;
  }
}
