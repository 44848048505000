export interface CreateBookingResponse {
  bookingId: number;
  userId: number;
  testEventId: number;
  test: {
    id: number;
    name: string;
    templateName:
      | 'PhaST'
      | 'ITB-Business'
      | 'TM-WISO'
      | 'GSAT'
      | 'SdV';
    logoPath?: string;
  };
  reservedUntil: string;
}
