import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { ExamService } from '../services';
import { Exam } from '../types';

@Injectable({
  providedIn: 'root',
})
export class InitExamFormGuard implements CanActivate {
  constructor(private service: ExamService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Promise<boolean>((resolve) => {
      const id = Number(route.paramMap.get('id'));
      this.service.loadExamById(id).then((item: Exam) => {
        this.service.formState.item = item;

        const promises$: Promise<any>[] = [];

        promises$.push(this.service.loadTemplateOptions());

        Promise.all(promises$).then(() => {
          resolve(true);
        });
      });
    });
  }
}
