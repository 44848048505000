<mat-card *ngIf="!resetSuccessful">
  <mat-card-content>
    <app-form-field-password
      [field]="passwordControl"
      label="label.password"></app-form-field-password>
    <app-form-field-password
      [field]="passwordRepeatControl"
      label="label.passwordRepeat"></app-form-field-password>
  </mat-card-content>
  <mat-card-actions>
    <div class="buttons">
      <button (click)="submit()" mat-raised-button color="primary">{{'button.save'|translate}}</button>
    </div>
  </mat-card-actions>

</mat-card>
<mat-card *ngIf="resetSuccessful">
  <mat-card-content>
    <div class="flex-row">
      <div class="flex-1">{{'message.reset-password-successful'|translate}}</div>
      <button class="to-login-button"
              [routerLink]="['/landing']"
              mat-button>{{'button.to-login'|translate}}</button>
    </div>
  </mat-card-content>
</mat-card>
