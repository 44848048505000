import { Component } from '@angular/core';
import { FormFieldAbstractComponent } from '../form-field-abstract.component';

@Component({
  selector: 'app-form-field-password',
  templateUrl: './form-field-password.component.html',
  styleUrls: ['./form-field-password.component.scss'],
})
export class FormFieldPasswordComponent extends FormFieldAbstractComponent {
  type = 'password';
  get showPassword(): boolean {
    return 'text' === this.type;
  }
  switchType(): void {
    this.type =
      'password' === this.type ? 'text' : 'password';
  }
}
