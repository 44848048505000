<ng-container *ngIf="bookingInfo$ | async as bookingInfo">
  <h2 mat-dialog-title>
    {{
      'title.booking-cancel'
        | translate
          : {
              bookingReference: bookingInfo.bookingReference
            }
    }}
  </h2>

  <mat-dialog-content [formGroup]="form">
    <p>
      {{ 'message.booking-cancel-confirm' | translate }}
    </p>
    <mat-card class="booking-info">
      <mat-card-content>
        <p>
          <em>
            {{ 'booking.default.booked-at' | translate }}

            <time
              [dateTime]="
                bookingInfo.bookedAt | date: 'yyyy-MM-dd'
              "
            >
              {{
                bookingInfo.bookedAt
                  | localizedDate: 'mediumDate'
              }}
            </time>
          </em>
        </p>
        <p>
          {{ eventBooking.test.name }}
        </p>
        <p>
          <span>
            {{
              'booking.default.details.timeFormat.dayOfWeek'
                | translate
                  : {
                      timeSlot:
                        examEvent.testeventTime
                        | localizedDate: 'EEEE'
                    }
            }}
            <time
              [dateTime]="
                examEvent.testeventTime | date: 'yyyy-MM-dd'
              "
              >{{
                'booking.default.details.timeFormat.date'
                  | translate
                    : {
                        timeSlot:
                          examEvent.testeventTime
                          | localizedDate: 'mediumDate'
                      }
              }}</time
            >
          </span>
          <br />
          <span
            >{{
              'booking.default.details.timeFormat.area'
                | translate
                  : { testarea: examEvent.testarea }
            }}
          </span>
          <time
            [dateTime]="
              examEvent.testeventTime | date: 'HH:mm:ss'
            "
            >{{
              'booking.default.details.timeFormat.time'
                | translate
                  : {
                      timeSlot:
                        examEvent.testeventTime
                        | localizedDate: 'shortTime'
                    }
            }}</time
          >
        </p>
        <address>
          <span
            (click)="showAddress()"
            class="address-toggle"
            [class.address-toggle-active]="isShowAddress"
          >
            {{ bookingInfo.testEvent.name }}
            <mat-icon
              class="arrow set-down"
              [class.arrow-rotate]="isShowAddress"
              >arrow_drop_down</mat-icon
            >
          </span>
          <ng-container *ngIf="isShowAddress">
            <p>
              {{
                bookingInfo.testEvent.address
                  .streetAndNumber
              }}
              <br />
              {{ bookingInfo.testEvent.address.zip }}
              {{ bookingInfo.testEvent.address.city }}
              <br />
              {{ bookingInfo.testEvent.address.country }}
            </p>
          </ng-container>
        </address>
      </mat-card-content>
    </mat-card>

    <div>
      <p>
        {{ 'label.paid-until-now' | translate }}
        <strong>
          {{
            bookingInfo.totalPaidAmount.amount
              | currency
                : bookingInfo.totalPaidAmount.currency
                : undefined
                : undefined
                : currentLanguage
          }}
        </strong>
      </p>
      <mat-radio-group
        fxLayout="column"
        formControlName="refundMethod"
      >
        <mat-radio-button value="none" class="radio-btn">{{
          'label.no-refund' | translate
        }}</mat-radio-button>
        <mat-radio-button
          value="manual"
          class="radio-btn"
          [disabled]="!bookingInfo.totalPaidAmount.amount"
          >{{
            'label.manual-refund' | translate
          }}</mat-radio-button
        >
        <!-- TODO: Not implemented yet, so disabled -->
        <mat-radio-button
          value="automatic"
          class="radio-btn"
          [disabled]="true"
          >{{
            'label.refund-via-boti' | translate
          }}</mat-radio-button
        >
      </mat-radio-group>
      <div fxFlexFill>
        <app-form-field-textarea
          [field]="reason"
          [label]="
            'label.reason-for-cancelation' | translate
          "
        ></app-form-field-textarea>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      mat-stroked-button
      mat-dialog-close
      color="primary"
    >
      {{ 'button.cancel' | translate }}
    </button>
    <button
      mat-raised-button
      color="warn"
      cdkFocusInitial
      (click)="cancellationBooking()"
      [disabled]="!form.valid"
    >
      {{ 'button.booking-cancel' | translate }}
    </button>
  </mat-dialog-actions>
</ng-container>

<app-loading-indicator-full
  *ngIf="isLoading"
></app-loading-indicator-full>
