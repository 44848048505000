export interface PrerequisiteQuestionDataResponse
  extends PrerequisiteQuestionData {
  id?: number;
  prerequisitesMet?: boolean;
}

export interface PrerequisiteQuestionData {
  academicStudiesCompleted: boolean;
  enrolledInFirstOrSecondSemester: boolean;
  formOfStudies: FormOfStudies;
  hasGermanNationality?: boolean;
  permittedToReceiveBafoeg?: boolean;
  studiedInEuOrSwitzerland?: boolean;
  studiesPrimarilyInGermany?: boolean;
  suggestedForSdV: boolean;
  typeOfSchool: TypeOfSchool;
}

export enum TypeOfSchool {
  University = 'university',
  TechnicalCollege = 'technical-college',
  DualStudiesTechnicalCollege = 'dual-studies.technical-college',
  DualStudiesUniversity = 'dual-studies.university-of-cooperative-education',
  ArtCollege = 'art-college',
  Conservatory = 'conservatory',
}

export enum FormOfStudies {
  FullTime = 'full-time',
  PartTime = 'part-time',
}
