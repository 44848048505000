<div class="container-content" fxLayout="column">

  <h1 class="mat-headline">
    {{ 'mbr.registration-data-retrieval.title' | translate }}
  </h1>

  <mat-accordion [multi]="true">
    <!-- Install the app -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'mbr.registration-data-retrieval.install-ablage-app.title' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      {{ 'mbr.registration-data-retrieval.install-ablage-app.text.part-1' | translate }}

      <mat-grid-list
        cols="2"
        rowHeight="40vh"
      >
        <mat-grid-tile
          colspan="1"
        >
          <mat-grid-tile-header>
            {{ 'mbr.registration-data-retrieval.install-ablage-app.text.apple-app-store.text' | translate }}
          </mat-grid-tile-header>
          <img
            class="qr-code"
            src="/assets/img/mbr/Ablage_App-Apple_App_Store.png"
            [alt]="'mbr.registration-data-retrieval.install-ablage-app.text.apple-app-store.qr-code-alt' | translate"
          >
          <mat-grid-tile-footer>
            <a [href]="'mbr.registration-data-retrieval.install-ablage-app.text.apple-app-store.link' | translate">
              {{ 'mbr.registration-data-retrieval.install-ablage-app.text.apple-app-store.link' | translate }}
            </a>
          </mat-grid-tile-footer>
        </mat-grid-tile>
        <mat-grid-tile
          colspan="1"
        >
          <mat-grid-tile-header>
            {{ 'mbr.registration-data-retrieval.install-ablage-app.text.google-play-store.text' | translate }}
          </mat-grid-tile-header>
          <img
            class="qr-code"
            src="/assets/img/mbr/Ablage_App-Google_Play_Store.png"
            [alt]="'mbr.registration-data-retrieval.install-ablage-app.text.google-play-store.qr-code-alt' | translate"
          >
          <mat-grid-tile-footer>
            <a [href]="'mbr.registration-data-retrieval.install-ablage-app.text.google-play-store.link' | translate">
              {{ 'mbr.registration-data-retrieval.install-ablage-app.text.google-play-store.link' | translate }}
            </a>
          </mat-grid-tile-footer>
        </mat-grid-tile>
      </mat-grid-list>
    </mat-expansion-panel>

    <!-- Request data fields -->
    <mat-expansion-panel [hideToggle]="true" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'mbr.registration-data-retrieval.retrieve-data.title' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        {{ 'mbr.registration-data-retrieval.retrieve-data.text.description' | translate }}
      </p>
      <p class="data-qr-code">
        <mat-grid-list
          cols="1"
          rowHeight="40vh"
        >
          <mat-grid-tile
            colspan="1"
          >
            <img
              class="qr-code"
              [src]="qrCodeSrc"
              [alt]="'mbr.registration-data-retrieval.retrieve-data.text.qr-code-alt' | translate"
            >
          </mat-grid-tile>
        </mat-grid-list>
      </p>
      <p>
        {{ 'mbr.registration-data-retrieval.retrieve-data.text.link' | translate }}
        <a [href]="enmeshedAppLink">{{ enmeshedAppLink }}</a>
        <button mat-flat-button type="button" (click)="copyLinkToClipboard()">
          <mat-icon>content_copy</mat-icon>
        </button>
      </p>

      <ng-container *ngIf="!completed; else confirmationText">
        {{ 'mbr.registration-data-retrieval.retrieve-data.text.waiting-text.part-1' | translate }}
        <time [dateTime]="remainingTime?.toString()">{{ timeInMinutesAndSeconds(remainingTime) }}</time>
        {{ 'mbr.registration-data-retrieval.retrieve-data.text.waiting-text.part-2' | translate }}
      </ng-container>

      <ng-template #confirmationText>
        {{ 'mbr.registration-data-retrieval.retrieve-data.text.confirmation-text' | translate }}
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-card-actions>
    <button
      *ngIf="!completed"
      mat-raised-button
      color="warn"
      type="button"
      (click)="logoutAndDeleteUser()"
    >
      {{ 'button.logout-and-delete-account' | translate }}
    </button>
    <button
      *ngIf="completed"
      mat-raised-button
      color="primary"
      type="button"
      (click)="continueToDashboard()"
    >
      {{ 'button.next' | translate }}
    </button>
  </mat-card-actions>
</div>
