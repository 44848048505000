<mat-card>
  <mat-card-header>
    <mat-card-title>{{
      headline | translate
    }}</mat-card-title>
  </mat-card-header>
  <app-form-field-select
    [field]="examControl"
    [options]="examSelectOptions"
    label="label.exams.exam"
    (selectionChange)="testSelected($event)"
  ></app-form-field-select>
  <app-form-field-text
    [field]="capacityControl"
    label="label.capacity"
  ></app-form-field-text>

  <ng-container *ngIf="showFormFields">
    <app-form-field-datetime
      [field]="timeControl"
      label="label.time"
    ></app-form-field-datetime>
    <app-form-field-checkbox
      [field]="defaultPriceControl"
      label="label.event-use-standard-price"
      [translateParameters]="{
        param1: this.defaultPrice,
        param2: this.currency
      }"
    >
    </app-form-field-checkbox>
    <app-form-field-monetary-amount
      *ngIf="!defaultPriceControl.value"
      [field]="priceControl"
      label="label.registration-price"
      [currency]="currency"
    ></app-form-field-monetary-amount>
    <app-form-field-datetime
      [field]="registrationPeriodStartControl"
      label="label.registration-period-start"
    ></app-form-field-datetime>
    <app-form-field-datetime
      [field]="registrationPeriodEndControl"
      label="label.registration-period-end"
      [min]="startingPointForRegistrationPeriodEnd"
    ></app-form-field-datetime>
  </ng-container>

  <mat-card-actions>
    <button
      (click)="delete()"
      mat-raised-button
      color="accent"
      [disabled]="deleteButtonDisabled"
    >
      {{ 'button.delete' | translate }}
    </button>
    <button
      (click)="submit()"
      mat-raised-button
      color="primary"
    >
      {{ 'button.save' | translate }}
    </button>
  </mat-card-actions>
</mat-card>
<button
  (click)="closeForm.emit()"
  class="close-button"
  color="primary"
  mat-mini-fab
>
  <mat-icon>close</mat-icon>
</button>
