import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  MatSort,
  MatSortable,
  Sort,
} from '@angular/material/sort';
import { Subscription } from 'rxjs';
import Tools from '../../../lib/Tools';
import {
  ExamEventService,
  ExamService,
} from '../../../services';
import {
  ExamCenter,
  ExamEvent,
  ListFilter,
  SelectOption,
} from '../../../types';

@Component({
  selector: 'app-exam-event-table',
  templateUrl: './exam-event-table.component.html',
  styleUrls: ['./exam-event-table.component.scss'],
})
export class ExamEventTableComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() examCenter!: ExamCenter;
  @Output()
  itemClicked: EventEmitter<ExamEvent> = new EventEmitter<ExamEvent>();
  @Output()
  createClicked: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild(MatSort, { static: true }) sortTable!: MatSort;

  rowsConfig = ['testeventTime', 'testId'];

  private defaultSortColumnConfig: MatSortable;
  private sortSubcription!: Subscription;

  constructor(
    private examEventService: ExamEventService,
    private examService: ExamService
  ) {
    this.defaultSortColumnConfig = {
      id: this.examEventService.listState.filter.sort,
      start:
        this.examEventService.listState.filter.direction,
      disableClear: true,
    };
  }

  ngOnInit(): void {
    // Default sort column
    this.sortTable.sort(this.defaultSortColumnConfig);
    this.examEventService.listState.filter =
      ExamEventService.resetFilter();
    this.examEventService.listState.filter.testcentersId =
      this.examCenter.id;
    this.examEventService.loadList();
  }

  ngAfterViewInit(): void {
    this.sortSubcription =
      this.sortTable.sortChange.subscribe(
        (sortColumn: Sort) => {
          this.examEventService.listState.filter.sort =
            sortColumn.active;
          this.examEventService.listState.filter.testcentersId =
            this.examCenter.id;
          this.examEventService.listState.filter.direction =
            sortColumn.direction as ListFilter['direction'];
          this.examEventService.loadList();
        }
      );
  }

  get items(): ExamEvent[] {
    return this.examEventService.listState.response.items;
  }

  getTestName(examEvent: ExamEvent): string {
    const examOption =
      this.examService.examSelectOptions.find(
        (item) => item.value === examEvent.testId
      );
    return (examOption as SelectOption)?.label;
  }

  getTestLogo(examEvent: ExamEvent): string | undefined {
    const examOption =
      this.examService.examSelectOptions.find(
        (item) => item.value === examEvent.testId
      );
    return (examOption as SelectOption)?.imagePath;
  }

  getTimeLabel(examEvent: ExamEvent): string {
    const date = Tools.dateFromIso(examEvent.testeventTime);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  }

  rowClicked(examEvent: ExamEvent): void {
    this.itemClicked.emit(examEvent);
  }

  createButtonClicked(): void {
    this.createClicked.emit();
  }

  ngOnDestroy(): void {
    this.sortSubcription.unsubscribe();
  }
}
