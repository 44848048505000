import { Supervision } from './ExamLocation';

export interface SelectOption {
  value: null | boolean | number | string;
  imagePath?: string;
  label: string;
  supervision?: Supervision;
}

export interface SelectOptionGroup {
  groupName: string;
  options: SelectOption[];
}
