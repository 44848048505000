import {
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogResult } from '../../../types';

@Component({
  selector: 'app-invite-success-dialog',
  templateUrl: './invite-success-dialog.component.html',
  styleUrls: ['./invite-success-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InviteSuccessDialogComponent {
  constructor(
    public dialog: MatDialogRef<
      InviteSuccessDialogComponent,
      DialogResult
    >
  ) {}
}
