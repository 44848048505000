import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { InfoPageDialogComponent } from 'src/app/components/dialogs';
import { BookingService } from 'src/app/services';

@Component({
  selector: 'app-step3-requirements-info-page-sdv',
  templateUrl:
    './step3-requirements-info-page-sdv.component.html',
  styleUrls: [
    './step3-requirements-info-page-sdv.component.scss',
  ],
})
export class Step3RequirementsInfoPageSdvComponent {
  constructor(
    private bookingService: BookingService,
    public dialog: MatDialog,
    private router: Router
  ) {}

  showInfoModal(event: MouseEvent, template: string): void {
    event.preventDefault();
    this.dialog.open(InfoPageDialogComponent, {
      data: template,
      autoFocus: false,
    });
  }

  async cancelBooking(): Promise<void> {
    if (this.bookingId > 0) {
      await this.bookingService
        .deleteBooking(this.bookingId)
        .finally(() => {
          this.bookingService.formState = {
            loading: false,
            item: BookingService.factoryItem(),
          };
        });
    }
    this.router.navigate(['/home']);
  }

  private get bookingId(): number {
    return this.bookingService.formState.item.bookingId;
  }
}
