<mat-form-field
  [appearance]="appearance"
  [class.disabled]="disabled"
>
  <mat-label *ngIf="hasLabel">{{
    label | translate
  }}</mat-label>
  <input
    [matDatepicker]="picker"
    placeholder="{{ placeholder | translate }}"
    [formControl]="field"
    (click)="picker.open()"
    readonly
    matInput
    [min]="minDate"
    [max]="maxDate"
    [required]="required && !disabled"
  />
  <button
    *ngIf="hasValue && !disabled"
    (click)="clear()"
    mat-icon-button
    matSuffix
  >
    <mat-icon>close</mat-icon>
  </button>
  <mat-datepicker-toggle
    [for]="picker"
    *ngIf="!disabled"
    matSuffix
  ></mat-datepicker-toggle>
  <mat-datepicker
    [disabled]="disabled"
    #picker
  ></mat-datepicker>
  <mat-error>{{ error | translate }}</mat-error>
</mat-form-field>
