import {
  Component,
  HostBinding,
  OnInit,
} from '@angular/core';
import { ExamCenterService } from '../../services';
import { ListFilter } from '../../types';

@Component({
  selector: 'app-exam-center-page',
  templateUrl: './exam-center-page.component.html',
  styleUrls: ['./exam-center-page.component.scss'],
})
export class ExamCenterPageComponent implements OnInit {
  @HostBinding('class.table-page-component')
  hostClass = true;
  constructor(private service: ExamCenterService) {}

  ngOnInit(): void {
    this.service.loadList();
  }

  get filter(): ListFilter {
    return this.service.listState.filter;
  }

  get total(): number {
    return this.service.listState.response.total;
  }

  get loading(): boolean {
    return this.service.listState.loading;
  }

  load(): void {
    this.service.loadList();
  }
}
