import { Component, HostBinding } from '@angular/core';
import { ExamService } from '../../services';

@Component({
  selector: 'app-exam-form-page',
  templateUrl: './exam-form-page.component.html',
  styleUrls: ['./exam-form-page.component.scss'],
})
export class ExamFormPageComponent {
  @HostBinding('class.form-page-component')
  hostClass = true;
  constructor(private service: ExamService) {}

  get headline(): string {
    return this.service.formState.item.id > 0
      ? 'label.exams.edit-exam'
      : 'label.exams.create-exam';
  }
}
