import { NgModule } from '@angular/core';
import { NGX_TRANSLATE_CONFIG } from 'src/app/config/ngx-translate-config';
import { SharedModule } from 'src/app/config/shared.module';
import { HasRequiredValidatorPipe } from 'src/app/pipes';
import {
  ExamBookingFormDefaultComponent,
  Step1ProfileCheckFormComponent,
  Step2EventLocationFormComponent,
  Step3TestTimeFormComponent,
  Step4AdditionalQuestionsFormComponent,
  Step5BookingOverviewComponent,
} from './default';
import {
  AddQuestionsAnswersOverviewComponent,
  ExamBookingFormSdvComponent,
  OptionalQuestionsAnswersOverviewComponent,
  RequirementsCheckDeclineMessageComponent,
  Step1ProfileCheckFormSdvComponent,
  Step2RequirementsCheckFormSdvComponent,
  Step3RequirementsInfoPageSdvComponent,
  Step4aConsentProctoringModalComponent,
  Step4TestTimeFormSdvComponent,
  Step5PricingSdvComponent,
  Step6AdditionalQuestionsFormSdvComponent,
  Step7BookingOverviewSdvComponent,
} from './sdv';

@NgModule({
  imports: [
    NGX_TRANSLATE_CONFIG,
    SharedModule,
    HasRequiredValidatorPipe,
  ],
  declarations: [
    AddQuestionsAnswersOverviewComponent,
    ExamBookingFormDefaultComponent,
    ExamBookingFormSdvComponent,
    OptionalQuestionsAnswersOverviewComponent,
    RequirementsCheckDeclineMessageComponent,
    Step1ProfileCheckFormComponent,
    Step1ProfileCheckFormSdvComponent,
    Step2EventLocationFormComponent,
    Step2RequirementsCheckFormSdvComponent,
    Step3RequirementsInfoPageSdvComponent,
    Step3TestTimeFormComponent,
    Step4aConsentProctoringModalComponent,
    Step4AdditionalQuestionsFormComponent,
    Step4TestTimeFormSdvComponent,
    Step5BookingOverviewComponent,
    Step5PricingSdvComponent,
    Step6AdditionalQuestionsFormSdvComponent,
    Step7BookingOverviewSdvComponent,
  ],
  exports: [
    AddQuestionsAnswersOverviewComponent,
    ExamBookingFormDefaultComponent,
    ExamBookingFormSdvComponent,
    OptionalQuestionsAnswersOverviewComponent,
  ],
})
export class ExamBookingFormModule {}
