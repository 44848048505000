import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { firstValueFrom, Subscription } from 'rxjs';
import {
  BookingService,
  NotificationService,
} from 'src/app/services';
import { EventBooking, SelectOption } from 'src/app/types';
import { ExamBookingAddQuestionsAnswersRequest } from 'src/app/types/request/ExamBookingAddQuestionsAnswersRequest';

@Component({
  selector: 'app-step4-additional-questions-form',
  templateUrl:
    './step4-additional-questions-form.component.html',
  styleUrls: [
    './step4-additional-questions-form.component.scss',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class Step4AdditionalQuestionsFormComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Output()
  additionalQuestionsFormValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() nextStep: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  confirmSendResultsOption: SelectOption[] = [
    {
      value: 1,
      label: 'tm-wiso.questions.confirm-send-results.yes',
    },
    {
      value: 2,
      label: 'tm-wiso.questions.confirm-send-results.no',
    },
  ];
  form!: FormGroup;
  languageOptions: SelectOption[] = [];
  sendResultsToUniversitiesOptions: SelectOption[] = [];
  universityOptions: SelectOption[] = [];

  private unsubOnDestroy: Subscription = new Subscription();

  constructor(
    private bookingService: BookingService,
    private changeDetectorRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private logger: NGXLogger,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.getAdditionalBookingQuestions(
      this.booking.bookingId
    );

    this.form = this.formBuilder.group({
      applyForUniversity: [''],
      confirmSendResults: [''],
      currentStudySubject: [''],
      currentTypeOfDegree: [''],
      currentUniversityName: [''],
      currentUniversityPlace: [''],
      finalGrade: [''],
      graduateDegree: [''],
      language: [''],
      sendResultsToUniversities: [''],
      university: [''],
    });

    // Set timeout to avoid ExpressionChangedAfterItHasBeenCheckedError in ExamBookingFormComponent
    // TODO: Find another way to update the form in the parent component
    setTimeout(() => {
      this.additionalQuestionsFormValid.emit(
        this.form.valid
      );
    }, 0);

    this.unsubOnDestroy.add(
      this.translateService.onLangChange.subscribe(() => {
        this.sortUniversitiesByTranslation(
          this.universityOptions
        );
        this.sortUniversitiesByTranslation(
          this.sendResultsToUniversitiesOptions
        );
      })
    );
  }

  ngAfterViewInit(): void {
    this.unsubOnDestroy.add(
      this.form.valueChanges.subscribe((_value) => {
        this.additionalQuestionsFormValid.emit(
          this.form.valid
        );
      })
    );
    this.changeDetectorRef.detectChanges();
  }

  async updateBooking(): Promise<void> {
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      return;
    }

    let addQuestionAnswersRequestBody: ExamBookingAddQuestionsAnswersRequest;

    switch (this.booking.test.templateName) {
      case 'PhaST':
        addQuestionAnswersRequestBody = {
          universityIds: this.university.value || [],
          finalGrade:
            this.finalGrade.value?.trim() || undefined,
        };
        break;

      case 'TM-WISO':
        addQuestionAnswersRequestBody = {
          finalGrade:
            this.finalGrade.value?.trim() || undefined,
          language: this.language.value as string,
          sendResultsToUniversityIds: this
            .sendResultsToUniversities.value?.length
            ? this.sendResultsToUniversities.value
            : undefined,

          studiesDetails: {
            graduateDegree:
              this.graduateDegree.value?.trim() ||
              undefined,
            subject:
              this.currentStudySubject.value || undefined,
            typeOfDegree:
              this.currentTypeOfDegree.value || undefined,
          },
          universityDetails: {
            name:
              this.currentUniversityName.value || undefined,
            city:
              this.currentUniversityPlace.value ||
              undefined,
          },
          universityIds: this.applyForUniversity
            .value as number[],
        };
        break;

      case 'GSAT':
        addQuestionAnswersRequestBody = {
          language: this.language.value as string,
        };
        break;

      case 'ITB-Business':
        addQuestionAnswersRequestBody = {
          language: this.language.value as string,
          universityIds: this.applyForUniversity
            .value as number[],
          sendResultsToUniversityIds: this
            .sendResultsToUniversities.value?.length
            ? this.sendResultsToUniversities.value
            : undefined,
          finalGrade:
            this.finalGrade.value?.trim() || undefined,
        };
        break;

      case 'ITB-Science':
      case 'ITB-Technology':
        addQuestionAnswersRequestBody = {
          language: this.language.value as string,
          finalGrade:
            this.finalGrade.value?.trim() || undefined,
        };
        break;

      default:
        addQuestionAnswersRequestBody = {
          universityIds: [],
        };
    }

    this.booking.additionalQuestionData =
      addQuestionAnswersRequestBody;

    await this.bookingService
      .updateBooking(this.booking.bookingId)
      .catch(async (error) => {
        this.nextStep.emit(false);
        await this.fail(error);
      })
      .then(() => this.nextStep.emit(true));
  }

  ngOnDestroy(): void {
    this.unsubOnDestroy.unsubscribe();
  }

  private getAdditionalBookingQuestions(
    bookingId: number
  ): void {
    this.bookingService
      .loadAdditionalBookingQuestions(bookingId)
      .then((additionalBookingQuestions) => {
        if (additionalBookingQuestions.languages) {
          this.languageOptions =
            additionalBookingQuestions.languages.map(
              (lang) => ({
                value: lang,
                label: lang,
              })
            );
        }

        if (
          additionalBookingQuestions.sendResultsToUniversities
        ) {
          this.sendResultsToUniversitiesOptions =
            additionalBookingQuestions.sendResultsToUniversities.map(
              ({ id, translationKey }) => ({
                value: id,
                label: translationKey,
              })
            );
        }

        if (additionalBookingQuestions.universities) {
          this.universityOptions =
            additionalBookingQuestions.universities.map(
              ({ id, translationKey }) => ({
                value: id,
                label: translationKey,
              })
            );
        }
      })
      .then(() => {
        this.sortUniversitiesByTranslation(
          this.universityOptions
        ).then((sortedOptions) => {
          this.universityOptions = sortedOptions;
        });

        this.sortUniversitiesByTranslation(
          this.sendResultsToUniversitiesOptions
        ).then((sortedOptions) => {
          this.sendResultsToUniversitiesOptions =
            sortedOptions;
        });
      })
      .catch(() => this.fail);
  }

  private async sortUniversitiesByTranslation(
    universityOptions: SelectOption[]
  ): Promise<SelectOption[]> {
    const translatedUniversityOptions = await Promise.all(
      universityOptions.map(async (selectOption) => {
        const translation = await firstValueFrom(
          this.translateService.get(selectOption.label)
        );
        return {
          selectOption,
          translation,
        };
      })
    );

    universityOptions = translatedUniversityOptions
      .sort((first, second) =>
        (first.translation as string).localeCompare(
          second.translation as string
        )
      )
      .map(({ selectOption }) => selectOption);

    return universityOptions;
  }

  private fail(
    error: HttpErrorResponse
  ): Promise<undefined> {
    this.logger.error(
      'get additional booking questions failed',
      { error }
    );

    const errorDialog =
      this.notificationService.httpError(error);
    return errorDialog.finally(() => Promise.reject(error));
  }

  get booking(): EventBooking {
    return this.bookingService.formState.item;
  }

  get university(): FormControl<number[] | null> {
    return this.form.get('university') as FormControl<
      number[] | null
    >;
  }

  get finalGrade(): FormControl<string | null> {
    return this.form.get('finalGrade') as FormControl<
      string | null
    >;
  }

  get language(): FormControl<string | null> {
    return this.form.get('language') as FormControl<
      string | null
    >;
  }

  get applyForUniversity(): FormControl<number[] | null> {
    return this.form.get(
      'applyForUniversity'
    ) as FormControl<number[] | null>;
  }

  get currentUniversityName(): FormControl<string | null> {
    return this.form.get(
      'currentUniversityPlace'
    ) as FormControl<string | null>;
  }

  get currentUniversityPlace(): FormControl<string | null> {
    return this.form.get(
      'currentUniversityPlace'
    ) as FormControl<string | null>;
  }

  get currentStudySubject(): FormControl<string | null> {
    return this.form.get(
      'currentStudySubject'
    ) as FormControl<string | null>;
  }

  get currentTypeOfDegree(): FormControl<string | null> {
    return this.form.get(
      'currentTypeOfDegree'
    ) as FormControl<string | null>;
  }

  get graduateDegree(): FormControl<string | null> {
    return this.form.get('graduateDegree') as FormControl<
      string | null
    >;
  }

  get confirmSendResults(): FormControl<number | null> {
    return this.form.get(
      'confirmSendResults'
    ) as FormControl<number | null>;
  }

  get sendResultsToUniversities(): FormControl<
    number[] | null
  > {
    return this.form.get(
      'sendResultsToUniversities'
    ) as FormControl<number[] | null>;
  }
}
