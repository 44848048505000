import { AfterViewInit, Component } from '@angular/core';
import {
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import {
  BookingService,
  CountryService,
  ExamService,
  GenderService,
  NotificationService,
  ProfileService,
  SnackBarService,
  UserService,
} from 'src/app/services';
import { RadioOption } from 'src/app/types';
import { Step1ProfileCheckFormComponent as DefaultStep1ProfileCheckFormComponent } from '../../default';

@Component({
  selector: 'app-step1-profile-check-form-sdv',
  templateUrl:
    './step1-profile-check-form-sdv.component.html',
  styleUrls: [
    '../../default/step1-profile-check-form/step1-profile-check-form.component.scss',
    './step1-profile-check-form-sdv.component.scss',
  ],
})
export class Step1ProfileCheckFormSdvComponent
  extends DefaultStep1ProfileCheckFormComponent
  implements AfterViewInit
{
  parentalAddressesOptions: RadioOption[] = [
    {
      label:
        'userInfo.parental-addresses.radio-btn.options.yes',
      value: true,
    },
    {
      label:
        'userInfo.parental-addresses.radio-btn.options.no',
      value: false,
    },
  ];

  constructor(
    private bookingService: BookingService,
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    protected countryService: CountryService,
    protected dialog: MatDialog,
    protected examService: ExamService,
    protected formBuilder: UntypedFormBuilder,
    protected genderService: GenderService,
    protected logger: NGXLogger,
    protected notificationService: NotificationService,
    protected profileService: ProfileService,
    protected snackBar: SnackBarService,
    protected userService: UserService
  ) {
    super(
      activatedRoute,
      countryService,
      dialog,
      examService,
      formBuilder,
      genderService,
      logger,
      notificationService,
      profileService,
      snackBar,
      userService
    );
  }

  ngAfterViewInit(): void {
    this.parentalAddressesRadioControl.valueChanges.subscribe(
      (isParentalAddresses) => {
        if (
          isParentalAddresses &&
          this.item.parentalAddresses.length === 0
        ) {
          this.addParentalAddress();
        } else if (!isParentalAddresses) {
          this.removeParentalAddresses();
        }
      }
    );
  }

  async cancelBooking(): Promise<void> {
    if (this.bookingId > 0) {
      await this.bookingService
        .deleteBooking(this.bookingId)
        .finally(() => {
          this.bookingService.formState = {
            loading: false,
            item: BookingService.factoryItem(),
          };
        });
    }
    this.router.navigate(['/home']);
  }

  private get bookingId(): number {
    return this.bookingService.formState.item.bookingId;
  }

  private addParentalAddress(): void {
    const parentAddress = this.formBuilder.group({
      street: ['', Validators.required],
      streetNumber: ['', Validators.required],
      additionalAddressInfo: [''],
      zip: ['', Validators.required],
      city: ['', Validators.required],
      state: [''],
      country: ['', Validators.required],
    });

    this.parentalAddressesFormArray.push(parentAddress);
  }

  private removeParentalAddresses(): void {
    this.parentalAddressesFormArray.clear();
    this.userService.formState.item.parentalAddresses = [];
  }
}
