<div class="form-content">
  <div class="form-block">
    <app-form-field-text
      [field]="nameControl"
      label="label.name"
      [required]="true"
      [disabled]="!isEditMode"
    ></app-form-field-text>
    <app-form-field-select
      [field]="templateControl"
      [options]="templateOptions"
      label="label.test-template"
      [required]="true"
      [disabled]="!isEditMode"
    ></app-form-field-select>
  </div>

  <div class="form-block">
    <app-form-field-date
      [field]="validityStartControl"
      [required]="true"
      [disabled]="!isEditMode"
      label="label.validity-period-start"
    ></app-form-field-date>
    <app-form-field-date
      [field]="validityEndControl"
      [minDate]="earliestEndDate"
      [disabled]="!isEditMode"
      label="label.validity-period-end"
    ></app-form-field-date>
  </div>

  <div class="form-block">
    <app-form-field-text
      [field]="testReportPdfTemplateIdControl"
      label="label.pft-templates.report"
      [required]="true"
      [disabled]="!isEditMode"
    ></app-form-field-text>
    <app-form-field-text
      [field]="invitationPdfTemplateIdControl"
      label="label.pft-templates.invitation"
      [required]="true"
      [disabled]="!isEditMode"
    ></app-form-field-text>
  </div>

  <mat-tab-group>
    <mat-tab
      [label]="'label.exams.exam-statistics' | translate"
      [aria-label]="'label.exams.exam-statistics' | translate"
    >
      <app-exam-event-statistics-table
        [searchFilters]="eventStatisticsSearchFilters"
        (eventChosen)="testEventSelected($event)"
        (eventsSelectedForMultiAction)="updateEventsSelectedForMultiAction($event)"
        [hidden]="isEditMode"
      ></app-exam-event-statistics-table>

      <div class="buttons">
        <!-- Back, Cancel, and Delete buttons-->
        <button
          *ngIf="!isEditMode"
          [routerLink]="['/home/exam']"
          mat-raised-button
        >
          <mat-icon>arrow_back_ios</mat-icon>
          {{ 'button.back' | translate }}
        </button>
        <button
          *ngIf="isEditMode"
          (click)="toggleEditMode()"
          mat-raised-button
        >
          <mat-icon>edit_off</mat-icon>
          {{ 'button.cancel' | translate }}
        </button>
        <button
          (click)="delete()"
          mat-raised-button
          color="warn"
          *ngIf="isEditMode"
        >
          <mat-icon>delete</mat-icon>
          {{ 'button.delete' | translate }}
        </button>

        <!-- Spacer before we get to the testevent buttons -->
        <div class="flex-1"></div>

        <!-- Up- and Download buttons -->
        <button
          (click)="downloadRegistrations()"
          mat-raised-button
          color="accent"
          *ngIf="!isEditMode"
          [disabled]="areTestEventsSelected"
          attr.aria-label="{{ 'button.download.test-registrations-csv-full' | translate }}"
          [matTooltip]="'button.download.test-registrations-csv-full' | translate"
        >
          <mat-icon aria-hidden="true">download</mat-icon>
          <span aria-hidden="true">
      {{ 'button.download.test-registrations-csv' | translate }}
    </span>
        </button>
        <button
          (click)="downloadStatistics()"
          mat-raised-button
          color="accent"
          *ngIf="!isEditMode"
          [disabled]="areTestEventsSelected"
          attr.aria-label="{{ 'button.download.test-event-statistics-csv-full' | translate }}"
          [matTooltip]="'button.download.test-event-statistics-csv-full' | translate"
        >
          <mat-icon aria-hidden="true">download</mat-icon>
          <span aria-hidden="true">
      {{ 'button.download.test-event-statistics-csv' | translate }}
    </span>
        </button>

        <button
          mat-raised-button
          color="accent"
          *ngIf="!isEditMode"
          [matMenuTriggerFor]="invitationMenu"
          attr.aria-label="{{ 'button.invitations.menu-full' | translate }}"
          [matTooltip]="'button.invitations.menu-full' | translate"
        >
          <mat-icon>more_vert</mat-icon>
          {{ 'button.invitations.menu' | translate }}
        </button>
        <mat-menu
          #invitationMenu="matMenu"
          [aria-label]="'button.invitations.menu-full' | translate"
          class="invitations-menu"
          yPosition="above"
        >
          <ngx-file-drop
            class="invitation-file-upload"
            (onFileDrop)="uploadTestInvitationData($event)"
            [directory]="false"
            dropZoneClassName="file-upload-drop-zone"
            contentClassName="file-upload-content"
            [multiple]="false"
            accept=".csv,.ssv"
          >
            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
              <button
                (click)="openFileSelector()"
                mat-button
                color="accent"
                *ngIf="!isEditMode"
                [disabled]="currentlyUploadingInvitationData"
                attr.aria-label="{{ 'button.invitations.upload-full' | translate }}"
                [matTooltip]="'button.invitations.upload-full' | translate"
                matTooltipPosition="before"
              >
                <mat-icon aria-hidden="true">upload</mat-icon>
                <span aria-hidden="true">
            {{ 'button.invitations.upload' | translate }}
          </span>
              </button>
            </ng-template>
          </ngx-file-drop>

          <button
            (click)="sendInvitationsForSelectedEvents()"
            mat-button
            color="accent"
            *ngIf="!isEditMode"
            [disabled]="isSendBookingsDisabled"
            attr.aria-label="{{ 'button.invitations.send-selected' | translate }}"
            [matTooltip]="'button.invitations.send-selected' | translate"
            matTooltipPosition="before"
          >
            <mat-icon aria-hidden="true">forward_to_inbox</mat-icon>
            <span aria-hidden="true">
              {{ 'button.invitations.send-selected' | translate }}
            </span>
          </button>
        </mat-menu>

        <button
          mat-raised-button
          color="accent"
          *ngIf="!isEditMode"
          [matMenuTriggerFor]="testResultMenu"
          attr.aria-label="{{ 'button.test-results.menu-full' | translate }}"
          [matTooltip]="'button.test-results.menu-full' | translate"
        >
          <mat-icon>more_vert</mat-icon>
          {{ 'button.test-results.menu' | translate }}
        </button>
        <mat-menu
          #testResultMenu="matMenu"
          [aria-label]="'button.test-results.menu-full' | translate"
          class="test-result-menu"
          yPosition="above"
        >
          <ngx-file-drop
            class="result-file-upload"
            (onFileDrop)="uploadTestResults($event)"
            [directory]="false"
            dropZoneClassName="file-upload-drop-zone"
            contentClassName="file-upload-content"
            [multiple]="false"
            accept=".csv,.ssv"
          >
            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
              <button
                (click)="openFileSelector()"
                mat-button
                color="accent"
                *ngIf="!isEditMode"
                [disabled]="currentlyUploadingResults"
                attr.aria-label="{{ 'button.test-results.upload-full' | translate }}"
                [matTooltip]="'button.test-results.upload-full' | translate"
                matTooltipPosition="before"
              >
                <mat-icon aria-hidden="true">upload</mat-icon>
                <span aria-hidden="true">
            {{ 'button.test-results.upload' | translate }}
          </span>
              </button>
            </ng-template>
          </ngx-file-drop>
          <button
            (click)="downloadTestResults()"
            mat-button
            color="accent"
            *ngIf="!isEditMode"
            [disabled]="areTestEventsSelected"
            attr.aria-label="{{ 'button.test-results.download-full' | translate }}"
            [matTooltip]="'button.test-results.download-full' | translate"
            matTooltipPosition="before"
          >
            <mat-icon aria-hidden="true">download</mat-icon>
            <span aria-hidden="true">
          {{ 'button.test-results.download' | translate }}
        </span>
          </button>
          <button
            (click)="generateAndSendOutReports()"
            mat-button
            color="accent"
            *ngIf="!isEditMode"
            [disabled]="areTestEventsSelected"
            attr.aria-label="{{ 'button.test-results.generate-pdfs-full' | translate }}"
            [matTooltip]="'button.test-results.generate-pdfs-full' | translate"
            matTooltipPosition="before"
          >
            <mat-icon aria-hidden="true">forward_to_inbox</mat-icon>
            <span aria-hidden="true">
      {{ 'button.test-results.generate-pdfs' | translate }}
    </span>
          </button>
        </mat-menu>

        <!-- Spacer before we get back to the test buttons -->
        <div class="flex-1"></div>

        <!-- Edit / Save buttons -->
        <button
          (click)="toggleEditMode()"
          mat-raised-button
          color="warn"
          *ngIf="!isEditMode"
        >
          <mat-icon>edit</mat-icon>
          {{ 'button.edit' | translate }}
        </button>
        <button
          (click)="submit()"
          mat-raised-button
          color="accent"
          *ngIf="isEditMode"
        >
          <mat-icon>save</mat-icon>
          {{ 'button.save' | translate }}
        </button>
      </div>
    </mat-tab>
<!--    <mat-tab-->
<!--      [label]="'label.exams.exam-events' | translate"-->
<!--      [aria-label]="'label.exams.exam-events' | translate"-->
<!--    >-->
<!--      TODO: Hier kommt eine Liste der Testevents rein.-->
<!--    </mat-tab>-->
  </mat-tab-group>

</div>
