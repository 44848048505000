import {
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { I18nService, LayoutService } from '../../services';
import { SupportedLanguage } from '../../types';

@Component({
  selector: 'app-i18n-button',
  templateUrl: './i18n-button.component.html',
  styleUrls: ['./i18n-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class I18nButtonComponent
  implements OnInit, OnDestroy
{
  private isHandset!: boolean;
  private unsubOnDestroy: Subscription = new Subscription();

  constructor(
    private i18nService: I18nService,
    private layout: LayoutService
  ) {}

  ngOnInit(): void {
    this.unsubOnDestroy.add(
      this.layout.isHandset$.subscribe(
        (isHandset) => (this.isHandset = isHandset)
      )
    );
  }

  get languages(): SupportedLanguage[] {
    return this.i18nService.supportedLanguages;
  }

  get currentLangLabel(): string {
    return this.getLangLabel(
      this.i18nService.currentLanguage as SupportedLanguage
    );
  }

  getLangLabel(lang: SupportedLanguage): string {
    const langLabel = this.isHandset
      ? lang?.code ?? ''
      : lang?.label;

    return langLabel[0].toUpperCase() + langLabel.slice(1);
  }

  switchLanguage(lang: SupportedLanguage): void {
    this.i18nService.switchLanguage(lang);
  }

  ngOnDestroy(): void {
    this.unsubOnDestroy.unsubscribe();
  }
}
