import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import {
  BookingService,
  ExamEventService,
  NotificationService,
  UserService,
} from 'src/app/services';
import { Supervision } from 'src/app/types';
import { Step3TestTimeFormComponent as DefaultStep3TestTimeFormComponent } from '../../default';
import { Step4aConsentProctoringModalComponent } from '../step4a-consent-proctoring-modal';

@Component({
  selector: 'app-step4-test-time-form-sdv',
  templateUrl: 'step4-test-time-form-sdv.component.html',
  styleUrls: [
    '../../default/step3-test-time-form/step3-test-time-form.component.scss',
    './step4-test-time-form-sdv.component.scss',
  ],
})
export class Step4TestTimeFormSdvComponent extends DefaultStep3TestTimeFormComponent {
  constructor(
    protected activatedRoute: ActivatedRoute,
    protected bookingService: BookingService,
    public dialog: MatDialog,
    protected examEventService: ExamEventService,
    protected formBuilder: UntypedFormBuilder,
    protected logger: NGXLogger,
    protected notificationService: NotificationService,
    private router: Router,
    protected userService: UserService
  ) {
    super(
      activatedRoute,
      bookingService,
      examEventService,
      formBuilder,
      logger,
      notificationService,
      userService
    );
  }

  createBookingSdv(): void {
    const isProctoringEvent =
      this.examEventService.listState.response.items.find(
        (event) => event.id === this.examEventChoosen.value
      )?.supervision === Supervision.Proctoring;

    this.form.markAllAsTouched();
    if (!this.form.valid) {
      return;
    } else if (
      this.bookingService.formState.item.testEventId ===
        this.examEventChoosen.value &&
      this.isBookingCreated
    ) {
      // This is the case where we:
      // - select an event
      // - navigate forward
      // - navigate back to this step
      // - do NOT select a different event
      // - proceed from this step once again
      // In this case, we don't have to notify the backend to create a new booking.
      if (isProctoringEvent) {
        this.dialog
          .open(Step4aConsentProctoringModalComponent, {
            data: false,
            autoFocus: false,
          })
          .afterClosed()
          .subscribe((result: any) => {
            //Only if the consent form is true
            if (result?.data) {
              this.nextStep.emit(true);
            }
          });
      } else {
        this.nextStep.emit(true);
      }
    } else if (isProctoringEvent) {
      this.dialog
        .open(Step4aConsentProctoringModalComponent, {
          data: false,
          autoFocus: false,
        })
        .afterClosed()
        .subscribe((result: any) => {
          //Only if the consent form is true
          if (result?.data) {
            this.bookingRequest();
          }
        });
    } else {
      this.booking.priceCalculationData = undefined;
      this.bookingRequest();
    }
  }

  private bookingRequest(): void {
    const { prerequisiteQuestionId, priceCalculationData } =
      this.booking;

    this.bookingService.formState.item =
      BookingService.factoryItem();

    this.booking.userId =
      this.userService.formState.item.id;
    this.booking.testEventId = this.examEventChoosen.value;
    this.booking.prerequisiteQuestionId =
      prerequisiteQuestionId;
    this.booking.priceCalculationData =
      priceCalculationData;

    this.bookingService
      .createBooking()
      .catch(async (error: HttpErrorResponse) => {
        this.isBookingCreated = false;
        this.nextStep.emit(false);

        if (error.status === 409) {
          // This will happen when the last seat at this specific
          // timeslot in the chosen testarea has been booked
          // since the list of test events was loaded.
          const errorNotice =
            this.notificationService.error(
              `booking.${this.tenant}.error-message.event-overbooked`
            );
          return Promise.all([
            errorNotice,
            this.loadEvents(this.testId),
          ]).finally(() => Promise.reject(error));
        } else {
          return await this.fail(error);
        }
      })
      .then(() => {
        this.isBookingCreated = true;
        this.nextStep.emit(true);
      });
  }

  async cancelBooking(): Promise<void> {
    if (this.bookingId > 0) {
      await this.bookingService
        .deleteBooking(this.bookingId)
        .finally(() => {
          this.bookingService.formState = {
            loading: false,
            item: BookingService.factoryItem(),
          };
        });
    }
    this.router.navigate(['/home']);
  }

  private get bookingId(): number {
    return this.bookingService.formState.item.bookingId;
  }
}
