<form [formGroup]="form">
  <div class="form-content">
    <!-- block: base -->
    <div class="form-block">
      <app-form-field-text
        [field]="firstnameControl"
        label="label.firstname"
        [required]="firstnameControl | hasRequiredValidator"
      ></app-form-field-text>
      <app-form-field-text
        [field]="lastnameControl"
        label="label.lastname"
        [required]="lastnameControl | hasRequiredValidator"
      ></app-form-field-text>
      <app-form-field-text
        [field]="birthNameControl"
        label="label.birthname"
        [required]="birthNameControl | hasRequiredValidator"
      ></app-form-field-text>
      <app-form-field-date
        [field]="birthDateControl"
        [maxDate]="maxDate"
        label="label.birthDate"
        [required]="birthDateControl | hasRequiredValidator"
      >
      </app-form-field-date>
      <app-form-field-select
        id="genderField"
        [field]="genderControl"
        [options]="genderOptions"
        label="gender.label"
        [required]="genderControl | hasRequiredValidator"
      ></app-form-field-select>
      <mat-hint
        ><em>
          {{ 'gender.hint' | translate }}
        </em>
      </mat-hint>
    </div>

    <!-- block: personal -->
    <div class="form-block">
      <app-form-field-text
        [field]="birthLocationControl"
        label="label.birthLocation"
        [required]="
          birthLocationControl | hasRequiredValidator
        "
      ></app-form-field-text>
      <app-form-field-text
        [field]="phoneControl"
        label="label.phone"
        [required]="phoneControl | hasRequiredValidator"
      ></app-form-field-text>
      <app-form-field-text
        [field]="email2Control"
        label="label.email2"
        [required]="email2Control | hasRequiredValidator"
      ></app-form-field-text>
      <app-form-field-text
        [field]="streetControl"
        label="label.street"
        [required]="streetControl | hasRequiredValidator"
      ></app-form-field-text>
      <app-form-field-text
        [field]="streetNumberControl"
        label="label.street-number"
        [required]="
          streetNumberControl | hasRequiredValidator
        "
      ></app-form-field-text>
      <app-form-field-text
        [field]="addressAdditionControl"
        label="label.addressAddition"
        [required]="
          addressAdditionControl | hasRequiredValidator
        "
      ></app-form-field-text>
    </div>

    <!-- block: address -->
    <div class="form-block">
      <app-form-field-text
        [field]="postalCodeControl"
        label="label.postalCode"
        [required]="
          postalCodeControl | hasRequiredValidator
        "
      ></app-form-field-text>
      <app-form-field-text
        [field]="cityControl"
        label="label.city"
        [required]="cityControl | hasRequiredValidator"
      ></app-form-field-text>
      <app-form-field-text
        [field]="stateControl"
        label="label.state"
        [required]="stateControl | hasRequiredValidator"
      ></app-form-field-text>
      <app-form-field-select
        [field]="countryControl"
        [options]="countryOptions"
        sortBy="translated-label-asc"
        label="label.country"
        [required]="countryControl | hasRequiredValidator"
      ></app-form-field-select>
      <app-form-field-text
        [field]="emailControl"
        label="label.email"
      ></app-form-field-text>
      <!-- Not in use yet: BOTI-306
        <div class="spacerBottom">
        <button
          *ngIf="!isCreateMode"
          class="width100"
          (click)="editEmail()"
          mat-raised-button
        >
          {{ 'label.edit-email' | translate }}
        </button>
      </div> -->

      <div class="spacerBottom">
        <button
          class="width100"
          (click)="resetPassword()"
          mat-raised-button
        >
          {{ 'label.reset-password' | translate }}
        </button>
      </div>
    </div>
  </div>

  <ng-container *ngIf="tenant === 'sdv'">
    <div class="form-content">
      <app-form-field-radio
        [field]="parentalAddressesRadioControl"
        [options]="parentalAddressesOptions"
        [color]="'accent'"
        label="userInfo.parental-addresses.radio-btn.label"
      >
      </app-form-field-radio>
    </div>

    <ng-container
      *ngIf="parentalAddressesRadioControl.value"
      formArrayName="parentalAddresses"
    >
      <ng-container
        *ngFor="
          let parentalAddressFormGroup of parentalAddressesFormArray.controls
        "
      >
        <app-parental-address-form
          [parentalAddressFormGroup]="
            parentalAddressFormGroup
          "
        ></app-parental-address-form>
      </ng-container>
    </ng-container>
  </ng-container>

  <div class="form-content">
    <app-form-field-checkbox-gdpr
      class="spacerBottom"
      [field]="confirmedGDPRControl"
    ></app-form-field-checkbox-gdpr>
  </div>

  <div class="buttons">
    <button [routerLink]="['/profile']" mat-raised-button>
      {{ 'button.cancel' | translate }}
    </button>
    <div class="flex-1"></div>

    <!-- TODO: Delete account
  <button
    (click)="delete()"
    mat-raised-button
    color="accent"
    [disabled]="!canDelete"
  >
    {{ 'button.delete' | translate }}
  </button> -->
    <button
      (click)="submit()"
      mat-raised-button
      color="accent"
      [disabled]="form.invalid"
    >
      {{ 'button.save' | translate }}
    </button>
  </div>
</form>
