export type User = {
  additionalAddressInfo: string;
  admin: boolean;
  birthday: string;
  birthName: string;
  birthplace: string;
  candidate: boolean;
  city: string;
  confirmedGDPR: boolean;
  country: string;
  email: string;
  firstname: string;
  gender: string;
  id: number;
  lastname: string;
  parentalAddresses: ParentalAddressInfo[];
  phoneNumber: string;
  secondEmail: string;
  state: string;
  street: string;
  streetNumber: string;
  zip: string;
};

export interface ParentalAddressInfo {
  street: string;
  streetNumber?: string;
  additionalAddressInfo?: string;
  zip?: string;
  city?: string;
  state?: string;
  country?: string;
}
