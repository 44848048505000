export interface AdditionalBookingQuestionsResponse {
  finalGrade?: string;
  languages?: string[];
  sendResultsToUniversities?: University[];
  universities?: University[];
}

export interface University {
  id: number;
  translationKey: string;
}
