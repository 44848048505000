<mat-form-field
  [appearance]="appearance"
  [class.disabled]="disabled"
>
  <mat-label *ngIf="hasLabel">{{
    label | translate
  }}</mat-label>
  <mat-select
    [formControl]="field"
    placeholder="{{ placeholder | translate }}"
    [required]="required"
    [disabled]="disabled"
    [multiple]="multiple"
    (selectionChange)="selectionChange.emit($event)"
    [panelClass]="panelClass"
  >
    <mat-option *ngIf="hasDeselectOption">{{
      'label.clear-selection' | translate
    }}</mat-option>
    <ng-container *ngIf="!localizedDate">
      <mat-option
        *ngFor="let option of sortedOptions"
        [value]="stringify(option.value)"
      >
        {{ option.label | translate }}
        <img
          *ngIf="option.imagePath"
          [src]="option.imagePath"
          alt="{{ option.label | translate }}"
          class="image"
        />
      </mat-option>
    </ng-container>

    <ng-container *ngIf="localizedDate">
      <mat-option
        *ngFor="let option of sortedOptions"
        [value]="option.value"
      >
        {{ option.label | localizedDate }}
        <img
          *ngIf="option.imagePath"
          [src]="option.imagePath"
          alt="{{ option.label | translate }}"
          class="image"
        />
      </mat-option>
    </ng-container>
  </mat-select>
  <mat-hint *ngIf="hasHint">{{
    hint | translate
  }}</mat-hint>
  <mat-error>{{ error | translate }}</mat-error>
</mat-form-field>
