import { Component, Input } from '@angular/core';
import { TranslateParameters } from 'src/app/types/TranslateParameters';
import { FormFieldAbstractComponent } from '../form-field-abstract.component';

@Component({
  selector: 'app-form-field-checkbox',
  templateUrl: './form-field-checkbox.component.html',
  styleUrls: ['./form-field-checkbox.component.scss'],
})
export class FormFieldCheckboxComponent extends FormFieldAbstractComponent {
  @Input() translateParameters!: TranslateParameters;
  @Input() breakLines = false;

  get checkBoxLabel(): string {
    return this.label;
  }

  get showError(): boolean {
    return this.field.touched && !this.field.valid;
  }

  get hasHint(): boolean {
    return null !== this.hint && !this.showError;
  }
}
