import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { ExamEventService } from 'src/app/services';
import { EventBooking, ExamEvent } from 'src/app/types';

@Component({
  selector: 'app-dashboard-booking-card',
  templateUrl: './dashboard-booking-card.component.html',
  styleUrls: ['./dashboard-booking-card.component.scss'],
})
export class DashboardBookingCardComponent
  implements OnInit
{
  @Input() booking!: EventBooking;
  @Input() routerLink!: string;

  examEvent!: ExamEvent;
  constructor(
    private examEventService: ExamEventService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.getExamEvent();
  }

  private getExamEvent(): void {
    if (this.booking) {
      this.examEventService
        .loadByEventId(this.booking.testEventId)
        .then((event) => (this.examEvent = event));
    }
  }

  get timeSlot(): DateTime | undefined {
    if (this.examEvent) {
      return DateTime.fromISO(this.examEvent.testeventTime);
    }
    return;
  }

  get currentLanguage(): string {
    return this.translateService.currentLang;
  }
}
