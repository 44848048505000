<div class="flex-row">
  <div class="flex-1"></div>
  <div class="flex-1 flex-no-shrink search-container">
    <app-form-field-text
      (keyup.enter)="reload()"
      [field]="searchControl"
      appearance="standard"
      label="label.search"
    ></app-form-field-text>
  </div>
  <button (click)="reload()" mat-icon-button>
    <mat-icon>search</mat-icon>
  </button>
  <button [routerLink]="['create']" color="primary" mat-fab>
    <mat-icon>add</mat-icon>
  </button>
</div>
