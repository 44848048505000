import { Component, Input, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import { ExamEventService } from '../../services';
import { Exam, ExamEvent } from '../../types';

@Component({
  selector: 'app-dashboard-exam-card',
  templateUrl: './dashboard-exam-card.component.html',
  styleUrls: ['./dashboard-exam-card.component.scss'],
})
export class DashboardExamCardComponent implements OnInit {
  @Input() exam!: Exam;
  examEvents: ExamEvent[] = [];

  constructor(private examEventService: ExamEventService) {}

  ngOnInit(): void {
    this.examEventService
      .loadByExamId(this.exam.id)
      .then((examEvents) => {
        this.examEvents = examEvents;
      });
  }

  get isExamEvents(): boolean {
    return this.examEvents.length >= 1;
  }

  get isTooLateForBooking(): boolean {
    return this.diffToStart < 0 && this.diffToEnd < 0;
  }

  get isTooEarlyForBooking(): boolean {
    return this.diffToStart > 0 && this.diffToEnd > 0;
  }

  get isBookingExist(): boolean {
    return !!this.exam.existingBooking;
  }

  get examName(): string {
    return this.exam.testname;
  }

  get srcLogo(): string {
    return this.exam.logoPath;
  }

  get registerDateFrom(): DateTime | undefined {
    const dateFrom = this.examEvents
      .map((event) => event.registerTimeFrom)
      .sort()[0];

    if (dateFrom) {
      return DateTime.fromISO(dateFrom);
    } else {
      return;
    }
  }

  get registerDateTo(): DateTime | undefined {
    const dateTo = this.examEvents
      .map((event) => event.registerTimeTo)
      .sort()[this.examEvents.length - 1];

    if (dateTo) {
      return DateTime.fromISO(dateTo);
    } else {
      return;
    }
  }

  get diffToStart(): number {
    return this.registerDateFrom
      ? this.registerDateFrom.diffNow().toMillis()
      : -1;
  }

  get diffToEnd(): number {
    return this.registerDateTo
      ? this.registerDateTo.diffNow().toMillis()
      : 1;
  }
}
