import {
  HttpClient,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import {
  ExamCenter,
  ExamCenterListFilter,
  FormState,
  ListResponse,
  ListState,
} from '../../types';

@Injectable({
  providedIn: 'root',
})
export class ExamCenterService {
  listState: ListState<ExamCenter, ExamCenterListFilter> = {
    loading: false,
    response: {
      items: [],
      total: 0,
    },
    filter: {
      search: '',
      page: 0,
      size: 10,
      sort: 'name',
      direction: 'asc',
      testareaId: 0,
    },
  };
  formState: FormState<ExamCenter> = {
    loading: false,
    item: ExamCenterService.factoryItem(),
  };

  constructor(private http: HttpClient) {}

  static factoryItem(): ExamCenter {
    return {
      id: 0,
      testareaId: 0,
      name: '',
      provider: '',
      street: '',
      streetNumber: '',
      additionalAddressInfo: '',
      zip: '',
      city: '',
      state: '',
      country: '',
      maximumCapacity: 0,
      additionalInformation: '',
    };
  }

  loadList(): Promise<ListResponse<ExamCenter>> {
    this.listState.loading = true;

    const params = new HttpParams()
      .set('filter', `${this.listState.filter.search}`)
      .set('page', `${this.listState.filter.page}`)
      .set('size', `${this.listState.filter.size}`)
      .set('sort', `${this.listState.filter.sort}`)
      .set(
        'direction',
        `${this.listState.filter.direction}`
      );

    if (this.listState.filter.testareaId > 0) {
      params.set(
        'testareaId',
        `${this.listState.filter.testareaId}`
      );
    }

    const url = `/api/testcenter`;
    const promise$: Promise<ListResponse<ExamCenter>> =
      firstValueFrom(
        this.http.get<ListResponse<ExamCenter>>(url, {
          params,
        })
      ).then(
        (response) => (this.listState.response = response)
      );

    promise$.finally(
      () => (this.listState.loading = false)
    );
    return promise$;
  }

  loadById(id: number): Promise<ExamCenter> {
    return firstValueFrom(
      this.http.get<ExamCenter>(`/api/testcenter/${id}`)
    );
  }

  create(): Promise<ExamCenter> {
    return firstValueFrom(
      this.http.post<ExamCenter>(
        '/api/testcenter',
        this.formState.item
      )
    );
  }

  update(): Promise<ExamCenter> {
    return firstValueFrom(
      this.http.put<ExamCenter>(
        `/api/testcenter/${this.formState.item.id}`,
        this.formState.item
      )
    );
  }

  delete(id: number): Promise<void> {
    return firstValueFrom(
      this.http.delete<void>(`/api/testcenter/${id}`)
    );
  }

  save(): Promise<ExamCenter> {
    this.formState.loading = true;
    const promise =
      this.formState.item.id > 0
        ? this.update()
        : this.create();
    promise.finally(() => (this.formState.loading = false));
    return promise;
  }
}
