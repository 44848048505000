<mat-form-field
  [appearance]="appearance"
  [class.disabled]="disabled"
>
  <mat-label *ngIf="hasLabel">{{
    label | translate
  }}</mat-label>
  <textarea
    [formControl]="field"
    placeholder="{{ placeholder | translate }}"
    [required]="required"
    [disabled]="disabled"
    [type]="type"
    matInput
  ></textarea>
  <mat-hint *ngIf="hasHint">{{
    hint | translate
  }}</mat-hint>
  <mat-hint *ngIf="isPending">{{
    'label.pending' | translate
  }}</mat-hint>
  <mat-error>{{ error | translate }}</mat-error>
</mat-form-field>
