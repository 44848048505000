import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Settings as LuxonSettings } from 'luxon';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'boti-frontend';

  constructor(
    private breadcrumbService: BreadcrumbService,
    private translateService: TranslateService,
    private titleService: Title,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // Configure the default time zone
    LuxonSettings.defaultZone = 'Europe/Berlin';

    this.translateService.onLangChange.subscribe(
      (language) => {
        this.titleService.setTitle(
          language.translations['title.application']
        );

        if (language.lang === 'de') {
          LuxonSettings.defaultLocale = 'de-DE';
        } else if (language.lang === 'en') {
          LuxonSettings.defaultLocale = 'en-US';
        }
      }
    );

    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.lang === 'en') {
        this.translateService.use('en');
      } else {
        this.translateService.use('de');
      }
    });
  }
}
