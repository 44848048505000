import { Injectable } from '@angular/core';
import { EmailConfirmResponse } from '../../types/response/EmailConfirmResponse';

@Injectable({
  providedIn: 'root',
})
export class RegisterServiceMock {
  confirmEmail(
    token: string
  ): Promise<EmailConfirmResponse> {
    return new Promise<EmailConfirmResponse>(
      (resolve, reject) => {
        resolve({
          email: 'mock@mail-mail.io',
        } as EmailConfirmResponse);
      }
    );
  }

  checkResetPasswordToken(token: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      resolve('');
    });
  }
}
