import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import { TenantId } from '../../../environments/environments.types';

@Component({
  selector: 'app-error404-page',
  templateUrl: './error404-page.component.html',
  styleUrls: ['./error404-page.component.scss'],
})
export class Error404PageComponent {
  get tenant(): TenantId {
    return environment.tenant;
  }
}
