import { Component, Input } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { CountryService } from 'src/app/services';
import { SelectOption } from 'src/app/types';

@Component({
  selector: 'app-parental-address-form',
  templateUrl: './parental-address-form.component.html',
  styleUrls: ['./parental-address-form.component.scss'],
})
export class ParentalAddressFormComponent {
  @Input() parentalAddressFormGroup!: UntypedFormGroup;

  constructor(private countryService: CountryService) {}

  get streetControl(): UntypedFormControl {
    return this.parentalAddressFormGroup.controls
      .street as UntypedFormControl;
  }

  get streetNumber(): UntypedFormControl {
    return this.parentalAddressFormGroup.controls
      .streetNumber as UntypedFormControl;
  }

  get addressAdditionControl(): UntypedFormControl {
    return this.parentalAddressFormGroup.controls
      .additionalAddressInfo as UntypedFormControl;
  }

  get postalCodeControl(): UntypedFormControl {
    return this.parentalAddressFormGroup.controls
      .zip as UntypedFormControl;
  }

  get cityControl(): UntypedFormControl {
    return this.parentalAddressFormGroup.controls
      .city as UntypedFormControl;
  }

  get stateControl(): UntypedFormControl {
    return this.parentalAddressFormGroup.controls
      .state as UntypedFormControl;
  }

  get countryControl(): UntypedFormControl {
    return this.parentalAddressFormGroup.controls
      .country as UntypedFormControl;
  }

  get countryOptions(): SelectOption[] {
    return this.countryService.selectOptions;
  }
}
