import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../../services';
import { Profile } from '../../types';

@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss'],
})
export class ProfilePageComponent implements OnInit {
  constructor(private profileService: ProfileService) {}

  ngOnInit() {
    this.profileService.load();
  }

  get profile(): Profile {
    return this.profileService.profile;
  }
}
