export interface FindBookingResponse {
  bookingId: number;
  userId: number;
  testEventId: number;
  test: {
    id: number;
    name: string;
    logoPath?: string;
  };
  reservedUntil?: string; // ISO 8601 date time
  additionalQuestions?: {
    universityIds: number[];
    finalGrade: string;
  };
  status:
    | 'OPEN'
    | 'CANCELED'
    | 'PENDING'
    | 'AUTHORIZED'
    | 'EXPIRED'
    | 'FAILED'
    | 'PAID';
  paymentExpectedUntil?: string; // ISO 8601 date time
}
