import {
  HttpClient,
  HttpResponse,
} from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { firstValueFrom, Observable } from 'rxjs';

export enum ServerNotificationEventType {
  REQUEST_DATA_ACCEPTED = 'request-data-accepted',
  REQUEST_ACCEPTANCE_TIMEOUT = 'request-acceptance-timeout',
  REQUEST_HEARTBEAT = 'keep-alive',
}

@Injectable({
  providedIn: 'root',
})
export class MbrUserService {
  private serverUpdatesEventSource?: EventSource;
  private qrCodeObservable?: Observable<HttpResponse<Blob>>;
  private qrCode?: Blob;
  private _ablageAppLink?: string;

  constructor(
    private http: HttpClient,
    private keycloak: KeycloakService,
    private zone: NgZone
  ) {}

  async requestFirstAndLastNameFromWallet(): Promise<void> {
    const url =
      '/api/user/mbr/wallet/registration_data/requests';
    if (!this.qrCodeObservable) {
      this.qrCodeObservable = this.http.post(
        url,
        {},
        {
          responseType: 'blob',
          observe: 'response',
          headers: {
            Accept: 'image/png',
            // TODO This is only a temporary measure, to be used while the MBR Enmeshed backbone is unavailable.
            'BOTI-Mock-MBR-Connector': 'true',
          },
        }
      );
    }
    return firstValueFrom(this.qrCodeObservable).then(
      (response) => {
        if (response.body) {
          this.qrCode = response.body;
          this._ablageAppLink =
            response.headers.get('Ablage-App-Link') ||
            undefined;
        }
        this.qrCodeObservable = undefined;
      }
    );
  }

  establishConnectionForServerNotifications(): Observable<MessageEvent> {
    if (!this.serverUpdatesEventSource) {
      const keycloakId =
        this.keycloak.getKeycloakInstance().subject;
      const connectionId = crypto.randomUUID();
      const url = `/api/user/mbr/data-accept-stream/${keycloakId}/${connectionId.toString()}`;
      this.serverUpdatesEventSource = new EventSource(url, {
        withCredentials: false,
      });
    }

    return new Observable((subscriber) => {
      if (this.serverUpdatesEventSource) {
        this.serverUpdatesEventSource.onerror = (error) => {
          this.zone.run(() => {
            console.error(
              `Received message event error ${JSON.stringify(
                error
              )}`
            );
            subscriber.error(
              'Unknown response type received.'
            );
          });
          this.serverUpdatesEventSource?.close();
        };

        this.serverUpdatesEventSource.addEventListener(
          'message',
          (event) => {
            switch (event.data as string) {
              case ServerNotificationEventType.REQUEST_DATA_ACCEPTED:
              case ServerNotificationEventType.REQUEST_ACCEPTANCE_TIMEOUT: {
                // Forward the event to the subscriber.
                this.zone.run(() => subscriber.next(event));
                this.serverUpdatesEventSource?.close();
                break;
              }
              case ServerNotificationEventType.REQUEST_HEARTBEAT: {
                // Do nothing, this is just to keep the connection alive.
                break;
              }
              default: {
                console.error(
                  `Received unknown server event of type 'message' with data ${JSON.stringify(
                    event.data
                  )}.`
                );
              }
            }
          }
        );
      }
    });
  }

  closeConnectionForServerNotifications(): void {
    if (this.serverUpdatesEventSource) {
      this.serverUpdatesEventSource.close();
      this.serverUpdatesEventSource = undefined;
    }
  }

  async deleteUserWithIncompleteSetup(): Promise<unknown> {
    const request = this.http.delete('/api/user/mbr');
    return firstValueFrom(request).then(() =>
      this.keycloak.logout(
        `${window.location.origin}/landing`
      )
    );
  }

  getQrCode(): Blob | undefined {
    return this.qrCode;
  }

  get ablageAppLink(): string | undefined {
    return this._ablageAppLink;
  }
}
