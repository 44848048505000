import { AdditionalQuestionData } from '../AdditionalQuestionData';
import { PriceCalculationData } from '../EventBooking';

export interface AddBookingDetailsRequest {
  additionalQuestionData?: AdditionalQuestionData;
  bookingId: number;
  prerequisiteQuestionId?: number;
  priceCalculationData?: PriceCalculationData;
  testEventId: number;
  userId: number;
}
