import { StepperSelectionEvent } from '@angular/cdk/stepper';
import {
  Component,
  HostBinding,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoadingDialogComponent } from 'src/app/components';
import {
  BookingService,
  ExamService,
  LayoutService,
  NotificationService,
} from 'src/app/services';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'app-exam-booking-form-default',
  templateUrl: './exam-booking-form-default.component.html',
  styleUrls: ['./exam-booking-form-default.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExamBookingFormDefaultComponent
  implements OnInit
{
  @HostBinding('class.form-page-component')
  hostClass = true;
  @ViewChild('stepper') private myStepper!: MatStepper;

  hasEventRelevantQuestions = false;
  eventLocationFormValid = false;
  profileCheckFormValid = false;
  timeSlotFormValid = false;
  additionalQuestionsFormValid = false;
  overviewFormValid = false;
  isPayBtnClicked = false;
  stepId = '';

  constructor(
    private bookingService: BookingService,
    private layoutService: LayoutService,
    private note: NotificationService,
    private router: Router,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private examService: ExamService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.set(
      '@examName',
      this.examTestName
    );
  }

  setProfileCheckFormValid(value: boolean): void {
    this.profileCheckFormValid = value;
  }

  setEventLocationFormValid(value: boolean): void {
    this.eventLocationFormValid = value;
  }

  setTimeSlotFormValid(value: boolean): void {
    this.timeSlotFormValid = value;
  }

  nextStep(isNextStep: boolean): void {
    if (isNextStep) {
      this.myStepper.next();
    }
  }

  selectionEvent(evt: StepperSelectionEvent): void {
    this.stepId =
      evt.selectedStep.content.elementRef.nativeElement.parentElement.id;
  }

  setAdditionalQuestionsFormValid(value: boolean): void {
    this.additionalQuestionsFormValid = value;
  }

  setOverviewFormValid(value: boolean) {
    this.overviewFormValid = value;
  }

  doPayment(): void {
    this.isPayBtnClicked = true;

    this.dialog.open(LoadingDialogComponent, {
      minWidth: '300px',
      disableClose: true,
    });

    this.bookingService
      .doPayment(this.bookingId)
      .finally(() => {
        this.dialog.closeAll();
        this.isPayBtnClicked = false;
      });
  }

  deleteBooking(): void {
    this.bookingService
      .deleteBooking(this.bookingId)
      .then(() => {
        this.bookingService.formState = {
          loading: false,
          item: BookingService.factoryItem(),
        };
      })
      .then(() => this.router.navigate(['/home']))
      .catch((e) => {
        if (e.code === 409) {
          this.note.error(
            'error.notification-payment-started'
          );
        } else {
          this.note.error('error.general');
        }
        return Promise.reject(e);
      });
  }

  get bookingId(): number {
    return this.bookingService.formState.item.bookingId;
  }

  get isHandset$(): Observable<boolean> {
    return this.layoutService.isHandset$;
  }

  get examIdParam(): number {
    return Number(
      this.activatedRoute.snapshot.paramMap
        .get('id')
        ?.match(/^\d+/i)
    );
  }

  get examTestName(): string {
    const examName =
      this.examService.listState.response.items.find(
        (exam) => exam.id === this.examIdParam
      )?.testname;
    return examName ?? '';
  }
}
