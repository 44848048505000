<div class="content">
  <section
    fxLayout="column"
    fxLayout.gt-sm="row"
    fxLayoutGap="0"
    fxLayoutGap.gt-sm="2rem"
  >
    <div fxFlex="grow" class="section-block">
      <h4 class="section-bg-contrast">
        {{ 'label.personal-information' | translate }}
      </h4>
      <p>
        <span>{{ namePrefixKey | translate }}</span>
        {{ user.firstname }} {{ user.lastname }} <br />
        {{
          'userInfo.birth'
            | translate
              : {
                  birthday:
                    user.birthday
                    | localizedDate: 'mediumDate',
                  birthplace: user.birthplace
                }
        }}
      </p>
      <p>
        {{ user.email }} <br />
        Tel.: {{ user.phoneNumber }}
      </p>
      <p>
        {{ 'userInfo.address-formats.street-and-street-number' | translate:  {
            street: user.street,
            streetNumber: user.streetNumber
          }
        }} <br />
        {{ 'userInfo.address-formats.zip-code-and-city' | translate:  {
        zip: user.zip,
        city: user.city
      }
        }} <br />
        {{ user.country }}
      </p>
    </div>

    <div fxFlex="grow" class="section-block">
      <div *ngIf="booking">
        <h4 class="section-bg-contrast">
          {{ 'label.test-event-date' | translate }}
        </h4>

        <img
          *ngIf="booking.test.logoPath"
          [src]="booking.test.logoPath"
          [alt]="booking.test.name"
          class="logo"
        />
        <p>
          {{ booking.test.name }}
        </p>
        <p>
          <span>
            {{
              'booking.default.confirmationPage.format.dayOfWeek'
                | translate
                  : {
                      timeSlot:
                        timeSlot | localizedDate: 'EEEE'
                    }
            }}
            <strong>{{
              'booking.default.confirmationPage.format.date'
                | translate
                  : {
                      timeSlot:
                        timeSlot
                        | localizedDate: 'mediumDate'
                    }
            }}</strong>
          </span>
          <br />
          <span>
            {{
              'booking.default.confirmationPage.format.area.prefix'
                | translate
            }}
            <strong>{{
              'booking.default.confirmationPage.format.area.area'
                | translate
                  : {
                      testarea: examEvent
                        ? examEvent.testarea
                        : ''
                    }
            }}</strong>
            {{
              'booking.default.confirmationPage.format.area.suffix'
                | translate
            }}
          </span>
          <span>
            {{
              'booking.default.confirmationPage.format.time.prefix'
                | translate
            }}
            <strong>{{
              timeSlot | localizedDate: 'shortTime'
            }}</strong>
            {{
              'booking.default.confirmationPage.format.time.suffix'
                | translate
            }}
          </span>
        </p>
        <p>
          {{ 'label.test-event-price' | translate }}:
          <strong>{{
            examEvent && booking.priceInCt
              ? (booking.priceInCt / 100
                | currency
                  : examEvent.price.currency
                  : undefined
                  : undefined
                  : currentLanguage)
              : ''
          }}</strong>
        </p>
      </div>
    </div>
  </section>

  <section>
    <app-add-questions-answers-overview
      [booking]="booking"
    ></app-add-questions-answers-overview>
  </section>

  <section *ngIf="isOptionalQuestionAnswered">
    <app-optional-questions-answers-overview
      [booking]="booking"
    ></app-optional-questions-answers-overview>
  </section>
</div>
