import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  MatPaginator,
  PageEvent,
} from '@angular/material/paginator';
import { ListFilter } from '../../../types';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent {
  @ViewChild('paginator') paginator!: MatPaginator;
  @Input() filter!: ListFilter;
  @Input() total!: number;
  @Output()
  filterApply: EventEmitter<void> = new EventEmitter<void>();

  get pageSize(): number {
    return this.filter.size || 10;
  }

  paginate(event: PageEvent): void {
    // ensure reset the page number of paginator on changing page size
    // the material paginator will not do it by default,
    // in case the new page size is smaller then the previous one
    if (event.pageSize !== this.filter.size) {
      this.filter.size = event.pageSize;
      this.filter.page = 0;
      this.paginator.pageIndex = 0; // official hack to force changing page size
      this.filterApply.emit();
    } else if (
      event.previousPageIndex !== event.pageIndex
    ) {
      this.filter.page = event.pageIndex;
      this.filterApply.emit();
    }
  }
}
