<div class="form-content">
  <div class="form-block">
    <app-form-field-text
      [field]="nameControl"
      label="label.name"
    ></app-form-field-text>
    <app-form-field-select
      [field]="examLocationControl"
      [options]="examLocationSelectOptions"
      label="label.exam-location.title"
    ></app-form-field-select>
    <app-form-field-select
      [field]="countryControl"
      [options]="countryOptions"
      sortBy="translated-label-asc"
      label="label.country"
    ></app-form-field-select>
    <app-form-field-text
      [field]="stateControl"
      label="label.state"
    ></app-form-field-text>
    <app-form-field-text
      [field]="streetControl"
      label="label.street"
    ></app-form-field-text>
    <app-form-field-text
      [field]="streetNumberControl"
      label="label.street-number"
    ></app-form-field-text>
    <app-form-field-text
      [field]="additionalAddressInfoControl"
      label="label.addressAddition"
    ></app-form-field-text>
    <app-form-field-text
      [field]="postalCodeControl"
      label="label.postalCode"
    ></app-form-field-text>
    <app-form-field-text
      [field]="cityControl"
      label="label.city"
    ></app-form-field-text>
  </div>
  <div class="form-block">
    <app-form-field-text
      [field]="providerControl"
      label="label.provider"
    ></app-form-field-text>
    <app-form-field-text
      [field]="maxCapacityControl"
      label="label.max-capacity"
    ></app-form-field-text>
    <app-form-field-text
      [field]="hintControl"
      label="label.hint"
    ></app-form-field-text>
  </div>
  <div
    class="form-block event-block"
    *ngIf="showEventBlock"
  >
    <app-exam-event-table
      *ngIf="showEventTable"
      [examCenter]="item"
      (itemClicked)="examEventClicked($event)"
      (createClicked)="examEventCreateClicked()"
    ></app-exam-event-table>
    <app-exam-event-form
      *ngIf="!showEventTable"
      [examCenter]="item"
      (closeForm)="examFormClosed()"
    ></app-exam-event-form>
  </div>
</div>

<div class="buttons">
  <button
    [routerLink]="['/home/exam-center']"
    mat-raised-button
  >
    {{ 'button.cancel' | translate }}
  </button>
  <div class="flex-1"></div>
  <button
    (click)="delete()"
    mat-raised-button
    color="warn"
  >
    {{ 'button.delete' | translate }}
  </button>
  <button
    (click)="submit()"
    mat-raised-button
    color="accent"
  >
    {{ 'button.save' | translate }}
  </button>
</div>
