<div class="container-content" fxLayout="column">
  <ng-container *ngIf="showBreadcrumb">
    <xng-breadcrumb [separator]="separatorIconTemplate">
      <ng-container
        *xngBreadcrumbItem="
          let breadcrumb;
          let info = info;
          let first = first
        "
      >
        <mat-icon *ngIf="info">{{ info }}</mat-icon>
        <ng-container *ngIf="!first">{{
          breadcrumb | translate
        }}</ng-container>
      </ng-container>
    </xng-breadcrumb>

    <ng-template #separatorIconTemplate>
      <mat-icon>arrow_right</mat-icon>
    </ng-template>
  </ng-container>

  <h1 class="mat-headline">
    {{ 'label.terms-of-service' | translate }}
  </h1>

  <div *ngIf="currentLanguage === 'de'; else english">
    <h2 class="mat-title">§1 Geltungsbereich</h2>
    <p>
      Die ITB Consulting GmbH stellt ihren Kunden Tests zur
      Verfügung, mit denen man seine Chancen auf einen
      Studienplatz in Bachelor- oder Masterstudiengängen
      verbessern kann. Diese Allgemeinen
      Geschäftsbedingungen regeln rechtliche Bedingungen der
      Durchführung der Tests. Eine nähere Darstellung der
      Tests findet sich auf den jeweiligen testspezifischen
      Seiten.
    </p>

    <h2 class="mat-title">
      §2 Leistungsumfang und Verfügbarkeit
    </h2>
    <p>
      Der Kunde kann den Test am ausgewählten Termin zu den
      ausgewählten Zeiten sowie am ausgewählten Ort
      absolvieren und erhält ein schriftliches Zertifikat
      mit seinen Ergebnissen.
    </p>
    <p>
      Die ITB Consulting GmbH behält sich jedoch vor,
      notwendige Änderungen der Testdurchführung unter
      Wahrung des Gesamtcharakters vorzunehmen, insbesondere
      die Anpassung der Durchführung an geltende rechtliche
      Regelungen (z. B. Hygienekonzepte) und die Änderung
      des Testzentrums, sofern dies dem Kunden zumutbar ist
      und das alternative Testzentrum gleichwertig ist
      (Beispiel: vergleichbares Testzentrum in Region oder
      Proctoring).
    </p>
    <p>
      Liegt das Hindernis in der Verhinderung der
      Testdurchführung in Fällen „Höhere Gewalt“ (dies sind
      Fälle wie Krieg, Revolutionen, Streik,
      Naturkatastrophen oder Pandemien, die außerhalb
      unserer Einflusssphäre liegen), kann die
      Testdurchführung einmalig auf einen angemessenen neuen
      Zeitpunkt verschoben werden, wobei grundsätzlich ein
      Alternativtermin, der die Ergebniseinreichung
      innerhalb der Bewerbungsfrist ermöglicht, als
      angemessen gilt. Sollte ein Testverfahren innerhalb
      eines angemessenen Zeitraums nicht durchgeführt werden
      können, erhält der Kunde die entrichtete
      Teilnahmegebühr zurück. Weitere Ansprüche darüber
      hinaus bestehen nicht.
    </p>

    <h2 class="mat-title">§3 Preis; Freischaltung</h2>
    <p>
      Der Kunde zahlt den vereinbarten Preis. Die Bezahlung
      erfolgt wahlweise über Kreditkarte oder PayPal.
      Unmittelbar nach Freigabe der Zahlung durch den
      Finanzdienstleister erfolgt die Reservierung von Testort,
      Testtermin und Testzeitraum.
    </p>

    <h2 class="mat-title">§4 Vertragsdauer</h2>
    <p>
      Der Kunde erhält bei seiner Bestellung die
      Berechtigung, an einem der regulären Testtermine
      teilzunehmen und seine Ergebnisse aus seinem
      Benutzerkonto abzurufen. Die Ergebnisse werden online
      gestellt und sind dann für mindestens vier Wochen
      abrufbar. Nach dieser Zeit kann eine Zweitschrift
      gegen ein Entgelt von 9 Euro angefordert werden.
    </p>

    <h2 class="mat-title">§5 Widerrufsrecht</h2>
    <p>
      Sie haben das Recht, binnen vierzehn Tagen ohne Angabe
      von Gründen diesen Vertrag zu widerrufen. Die
      Widerrufsfrist beträgt vierzehn Tage ab dem Tag des
      Vertragsabschlusses. Nach Ablauf der Widerrufsfrist ist
      eine Rückerstattung des Teilnahmeentgelts ausgeschlossen.
      Um Ihr Widerrufsrecht auszuüben, müssen Sie uns
      (ITB Consulting GmbH, Koblenzer Straße
      77, 53177 Bonn; Telefax: 0228-82090-38; E-Mail:
      <a href="mailto:test-info@itb-consulting.de"
        >test-info@itb-consulting.de</a
      >) mittels einer eindeutigen Erklärung (z. B. ein mit
      der Post versandter Brief, Telefax oder E-Mail) über
      Ihren Entschluss, diesen Vertrag zu widerrufen,
      informieren. Sie können dafür das beigefügte
      Muster-Widerrufsformular verwenden, das jedoch nicht
      vorgeschrieben ist. Zur Wahrung der Widerrufsfrist
      reicht es aus, dass Sie die Mitteilung über die
      Ausübung des Widerrufsrechts vor Ablauf der
      Widerrufsfrist absenden.
    </p>
    <p>
      Folgen des Widerrufs: Wenn Sie den Vertrag widerrufen,
      haben wir Ihnen alle Zahlungen, die wir von Ihnen
      erhalten haben, einschließlich der Lieferkosten (mit
      Ausnahme der zusätzlichen Kosten, die sich daraus
      ergeben, dass Sie eine andere Art der Lieferung als
      die von uns angebotene, günstigste Standardlieferung
      gewählt haben), unverzüglich und spätestens binnen
      vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die
      Mitteilung über Ihren Widerruf dieses Vertrags bei uns
      eingegangen ist. Für diese Rückzahlung verwenden wir
      dasselbe Zahlungsmittel, das Sie bei der
      ursprünglichen Transaktion eingesetzt haben, es sei
      denn, mit Ihnen wurde ausdrücklich etwas anderes
      vereinbart; in keinem Fall werden Ihnen wegen dieser
      Rückzahlung Entgelte berechnet.
    </p>

    <h2 class="mat-title">§6 Schadensersatz</h2>
    <p>
      Die ITB Consulting GmbH haftet bei Vorsatz und grober
      Fahrlässigkeit. Die ITB Consulting GmbH haftet ferner
      bei der fahrlässigen Verletzung von Pflichten, deren
      Erfüllung die ordnungsgemäße Durchführung des
      Vertrages überhaupt erst ermöglicht, deren Verletzung
      die Erreichung des Vertragszwecks gefährdet und auf
      deren Einhaltung der Kunde regelmäßig vertraut
      (Kardinalpflichten). Im letztgenannten Fall haftet sie
      jedoch nicht für nicht vorhersehbare, nicht
      vertragstypische Schäden. Ferner verjähren
      Schadensersatzansprüche im letztgenannten Fall nach
      zwei Jahren. Die ITB Consulting GmbH haftet nicht bei
      leicht fahrlässiger Verletzung anderer Pflichten. Die
      vorstehenden Haftungsausschlüsse und -begrenzungen
      gelten nicht bei einer schuldhaft verursachten
      Verletzung von Leben, Körper und Gesundheit. Die
      Haftung nach dem Produkthaftungsgesetz bleibt
      unberührt.
    </p>

    <h2 class="mat-title">
      §7 Aufrechnung; Zurückbehaltungsrechte
    </h2>
    <p>
      Der Kunde kann gegenüber Forderungen von der ITB
      Consulting GmbH nur mit unbestrittenen oder
      rechtskräftig festgestellten Forderungen aufrechnen.
      Die Ausübung eines Zurückbehaltungsrechts durch den
      Kunden, das nicht auf einem Recht aus dem gleichen
      Vertragsverhältnis zwischen den Parteien wie der
      Anspruch beruht, gegen den es ausgeübt wird, ist
      unzulässig.
    </p>

    <h2 class="mat-title">§8 Unerlaubtes Verhalten</h2>
    <p>
      Die ITB Consulting GmbH behält sich das Recht vor, den
      Kunden bei unerlaubten Verhaltensweisen zu verwarnen
      bzw. vom Test auszuschließen. Daraus entsteht für den
      Kunden kein Anspruch auf die Rückerstattung der
      Teilnahmegebühr. In besonders schweren Fällen kann der
      Ausschluss ohne Wiederholungsmöglichkeit ausgesprochen
      werden.
    </p>
    <p>Zu einer Verwarnung führt es, wenn…</p>

    <ul>
      <li>
        unerlaubte Gegenstände an den Arbeitsplatz
        mitgenommen werden. Eine aktuelle Liste der
        erlaubten Hilfsmittel sowie Beispiele für unerlaubte
        Hilfsmittel
        <a
          href="https://itb-academic-tests.org/teilnehmer/full-service-tests/unerlaubtes-verhalten/"
        >
          finden Sie auf dieser Seite.</a
        >
      </li>
      <li>
        der Kunde bei der Bearbeitung des Tests einen
        Täuschungsversuch unternimmt (z. B. versucht,
        „abzugucken“).
      </li>
      <li>
        der Kunde während der Testdurchführung mit anderen
        Testteilnehmern spricht oder auf andere Art Kontakt
        aufnimmt.
      </li>
      <li>
        andere Teilnehmer durch den Kunden gestört werden.
      </li>
    </ul>
    <p>
      Zum <strong>sofortigen</strong> Ausschluss führt es,
      wenn…
    </p>
    <ul>
      <li>
        nach einer einmaligen Verwarnung ein weiterer
        Verstoß erfolgt.
      </li>
      <li>
        der Test vom Kunden unter falschem Namen bearbeitet
        wird. Der amtliche Lichtbildaus­weis wird vor Beginn
        der Testung sowie während der Testbearbeitung von
        den Aufsichts­personen überprüft. Jegliches Vergehen
        wird juristisch verfolgt und von der ITB Consulting
        GmbH zur Anzeige gebracht.
      </li>
      <li>
        der Kunde weitere Browserfenster oder Programme
        öffnet.
      </li>
      <li>
        der Kunde Gegenstände an den Arbeitsplatz mitnimmt,
        die zum Kopieren oder Vervielfältigen von
        Testmaterial verwendet werden können. Zu diesen
        Gegenständen gehören unter anderem: Kameras in
        jeglicher Form, Handys, eigenes Notizpapier.
        Jegliches Vergehen wird juristisch verfolgt und von
        der ITB Consulting GmbH zur Anzeige gebracht.
      </li>
      <li>
        der Kunde versucht, die Aufgaben, Konzeptpapier oder
        die Hinweise zur Bearbeitung zu entwenden oder zu
        vervielfältigen. Jegliches Vergehen wird juristisch
        verfolgt und von der ITB Consulting GmbH zur Anzeige
        gebracht.
      </li>
      <li>
        der Kunde seiner Pflicht, zum Mitführen eines
        gültigen amtlichen Lichtbildausweises oder
        Passersatzpapieren und diese auf Verlangen den
        Aufsichtführenden zu zeigen, nicht nachkommt.
      </li>
      <li>
        der Kunde geltenden Regelungen zur Teilnahme am
        Testverfahren (z. B. Nachweise über Impfung,
        Genesung oder negativer Corona-Testung im Rahmen von
        Hygienekonzepten), nicht nachkommt.
      </li>
    </ul>

    <h2 class="mat-title">
      §9 Anwendbares Recht; Gerichtsstand
    </h2>
    <p>
      Es gilt ausschließlich deutsches Recht unter
      Ausschluss des UN-Kaufrechts. Gerichtsstand ist, wenn
      der Kunde keinen allgemeinen Gerichtsstand in
      Deutschland hat, Bonn.
    </p>
    <p>
      Die Europäische Kommission stellt eine Plattform zur
      Online-Streitbeilegung (OS) bereit, die Sie hier
      finden
      <a href="http://ec.europa.eu/consumers/odr/"
        >http://ec.europa.eu/consumers/odr/</a
      >. Verbraucher haben die Möglichkeit, diese Plattform
      für die Beilegung ihrer Streitigkeiten zu nutzen.
    </p>

    <h2 class="mat-headline">
      Abschnitt II
      <br />
      Zusätzliche Geschäftsbedingungen für die Durchführung
      des Testverfahrens mit Proctoring
    </h2>

    <h2 class="mat-title">
      §10 Sicherstellung der Freiwilligkeit der Teilnahme an
      der Durchführung mit Proctoring
    </h2>
    <p>
      Die Durchführung mit Proctoring wird als Alternative
      zur Durchführung in Testzentren angeboten. Eine
      Verpflichtung zur Teilnahme an einem Testverfahren mit
      Proctoring besteht nicht. Sollte eine Durchführung in
      Testzentren an einem Testtag nicht möglich sein und
      die Durchführung ausschließlich über Proctoring
      erfolgen, so kann der Kunde, der sich für eine
      Teilnahme in Testzentren angemeldet hat, den Vertrag
      innerhalb einer Frist von vierzehn Tagen nach
      Bekanntgabe widerrufen.
    </p>

    <h2 class="mat-title">
      §11 Sicherstellung der Anforderungen für eine
      Durchführung des Testverfahrens mit Proctoring
    </h2>
    <p>
      Der Kunde, der an der Durchführung eines
      Testverfahrens mit Proctoring teilnimmt, ist
      verpflichtet, für eine den Anforderungen der
      Durchführung gerechten Umgebung an seinem jeweiligen
      Teilnahmeort zu sorgen. Insbesondere ist der Kontakt
      zu anderen Personen über die gesamte Testdauer
      auszuschließen; die zuständige Aufsicht sowie
      gegebenenfalls in Störungsfällen der technische
      Support gelten nicht als andere Personen im Sinne
      dieser Vorschrift. Zur Unterbindung von unerlaubten
      Verhaltensweisen ist der Kunde verpflichtet, die
      Kamera- und Mikrofonfunktion sowie die
      Bildschirmfreigabe der zur Aufsicht eingesetzten
      Kommunikationseinrichtung während der gesamten
      Testdauer zu aktivieren. Für die Funktionsfähigkeit
      der vom Kunden eingesetzten technischen Ausstattung
      ist dieser selbst verantwortlich.
    </p>
    <p>
      Eine aktuelle Übersicht über die geltenden Regelungen
      sowie die notwendigen technischen Voraussetzungen
      finden Sie
      <a
        href="https://itb-academic-tests.org/teilnehmer/proctoring/"
      >
        auf dieser Seite</a
      >.
    </p>

    <h2 class="mat-title">
      §12 Sicherstellung des Datenschutzes Dritter
    </h2>
    <p>
      Kunden, die an einer Durchführung mit Proctoring
      teilnehmen, haben bei der Wahl des Prüfungsorts und
      der Ausrichtung von Kamera und Mikrofon Sorge dafür zu
      tragen, dass nicht Bilder oder Töne Dritter übertragen
      werden.
    </p>
  </div>

  <ng-template #english>
    <h2 class="mat-title">
      Article 1 − Scope of Application
    </h2>
    <p>
      ITB Consulting GmbH provides its customers with tests
      which helps to improve their chances of gaining a
      place on a Bachelor’s or Master’s degree programme.
      These standard terms of business (Allgemeine
      Geschäftsbedingungen) govern the legal conditions for
      conducting the test (the German original text is the
      legally binding version). The tests are described in
      greater detail at the relevant web pages
      (e.g. test-specific web page).
    </p>

    <h2 class="mat-title">
      Article 2 – Scope of Services and Availability
    </h2>
    <p>
      The customer may sit the test on the chosen date at
      the chosen times and at the chosen location and
      receives a written certificate of his or her results.
    </p>
    <p>
      ITB Consulting GmbH reserves the right to make
      necessary changes to the test execution while
      maintaining the overall character, in particular the
      adaptation of the execution to applicable legal
      regulations (e. g. hygiene concepts) and the change of
      the test center, provided this is reasonable for the
      customer and the alternative test center is equivalent
      (example: comparable test center in the same region or
      proctoring).
    </p>
    <p>
      If the obstacle lies in the prevention of the test
      execution in cases of “force majeure” (these are cases
      such as war, revolutions, strikes, natural disasters
      or pandemics that are beyond our sphere of influence),
      the test procedure can be postponed once to a
      reasonable new date, whereby an alternative date that
      allows the results to be submitted within the
      application period is generally considered reasonable.
      If a test cannot be carried out within a reasonable
      period of time, the customer will receive a refund of
      the participation fee paid. There are no further
      claims beyond this.
    </p>

    <h2 class="mat-title">Article 3 – Price, Activation</h2>
    <p>
      The customer pays the agreed price. Payment is rendered
      either via credit card or PayPal. Directly after payment
      has been confirmed by the financial service provider,
      a reservation is made for the test location, test date,
      and test period.
    </p>

    <h2 class="mat-title">Article 4 – Contract Period</h2>
    <p>
      In placing an order, the customer becomes entitled to
      participate in the test on one of the regular test
      dates and may download his or her results from the
      corresponding user account. The results are placed
      online and are then available for download for at
      least four weeks. After that time, a duplicate may be
      requested for a fee of € 9.
    </p>

    <h2 class="mat-title">
      Article 5 – Cancellation Right
    </h2>
    <p>
      You have the right to withdraw from this contract
      within 14 days without giving any reason. The
      withdrawal period will expire after 14 days from the
      day of the conclusion of the contract. After the expiry
      of the withdrawal period, a refund of the participation
      fee is excluded. To exercise the right of withdrawal,
      you must inform us (ITB Consulting GmbH, Koblenzer
       Straße 77, 53177 Bonn; Telefax: 0228-82090-38; E-Mail:
      <a href="mailto:test-info@itb-consulting.de"
        >test-info@itb-consulting.de</a
      >) of your decision to withdraw from this contract by
      an unequivocal statement (e.g. a letter sent by post,
      fax or e-mail). You may use the attached model
      withdrawal form, but it is not obligatory. To meet the
      withdrawal deadline, it is sufficient for you to send
      your communication concerning your exercise of the
      right of withdrawal before the withdrawal period has
      expired.
    </p>
    <p>
      Effects of withdrawal: If you withdraw from this
      contract, we shall reimburse to you all payments
      received from you, including the costs of delivery
      (with the exception of the supplementary costs
      resulting from your choice of a type of delivery other
      than the least expensive type of standard delivery
      offered by us), without undue delay and in any event
      not later than 14 days from the day on which we are
      informed about your decision to withdraw from this
      contract. We will carry out such reimbursement using
      the same means of payment as you used for the initial
      transaction, unless you have expressly agreed
      otherwise; in any event, you will not incur any fees
      as a result of such reimbursement.
    </p>

    <h2 class="mat-title">Article 6 – Compensation</h2>
    <p>
      ITB Consulting GmbH shall be liable in the event of
      intent or gross negligence. ITB Consulting GmbH shall
      also be liable in the event of negligence causing a
      breach of obligations whose fulfilment is a key
      prerequisite for properly executing the contract,
      whose breach jeopardises the realisation of the
      purpose of the contract, and in whose observance the
      customer regularly trusts (cardinal duties). In the
      latter case it shall not, however, be liable for
      unforeseeable damage not typical of this type of
      contract. Furthermore, in the latter case damage
      claims shall become void after a period of two years.
      ITB Consulting GmbH shall not be liable for slight
      negligence causing a breach of other obligations. The
      aforementioned liability disclaimers and limitations
      of liability shall not apply in the event of culpable
      loss of life, bodily harm, and damage to health. This
      shall not affect the liability obligations under the
      German Product Liability Act.
    </p>

    <h2 class="mat-title">
      Article 7 – Offset; Rights of Retention
    </h2>
    <p>
      The customer can only offset claims held by ITB
      Consulting GmbH with claims that are uncontested or
      finally adjudicated by a court of law. The customer
      may not exercise a retention right which is not based
      on a right stemming from the same contract between the
      parties as the claim against which it is exercised.
    </p>

    <h2 class="mat-title">
      Article 8 - Forbidden Behaviour
    </h2>
    <p>
      ITB Consulting GmbH reserves the right, to warn or to
      exclude the customer in case of forbidden behaviour.
      In such case, the customer has no claim for
      reimbursement of the participation fee. In serious
      cases, the exclusion of the test can be pronounced
      without the opportunity to retake the test. The
      following will result in a warning…
    </p>
    <p>A warning is issued, if…</p>

    <ul>
      <li>
        the customer makes use of unauthorised auxiliary
        means.
        <a
          href="https://itb-academic-tests.org/en/participants/full-service-tests/forbidden-behavior/"
        >
          On this site you can find a list with more
          details</a
        >.
      </li>
      <li>
        the customer attempts to deceive (e.g. tries to copy
        from other participants).
      </li>
      <li>
        the customer attempts to communicate with fellow
        candidates or other persons.
      </li>
      <li>the customer disturbs any other test taker.</li>
    </ul>
    <p>
      The following will result in
      <strong>immediate</strong> exclusion…
    </p>
    <ul>
      <li>
        after having received a prior warning another
        infringement is made.
      </li>
      <li>
        the test is taken under an assumed name (the
        official photo identification will be checked by the
        test supervisors before as well as during the test).
        Each violation will be prosecuted and reported by
        ITB Consulting GmbH.
      </li>
      <li>
        the customer tampers with the computer, opens
        further browser windows or programmes.
      </li>
      <li>
        the customer carries unapproved items to the work
        desk that can be used to copy or duplicate the test
        material. These include amongst others: cameras in
        all forms, mobile phones, one’s own notice paper.
        Each violation will be prosecuted and reported by
        ITB Consulting GmbH.
      </li>
      <li>
        the customer tries to take away or copy test
        material, such as exercises, concept paper or
        instructions. Each violation will be prosecuted and
        reported by ITB Consulting GmbH
      </li>
      <li>
        the customer does not fulfill his obligation to
        carry a valid official photo ID or passport
        replacement papers and to show them to the
        supervisors upon request
      </li>
      <li>
        the customer does not comply with applicable
        regulations for participation in the test (e. g.
        proof of vaccination, recovery or negative corona
        test in the context of hygiene concepts).
      </li>
    </ul>

    <h2 class="mat-title">
      Article 9 – Governing Law; Legal Venue
    </h2>
    <p>
      The law of the Federal Republic of Germany – subject
      to the exclusion of UN legislation on the sale of
      goods – shall exclusively govern this contract. If the
      customer does not have a general legal venue in
      Germany, any disputes arising hereunder shall be
      settled by a competent court of law in Bonn.
    </p>
    <p>
      Online Dispute Resolution in accordance with Art. 14
      (1) ODR-VO: The European Commission is providing a
      platform for Online Dispute Resolution (ODR). You can
      find the platform here:
      <a href="http://ec.europa.eu/consumers/odr/"
        >http://ec.europa.eu/consumers/odr/</a
      >. Consumers have the possibility to use this platform
      to resolve their disputes.
    </p>

    <h1 class="mat-headline">
      Section II
      <br />
      Additional terms and conditions for the implementation
      of the test procedure with proctoring
    </h1>

    <h2 class="mat-title">
      Article 10 – Ensuring the voluntary nature of
      participation in testing with proctoring
    </h2>
    <p>
      Testing with proctoring is offered as an alternative
      to testing in test centers. There is no obligation to
      participate in a test with proctoring. If it is not
      possible to conduct the test in test centers on a test
      day and the execution is carried out exclusively via
      proctoring, the customer who has registered for
      participation in test centers may revoke the contract
      within a period of 14 days after notification.
    </p>

    <h2 class="mat-title">
      Article 11 – Ensuring the requirements for testing
      with proctoring
    </h2>
    <p>
      The customer who participates in the implementation of
      a test with proctoring is obliged to ensure an
      environment at his or her respective place of
      participation that meets the requirements of the
      implementation. In particular, contact with other
      persons is to be excluded for the entire duration of
      the test; the responsible supervisor as well as, if
      applicable, the technical support in case of
      malfunctions are not considered other persons in the
      sense of this provision. In order to prevent
      unauthorized behavior, the customer is obliged to
      activate the camera and microphone function as well as
      the screen release of the communication device used
      for supervision during the entire test period. The
      customer is responsible for the functionality of the
      technical equipment used by the customer;
    </p>
    <p>
      An up-to-date overview of the applicable regulations
      and the necessary technical requirements can be found
      <a
        href="https://itb-academic-tests.org/en/participants/proctoring/"
      >
        on this page</a
      >.
    </p>

    <h2 class="mat-title">
      Article 12 – Ensuring the data protection of third
      parties
    </h2>
    <p>
      When selecting the test location and aligning the
      camera and microphone, the customer must ensure that
      no images or sounds of third parties are transmitted.
    </p>
  </ng-template>
</div>
