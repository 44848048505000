import {
  HttpClient,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import {
  ExamEvent,
  ExamEventListFilter,
  FormState,
  ListResponse,
  ListState,
  Supervision,
} from '../../types';
import { ExamEventStatistics } from '../../types/ExamEventStatistics';

export type StatisticsSearchFilters = {
  testEventIds?: number[];
  testIds?: number[];
  testAreaIds?: number[];
  testCenterIds?: number[];
  includeDeleted?: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class ExamEventService {
  listState: ListState<ExamEvent, ExamEventListFilter> = {
    loading: false,
    response: {
      items: [],
      total: 0,
    },
    filter: ExamEventService.resetFilter(),
  };
  formState: FormState<ExamEvent> = {
    loading: false,
    item: ExamEventService.factoryItem(),
  };

  constructor(private http: HttpClient) {}

  static factoryItem(): ExamEvent {
    return {
      id: 0,
      logoPath: '',
      maximumCapacity: 0,
      price: {
        amount: 0,
        currency: 'EUR',
      },
      registerTimeFrom: '',
      registerTimeTo: '',
      supervision: Supervision.Testcenter,
      templateId: 0,
      testId: 0,
      testcentersId: 0,
      testeventTime: '',
      testarea: '',
    };
  }

  static resetFilter(): ExamEventListFilter {
    return {
      availableForBooking: true,
      direction: 'asc',
      includeEvent: 0,
      search: '',
      sort: 'testeventTime',
      testcentersId: 0,
      testId: 0,
    };
  }

  loadList(): Promise<ListResponse<ExamEvent>> {
    this.listState.loading = true;
    let params = new HttpParams()
      .set('search', `${this.listState.filter.search}`)
      .set('sort', `${this.listState.filter.sort}`)
      .set(
        'direction',
        `${this.listState.filter.direction}`
      )
      .set(
        'availableForBooking',
        `${this.listState.filter.availableForBooking}`
      );

    if (this.listState.filter.includeStatistics) {
      params = params.set(
        'includeStatistics',
        `${this.listState.filter.includeStatistics}`
      );
    }

    if (this.listState.filter.testcentersId > 0) {
      params = params.set(
        'testcentersId',
        `${this.listState.filter.testcentersId}`
      );
    }

    if (
      (this.listState.filter.page || 0) > 0 &&
      (this.listState.filter.size || 0) > 0
    ) {
      params = params.set(
        'page',
        `${this.listState.filter.page}`
      );
      params = params.set(
        'size',
        `${this.listState.filter.size}`
      );
    }

    if (this.listState.filter.testId > 0) {
      params = params.set(
        'testId',
        `${this.listState.filter.testId}`
      );
    }

    if (this.listState.filter.includeEvent > 0) {
      params = params.set(
        'includeEvent',
        `${this.listState.filter.includeEvent}`
      );
    }

    const url = `/api/testevent`;

    const promise$: Promise<ListResponse<ExamEvent>> =
      firstValueFrom(
        this.http.get<ListResponse<ExamEvent>>(url, {
          params,
        })
      ).then(
        (response) => (this.listState.response = response)
      );

    promise$.finally(
      () => (this.listState.loading = false)
    );

    return promise$;
  }

  private httpParamsFromStatisticsSearchFilters(
    searchFilters: StatisticsSearchFilters
  ): HttpParams {
    let params = new HttpParams();
    if (searchFilters.testEventIds) {
      params = params.set(
        'testEventIds',
        searchFilters.testEventIds.join(',')
      );
    }
    if (searchFilters.testIds) {
      params = params.set(
        'testIds',
        searchFilters.testIds.join(',')
      );
    }
    if (searchFilters.testAreaIds) {
      params = params.set(
        'testAreaIds',
        searchFilters.testAreaIds.join(',')
      );
    }
    if (searchFilters.testCenterIds) {
      params = params.set(
        'testCenterIds',
        searchFilters.testCenterIds.join(',')
      );
    }
    if (searchFilters.includeDeleted) {
      params = params.set('includeDeleted', true);
    }
    return params;
  }

  loadStatisticsList(
    searchFilters: StatisticsSearchFilters
  ): Promise<ListResponse<ExamEventStatistics>> {
    let params =
      this.httpParamsFromStatisticsSearchFilters(
        searchFilters
      );

    const url = `/api/testevent/statistics`;

    return firstValueFrom(
      this.http.get<ListResponse<ExamEventStatistics>>(
        url,
        {
          params,
        }
      )
    );
  }

  downloadStatisticsList(
    searchFilters: StatisticsSearchFilters
  ): Promise<HttpResponse<Blob>> {
    let params =
      this.httpParamsFromStatisticsSearchFilters(
        searchFilters
      );

    const url = `/api/testevent/statistics`;

    return firstValueFrom(
      this.http.get(url, {
        headers: {
          Accept: 'text/csv',
        },
        observe: 'response',
        params,
        responseType: 'blob',
      })
    );
  }

  async sendInvitationsForSelectedEvents(
    examId: number,
    selectedTestEventIds: number[]
  ): Promise<void> {
    const url = `/api/testevent/invitations?testId=${examId}`;

    await firstValueFrom(
      this.http.put<void>(url, selectedTestEventIds, {
        headers: {
          Accept: 'text/csv',
        },
        observe: 'response',
        responseType: 'json',
      })
    );
  }

  downloadTestResultsList(
    examId: number
  ): Promise<HttpResponse<Blob>> {
    const url = `/api/test/${examId}/testresults`;

    return firstValueFrom(
      this.http.get(url, {
        headers: {
          Accept: 'text/csv',
        },
        observe: 'response',
        responseType: 'blob',
      })
    );
  }

  generateAndSendOutReports(
    examId: number
  ): Promise<HttpResponse<Object>> {
    const url = `/api/test/${examId}/testresults/reports`;

    return firstValueFrom(
      this.http.put(url, null, {
        observe: 'response',
      })
    );
  }

  //TODO: Replace by filter function
  loadByExamId(examId: number): Promise<ExamEvent[]> {
    this.listState.loading = true;
    return new Promise<ExamEvent[]>((resolve) => {
      resolve(
        this.listState.response.items.filter(
          (item) => item.testId === examId
        )
      );
    }).finally(() => (this.listState.loading = false));
  }

  loadByEventId(eventId: number): Promise<ExamEvent> {
    this.formState.loading = true;

    const url = `/api/testevent/${eventId}`;

    const promise$: Promise<ExamEvent> = firstValueFrom(
      this.http.get<ExamEvent>(url)
    );

    promise$
      .then((response) => (this.formState.item = response))
      .finally(() => {
        this.formState.loading = false;
      });

    return promise$;
  }

  create(): Promise<ExamEvent> {
    return firstValueFrom(
      this.http.post<ExamEvent>(
        '/api/testevent',
        this.formState.item
      )
    );
  }

  update(): Promise<ExamEvent> {
    return firstValueFrom(
      this.http.put<ExamEvent>(
        `/api/testevent/${this.formState.item.id}`,
        this.formState.item
      )
    );
  }

  delete(id: number): Promise<void> {
    return firstValueFrom(
      this.http.delete<void>(`/api/testevent/${id}`)
    );
  }

  save(): Promise<ExamEvent> {
    this.formState.loading = true;
    const promise =
      this.formState.item.id > 0
        ? this.update()
        : this.create();
    promise.finally(() => (this.formState.loading = false));
    return promise;
  }
}
