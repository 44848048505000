import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

/**
 * Copied from <a href="https://stackoverflow.com/a/49757625/2382246">this StackOverflow answer</a>.
 */
@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
  constructor(
    @Inject('BASE_API_URL') private baseUrl: string
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Only use this if a baseUrl has been set and the request is against the API. For this to work, the URL to the API MUST include the string 'api'.
    if (
      this.baseUrl?.length > 0 &&
      request.url.indexOf('api') > -1
    ) {
      const newUrl = new URL(request.url, this.baseUrl);
      const apiRequest = request.clone({
        url: newUrl.href,
      });
      return next.handle(apiRequest);
    } else {
      return next.handle(request);
    }
  }
}

export const BASE_API_INTERCEPTOR_PROVIDER = {
  provide: HTTP_INTERCEPTORS,
  useClass: BaseUrlInterceptor,
  multi: true,
};

export const BASE_API_URL_PROVIDER = {
  provide: 'BASE_API_URL',
  useValue: environment.apiUrl,
};
