import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-exam-booking-form-page',
  templateUrl: './exam-booking-form-page.component.html',
  styleUrls: ['./exam-booking-form-page.component.scss'],
})
export class ExamBookingFormPageComponent
  implements OnInit
{
  tenant?: string;

  ngOnInit(): void {
    this.tenant = environment.tenant;
  }
}
