import {
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {
  RegisterService,
  UserService,
} from 'src/app/services';
import { DialogResult, User } from 'src/app/types';

@Component({
  selector: 'app-edit-email-dialog',
  templateUrl: './edit-email-dialog.component.html',
  styleUrls: ['./edit-email-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditEmailDialogComponent implements OnInit {
  private form!: UntypedFormGroup;

  constructor(
    public dialog: MatDialogRef<
      EditEmailDialogComponent,
      DialogResult
    >,
    private formBuilder: UntypedFormBuilder,
    private service: RegisterService,
    private userService: UserService
  ) {}

  close(): void {
    this.dialog.close();
  }

  get emailControl(): UntypedFormControl {
    return this.form.get('email') as UntypedFormControl;
  }

  get user(): User {
    return this.userService.formState.item as User;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  submit() {
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      return;
    }
    this.service
      .editEmail(this.emailControl.value, this.user)
      .then(() => this.dialog.close({ success: true }))
      .catch(() => this.dialog.close({ success: false }));
  }
}
