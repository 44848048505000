export * from './candidate-form-page';
export * from './dashboard-page';
export * from './error404-page';
export * from './exam-booking-form-page';
export * from './exam-booking-summary-page';
export * from './exam-center-form-page';
export * from './exam-center-page';
export * from './exam-form-page';
export * from './exam-location-form-page';
export * from './exam-location-page';
export * from './exam-page';
export * from './info-pages';
export * from './landing-page';
export * from './mbr-retrieve-names-page';
export * from './my-bookings-page';
export * from './my-registrations-page';
export * from './profile-edit-page';
export * from './profile-page';
export * from './register-page';
export * from './reset-password-form-page';
export * from './user-form-page';
export * from './user-page';
