<div class="container-content" fxLayout="column">
  <xng-breadcrumb [separator]="separatorIconTemplate">
    <ng-container
      *xngBreadcrumbItem="
        let breadcrumb;
        let info = info;
        let first = first
      "
    >
      <mat-icon *ngIf="info">{{ info }}</mat-icon>
      <ng-container *ngIf="!first">{{
        breadcrumb | translate
      }}</ng-container>
    </ng-container>
  </xng-breadcrumb>

  <ng-template #separatorIconTemplate>
    <mat-icon>arrow_right</mat-icon>
  </ng-template>

  <ng-container [ngSwitch]="tenant">
    <app-exam-booking-form-default
      *ngSwitchCase="'default'"
    ></app-exam-booking-form-default>

    <app-exam-booking-form-sdv
      *ngSwitchCase="'sdv'"
    ></app-exam-booking-form-sdv>
  </ng-container>
</div>
