export type ExamCenter = {
  id: number;
  testareaId: number;
  name: string;
  provider: string;
  street: string;
  streetNumber: string;
  additionalAddressInfo: string;
  zip: string;
  city: string;
  state: string;
  country: string;
  maximumCapacity: number;
  additionalInformation: string;
};
