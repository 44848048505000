import { MonetaryAmount } from '../MonetaryAmount';

export interface BookingCancellationInfoResponse {
  bookingReference: string;
  totalPaidAmount: MonetaryAmount;
  defaultRefundAmount: MonetaryAmount;
  bookedAt: string; // ISO 8601 date time
  durationToTestEvent: string; // ISO 8601 duration
  testEvent: {
    testArea: string;
    testAreaDescription?: string;
    name: string;
    provider: string;
    address: {
      streetAndNumber: string;
      additionalAddressInfo?: string;
      zip?: string;
      city: string;
      state?: string;
      country: string;
    };
    additionalInformation?: string;
    dateAndTime: string; // ISO 8601 date time
  };
}
