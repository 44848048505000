import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Tools from 'src/app/lib/Tools';
import {
  BookingService,
  ExamEventService,
  UserService,
} from 'src/app/services';
import {
  AdditionalBookingQuestionsResponse,
  EventBooking,
  ExamEvent,
  User,
} from 'src/app/types';

@Component({
  selector: 'app-step7-booking-overview-sdv',
  templateUrl:
    './step7-booking-overview-sdv.component.html',
  styleUrls: [
    './step7-booking-overview-sdv.component.scss',
  ],
})
export class Step7BookingOverviewSdvComponent
  implements OnInit
{
  addQuestions!: AdditionalBookingQuestionsResponse;
  booking!: EventBooking;
  examEvent!: ExamEvent;
  user!: User;

  constructor(
    private bookingService: BookingService,
    private translateService: TranslateService,
    private examEventService: ExamEventService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.user = this.userService.formState.item;
    this.booking = this.bookingService.formState.item;

    this.getExamEvent(this.booking.testEventId);

    this.bookingService.eventBooking$.subscribe(
      (booking) => (this.booking = booking)
    );
  }

  get namePrefixKey(): string {
    switch (this.user.gender) {
      case 'm':
        return 'userInfo.name-prefix.male';
      case 'f':
        return 'userInfo.name-prefix.female';
      default:
        return 'userInfo.name-prefix.diverse';
    }
  }

  get currentLanguage(): string {
    return this.translateService.currentLang;
  }

  get isOptionalQuestionAnswered(): boolean | undefined {
    const addQuestions =
      this.booking.additionalQuestionData;

    if (addQuestions) {
      const { finalGrade, studentDetails } = addQuestions;
      const existingAnswers = new Set([
        finalGrade,
        studentDetails?.schoolSubjects?.german?.grade,
        studentDetails?.schoolSubjects?.german?.points,
        studentDetails?.schoolSubjects?.mathematics?.grade,
        studentDetails?.schoolSubjects?.mathematics?.points,
        studentDetails?.germanLanguageLevel,
        studentDetails?.migrationBackground,
      ]);
      // Any of the answers may be undefined, so we'll remove that as it is really a non-answer.
      existingAnswers.delete(undefined);
      return existingAnswers.size > 0; // If at least one answer has been given, return true.
    } else {
      return;
    }
  }

  get timeSlot(): Date | undefined {
    if (this.examEvent) {
      return Tools.dateFromIso(
        this.examEvent.testeventTime
      );
    }
    return;
  }

  private getExamEvent(testEventId: number): void {
    this.examEventService
      .loadByEventId(testEventId)
      .then((event) => {
        this.examEvent = event;
      });
  }
}
