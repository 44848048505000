<mat-dialog-content>
  <p>
    {{
      'message.get-reset-password-successful.' + tenant
        | translate
    }}
  </p>
  <div class="button-container flex-row flex-center">
    <div class="icon-success flex-row flex-center">
      <mat-icon>done</mat-icon>
    </div>
  </div>
</mat-dialog-content>
