<button [matMenuTriggerFor]="menu" mat-button>
  <span class="button-label mat-headline">{{
    currentLangLabel
  }}</span>
</button>
<mat-menu #menu="matMenu" class="i18n-button-menu">
  <button
    *ngFor="let lang of languages"
    (click)="switchLanguage(lang)"
    mat-menu-item
  >
    {{ getLangLabel(lang) }}
  </button>
</mat-menu>
