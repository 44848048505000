import {
  Component,
  HostBinding,
  OnInit,
} from '@angular/core';
import { DateTime } from 'luxon';
import {
  BookingService,
  ExamEventService,
  ExamService,
  ProfileService,
} from 'src/app/services';
import { EventBooking, Exam, User } from 'src/app/types';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss'],
})
export class DashboardPageComponent implements OnInit {
  @HostBinding('class.default-page-component')
  hostClass = true;
  isLoading = true;

  constructor(
    private bookingService: BookingService,
    private examEventService: ExamEventService,
    private examService: ExamService,
    private profileService: ProfileService
  ) {}

  async ngOnInit(): Promise<void> {
    this.examEventService.listState.filter =
      ExamEventService.resetFilter();
    await this.examEventService.loadList();
    await this.examService.loadExamList();
    await this.profileService.load();
    await this.bookingService.loadBookingsList(
      this.user.id
    );
    this.isLoading = false;
  }

  get items(): Exam[] {
    const validItems =
      this.examService.listState.response.items.filter(
        ({ validityPeriodStart, validityPeriodEnd }) => {
          const start = DateTime.fromISO(
            validityPeriodStart
          );
          const end = validityPeriodEnd
            ? DateTime.fromISO(validityPeriodEnd)
            : DateTime.now().plus({ days: 1 });

          return (
            start.diffNow().toMillis() < 0 &&
            end.diffNow().toMillis() > 0
          );
        }
      );

    return validItems;
  }

  get bookings(): EventBooking[] {
    return this.bookingService.listState.response.items;
  }

  get user(): User {
    return this.profileService.profile.user;
  }

  get loading(): boolean {
    return (
      this.examService.listState.loading ||
      this.bookingService.listState.loading
    );
  }
}
