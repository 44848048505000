import {
  Component,
  HostBinding,
  OnInit,
} from '@angular/core';
import { ExamService } from '../../services';
import { ListFilter } from '../../types';

@Component({
  selector: 'app-exam-page',
  templateUrl: './exam-page.component.html',
  styleUrls: ['./exam-page.component.scss'],
})
export class ExamPageComponent implements OnInit {
  @HostBinding('class.table-page-component')
  hostClass = true;
  constructor(private service: ExamService) {}

  ngOnInit(): void {
    this.service.loadExamList();
  }

  get filter(): ListFilter {
    return this.service.listState.filter;
  }

  get total(): number {
    return this.service.listState.response.total;
  }

  get loading(): boolean {
    return this.service.listState.loading;
  }

  load(): void {
    this.service.loadExamList();
  }
}
