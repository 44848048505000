import { Injectable } from '@angular/core';
import * as de from '../../../assets/i18n/translations/de.json';
import { SupportedLanguage } from '../../types';

export const i18Mock = { de };

@Injectable({
  providedIn: 'root',
})
export class I18nServiceMock {
  public supportedLanguages: SupportedLanguage[] = [
    {
      code: 'de',
      label: 'deutsch',
    },
    {
      code: 'en',
      label: 'english',
    },
  ];
  public currentLanguage: SupportedLanguage | null = {
    code: 'de',
    label: 'deutsch',
  };
  init(): Promise<void> {
    return new Promise<void>((resolve) => resolve());
  }
}
