<mat-card-content>
  <p>
    {{ 'hint.forgot-password-email.' + tenant | translate }}
  </p>

  <app-form-field-text
    [field]="emailControl"
    appearance="outline"
    label="label.email"
  ></app-form-field-text>
</mat-card-content>
<mat-dialog-actions>
  <div class="flex-1"></div>
  <button
    (click)="submit()"
    color="primary"
    mat-raised-button
  >
    {{ 'label.reset-password' | translate }}
  </button>
</mat-dialog-actions>
