import {
  Component,
  HostBinding,
  OnInit,
} from '@angular/core';
import {
  BookingService,
  ProfileService,
} from 'src/app/services';
import { EventBooking, User } from 'src/app/types';

@Component({
  selector: 'app-my-bookings-page',
  templateUrl: './my-bookings-page.component.html',
  styleUrls: ['./my-bookings-page.component.scss'],
})
export class MyBookingsPageComponent implements OnInit {
  @HostBinding('class.default-page-component')
  hostClass = true;
  isLoading = true;

  constructor(
    private bookingService: BookingService,
    private profileService: ProfileService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.profileService.load();

    await this.bookingService.loadBookingsList(
      this.user.id,
      {
        incompleteBookings: false,
        deletedBookings: false,
      }
    );
    this.isLoading = false;
  }

  get bookings(): EventBooking[] {
    return this.bookingService.listState.response.items;
  }

  get user(): User {
    return this.profileService.profile.user;
  }
}
