<mat-form-field
  [appearance]="appearance"
  class="triggers"
  [class.disabled]="disabled"
>
  <mat-label *ngIf="hasLabel">{{label|translate}}</mat-label>
  <input [matMenuTriggerFor]="menu"
         placeholder="{{placeholder|translate}}"
         [formControl]="field"
         [disabled]="disabled"
         readonly matInput>
  <button *ngIf="hasValue" [disabled]="disabled" (click)="clear()"
          mat-button mat-icon-button matSuffix>
    <mat-icon>close</mat-icon>
  </button>
  <button [disabled]="disabled" [matMenuTriggerFor]="menu" mat-button mat-icon-button matSuffix>
    <mat-icon>date_range</mat-icon>
  </button>
</mat-form-field>

<mat-menu #menu="matMenu" class="datetime-picker">
  <div (click)="preventMenuFromClosingDuringSelection($event)">
    <mat-calendar (selectedChange)="handleDateChanged($event)"
                  [minDate]="min"
                  [maxDate]="max"
                  [selected]="selectedDate"
    ></mat-calendar>

    <div class="flex-row time-select">
      <app-form-field-select [field]="hours"
                             [options]="hoursOptions"
                             [disabled]="disabled"
                             label="label.hours"
      ></app-form-field-select>
      <app-form-field-select [field]="minutes"
                             [options]="minutesOptions"
                             [disabled]="disabled"
                             label="label.minutes"
      ></app-form-field-select>
      <app-form-field-select [field]="seconds"
                             [options]="secondsOptions"
                             [disabled]="disabled"
                             label="label.seconds"
      ></app-form-field-select>
    </div>
    <button (click)="select()"
            [disabled]="disabled"
            class="button-select"
            color="primary"
            mat-raised-button
    >{{'button.select'|translate}}</button>
  </div>
</mat-menu>
