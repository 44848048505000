import { Pipe, PipeTransform } from '@angular/core';
import {
  AbstractControl,
  Validators,
} from '@angular/forms';

@Pipe({
  name: 'hasRequiredValidator',
  standalone: true,
})
export class HasRequiredValidatorPipe
  implements PipeTransform
{
  transform(control: AbstractControl | null): boolean {
    return !!control?.hasValidator(Validators.required);
  }
}
