import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BreadcrumbModule } from 'xng-breadcrumb';
import {
  FormFieldCheckboxGdprComponent,
  FormFieldCheckboxTermsOfServiceComponent,
  FormFieldSelectionTestLocationComponent,
  TestTimeCardComponent,
} from '../components';
import { ParentalAddressFormComponent } from '../components/forms/exam-booking/sdv';
import { FormFieldsModule } from '../lib/form-fields/form-fields.module';
import { MaterialsModule } from '../lib/materials/materials.module';
import { LoadingIndicatorFullComponent } from '../lib/misc';
import { ConsentProctoringPageComponent } from '../pages';
import { NG_MODULES } from './ng-modules';
import { NGX_TRANSLATE_CONFIG } from './ngx-translate-config';

@NgModule({
  declarations: [
    ConsentProctoringPageComponent,
    FormFieldCheckboxGdprComponent,
    FormFieldCheckboxTermsOfServiceComponent,
    FormFieldSelectionTestLocationComponent,
    LoadingIndicatorFullComponent,
    ParentalAddressFormComponent,
    TestTimeCardComponent,
  ],
  imports: [
    ...NG_MODULES,
    BreadcrumbModule,
    CommonModule,
    MaterialsModule,
    NGX_TRANSLATE_CONFIG,
    FormFieldsModule,
    FlexLayoutModule,
  ],
  exports: [
    ...NG_MODULES,
    BreadcrumbModule,
    CommonModule,
    ConsentProctoringPageComponent,
    FormFieldCheckboxGdprComponent,
    FormFieldCheckboxTermsOfServiceComponent,
    FormFieldSelectionTestLocationComponent,
    LoadingIndicatorFullComponent,
    MaterialsModule,
    ParentalAddressFormComponent,
    TestTimeCardComponent,
    FormFieldsModule,
    FlexLayoutModule,
  ],
})
export class SharedModule {}
