import { Clipboard } from '@angular/cdk/clipboard';
import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  DomSanitizer,
  SafeUrl,
} from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Duration } from 'luxon';
import { Subscription } from 'rxjs';
import {
  MbrUserRegistrationTimeoutComponent,
  MbrUserRegistrationTimeoutDialogData,
} from '../../components';
import {
  MbrUserService,
  ServerNotificationEventType,
} from '../../services';

export const DEFAULT_TIMEOUT_DURATION = Duration.fromObject(
  {
    minutes: 15,
  }
);

@Component({
  selector: 'app-mbr-retrieve-names-page',
  templateUrl: './mbr-retrieve-names-page.component.html',
  styleUrls: ['./mbr-retrieve-names-page.component.scss'],
})
export class MbrRetrieveNamesPageComponent
  implements OnInit, OnDestroy
{
  private qrCode?: Blob;
  private remainingTimeCounterId?: number;
  private serverUpdateSubscription?: Subscription;

  enmeshedAppLink?: string;
  qrCodeSrc?: SafeUrl;
  remainingTime?: Duration;
  completed = false;

  constructor(
    private clipboard: Clipboard,
    private dialog: MatDialog,
    private mbrUserService: MbrUserService,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.qrCode = this.mbrUserService.getQrCode();
    this.makeQrCodeAvailableAsImageUrl();
    this.enmeshedAppLink =
      this.mbrUserService.ablageAppLink;

    if (!this.qrCode || !this.enmeshedAppLink) {
      console.log(
        'Loaded the page again, trying to retrieve the MBR data retrieval QR code....'
      );
      this.mbrUserService
        .requestFirstAndLastNameFromWallet()
        .then(() => {
          // Receive and display the QR code and link
          this.qrCode = this.mbrUserService.getQrCode();
          this.enmeshedAppLink =
            this.mbrUserService.ablageAppLink;

          this.makeQrCodeAvailableAsImageUrl();
        })
        .then(() => this.requestUpdatesFromServer())
        .then(() => this.setupCountdown());
    } else {
      this.requestUpdatesFromServer();
      this.setupCountdown();
    }
  }

  private requestUpdatesFromServer() {
    // Connect to the server for notifications
    this.serverUpdateSubscription = this.mbrUserService
      .establishConnectionForServerNotifications()
      .subscribe({
        next: ({ data }) => {
          if (
            data ===
            ServerNotificationEventType.REQUEST_DATA_ACCEPTED
          ) {
            this.completed = true;
          } else if (
            data ===
            ServerNotificationEventType.REQUEST_ACCEPTANCE_TIMEOUT
          ) {
            this.handleTimeout();
          }
        },
        error: (error) => {
          console.error(
            `Received server error: ${JSON.stringify(
              error
            )}`
          );
        },
      });
  }

  private makeQrCodeAvailableAsImageUrl(): void {
    // Make the qr code available as an image URL
    if (this.qrCode) {
      const urlCreator = window.URL || window.webkitURL;
      const srcUrl = urlCreator.createObjectURL(
        this.qrCode
      );
      this.qrCodeSrc =
        this.sanitizer.bypassSecurityTrustUrl(srcUrl);
    }
  }

  private setupCountdown(): void {
    // Start the countdown
    this.remainingTime = DEFAULT_TIMEOUT_DURATION; // TODO Get the timeout from elsewhere
    this.remainingTimeCounterId = setInterval(() => {
      this.remainingTime = this.remainingTime
        ?.minus({ second: 1 })
        ?.normalize();
      const remaining = this.remainingTime?.toMillis() ?? 0;
      // Stop counting down if we reach 0 remaining seconds.
      if (remaining <= 0) {
        this.handleTimeout();
      }
    }, Duration.fromObject({ second: 1 }).toMillis());
  }

  private handleTimeout(): void {
    clearInterval(this.remainingTimeCounterId);
    this.remainingTimeCounterId = undefined;

    if (!this.completed) {
      this.dialog.open(
        MbrUserRegistrationTimeoutComponent,
        {
          minWidth: '80vw',
          minHeight: '22vh',
          panelClass: 'position-relative',
          closeOnNavigation: false,
          disableClose: true,
          data: {
            onClose: () => this.logoutAndDeleteUser(),
          } as MbrUserRegistrationTimeoutDialogData,
        }
      );
    }
  }

  copyLinkToClipboard(): void {
    if (this.enmeshedAppLink) {
      this.clipboard.copy(this.enmeshedAppLink);
    }
  }

  timeInMinutesAndSeconds(
    time: Duration | undefined
  ): string | undefined {
    return time?.toFormat('mm:ss');
  }

  async logoutAndDeleteUser(): Promise<void> {
    await this.mbrUserService.deleteUserWithIncompleteSetup();
  }

  async continueToDashboard(): Promise<void> {
    await this.router.navigate(['/home']);
  }

  ngOnDestroy() {
    if (this.serverUpdateSubscription) {
      this.serverUpdateSubscription.unsubscribe();
      this.serverUpdateSubscription = undefined;
    }
    this.mbrUserService.closeConnectionForServerNotifications();
    if (this.remainingTimeCounterId) {
      clearInterval(this.remainingTimeCounterId);
      this.remainingTimeCounterId = undefined;
    }
  }
}
