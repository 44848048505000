<div class="container-content" fxLayout="column">
  <ng-container *ngIf="showBreadcrumb">
    <xng-breadcrumb [separator]="separatorIconTemplate">
      <ng-container
        *xngBreadcrumbItem="
          let breadcrumb;
          let info = info;
          let first = first
        "
      >
        <mat-icon *ngIf="info">{{ info }}</mat-icon>
        <ng-container *ngIf="!first">{{
          breadcrumb | translate
        }}</ng-container>
      </ng-container>
    </xng-breadcrumb>

    <ng-template #separatorIconTemplate>
      <mat-icon>arrow_right</mat-icon>
    </ng-template>
  </ng-container>

  <ng-template #privacyTerms>
    <ng-container [ngSwitch]="tenant">
      <ng-container *ngSwitchCase="'default'">
        <h1 class="mat-headline">
          {{ 'privacy-terms.default.title' | translate }}
        </h1>

        <section id="introduction">
          <p>
            {{
              'privacy-terms.default.introduction.paragraph-1'
                | translate
            }}
          </p>
          <p>
            {{
              'privacy-terms.default.introduction.paragraph-2'
                | translate
            }}
          </p>
          <p>
            {{
              'privacy-terms.default.introduction.paragraph-3'
                | translate
            }}
          </p>
          <p>
            {{
              'privacy-terms.default.introduction.paragraph-4.part-1'
                | translate
            }}
            <strong>{{
              'privacy-terms.default.introduction.paragraph-4.part-2'
                | translate
            }}</strong>
            {{
              'privacy-terms.default.introduction.paragraph-4.part-3'
                | translate
            }}
          </p>
        </section>

        <section id="obligations">
          <h2 class="mat-title">
            {{
              'privacy-terms.default.obligations.title'
                | translate
            }}
          </h2>

          <p>
            {{
              'privacy-terms.default.obligations.contents'
                | translate
            }}
          </p>
        </section>

        <section id="processing-of-personal-data">
          <h2 class="mat-title">
            {{
              'privacy-terms.default.processing-of-personal-data.title'
                | translate
            }}
          </h2>
          <p>
            {{
              'privacy-terms.default.processing-of-personal-data.paragraph-1'
                | translate
            }}
          </p>
          <p>
            {{
              'privacy-terms.default.processing-of-personal-data.paragraph-2'
                | translate
            }}
          </p>
          <ol>
            <li>
              {{
                'privacy-terms.default.processing-of-personal-data.subject-of-processing.part-1'
                  | translate
              }}
            </li>
            <li>
              {{
                'privacy-terms.default.processing-of-personal-data.subject-of-processing.part-2'
                  | translate
              }}
            </li>
            <li>
              {{
                'privacy-terms.default.processing-of-personal-data.subject-of-processing.part-3'
                  | translate
              }}
            </li>
          </ol>
        </section>

        <section id="purposes-and-legal-basis">
          <h2 class="mat-title">
            {{
              'privacy-terms.default.purposes-and-legal-basis.title'
                | translate
            }}
          </h2>
          <p>
            {{
              'privacy-terms.default.purposes-and-legal-basis.contents'
                | translate
            }}
          </p>
        </section>

        <section id="third-parties">
          <h2 class="mat-title">
            {{
              'privacy-terms.default.third-parties.title'
                | translate
            }}
          </h2>
          <p>
            {{
              'privacy-terms.default.third-parties.paragraph-1'
                | translate
            }}
          </p>
          <p>
            {{
              'privacy-terms.default.third-parties.paragraph-carbone'
                | translate
            }}
          </p>
          <p *ngIf="showThirdPartyMbrParagraph">
            {{
              'privacy-terms.default.third-parties.paragraph-mbr'
                | translate
            }}
          </p>
          <p>
            {{
              'privacy-terms.default.third-parties.paragraph-2'
                | translate
            }}
          </p>
        </section>

        <section id="storage-duration">
          <h2 class="mat-title">
            {{
              'privacy-terms.default.storage-duration.title'
                | translate
            }}
          </h2>
          <p>
            {{
              'privacy-terms.default.storage-duration.paragraph-1'
                | translate
            }}
          </p>
          <p>
            {{
              'privacy-terms.default.storage-duration.paragraph-2'
                | translate
            }}
          </p>
          <p>
            {{
              'privacy-terms.default.storage-duration.paragraph-3'
                | translate
            }}
          </p>
        </section>

        <section id="complaints">
          <h2 class="mat-title">
            {{
              'privacy-terms.default.complaints.title'
                | translate
            }}
          </h2>
          <p>
            {{
              'privacy-terms.default.complaints.part-1'
                | translate
            }}
            <a
              href="{{
                'privacy-terms.default.complaints.link'
                  | translate
              }}"
            >
              {{
                'privacy-terms.default.complaints.link'
                  | translate
              }}
            </a>
            {{
              'privacy-terms.default.complaints.part-2'
                | translate
            }}
          </p>
        </section>

        <section id="controller">
          <h2 class="mat-title">
            {{
              'privacy-terms.default.controller.title'
                | translate
            }}
          </h2>
          <address>
            {{
              'privacy-terms.default.controller.name'
                | translate
            }}
            <br />
            {{
              'privacy-terms.default.controller.street-and-number'
                | translate
            }}
            <br />
            {{
              'privacy-terms.default.controller.zip-code-and-city'
                | translate
            }}
            <br />
            {{
              'privacy-terms.default.controller.telephone.label'
                | translate
            }}
            <a
              href="tel:{{
                'privacy-terms.default.controller.telephone.clean-number'
                  | translate
              }}"
            >
              {{
                'privacy-terms.default.controller.telephone.number'
                  | translate
              }}
            </a>
            <br />
            {{
              'privacy-terms.default.controller.telefax.label'
                | translate
            }}
            {{
              'privacy-terms.default.controller.telefax.number'
                | translate
            }}
            <br />
            {{
              'privacy-terms.default.controller.e-mail.label'
                | translate
            }}
            <a
              href="mailto:{{
                'privacy-terms.default.controller.e-mail.address'
                  | translate
              }}"
            >
              {{
                'privacy-terms.default.controller.e-mail.address'
                  | translate
              }}
            </a>
            <br />
            <span>
              {{
                'privacy-terms.default.controller.ceo.label'
                  | translate
              }}
              {{
                'privacy-terms.default.controller.ceo.name'
                  | translate
              }}
            </span>
          </address>
        </section>

        <section id="data-protection-officer">
          <h2 class="mat-title">
            {{
              'privacy-terms.default.data-protection-officer.title'
                | translate
            }}
          </h2>
          <address>
            {{
              'privacy-terms.default.data-protection-officer.name'
                | translate
            }}
            <br />
            {{
              'privacy-terms.default.data-protection-officer.street-and-number'
                | translate
            }}
            <br />
            {{
              'privacy-terms.default.data-protection-officer.zip-code-and-city'
                | translate
            }}
            <br />
            {{
              'privacy-terms.default.data-protection-officer.e-mail.label'
                | translate
            }}
            <a
              href="mailto:{{
                'privacy-terms.default.data-protection-officer.e-mail.address'
                  | translate
              }}"
            >
              {{
                'privacy-terms.default.data-protection-officer.e-mail.address'
                  | translate
              }}
            </a>
          </address>
        </section>

        <section id="e-mail">
          <h2 class="mat-title">
            {{
              'privacy-terms.default.e-mail.title' | translate
            }}
          </h2>
          <p>
            {{
              'privacy-terms.default.e-mail.paragraph-1'
                | translate
            }}
          </p>
          <p>
            {{
              'privacy-terms.default.e-mail.paragraph-2'
                | translate
            }}
          </p>
        </section>

        <section id="general-usage">
          <p>
            {{
              'privacy-terms.default.general-usage.prefix'
                | translate
            }}
            <a
              href="{{
                'privacy-terms.default.general-usage.link'
                  | translate
              }}"
            >
              {{
                'privacy-terms.default.general-usage.link'
                  | translate
              }}
            </a>
            {{
              'privacy-terms.default.general-usage.suffix'
                | translate
            }}
          </p>
        </section>
      </ng-container>

      <ng-container *ngSwitchCase="'sdv'">
        <h1 class="mat-headline">
          {{ 'privacy-terms.sdv.title' | translate }}
        </h1>

        <section id="introduction">
          <p>
            {{ 'privacy-terms.sdv.introduction' | translate }}
          </p>
        </section>

        <section id="controller">
          <h2 class="mat-title">
            {{
              'privacy-terms.sdv.controller.title' | translate
            }}
          </h2>
          <address>
            {{
              'privacy-terms.sdv.controller.name' | translate
            }}
            <br />
            {{
              'privacy-terms.sdv.controller.street-and-number'
                | translate
            }}
            <br />
            {{
              'privacy-terms.sdv.controller.zip-code-and-city'
                | translate
            }}
            <br />
            {{
              'privacy-terms.sdv.controller.telephone.label'
                | translate
            }}
            <a
              href="tel:{{
                'privacy-terms.sdv.controller.telephone.clean-number'
                  | translate
              }}"
            >
              {{
                'privacy-terms.sdv.controller.telephone.number'
                  | translate
              }}
            </a>
            <br />
            {{
              'privacy-terms.sdv.controller.e-mail.label'
                | translate
            }}
            <a
              href="mailto:{{
                'privacy-terms.sdv.controller.e-mail.address'
                  | translate
              }}"
            >
              {{
                'privacy-terms.sdv.controller.e-mail.address'
                  | translate
              }}
            </a>
          </address>
        </section>

        <section id="data-protection-officer">
          <h2 class="mat-title">
            {{
              'privacy-terms.sdv.data-protection-officer.title'
                | translate
            }}
          </h2>
          <p>
            {{
              'privacy-terms.sdv.data-protection-officer.part-1'
                | translate
            }}
          </p>
          <address>
            {{
              'privacy-terms.sdv.data-protection-officer.name'
                | translate
            }}
            <br />
            {{
              'privacy-terms.sdv.data-protection-officer.street-and-number'
                | translate
            }}
            <br />
            {{
              'privacy-terms.sdv.data-protection-officer.zip-code-and-city'
                | translate
            }}
            <br />
            {{
              'privacy-terms.sdv.data-protection-officer.telephone.label'
                | translate
            }}
            <a
              href="tel:{{
                'privacy-terms.sdv.data-protection-officer.telephone.clean-number'
                  | translate
              }}"
            >
              {{
                'privacy-terms.sdv.data-protection-officer.telephone.number'
                  | translate
              }}
            </a>
            <br />
            {{
              'privacy-terms.sdv.data-protection-officer.e-mail.label'
                | translate
            }}
            <a
              href="mailto:{{
                'privacy-terms.sdv.data-protection-officer.e-mail.address'
                  | translate
              }}"
            >
              {{
                'privacy-terms.sdv.data-protection-officer.e-mail.address'
                  | translate
              }}
            </a>
          </address>
        </section>

        <section id="purposes-and-legal-basis">
          <h2 class="mat-title">
            {{
              'privacy-terms.sdv.purposes-and-legal-basis.title'
                | translate
            }}
          </h2>
          <p>
            {{
              'privacy-terms.sdv.purposes-and-legal-basis.paragraph-1'
                | translate
            }}
          </p>
          <p>
            {{
              'privacy-terms.sdv.purposes-and-legal-basis.paragraph-2'
                | translate
            }}
          </p>
          <p>
            {{
              'privacy-terms.sdv.purposes-and-legal-basis.paragraph-3'
                | translate
            }}
          </p>
          <p>
            {{
              'privacy-terms.sdv.purposes-and-legal-basis.paragraph-4'
                | translate
            }}
          </p>
          <p>
            {{
              'privacy-terms.sdv.purposes-and-legal-basis.paragraph-5'
                | translate
            }}
          </p>
          <p>
            {{
              'privacy-terms.sdv.purposes-and-legal-basis.paragraph-6'
                | translate
            }}
          </p>
        </section>

        <section id="third-parties">
          <h2 class="mat-title">
            {{
              'privacy-terms.sdv.third-parties.title'
                | translate
            }}
          </h2>
          <p>
            {{
              'privacy-terms.sdv.third-parties.paragraph-1'
                | translate
            }}
          </p>
          <p>
            {{
              'privacy-terms.sdv.third-parties.paragraph-2'
                | translate
            }}
          </p>
          <p>
            {{
              'privacy-terms.sdv.third-parties.paragraph-3'
                | translate
            }}
          </p>
        </section>

        <section id="storage-duration">
          <h2 class="mat-title">
            {{
              'privacy-terms.sdv.storage-duration.title'
                | translate
            }}
          </h2>
          <p>
            {{
              'privacy-terms.sdv.storage-duration.paragraph-1'
                | translate
            }}
          </p>
          <p>
            {{
              'privacy-terms.sdv.storage-duration.paragraph-2'
                | translate
            }}
          </p>
        </section>

        <section id="complaints">
          <h2 class="mat-title">
            {{
              'privacy-terms.sdv.complaints.title' | translate
            }}
          </h2>
          <p>
            {{
              'privacy-terms.sdv.complaints.paragraph-1'
                | translate
            }}
          </p>
        </section>
      </ng-container>
    </ng-container>
  </ng-template>

  <div *ngIf="showMbrSpecificHighlighting else privacyTerms">
    <h1 class="mat-headline">{{ 'privacy-terms.default.mbr.title' | translate }}</h1>

    <section id="mbr-introduction">
      {{ 'privacy-terms.default.mbr.introduction' | translate }}
    </section>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'privacy-terms.default.title' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngTemplateOutlet="privacyTerms"></ng-container>
    </mat-expansion-panel>
    <section id="mbr-specifics">
      {{ 'privacy-terms.default.mbr.mbr-specifics.as-an-mbr-user' | translate }}
      <ul>
        <li>{{ 'privacy-terms.default.mbr.mbr-specifics.personal-data-transfer' | translate }}</li>
        <li>{{ 'privacy-terms.default.mbr.mbr-specifics.upload-calendar-entries-and-invoices' | translate }}</li>
        <li>{{ 'privacy-terms.default.mbr.mbr-specifics.upload-test-certificates-and-results' | translate }}</li>
      </ul>
    </section>
  </div>

  <mat-card-actions *ngIf="showAcceptAndRejectButtons">
    <button
      mat-raised-button
      color="warn"
      type="button"
      (click)="rejectPrivacyTerms()"
    >
      {{ 'button.privacy-terms.reject' | translate }}
    </button>
    <button
      mat-raised-button
      color="primary"
      type="button"
      (click)="acceptPrivacyTerms()"
    >
      {{ 'button.privacy-terms.accept' | translate }}
    </button>
  </mat-card-actions>
</div>
