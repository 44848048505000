import {
  DEFAULT_CURRENCY_CODE,
  NgModule,
} from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgxCurrencyModule } from 'ngx-currency';
import { NG_MODULES } from 'src/app/config/ng-modules';
import { LocalizedDatePipe } from 'src/app/pipes/localized-date.pipe';
import { MaterialsModule } from '../materials/materials.module';
import { FormFieldAbstractComponent } from './form-field-abstract.component';
import { FormFieldCheckboxComponent } from './form-field-checkbox';
import { FormFieldDateComponent } from './form-field-date';
import { FormFieldDatetimeComponent } from './form-field-datetime';
import { FormFieldMonetaryAmountComponent } from './form-field-monetary-amount';
import { FormFieldPasswordComponent } from './form-field-password';
import { FormFieldRadioComponent } from './form-field-radio';
import { FormFieldSelectComponent } from './form-field-select';
import { FormFieldTextComponent } from './form-field-text';
import { FormFieldTextareaComponent } from './form-field-textarea';

@NgModule({
  declarations: [
    FormFieldAbstractComponent,
    FormFieldTextComponent,
    FormFieldPasswordComponent,
    FormFieldCheckboxComponent,
    FormFieldSelectComponent,
    FormFieldDatetimeComponent,
    FormFieldDateComponent,
    FormFieldMonetaryAmountComponent,
    FormFieldTextareaComponent,
    FormFieldRadioComponent,
    LocalizedDatePipe,
  ],
  exports: [
    FormFieldTextComponent,
    FormFieldPasswordComponent,
    FormFieldCheckboxComponent,
    FormFieldSelectComponent,
    FormFieldDatetimeComponent,
    FormFieldDateComponent,
    FormFieldMonetaryAmountComponent,
    FormFieldTextareaComponent,
    FormFieldRadioComponent,
    LocalizedDatePipe,
  ],
  imports: [
    ...NG_MODULES,
    MaterialsModule,
    NgxCurrencyModule,
    TranslateModule.forChild({ isolate: false }),
  ],
  providers: [
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'de-DE',
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'EUR',
    },
  ],
})
export class FormFieldsModule {}
