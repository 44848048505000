import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-imprint-page',
  templateUrl: './imprint-page.component.html',
  styleUrls: ['./imprint-page.component.scss'],
})
export class ImprintPageComponent implements OnInit {
  @Input() showBreadcrumb = true;

  tenant!: string;

  ngOnInit(): void {
    this.tenant = environment.tenant;
  }
}
