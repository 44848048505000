<div class="container-content" fxLayout="column">
  <ng-container *ngIf="showBreadcrumb">
    <xng-breadcrumb [separator]="separatorIconTemplate">
      <ng-container
        *xngBreadcrumbItem="
          let breadcrumb;
          let info = info;
          let first = first
        "
      >
        <mat-icon *ngIf="info">{{ info }}</mat-icon>
        <ng-container *ngIf="!first">{{
          breadcrumb | translate
        }}</ng-container>
      </ng-container>
    </xng-breadcrumb>

    <ng-template #separatorIconTemplate>
      <mat-icon>arrow_right</mat-icon>
    </ng-template>
  </ng-container>

  <h1 class="mat-headline">
    {{ 'label.system-requirements' | translate }}
  </h1>

  <p>
    <em
      >{{
        'system-requirements.last-updated.text' | translate
      }}:
      <time
        [dateTime]="
          'system-requirements.last-updated.date'
            | translate
        "
        >{{
          'system-requirements.last-updated.date'
            | translate
            | localizedDate
        }}</time
      >
    </em>
  </p>

  <section id="supported-browsers">
    <h2 class="mat-title">
      {{
        'system-requirements.supported-browsers.title'
          | translate
      }}
    </h2>
    <p>
      {{
        'system-requirements.supported-browsers.preamble.' +
          tenant | translate
      }}
    </p>
    <ul>
      <li>
        <strong>{{
          'system-requirements.supported-browsers.chrome.name'
            | translate
        }}</strong>
        {{
          'system-requirements.supported-browsers.chrome.version'
            | translate
        }}
      </li>
      <li>
        <strong>{{
          'system-requirements.supported-browsers.firefox.name'
            | translate
        }}</strong>
        {{
          'system-requirements.supported-browsers.firefox.version.current'
            | translate
        }},
        {{
          'system-requirements.supported-browsers.firefox.version.esr.prefix'
            | translate
        }}
        <a
          href="https://support.mozilla.org/en-US/kb/switch-to-firefox-extended-support-release-esr"
        >
          {{
            'system-requirements.supported-browsers.firefox.version.esr.link-text'
              | translate
          }}
        </a>
      </li>
      <li>
        <strong>{{
          'system-requirements.supported-browsers.edge.name'
            | translate
        }}</strong>
        {{
          'system-requirements.supported-browsers.edge.version'
            | translate
        }}
      </li>
      <li>
        <strong>{{
          'system-requirements.supported-browsers.safari-mac.name'
            | translate
        }}</strong>
        {{
          'system-requirements.supported-browsers.safari-mac.version'
            | translate
        }}
      </li>
      <li>
        <strong>{{
          'system-requirements.supported-browsers.safari-ios.name'
            | translate
        }}</strong>
        {{
          'system-requirements.supported-browsers.safari-ios.version'
            | translate
        }}
      </li>
      <li>
        <strong>{{
          'system-requirements.supported-browsers.android-browser.name'
            | translate
        }}</strong>
        {{
          'system-requirements.supported-browsers.android-browser.version'
            | translate
        }}
      </li>
    </ul>
  </section>

  <section id="supported-oses">
    <h2 class="mat-title">
      {{
        'system-requirements.supported-oses.title'
          | translate
      }}
    </h2>
    <p>
      {{
        'system-requirements.supported-oses.preamble.' +
          tenant | translate
      }}
    </p>
    <ul>
      <li>
        <strong>{{
          'system-requirements.supported-oses.windows.name'
            | translate
        }}</strong>
        {{
          'system-requirements.supported-oses.windows.version'
            | translate
        }}
      </li>
      <li>
        <strong>{{
          'system-requirements.supported-oses.macos.name'
            | translate
        }}</strong>
        {{
          'system-requirements.supported-oses.macos.version'
            | translate
        }}
      </li>
      <li>
        <strong>{{
          'system-requirements.supported-oses.ubuntu.name'
            | translate
        }}</strong>
        {{
          'system-requirements.supported-oses.ubuntu.version'
            | translate
        }}
      </li>
      <li>
        <strong>{{
          'system-requirements.supported-oses.ios.name'
            | translate
        }}</strong>
        {{
          'system-requirements.supported-oses.ios.version'
            | translate
        }}
      </li>
      <li>
        <strong>{{
          'system-requirements.supported-oses.android.name'
            | translate
        }}</strong>
        {{
          'system-requirements.supported-oses.android.version'
            | translate
        }}
      </li>
    </ul>
  </section>

  <section id="other">
    <h2>
      {{ 'system-requirements.other.title' | translate }}
    </h2>
    <p>
      {{
        'system-requirements.other.javascript-required.' +
          tenant | translate
      }}
    </p>
  </section>
</div>
