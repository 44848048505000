import { AdditionalQuestionData } from '../AdditionalQuestionData';
import { PriceCalculationData } from '../EventBooking';
import { PrerequisiteQuestionData } from './PrerequisiteQuestionDataResponse';

export interface AddBookingDetailsResponse {
  additionalQuestionData: AdditionalQuestionData;
  bookingId: number;
  userId: number;
  testEventId: number;
  test: {
    id: number;
    name: string;
    templateName:
      | 'PhaST'
      | 'ITB-Business'
      | 'TM-WISO'
      | 'GSAT'
      | 'SdV';
    logoPath?: string;
  };
  reservedUntil: string;
  prerequisiteQuestionData?: PrerequisiteQuestionData;
  priceCalculationData?: PriceCalculationData;
  priceInCt?: number;
}
