<div
  class="page-content-cards container-content"
  fxLayoutAlign="center"
  *ngIf="!isLoading"
>
  <div class="page-block">
    <h2 class="mat-display-1">
      {{ 'title.test-registration' | translate }}
    </h2>
    <app-dashboard-exam-card
      *ngFor="let item of items"
      [exam]="item"
    ></app-dashboard-exam-card>
  </div>
  <div class="page-block">
    <h2 class="mat-display-1">
      {{ 'title.my-bookings' | translate }}
    </h2>
    <app-dashboard-booking-card
      *ngFor="let booking of bookings"
      [booking]="booking"
      [routerLink]="'/home/bookings/summary'"
    ></app-dashboard-booking-card>
  </div>
</div>

<app-loading-indicator-full
  *ngIf="isLoading"
></app-loading-indicator-full>
