<form fxLayout="column" [formGroup]="form">
  <ng-container [ngSwitch]="booking.test.templateName">
    <ng-container *ngSwitchCase="'PhaST'">
      <h4>
        {{ 'phast.question.university.text' | translate }}
        <mat-hint>
          <em>{{
            'phast.question.university.required' | translate
          }}</em>
        </mat-hint>
      </h4>

      <app-form-field-select
        [field]="university"
        [options]="universityOptions"
        [label]="'label.dropdown-select' | translate"
        [multiple]="true"
        [panelClass]="'university-select-panel'"
        [required]="false"
      ></app-form-field-select>

      <h4>
        {{ 'phast.question.final-grade.text' | translate }}
        <mat-hint>
          <em>{{
            'phast.question.final-grade.required'
              | translate
          }}</em>
        </mat-hint>
      </h4>

      <app-form-field-text [field]="finalGrade">
      </app-form-field-text>
    </ng-container>

    <ng-container *ngSwitchCase="'TM-WISO'">
      <h4>
        {{ 'tm-wiso.questions.language.text' | translate }}
        <mat-hint>
          <em>{{
            'tm-wiso.questions.language.required'
              | translate
          }}</em>
        </mat-hint>
      </h4>

      <app-form-field-select
        class="mb-2"
        [field]="language"
        [options]="languageOptions"
        [label]="'label.dropdown-select' | translate"
        [multiple]="false"
        [panelClass]="'university-select-panel'"
        [required]="true"
      ></app-form-field-select>

      <h4>
        {{
          'tm-wiso.questions.apply-for-university.text'
            | translate
        }}
        <mat-hint>
          <em>{{
            'tm-wiso.questions.apply-for-university.required'
              | translate
          }}</em>
        </mat-hint>
      </h4>

      <app-form-field-select
        class="mb-2"
        [field]="applyForUniversity"
        [options]="universityOptions"
        [label]="'label.dropdown-select' | translate"
        [multiple]="true"
        [panelClass]="'university-select-panel'"
        [required]="true"
      ></app-form-field-select>

      <h4>
        {{
          'tm-wiso.questions.confirm-send-results.text'
            | translate
        }}
        <mat-hint>
          <em>{{
            'tm-wiso.questions.confirm-send-results.hint'
              | translate
          }}</em>
        </mat-hint>
      </h4>

      <mat-radio-group
        class="radio-buttons mb-2"
        aria-label="Select an option"
        formControlName="confirmSendResults"
        required
      >
        <mat-radio-button
          [value]="confirmSendResultsOption[0].value"
          >{{
            'tm-wiso.questions.confirm-send-results.yes'
              | translate
          }}</mat-radio-button
        >
        <mat-radio-button
          [value]="confirmSendResultsOption[1].value"
          >{{
            'tm-wiso.questions.confirm-send-results.no'
              | translate
          }}</mat-radio-button
        >
      </mat-radio-group>

      <h4>
        {{
          'tm-wiso.questions.send-results.text' | translate
        }}
        <mat-hint>
          <em>{{
            'tm-wiso.questions.send-results.required'
              | translate
          }}</em>
        </mat-hint>
      </h4>

      <app-form-field-select
        class="mb-2"
        [field]="sendResultsToUniversities"
        [options]="sendResultsToUniversitiesOptions"
        [label]="'label.dropdown-select' | translate"
        [multiple]="true"
        [panelClass]="'university-select-panel'"
        [required]="false"
        [disabled]="
          !confirmSendResults.valid ||
          confirmSendResults.value === 2
        "
      ></app-form-field-select>

      <h4>
        {{
          'tm-wiso.questions.current-university.text'
            | translate
        }}
        <mat-hint>
          <em>{{
            'tm-wiso.questions.current-university.not-required'
              | translate
          }}</em>
        </mat-hint>
      </h4>
      <div
        class="mb-2"
        fxLayout="column"
        fxLayout.gt-sm="row"
        fxLayoutGap="0"
        fxLayoutGap.gt-sm="2rem"
      >
        <app-form-field-text
          fxFlex="grow"
          [field]="currentUniversityName"
          [label]="
            'tm-wiso.questions.current-university.label.university-name'
              | translate
          "
          [required]="false"
        >
        </app-form-field-text>
        <app-form-field-text
          fxFlex="grow"
          [field]="currentUniversityPlace"
          [label]="
            'tm-wiso.questions.current-university.label.university-place'
              | translate
          "
          [required]="false"
        >
        </app-form-field-text>
      </div>

      <h4>
        {{
          'tm-wiso.questions.current-subject.text'
            | translate
        }}
        <mat-hint>
          <em>{{
            'tm-wiso.questions.current-subject.not-required'
              | translate
          }}</em>
        </mat-hint>
      </h4>
      <div
        class="mb-2"
        fxLayout="column"
        fxLayout.gt-sm="row"
        fxLayoutGap="0"
        fxLayoutGap.gt-sm="2rem"
      >
        <app-form-field-text
          fxFlex="grow"
          [field]="currentStudySubject"
          [label]="
            'tm-wiso.questions.current-subject.label.study-subject'
              | translate
          "
          [required]="false"
        >
        </app-form-field-text>
        <app-form-field-text
          fxFlex="grow"
          [field]="currentTypeOfDegree"
          [label]="
            'tm-wiso.questions.current-subject.label.type-of-degree'
              | translate
          "
          [required]="false"
        >
        </app-form-field-text>
      </div>

      <h4>
        {{
          'tm-wiso.questions.school-grade.text' | translate
        }}
        <mat-hint>
          <em>{{
            'tm-wiso.questions.school-grade.not-required'
              | translate
          }}</em>
        </mat-hint>
      </h4>
      <app-form-field-text
        class="mb-2"
        fxFlex="grow"
        [field]="finalGrade"
        [label]="
          'tm-wiso.questions.school-grade.label' | translate
        "
        [required]="false"
      >
      </app-form-field-text>

      <h4>
        {{
          'tm-wiso.questions.study-grade.text' | translate
        }}
        <mat-hint>
          <em>{{
            'tm-wiso.questions.study-grade.not-required'
              | translate
          }}</em>
        </mat-hint>
      </h4>
      <app-form-field-text
        class="mb-2"
        fxFlex="grow"
        [field]="graduateDegree"
        [label]="
          'tm-wiso.questions.study-grade.label' | translate
        "
        [required]="false"
      >
      </app-form-field-text>
    </ng-container>

    <ng-container *ngSwitchCase="'GSAT'">
      <h4>
        {{ 'gsat.questions.language.text' | translate }}
        <mat-hint>
          <em>{{
            'gsat.questions.language.required' | translate
          }}</em>
        </mat-hint>
      </h4>

      <app-form-field-select
        class="mb-2"
        [field]="language"
        [options]="languageOptions"
        [label]="'label.dropdown-select' | translate"
        [multiple]="false"
        [panelClass]="'university-select-panel'"
        [required]="true"
      ></app-form-field-select>
    </ng-container>

    <ng-container *ngSwitchCase="'ITB-Business'">
      <h4>
        {{
          'itb-business.questions.language.text' | translate
        }}
        <mat-hint>
          <em>{{
            'itb-business.questions.language.required'
              | translate
          }}</em>
        </mat-hint>
      </h4>

      <app-form-field-select
        class="mb-2"
        [field]="language"
        [options]="languageOptions"
        [label]="'label.dropdown-select' | translate"
        [multiple]="false"
        [panelClass]="'language-select-panel'"
        [required]="true"
      ></app-form-field-select>

      <h4>
        {{
          'itb-business.questions.apply-for-university.text'
            | translate
        }}
        <mat-hint>
          <em>{{
            'itb-business.questions.apply-for-university.required'
              | translate
          }}</em>
        </mat-hint>
      </h4>

      <app-form-field-select
        class="mb-2"
        [field]="applyForUniversity"
        [options]="universityOptions"
        [label]="'label.dropdown-select' | translate"
        [multiple]="true"
        [panelClass]="'university-select-panel'"
        [required]="true"
      ></app-form-field-select>

      <h4>
        {{
          'itb-business.questions.confirm-send-results.text'
            | translate
        }}
        <mat-hint>
          <em>{{
              'itb-business.questions.confirm-send-results.hint'
                | translate
            }}</em>
        </mat-hint>
      </h4>

      <mat-radio-group
        class="radio-buttons mb-2"
        aria-label="Select an option"
        formControlName="confirmSendResults"
        required
      >
        <mat-radio-button
          [value]="confirmSendResultsOption[0].value"
        >{{
            'itb-business.questions.confirm-send-results.yes'
              | translate
          }}</mat-radio-button
        >
        <mat-radio-button
          [value]="confirmSendResultsOption[1].value"
        >{{
            'itb-business.questions.confirm-send-results.no'
              | translate
          }}</mat-radio-button
        >
      </mat-radio-group>

      <h4>
        {{
          'itb-business.questions.send-results.text' | translate
        }}
        <mat-hint>
          <em>{{
              'itb-business.questions.send-results.required'
                | translate
            }}</em>
        </mat-hint>
      </h4>

      <app-form-field-select
        class="mb-2"
        [field]="sendResultsToUniversities"
        [options]="sendResultsToUniversitiesOptions"
        [label]="'label.dropdown-select' | translate"
        [multiple]="true"
        [panelClass]="'university-select-panel'"
        [required]="false"
        [disabled]="
          !confirmSendResults.valid ||
          confirmSendResults.value === 2
        "
      ></app-form-field-select>

      <h4>
        {{
          'itb-business.questions.final-grade.text'
            | translate
        }}
        <mat-hint>
          <em>{{
            'itb-business.questions.final-grade.required'
              | translate
          }}</em>
        </mat-hint>
      </h4>

      <app-form-field-text
        class="mb-2"
        [field]="finalGrade"
      >
      </app-form-field-text>
    </ng-container>

    <ng-container *ngSwitchCase="'ITB-Science'">
      <h4>
        {{
          'itb-science.questions.language.text' | translate
        }}
        <mat-hint>
          <em>{{
              'itb-science.questions.language.required'
                | translate
            }}</em>
        </mat-hint>
      </h4>

      <app-form-field-select
        class="mb-2"
        [field]="language"
        [options]="languageOptions"
        [label]="'label.dropdown-select' | translate"
        [multiple]="false"
        [panelClass]="'language-select-panel'"
        [required]="true"
      ></app-form-field-select>

      <h4>
        {{
          'itb-science.questions.final-grade.text'
            | translate
        }}
        <mat-hint>
          <em>{{
              'itb-science.questions.final-grade.required'
                | translate
            }}</em>
        </mat-hint>
      </h4>

      <app-form-field-text
        class="mb-2"
        [field]="finalGrade"
      >
      </app-form-field-text>
    </ng-container>

    <ng-container *ngSwitchCase="'ITB-Technology'">
      <h4>
        {{
          'itb-technology.questions.language.text' | translate
        }}
        <mat-hint>
          <em>{{
              'itb-technology.questions.language.required'
                | translate
            }}</em>
        </mat-hint>
      </h4>

      <app-form-field-select
        class="mb-2"
        [field]="language"
        [options]="languageOptions"
        [label]="'label.dropdown-select' | translate"
        [multiple]="false"
        [panelClass]="'language-select-panel'"
        [required]="true"
      ></app-form-field-select>

      <h4>
        {{
          'itb-technology.questions.final-grade.text'
            | translate
        }}
        <mat-hint>
          <em>{{
              'itb-technology.questions.final-grade.required'
                | translate
            }}</em>
        </mat-hint>
      </h4>

      <app-form-field-text
        class="mb-2"
        [field]="finalGrade"
      >
      </app-form-field-text>
    </ng-container>
  </ng-container>

  <mat-card-actions>
    <button
      mat-raised-button
      matStepperPrevious
      type="button"
    >
      {{ 'button.back' | translate }}
    </button>
    <button
      mat-raised-button
      color="accent"
      type="button"
      (click)="updateBooking()"
      [disabled]="!form.valid"
    >
      {{ 'button.next' | translate }}
    </button>
  </mat-card-actions>
</form>
