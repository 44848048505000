import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import {
  ExamCenterService,
  ExamService,
} from '../../../services';
import {
  Exam,
  ExamCenter,
  ExamEvent,
} from '../../../types';

@Component({
  selector: 'app-exam-event-details-dialog',
  templateUrl: './exam-event-details-dialog.component.html',
  styleUrls: ['./exam-event-details-dialog.component.scss'],
})
export class ExamEventDetailsDialogComponent
  implements OnInit
{
  examEvent!: ExamEvent;
  exam!: Exam;
  examCenter!: ExamCenter;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { examEvent: ExamEvent },
    public translateService: TranslateService,
    public examService: ExamService,
    public examCenterService: ExamCenterService
  ) {
    this.examEvent = data.examEvent;
  }

  async ngOnInit(): Promise<void> {
    this.exam = await this.examService.loadExamById(
      this.examEvent.testId
    );

    this.examCenter = await this.examCenterService.loadById(
      this.examEvent.testcentersId
    );
  }

  get currentLanguage(): string {
    return this.translateService.currentLang;
  }
}
