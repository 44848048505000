<form [formGroup]="form">
  <mat-dialog-content>
    <app-consent-proctoring-page
      [showBreadcrumb]="showBreadcrumb"
    ></app-consent-proctoring-page>

    <div class="container-content">
      <app-form-field-checkbox
        [label]="
          'consent-form-proctoring.sdv.checkbox-label'
        "
        [field]="consentControl"
      ></app-form-field-checkbox>

      <p>
        {{
          'consent-form-proctoring.sdv.paragraph-7'
            | translate
        }}
      </p>
    </div>
  </mat-dialog-content>
</form>
<mat-dialog-actions align="end">
  <button
    mat-raised-button
    mat-dialog-close
    color="primary"
    [disabled]="!form.valid"
    (click)="nextAndClose()"
  >
    {{ 'button.next' | translate }}
  </button>
</mat-dialog-actions>
