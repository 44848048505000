<form [formGroup]="form">
  <div class="form-content">
    <!-- block: base -->
    <div class="form-block">
      <app-form-field-text
        class="spacerBottom"
        [field]="firstnameControl"
        label="label.firstname"
        [required]="firstnameControl | hasRequiredValidator"
      ></app-form-field-text>
      <app-form-field-text
        class="spacerBottom"
        [field]="lastnameControl"
        label="label.lastname"
        [required]="lastnameControl | hasRequiredValidator"
      ></app-form-field-text>
      <app-form-field-text
        class="spacerBottom"
        [field]="birthNameControl"
        label="label.birthname"
        [required]="birthNameControl | hasRequiredValidator"
      ></app-form-field-text>
      <app-form-field-date
        [field]="birthDateControl"
        [maxDate]="maxDate"
        class="spacerBottom"
        label="label.birthDate"
        [required]="birthDateControl | hasRequiredValidator"
      >
      </app-form-field-date>
      <app-form-field-select
        id="genderField"
        [field]="genderControl"
        [options]="genderOptions"
        label="gender.label"
        [required]="genderControl | hasRequiredValidator"
      ></app-form-field-select>
      <mat-hint
        ><em>
          {{ 'gender.hint' | translate }}
        </em>
      </mat-hint>
    </div>

    <!-- block: personal -->
    <div class="form-block">
      <app-form-field-text
        class="spacerBottom"
        [field]="birthLocationControl"
        label="label.birthLocation"
        [required]="
          birthLocationControl | hasRequiredValidator
        "
      ></app-form-field-text>
      <app-form-field-text
        class="spacerBottom"
        [field]="phoneControl"
        label="label.phone"
        [required]="phoneControl | hasRequiredValidator"
      ></app-form-field-text>
      <app-form-field-text
        class="spacerBottom"
        [field]="email2Control"
        label="label.email2"
        [required]="email2Control | hasRequiredValidator"
      ></app-form-field-text>
      <app-form-field-text
        class="spacerBottom"
        [field]="streetControl"
        label="label.street"
        [required]="streetControl | hasRequiredValidator"
      ></app-form-field-text>
      <app-form-field-text
        class="spacerBottom"
        [field]="streetNumberControl"
        label="label.street-number"
        [required]="
          streetNumberControl | hasRequiredValidator
        "
      ></app-form-field-text>
      <app-form-field-text
        class="spacerBottom"
        [field]="addressAdditionControl"
        label="label.addressAddition"
        [required]="
          addressAdditionControl | hasRequiredValidator
        "
      ></app-form-field-text>
    </div>

    <!-- block: address -->
    <div class="form-block">
      <app-form-field-text
        class="spacerBottom"
        [field]="postalCodeControl"
        label="label.postalCode"
        [required]="
          postalCodeControl | hasRequiredValidator
        "
      ></app-form-field-text>
      <app-form-field-text
        class="spacerBottom"
        [field]="cityControl"
        label="label.city"
        [required]="cityControl | hasRequiredValidator"
      ></app-form-field-text>
      <app-form-field-text
        class="spacerBottom"
        [field]="stateControl"
        label="label.state"
        [required]="stateControl | hasRequiredValidator"
      ></app-form-field-text>
      <app-form-field-select
        class="spacerBottom"
        [field]="countryControl"
        [options]="countryOptions"
        sortBy="translated-label-asc"
        label="label.country"
        [required]="countryControl | hasRequiredValidator"
      ></app-form-field-select>
      <app-form-field-text
        class="spacerBottom"
        [field]="emailControl"
        label="label.email"
      ></app-form-field-text>
      <!-- Not in use yet: BOTI-306
        <div class="spacerBottom">
        <button (click)="editEmail()" mat-raised-button>
          {{ 'label.edit-email' | translate }}
        </button>
      </div> -->
    </div>
  </div>

  <div class="form-content">
    <app-form-field-radio
      [field]="parentalAddressesRadioControl"
      [options]="parentalAddressesOptions"
      label="userInfo.parental-addresses.radio-btn.label"
      [color]="'accent'"
    >
    </app-form-field-radio>
  </div>

  <ng-container
    *ngIf="parentalAddressesRadioControl.value"
    formArrayName="parentalAddresses"
  >
    <ng-container
      *ngFor="
        let parentalAddressFormGroup of parentalAddressesFormArray.controls
      "
    >
      <app-parental-address-form
        [parentalAddressFormGroup]="
          parentalAddressFormGroup
        "
      ></app-parental-address-form>
    </ng-container>
  </ng-container>

  <div class="form-content">
    <app-form-field-checkbox-gdpr
      class="spacerBottom"
      [field]="confirmedGDPRControl"
    ></app-form-field-checkbox-gdpr>
  </div>

  <mat-card-actions fxLayout fxLayoutAlign="space-between">
    <button
      mat-raised-button
      color="warn"
      (click)="cancelBooking()"
    >
      {{ 'button.cancel' | translate }}
    </button>
    <button
      mat-raised-button
      color="primary"
      type="button"
      (click)="submit()"
      [disabled]="!form.valid"
    >
      {{ 'button.next' | translate }}
    </button>
  </mat-card-actions>
</form>
