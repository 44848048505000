import {
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { TenantId } from 'src/environments/environments.types';
import { RegisterService } from '../../../services';
import { DialogResult } from '../../../types';

@Component({
  selector: 'app-forgot-password-dialog',
  templateUrl: './forgot-password-dialog.component.html',
  styleUrls: ['./forgot-password-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ForgotPasswordDialogComponent
  implements OnInit
{
  private form!: UntypedFormGroup;

  constructor(
    public dialog: MatDialogRef<
      ForgotPasswordDialogComponent,
      DialogResult
    >,
    private formBuilder: UntypedFormBuilder,
    private service: RegisterService
  ) {}

  close(): void {
    this.dialog.close();
  }

  get emailControl(): UntypedFormControl {
    return this.form.get('email') as UntypedFormControl;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  submit(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.service
        .requestForgotPassword(this.emailControl.value)
        .then(() => this.dialog.close({ success: true }))
        .catch(() => this.dialog.close({ success: false }));
    }
  }

  get tenant(): TenantId {
    return environment.tenant;
  }
}
