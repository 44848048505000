import {
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { TenantId } from 'src/environments/environments.types';
import { InfoPageDialogComponent } from '..';
import { RegisterService } from '../../../services';
import { DialogResult } from '../../../types';

@Component({
  selector: 'app-invite-dialog',
  templateUrl: './invite-dialog.component.html',
  styleUrls: ['./invite-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InviteDialogComponent implements OnInit {
  private form!: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<
      InviteDialogComponent,
      DialogResult
    >,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private service: RegisterService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  submit(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.service
        .requestInvite(this.emailControl.value)
        .then(() => this.dialogRef.close({ success: true }))
        .catch(() =>
          this.dialogRef.close({ success: false })
        );
    }
  }

  openDialog(pageTemplate: string): void {
    this.dialog.open(InfoPageDialogComponent, {
      data: pageTemplate,
      autoFocus: false,
    });
  }

  get emailControl(): UntypedFormControl {
    return this.form.get('email') as UntypedFormControl;
  }

  get tenant(): TenantId {
    return environment.tenant;
  }
}
