import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InfoPageDialogComponent } from 'src/app/components';

@Component({
  selector: 'app-consent-proctoring-page',
  templateUrl: './consent-proctoring-page.component.html',
  styleUrls: ['./consent-proctoring-page.component.scss'],
})
export class ConsentProctoringPageComponent {
  @Input() showBreadcrumb = true;

  constructor(private dialog: MatDialog) {}

  openPrivacyTerms(pageTemplate: string) {
    this.dialog.open(InfoPageDialogComponent, {
      data: pageTemplate,
      autoFocus: false,
    });
  }
}
