import {
  HttpClient,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Subject } from 'rxjs';
import {
  FormState,
  ListFilter,
  ListResponse,
  ListState,
  User,
} from '../../types';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  listState: ListState<User, ListFilter> = {
    loading: false,
    response: {
      items: [],
      total: 0,
    },
    filter: {
      search: '',
      page: 0,
      size: 10,
      sort: 'email',
      direction: 'asc',
    },
  };
  formState: FormState<User> = {
    loading: false,
    item: UserService.factoryItem(),
  };

  loadingCounter = 0;
  loadingCounter$ = new Subject<number>();

  constructor(private http: HttpClient) {}

  static factoryItem(): User {
    return {
      id: 0,
      firstname: '',
      lastname: '',
      birthName: '',
      email: '',
      gender: '',
      birthplace: '',
      birthday: '',
      phoneNumber: '',
      secondEmail: '',
      street: '',
      streetNumber: '',
      additionalAddressInfo: '',
      zip: '',
      city: '',
      state: '',
      country: '',
      parentalAddresses: [],
      admin: false,
      candidate: false,
      confirmedGDPR: false,
    };
  }

  loadList(): Promise<ListResponse<User>> {
    this.loadingCounter$.next(this.loadingCounter++);

    this.listState.loading = true;

    const params = new HttpParams()
      .set('filter', `${this.listState.filter.search}`)
      .set('page', `${this.listState.filter.page}`)
      .set('size', `${this.listState.filter.size}`)
      .set('sort', `${this.listState.filter.sort}`)
      .set(
        'direction',
        `${this.listState.filter.direction}`
      );

    const url = `/api/user`;
    const promise$ = firstValueFrom(
      this.http.get<ListResponse<User>>(url, { params })
    ).then(
      (response) => (this.listState.response = response)
    );

    promise$.finally(
      () => (this.listState.loading = false)
    );

    return promise$;
  }

  loadById(id: number): Promise<User> {
    return firstValueFrom(
      this.http.get<User>(`/api/user/${id}`)
    );
  }

  create(): Promise<User> {
    return firstValueFrom(
      this.http.post<User>('/api/user', this.formState.item)
    );
  }

  update(): Promise<User> {
    return firstValueFrom(
      this.http.put<User>(
        `/api/user/${this.formState.item.id}`,
        this.formState.item
      )
    );
  }

  delete(id: number): Promise<void> {
    return firstValueFrom(
      this.http.delete<void>(`/api/user/${id}`)
    );
  }

  save(): Promise<User> {
    this.formState.loading = true;
    const promise =
      this.formState.item.id > 0
        ? this.update()
        : this.create();
    promise.finally(() => (this.formState.loading = false));
    return promise;
  }

  // this is used, when the admin reset the password by mail for someone
  sendPasswordResetMail(user: User): Promise<void> {
    return firstValueFrom(
      this.http.put<void>(
        `/api/user/${user.id}/reset-password`,
        {}
      )
    );
  }
}
