<form>
  <mat-card class="stepper">
    <mat-stepper
      #stepper
      labelPosition="bottom"
      linear
      (selectionChange)="selectionEvent($event)"
    >
      <mat-step
        [completed]="profileCheckFormValid"
        color="primary"
      >
        <ng-template matStepContent>
          <mat-card-title>{{
            'label.profile-check' | translate
          }}</mat-card-title>
          <app-step1-profile-check-form-sdv
            (profileCheckFormValid)="
              setProfileCheckFormValid($event)
            "
            (nextStep)="nextStep($event)"
          ></app-step1-profile-check-form-sdv>
        </ng-template>
      </mat-step>

      <mat-step
        [completed]="requirementsCheckFormValid"
        color="primary"
      >
        <ng-template matStepContent>
          <mat-card-title>
            {{
              'booking.sdv.prerequisites-check-page.title'
                | translate
            }}
          </mat-card-title>
          <mat-card-subtitle>
            {{
            'booking.sdv.prerequisites-check-page.subtitle'
              | translate
            }}
          </mat-card-subtitle>
          <app-step2-requirements-check-form-sdv
            (requirementsCheckFormValid)="
              setRequirementsCheckFormValid($event)
            "
            (nextStep)="nextStep($event)"
          ></app-step2-requirements-check-form-sdv>
        </ng-template>
      </mat-step>

      <mat-step color="primary">
        <ng-template matStepContent>
          <mat-card-title>
            {{ 'booking.sdv.info-page.title' | translate }}
          </mat-card-title>
          <app-step3-requirements-info-page-sdv></app-step3-requirements-info-page-sdv>
        </ng-template>
      </mat-step>

      <mat-step
        [completed]="timeSlotFormValid"
        [id]="'testEventSelect'"
        color="primary"
      >
        <ng-template matStepContent>
          <mat-card-title>
            {{ 'label.test-event-select' | translate }}
          </mat-card-title>
          <app-step4-test-time-form-sdv
            (timeSlotFormValid)="
              setTimeSlotFormValid($event)
            "
            (nextStep)="nextStep($event)"
            [stepId]="stepId"
          ></app-step4-test-time-form-sdv>
        </ng-template>
      </mat-step>

      <mat-step
        [completed]="pricingFormValid"
        color="primary"
      >
        <ng-template matStepContent>
          <mat-card-title>
            {{
              'booking.sdv.pricing-page.title' | translate
            }}
          </mat-card-title>
          <app-step5-pricing-sdv
            (pricingFormValid)="setPricingFormValid($event)"
            (nextStep)="nextStep($event)"
          ></app-step5-pricing-sdv>
        </ng-template>
      </mat-step>

      <mat-step
        [completed]="additionalQuestionsFormValid"
        color="primary"
      >
        <ng-template matStepContent>
          <mat-card-title>
            {{ 'title.additional-questions' | translate }}
          </mat-card-title>
          <app-step6-additional-questions-form-sdv
            (additionalQuestionsFormValid)="
              setAdditionalQuestionsFormValid($event)
            "
            (nextStep)="nextStep($event)"
          ></app-step6-additional-questions-form-sdv>
        </ng-template>
      </mat-step>

      <mat-step color="primary">
        <ng-template matStepContent>
          <mat-card-title>
            {{ 'title.summary' | translate }}
          </mat-card-title>
          <app-step7-booking-overview-sdv>
          </app-step7-booking-overview-sdv>

          <ng-container
            *ngIf="isHandset$ | async; else desktopLayout"
          >
            <mat-card-actions class="btn-group-mobile">
              <button
                mat-raised-button
                color="warn"
                (click)="deleteBooking()"
              >
                {{ 'button.cancel' | translate }}
              </button>
              <div>
                <button
                  mat-raised-button
                  matStepperPrevious
                  type="button"
                >
                  {{ 'button.back' | translate }}
                </button>
                <button
                  [disabled]="isPayBtnClicked"
                  mat-raised-button
                  color="primary"
                  (click)="doPayment()"
                  class="btn-pay-now"
                >
                  {{ 'button.pay-now' | translate }}
                </button>
              </div>
            </mat-card-actions>
          </ng-container>

          <ng-template #desktopLayout>
            <mat-card-actions
              fxLayout
              fxLayoutAlign="space-between"
            >
              <button
                mat-raised-button
                color="warn"
                (click)="deleteBooking()"
              >
                {{ 'button.cancel' | translate }}
              </button>
              <div>
                <button
                  mat-raised-button
                  matStepperPrevious
                  type="button"
                >
                  {{ 'button.back' | translate }}
                </button>
                <button
                  [disabled]="isPayBtnClicked"
                  mat-raised-button
                  color="primary"
                  (click)="doPayment()"
                  class="btn-pay-now"
                >
                  {{ 'button.pay-now' | translate }}
                </button>
              </div>
            </mat-card-actions>
          </ng-template>
        </ng-template>
      </mat-step>
    </mat-stepper>
  </mat-card>
</form>
