export enum Supervision {
  Testcenter = 'in-person',
  Proctoring = 'proctoring',
}

export interface ExamLocation {
  id: number;
  name: string;
  country: string;
  description?: string;
  supervision: Supervision;
}
