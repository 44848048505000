<table
  mat-table
  matSort
  matSortDisableClear
  [dataSource]="items"
>
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'label.name' | translate }}
    </th>
    <td mat-cell *matCellDef="let row">{{ row.name }}</td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="rowsConfig"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: rowsConfig"
    [routerLink]="[row.id]"
    class="hover-outline ripple clickable"
  ></tr>
</table>
