<div class="bg-contrast">
  <p>
    {{
      'booking.sdv.prerequisites-check-page.decline-message.part-1'
        | translate
    }}
  </p>

  <ng-container [ngSwitch]="question">
    <p *ngSwitchCase="'enrolledInFirstOrSecondSemester'">
      <strong>{{
        'booking.sdv.prerequisites-check-page.enrolledInFirstOrSecondSemester.explanation'
          | translate
      }}</strong>
    </p>

    <p *ngSwitchCase="'studiedInEuOrSwitzerland'">
      <strong>{{
        'booking.sdv.prerequisites-check-page.studiedInEuOrSwitzerland.explanation'
          | translate
      }}</strong>
    </p>

    <p *ngSwitchCase="'permittedToReceiveBafoeg'">
      <strong>{{
        'booking.sdv.prerequisites-check-page.permittedToReceiveBafoeg.explanation'
          | translate
      }}</strong>
    </p>

    <p *ngSwitchCase="'studiesPrimarilyInGermany'">
      <strong>{{
        'booking.sdv.prerequisites-check-page.studiesPrimarilyInGermany.explanation'
          | translate
      }}</strong>
    </p>

    <p
      *ngSwitchCase="
        'dual-studies.university-of-cooperative-education'
      "
    >
      <strong>{{
        'booking.sdv.prerequisites-check-page.typeOfSchool.explanation.university-of-cooperative-education.part-1'
          | translate
      }}</strong>
      <a
        href="mailto:{{
          'booking.sdv.prerequisites-check-page.typeOfSchool.explanation.university-of-cooperative-education.e-mail'
            | translate
        }}"
        >{{
          'booking.sdv.prerequisites-check-page.typeOfSchool.explanation.university-of-cooperative-education.e-mail'
            | translate
        }}</a
      >
      <strong>{{
        'booking.sdv.prerequisites-check-page.typeOfSchool.explanation.university-of-cooperative-education.part-2'
          | translate
      }}</strong>
      <a
        href="{{
          'booking.sdv.prerequisites-check-page.typeOfSchool.explanation.university-of-cooperative-education.link.link'
            | translate
        }}"
        target="_blank"
      >
        <strong>{{
          'booking.sdv.prerequisites-check-page.typeOfSchool.explanation.university-of-cooperative-education.link.text'
            | translate
        }}</strong>
      </a>
      <strong>{{
        'booking.sdv.prerequisites-check-page.typeOfSchool.explanation.university-of-cooperative-education.end'
          | translate
      }}</strong>
    </p>

    <ng-container
      *ngSwitchCase="
          ['art-college', 'conservatory'].includes(question)
            ? question
            : !question
        "
    >
      <p>
        <strong>{{
          'booking.sdv.prerequisites-check-page.typeOfSchool.explanation.conservatory.part-1'
            | translate
        }}</strong>
        <a
          href="{{
            'booking.sdv.prerequisites-check-page.typeOfSchool.explanation.conservatory.link.link'
              | translate
          }}"
          target="_blank"
        >
          <strong>{{
            'booking.sdv.prerequisites-check-page.typeOfSchool.explanation.conservatory.link.text'
              | translate
          }}</strong>
        </a>
        <strong>{{
          'booking.sdv.prerequisites-check-page.typeOfSchool.explanation.conservatory.end'
            | translate
        }}</strong>
      </p>
      <p>
        <strong>{{
          'booking.sdv.prerequisites-check-page.typeOfSchool.explanation.conservatory.part-2'
            | translate
        }}</strong>
        <a
          href="mailto:{{
            'booking.sdv.prerequisites-check-page.typeOfSchool.explanation.conservatory.e-mail'
              | translate
          }}"
          >{{
            'booking.sdv.prerequisites-check-page.typeOfSchool.explanation.conservatory.e-mail'
              | translate
          }}</a
        >
        <strong>{{
          'booking.sdv.prerequisites-check-page.typeOfSchool.explanation.conservatory.end'
            | translate
        }}</strong>
      </p>
    </ng-container>

    <p *ngSwitchCase="'formOfStudies'">
      <strong>{{
        'booking.sdv.prerequisites-check-page.formOfStudies.explanation'
          | translate
      }}</strong>
    </p>

    <p *ngSwitchCase="'academicStudiesCompleted'">
      <strong>{{
        'booking.sdv.prerequisites-check-page.academicStudiesCompleted.explanation'
          | translate
      }}</strong>
    </p>

    <p *ngSwitchCase="'suggestedForSdV'">
      <strong>{{
        'booking.sdv.prerequisites-check-page.suggestedForSdV.explanation'
          | translate
      }}</strong>
    </p>
  </ng-container>

  <p *ngIf="showInfoAboutUnfulfilledPrerequisites()">
    {{
      'booking.sdv.prerequisites-check-page.decline-message.part-2'
        | translate
    }}
    <a
      href="{{
        'booking.sdv.prerequisites-check-page.decline-message.link-1.link'
          | translate
      }}"
      target="_blank"
      >{{
        'booking.sdv.prerequisites-check-page.decline-message.link-1.text'
          | translate
      }}</a
    >
    {{
      'booking.sdv.prerequisites-check-page.decline-message.part-3'
        | translate
    }}
    <a
      href="mailto:{{
        'booking.sdv.prerequisites-check-page.decline-message.email'
          | translate
      }}"
      >{{
        'booking.sdv.prerequisites-check-page.decline-message.email'
          | translate
      }}</a
    >
    {{
      'booking.sdv.prerequisites-check-page.decline-message.part-4'
        | translate
    }}
  </p>
  <p *ngIf="showInfoAboutNonSelfApplication()">
    {{
    'booking.sdv.prerequisites-check-page.decline-message.part-5'
      | translate
    }}
    <a
      href="{{
        'booking.sdv.prerequisites-check-page.decline-message.link-2.link'
          | translate
      }}"
      target="_blank"
    >{{
      'booking.sdv.prerequisites-check-page.decline-message.link-2.text'
        | translate
      }}</a
    >
  </p>
</div>
