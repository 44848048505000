import {
  AfterContentInit,
  Component,
  Input,
} from '@angular/core';
import { EventBooking } from 'src/app/types';
import { LanguageLevel } from 'src/app/types/AdditionalQuestionData';

@Component({
  selector: 'app-optional-questions-answers-overview',
  templateUrl:
    './optional-questions-answers-overview.component.html',
  styleUrls: [
    './optional-questions-answers-overview.component.scss',
  ],
})
export class OptionalQuestionsAnswersOverviewComponent
  implements AfterContentInit
{
  @Input() booking!: EventBooking;
  languageLevel: LanguageLevel | undefined;

  ngAfterContentInit(): void {
    this.languageLevel =
      this.booking.additionalQuestionData?.studentDetails?.germanLanguageLevel;
  }

  get getLanguageLevelLabel(): string {
    let labelSuffix: string | undefined = undefined;

    switch (this.languageLevel) {
      case LanguageLevel.NATIVE_SPEAKER: {
        labelSuffix = 'native_speaker';
        break;
      }
      case LanguageLevel.C2: {
        labelSuffix = 'c2';
        break;
      }
      case LanguageLevel.C1: {
        labelSuffix = 'c1';
        break;
      }
      case LanguageLevel.B2: {
        labelSuffix = 'b2';
        break;
      }
      case LanguageLevel.B1: {
        labelSuffix = 'b1';
        break;
      }
      case LanguageLevel.A2: {
        labelSuffix = 'a2';
        break;
      }
      case LanguageLevel.A1: {
        labelSuffix = 'a1';
        break;
      }
    }
    if (labelSuffix) {
      return `booking.sdv.additional-questions-page.studentDetails.germanLanguageLevel.options.${labelSuffix}`;
    } else {
      return '';
    }
  }
}
