<mat-label
  ><em>{{
    'userInfo.parental-addresses.alt-title' | translate
  }}</em></mat-label
>
<div
  [formGroup]="parentalAddressFormGroup"
  class="form-content"
  *ngIf="parentalAddressFormGroup"
>
  <div class="form-block">
    <app-form-field-text
      [field]="streetControl"
      class="spacerBottom"
      label="label.street"
    ></app-form-field-text>

    <app-form-field-text
      [field]="streetNumber"
      class="spacerBottom"
      label="label.street-number"
    ></app-form-field-text>

    <app-form-field-text
      class="spacerBottom"
      [field]="addressAdditionControl"
      label="label.addressAddition"
    ></app-form-field-text>
  </div>

  <div class="form-block">
    <app-form-field-text
      class="spacerBottom"
      [field]="postalCodeControl"
      label="label.postalCode"
    ></app-form-field-text>

    <app-form-field-text
      class="spacerBottom"
      [field]="cityControl"
      label="label.city"
    ></app-form-field-text>

    <app-form-field-text
      class="spacerBottom"
      [field]="stateControl"
      label="label.state"
    ></app-form-field-text>
  </div>

  <div class="form-block">
    <app-form-field-select
      class="spacerBottom"
      [field]="countryControl"
      [options]="countryOptions"
      sortBy="translated-label-asc"
      label="label.country"
    ></app-form-field-select>
  </div>
</div>
