import { Component, OnInit } from '@angular/core';
import { FormFieldSelectComponent } from 'src/app/lib/form-fields/form-field-select';
import {
  SelectOptionGroup,
  Supervision,
} from 'src/app/types';

@Component({
  selector: 'app-form-field-selection-test-location',
  templateUrl:
    './form-field-selection-test-location.component.html',
  styleUrls: [
    './form-field-selection-test-location.component.scss',
  ],
})
export class FormFieldSelectionTestLocationComponent
  extends FormFieldSelectComponent
  implements OnInit
{
  isProctoringOption!: boolean;
  optionGroups: SelectOptionGroup[] = [];

  ngOnInit(): void {
    this.isProctoringOption = this.options.some(
      (option) =>
        option.supervision === Supervision.Proctoring
    );

    if (this.isProctoringOption) {
      this.optionGroups.push(
        {
          groupName:
            'label.exam-location.supervision.in-person',
          options: this.options.filter(
            (location) =>
              location.supervision ===
              Supervision.Testcenter
          ),
        },
        {
          groupName:
            'label.exam-location.supervision.proctoring',
          options: this.options.filter(
            (location) =>
              location.supervision ===
              Supervision.Proctoring
          ),
        }
      );
    }
  }
}
