<mat-checkbox
  class="label-with-link"
  [formControl]="field"
  color="primary"
>
  {{ 'label.confirmedTermsOfService-part-1' | translate
  }}<span
    (mousedown)="$event.stopPropagation()"
    (click)="preventClick($event)"
    ><a
      (click)="openDialog('terms-of-service')"
      attr.aria-label="{{
        'label.terms-of-service' | translate
      }}"
      >{{
        'label.confirmedTermsOfService-part-2-link'
          | translate
      }}</a
    ></span
  >{{
    'label.confirmedTermsOfService-part-3' | translate
  }}</mat-checkbox
>

<div
  *ngIf="hasHint"
  class="mat-form-field-subscript-wrapper"
>
  <mat-hint class="mat-hint">{{
    hint | translate
  }}</mat-hint>
</div>
<div
  *ngIf="showError"
  class="mat-form-field-subscript-wrapper"
>
  <mat-error class="mat-error">{{
    error | translate
  }}</mat-error>
</div>
