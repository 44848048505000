import {
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { PasswordValidator } from '../../../lib/form-fields/form-field-password/password-validator';
import { RegisterService } from '../../../services';

@Component({
  selector: 'app-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ResetPasswordFormComponent implements OnInit {
  @Input() token!: string;
  form!: UntypedFormGroup;
  resetSuccessful = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private service: RegisterService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      password: [
        '',
        [Validators.required, PasswordValidator.syntax],
      ],
      passwordRepeat: [''],
    });
    this.passwordRepeatControl.setValidators([
      Validators.required,
      PasswordValidator.sameAsPassword(
        this.passwordControl
      ),
    ]);
  }

  get passwordControl(): UntypedFormControl {
    return this.form.get('password') as UntypedFormControl;
  }

  get passwordRepeatControl(): UntypedFormControl {
    return this.form.get(
      'passwordRepeat'
    ) as UntypedFormControl;
  }

  submit() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.service
        .resetPassword(
          this.passwordControl.value,
          this.token
        )
        .then(() => this.success());
    }
  }

  private success() {
    this.resetSuccessful = true;
  }
}
