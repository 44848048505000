import {
  HttpClient,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import {
  ExamLocation,
  FormState,
  ListFilter,
  ListResponse,
  ListState,
  SelectOption,
  Supervision,
} from '../../types';

@Injectable({
  providedIn: 'root',
})
export class ExamLocationService {
  listState: ListState<ExamLocation, ListFilter> = {
    loading: false,
    response: {
      items: [],
      total: 0,
    },
    filter: {
      search: '',
      page: 0,
      size: 10,
      sort: 'name',
      direction: 'asc',
    },
  };
  formState: FormState<ExamLocation> = {
    loading: false,
    item: ExamLocationService.factoryItem(),
  };
  selectOptions: SelectOption[] = [];

  constructor(private http: HttpClient) {}

  static factoryItem(): ExamLocation {
    return {
      id: 0,
      name: '',
      country: '',
      description: '',
      supervision: Supervision.Testcenter,
    };
  }

  loadList(): Promise<ListResponse<ExamLocation>> {
    this.listState.loading = true;

    const params = new HttpParams()
      .set('filter', `${this.listState.filter.search}`)
      .set('page', `${this.listState.filter.page}`)
      .set('size', `${this.listState.filter.size}`)
      .set('sort', `${this.listState.filter.sort}`)
      .set(
        'direction',
        `${this.listState.filter.direction}`
      );

    const url = `/api/testarea`;
    const promise$: Promise<ListResponse<ExamLocation>> =
      firstValueFrom(
        this.http.get<ListResponse<ExamLocation>>(url, {
          params,
        })
      ).then(
        (response) => (this.listState.response = response)
      );

    promise$.finally(
      () => (this.listState.loading = false)
    );

    return promise$;
  }

  loadAllForSelect(): Promise<ExamLocation[]> {
    const promiseList$: Promise<
      ListResponse<ExamLocation>
    > = firstValueFrom(
      this.http.get<ListResponse<ExamLocation>>(
        '/api/testarea'
      )
    );

    const promise$ = promiseList$.then(
      (response) => response.items
    );

    promise$.then(
      (items) =>
        (this.selectOptions = items.map((item) => ({
          value: item.id,
          label: item.name,
        })))
    );
    return promise$;
  }

  loadById(id: number): Promise<ExamLocation> {
    return firstValueFrom(
      this.http.get<ExamLocation>(`/api/testarea/${id}`)
    );
  }

  create(): Promise<ExamLocation> {
    return firstValueFrom(
      this.http.post<ExamLocation>(
        '/api/testarea',
        this.formState.item
      )
    );
  }

  update(): Promise<ExamLocation> {
    return firstValueFrom(
      this.http.put<ExamLocation>(
        `/api/testarea/${this.formState.item.id}`,
        this.formState.item
      )
    );
  }

  delete(id: number): Promise<void> {
    return firstValueFrom(
      this.http.delete<void>(`/api/testarea/${id}`)
    );
  }

  save(): Promise<ExamLocation> {
    this.formState.loading = true;
    const promise =
      this.formState.item.id > 0
        ? this.update()
        : this.create();
    promise.finally(() => (this.formState.loading = false));
    return promise;
  }
}
