import { HttpClient } from '@angular/common/http';
import {
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export const NGX_TRANSLATE_CONFIG = TranslateModule.forRoot(
  {
    loader: {
      provide: TranslateLoader,
      useFactory: (http: HttpClient) =>
        new TranslateHttpLoader(
          http,
          '/assets/i18n/translations/',
          '.json'
        ),
      deps: [HttpClient],
    },
  }
);
