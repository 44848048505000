import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription, timer } from 'rxjs';
import { BookingService } from '../../../../services';
import { EventBookingHistoryEvents } from '../../../../types';

@Component({
  selector: 'app-exam-booking-history-table',
  templateUrl:
    './exam-booking-history-table.component.html',
  styleUrls: [
    './exam-booking-history-table.component.scss',
  ],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0' })
      ),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class ExamBookingHistoryTableComponent
  implements OnInit, OnDestroy
{
  @Input()
  bookingId!: number;

  private historyEvents$!: Promise<
    EventBookingHistoryEvents[]
  >;
  historyEvents: EventBookingHistoryEvents[] = [];

  readonly columnsToDisplay = [
    'timestamp',
    'historyKey',
    'expand',
  ];
  expandedElement: EventBookingHistoryEvents | null = null;

  private everyTenSeconds: Observable<number> = timer(
    0,
    10000
  );
  private unsubOnDestroy = new Subscription();

  constructor(
    private bookingService: BookingService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.loadHistory();

    this.unsubOnDestroy.add(
      this.everyTenSeconds.subscribe(() => {
        this.loadHistory();
      })
    );
  }

  private loadHistory(): void {
    const historyResponse =
      this.bookingService.loadBookingHistoryEventsByBookingId(
        this.bookingId
      );
    // TODO There is surely a better way to do this, maybe with Observables?
    this.historyEvents$ = historyResponse.then(
      (response) => response.items
    );
    this.historyEvents$.then((events) => {
      // Check whether any events were loaded that were previously not in the list. If so, add them.
      for (let event of events) {
        const listContainsEvent = this.historyEvents.some(
          (historyEvent) => historyEvent.id === event.id
        );
        if (!listContainsEvent) {
          this.historyEvents.push(event);
        }
      }
      // This is so that the table is rendered again
      this.historyEvents = [...this.historyEvents];
    });
  }

  get currentLanguage(): string {
    return this.translateService.currentLang;
  }

  ngOnDestroy() {
    this.unsubOnDestroy.unsubscribe();
  }
}
