import {
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { TenantId } from 'src/environments/environments.types';

@Component({
  selector: 'app-error-http-dialog',
  templateUrl: './error-http-dialog.component.html',
  styleUrls: ['./error-http-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ErrorHttpDialogComponent implements OnInit {
  errorMessage!: {
    message: string;
    eMail?: string;
    end?: string;
  };

  constructor(
    public dialog: MatDialogRef<ErrorHttpDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public statusCode: number
  ) {}

  ngOnInit(): void {
    this.setErrorMessage(this.statusCode);
  }

  private setErrorMessage(statusCode: number): void {
    if (statusCode >= 400 && statusCode < 500) {
      // Client errors
      this.errorMessage = {
        ...this.errorMessage,
        message: `booking.${this.tenant}.error-message.4xx`,
        eMail: `booking.${this.tenant}.error-message.e-mail`,
        end: `booking.${this.tenant}.error-message.end`,
      };
    } else if (statusCode >= 500 && statusCode < 600) {
      // Server errors
      this.errorMessage = {
        ...this.errorMessage,
        message: `booking.${this.tenant}.error-message.5xx`,
        eMail: `booking.${this.tenant}.error-message.e-mail`,
        end: `booking.${this.tenant}.error-message.end`,
      };
    } else {
      this.errorMessage = {
        ...this.errorMessage,
        message: 'error.general',
      };
    }
  }

  get tenant(): TenantId {
    return environment.tenant;
  }
}
