import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-terms-of-service-page',
  templateUrl: './terms-of-service-page.component.html',
  styleUrls: ['./terms-of-service-page.component.scss'],
})
export class TermsOfServicePageComponent {
  @Input() showBreadcrumb = true;
  @Input() showAcceptAndRejectButtons = false;

  constructor(private translateService: TranslateService) {}

  get currentLanguage() {
    return this.translateService.currentLang;
  }
}
