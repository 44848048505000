<div class="container-content" fxLayout="column">
  <ng-container *ngIf="showBreadcrumb">
    <xng-breadcrumb [separator]="separatorIconTemplate">
      <ng-container
        *xngBreadcrumbItem="
          let breadcrumb;
          let info = info;
          let first = first
        "
      >
        <mat-icon *ngIf="info">{{ info }}</mat-icon>
        <ng-container *ngIf="!first">{{
          breadcrumb | translate
        }}</ng-container>
      </ng-container>
    </xng-breadcrumb>

    <ng-template #separatorIconTemplate>
      <mat-icon>arrow_right</mat-icon>
    </ng-template>
  </ng-container>

  <h1 class="mat-headline">
    {{ 'consent-form-proctoring.sdv.title' | translate }}
  </h1>

  <h2 class="mat-title">
    {{ 'consent-form-proctoring.sdv.subtitle' | translate }}
  </h2>

  <p>
    <strong>
      {{
        'consent-form-proctoring.sdv.paragraph-1'
          | translate
      }}</strong
    >
  </p>

  <p>
    {{
      'consent-form-proctoring.sdv.paragraph-2' | translate
    }}
  </p>

  <p>
    {{
      'consent-form-proctoring.sdv.paragraph-3' | translate
    }}
  </p>

  <p>
    <strong>
      {{
        'consent-form-proctoring.sdv.paragraph-4'
          | translate
      }}</strong
    >
  </p>

  <p>
    {{
      'consent-form-proctoring.sdv.paragraph-5' | translate
    }}
  </p>

  <p>
    {{
      'consent-form-proctoring.sdv.paragraph-6.part-1'
        | translate
    }}
    <a
      href="javascript:void(0)"
      (click)="openPrivacyTerms('privacy-terms')"
      attr.aria-label="{{
        'label.privacy-terms' | translate
      }}"
    >
      {{
        'consent-form-proctoring.sdv.paragraph-6.link-text'
          | translate
      }}</a
    >
  </p>
</div>
