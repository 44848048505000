import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import {
  ListResponse,
  SupportedLanguage,
} from '../../types';

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  public supportedLanguages: SupportedLanguage[] = [];
  public currentLanguage: SupportedLanguage | null = null;
  private initPromise: Promise<void> | null = null;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private translateService: TranslateService,
    private http: HttpClient
  ) {}

  switchLanguage(lang: SupportedLanguage): Promise<any> {
    return firstValueFrom(
      this.translateService.use(lang.code)
    )
      .then(() => (this.currentLanguage = lang))
      .then(() => {
        this.document.documentElement.lang = lang.code;
      });
  }

  init(): Promise<void> {
    if (!this.initPromise) {
      this.initPromise = new Promise<void>((resolve) => {
        this.loadAvailableLanguages().then(() => {
          // set fallback language
          this.translateService.setDefaultLang('de');
          const lang = this.supportedLanguages.find(
            (item) => item.code === 'de'
          ) as SupportedLanguage;
          this.switchLanguage(lang).then(() => resolve());
        });
      });
    }
    return this.initPromise;
  }

  private loadAvailableLanguages(): Promise<void> {
    const promiseList$: Promise<
      ListResponse<SupportedLanguage>
    > = firstValueFrom(
      this.http.get<ListResponse<SupportedLanguage>>(
        '/assets/i18n/supported-languages.json'
      )
    );

    return new Promise<void>((resolve) => {
      promiseList$.then((response) => {
        this.supportedLanguages = response.items;
        resolve();
      });
    });
  }
}
