<table
  mat-table
  matSort
  matSortDisableClear
  [dataSource]="items"
>
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox
        (change)="$event ? toggleAllRows() : null"
        [checked]="selection.hasValue() && isAllSelected()"
        [indeterminate]="
          selection.hasValue() && !isAllSelected()
        "
      >
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox
        (click)="$event.stopPropagation()"
        (change)="$event ? selection.toggle(row) : null"
        [checked]="selection.isSelected(row)"
      >
      </mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="firstname">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'label.firstname' | translate }}
    </th>
    <td mat-cell *matCellDef="let row">
      {{ row.firstname }}
    </td>
  </ng-container>
  <ng-container matColumnDef="lastname">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'label.lastname' | translate }}
    </th>
    <td mat-cell *matCellDef="let row">
      {{ row.lastname }}
    </td>
  </ng-container>
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'label.email' | translate }}
    </th>
    <td mat-cell *matCellDef="let row">{{ row.email }}</td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="rowsConfig"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: rowsConfig"
    [routerLink]="[row.id]"
    class="hover-outline ripple clickable"
  ></tr>
</table>
