<h4>
  {{ 'label.test-pre-check-question' | translate }}
</h4>

<app-form-field-select
  [field]="testForm"
  [options]="testFormOptions"
  [label]="'label.dropdown-select' | translate"
></app-form-field-select>
<mat-card-actions>
  <button
    mat-raised-button
    matStepperPrevious
    type="button"
  >
    {{ 'button.back' | translate }}
  </button>
  <button
    mat-raised-button
    color="accent"
    matStepperNext
    type="button"
  >
    {{ 'button.next' | translate }}
  </button>
</mat-card-actions>
