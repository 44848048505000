<label *ngIf="examEvent">
  <input
    [value]="examEvent.id"
    type="radio"
    class="card-input-element"
    [formControl]="control"
    [name]="name"
    [id]="'timeSlot' + examEvent.id"
    (click)="chooseTimeSlot()"
    [checked]="checked"
  />
  <mat-card class="card-input">
    <p>
      {{
        'booking.default.details.timeFormat.dayOfWeek'
          | translate
            : {
                timeSlot: timeSlot | localizedDate: 'EEEE'
              }
      }}
      <strong>{{
        'booking.default.details.timeFormat.date'
          | translate
            : {
                timeSlot:
                  timeSlot | localizedDate: 'mediumDate'
              }
      }}</strong>
    </p>
    <p *ngIf="!isProctoring">
      {{
        'booking.default.details.timeFormat.area'
          | translate: { testarea: examEvent.testarea }
      }}
    </p>
    <p *ngIf="isProctoring">
      {{
        'booking.default.details.timeFormat.proctoring'
          | translate: { testarea: examEvent.testarea }
      }}
    </p>

    <span class="time">{{
      'booking.default.details.timeFormat.time'
        | translate
          : {
              timeSlot:
                timeSlot | localizedDate: 'shortTime'
            }
    }}</span>
    <p *ngIf="isPriceVisible">
      {{ 'label.test-event-price' | translate }}:
      {{
        examEvent.price.amount
          | currency
            : examEvent.price.currency
            : undefined
            : undefined
            : currentLanguage
      }}
    </p>
  </mat-card>
</label>
