<h2 mat-dialog-title>
  {{ 'title.booking-change' | translate }}
</h2>
<mat-dialog-content class="table-page-component">
  <mat-stepper linear labelPosition="bottom">
    <mat-step [completed]="!!newEvent">
      <app-exam-event-statistics-table
        [currentTestEventId]="
          this.bookingToUpdate.testEventId
        "
        [searchFilters]="statisticsSearchFilters"
        (activeEventSet)="setActiveEvent($event)"
        (eventChosen)="newEventChosen($event)"
      ></app-exam-event-statistics-table>
      <mat-dialog-actions>
        <button
          mat-stroked-button
          mat-dialog-close
          color="primary"
        >
          {{ 'button.cancel' | translate }}
        </button>
        <button
          mat-raised-button
          matStepperNext
          color="primary"
          [disabled]="!newEvent"
        >
          {{ 'button.next' | translate }}
        </button>
      </mat-dialog-actions>
    </mat-step>
    <mat-step>
      <div fxLayout="row wrap" fxLayoutAlign="center">
        <div class="eventInfoPreview">
          <p>
            <img
              *ngIf="bookingToUpdate.test?.logoPath"
              [src]="bookingToUpdate.test.logoPath"
              [alt]="bookingToUpdate.test.name"
              class="logo"
            />
          </p>

          <p>
            {{ bookingToUpdate.test.name }}
          </p>
          <p>
            <span>
              {{
                'booking.default.details.timeFormat.dayOfWeek'
                  | translate
                    : {
                        timeSlot:
                          oldEvent?.testeventTime
                          | localizedDate: 'EEEE'
                      }
              }}
              <strong>
                <time
                  [dateTime]="
                    oldEvent?.testeventTime
                      | date: 'yyyy-MM-dd'
                  "
                >
                  {{
                    'booking.default.details.timeFormat.date'
                      | translate
                        : {
                            timeSlot:
                              oldEvent?.testeventTime
                              | localizedDate: 'mediumDate'
                          }
                  }}
                </time>
              </strong>
            </span>
            <br />
            <span>{{
              'booking.default.details.timeFormat.area'
                | translate
                  : { testarea: oldEvent?.testarea }
            }}</span>
            <time
              [dateTime]="
                oldEvent?.testeventTime | date: 'HH:mm:ss'
              "
              >{{
                'booking.default.details.timeFormat.time'
                  | translate
                    : {
                        timeSlot:
                          oldEvent?.testeventTime
                          | localizedDate: 'shortTime'
                      }
              }}
            </time>
          </p>
          <p>
            {{ 'label.test-event-price' | translate }}:
            {{
              oldEvent?.price?.amount
                | currency
                  : oldEvent?.price?.currency
                  : undefined
                  : undefined
                  : currentLanguage
            }}
          </p>
        </div>

        <div gdAlignColumns="center">
          <mat-icon aria-hidden="false" class="icon"
            >arrow_forward_ios</mat-icon
          >
        </div>

        <div class="eventInfoPreview">
          <p>
            <img
              *ngIf="bookingToUpdate.test?.logoPath"
              [src]="bookingToUpdate.test.logoPath"
              [alt]="bookingToUpdate.test.name"
              class="logo"
            />
          </p>

          <p>
            {{ bookingToUpdate.test.name }}
          </p>
          <p>
            <span>
              {{
                'booking.default.details.timeFormat.dayOfWeek'
                  | translate
                    : {
                        timeSlot:
                          newEvent?.testeventTime
                          | localizedDate: 'EEEE'
                      }
              }}
              <strong>
                <time
                  [dateTime]="
                    newEvent?.testeventTime
                      | date: 'yyyy-MM-dd'
                  "
                >
                  {{
                    'booking.default.details.timeFormat.date'
                      | translate
                        : {
                            timeSlot:
                              newEvent?.testeventTime
                              | localizedDate: 'mediumDate'
                          }
                  }}</time
                >
              </strong>
            </span>
            <br />
            <span>{{
              'booking.default.details.timeFormat.area'
                | translate
                  : { testarea: newEvent?.testarea }
            }}</span>
            <time
              [dateTime]="
                newEvent?.testeventTime | date: 'HH:mm:ss'
              "
              >{{
                'booking.default.details.timeFormat.time'
                  | translate
                    : {
                        timeSlot:
                          newEvent?.testeventTime
                          | localizedDate: 'shortTime'
                      }
              }}
            </time>
          </p>
          <p>
            {{ 'label.test-event-price' | translate }}:
            {{
              newEvent?.price?.amount
                | currency
                  : newEvent?.price?.currency
                  : undefined
                  : undefined
                  : currentLanguage
            }}
          </p>
        </div>
      </div>
      <mat-dialog-actions>
        <button mat-stroked-button matStepperPrevious>
          {{ 'button.back' | translate }}
        </button>
        <button
          mat-raised-button
          color="warn"
          cdkFocusInitial
          (click)="changeBooking()"
        >
          {{ 'button.booking-change' | translate }}
        </button>
      </mat-dialog-actions>
    </mat-step>
  </mat-stepper>
</mat-dialog-content>
