import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'app-candidate-form-page',
  templateUrl: './candidate-form-page.component.html',
  styleUrls: ['./candidate-form-page.component.scss'],
})
export class CandidateFormPageComponent {
  @HostBinding('class.form-page-component')
  hostClass = true;
}
