import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RegisterService } from '../../services';
import { RegisterInput } from '../../types';

@Component({
  selector: 'app-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.scss'],
})
export class RegisterPageComponent implements OnInit {
  registerInput!: RegisterInput;

  private tokenValid = false;
  private validationIsLoading = true;

  constructor(
    private service: RegisterService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const token = params.token;
      this.service
        .confirmEmail(token)
        .then((result) => {
          this.registerInput = {
            email: result.email,
            token,
          };
          this.tokenValid = true;
        })
        .finally(() => (this.validationIsLoading = false));
    });
  }

  get showLoadingIndicator(): boolean {
    return this.validationIsLoading;
  }
  get showForm(): boolean {
    return !this.validationIsLoading && this.tokenValid;
  }
  get showFailMessage(): boolean {
    return !this.validationIsLoading && !this.tokenValid;
  }
}
