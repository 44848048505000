import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TenantId } from 'src/environments/environments.types';

@Component({
  selector: 'app-system-requirements-page',
  templateUrl: './system-requirements-page.component.html',
  styleUrls: ['./system-requirements-page.component.scss'],
})
export class SystemRequirementsPageComponent {
  @Input() showBreadcrumb = true;

  get tenant(): TenantId {
    return environment.tenant;
  }
}
