import { Component, HostBinding } from '@angular/core';
import { UserService } from '../../services';

@Component({
  selector: 'app-user-form-page',
  templateUrl: './user-form-page.component.html',
  styleUrls: ['./user-form-page.component.scss'],
})
export class UserFormPageComponent {
  @HostBinding('class.form-page-component')
  hostClass = true;

  constructor(private service: UserService) {}

  get headline(): string {
    return this.service.formState.item.id > 0
      ? 'label.edit-user'
      : 'label.create-user';
  }
}
