import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RegisterService } from '../../services';

@Component({
  selector: 'app-reset-password-form-page',
  templateUrl: './reset-password-form-page.component.html',
  styleUrls: ['./reset-password-form-page.component.scss'],
})
export class ResetPasswordFormPageComponent
  implements OnInit
{
  token = '';
  initialized = false;
  isTokenValid = false;

  get showLoadingIndicator(): boolean {
    return !this.initialized;
  }

  get showForm(): boolean {
    return this.initialized && this.isTokenValid;
  }

  get showFailMessage(): boolean {
    return this.initialized && !this.isTokenValid;
  }

  constructor(
    private service: RegisterService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.token = `${params.token}`;
      this.service
        .checkResetPasswordToken(this.token)
        .then((result) => (this.isTokenValid = true))
        .finally(() => (this.initialized = true));
    });
  }
}
