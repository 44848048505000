import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  MatSort,
  MatSortable,
  Sort,
} from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { UserService } from '../../../services';
import { ListFilter, User } from '../../../types';

@Component({
  selector: 'app-user-table',
  templateUrl: './user-table.component.html',
  styleUrls: ['./user-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserTableComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild(MatSort, { static: true }) sortTable!: MatSort;

  @HostBinding('class.entity-table-component')
  hostClass = true;

  rowsConfig = ['select', 'firstname', 'lastname', 'email'];
  readonly selection = new SelectionModel<User>(true, []);

  private defaultSortColumnConfig: MatSortable;
  private sortSubcription!: Subscription;

  constructor(private userService: UserService) {
    this.defaultSortColumnConfig = {
      id: this.userService.listState.filter.sort,
      start: this.userService.listState.filter.direction,
      disableClear: true,
    };
  }

  ngOnInit(): void {
    // Default sort column
    this.sortTable.sort(this.defaultSortColumnConfig);

    this.userService.loadList();

    this.userService.loadingCounter$.subscribe(() => {
      this.selection.clear();
    });
  }

  ngAfterViewInit(): void {
    this.sortSubcription =
      this.sortTable.sortChange.subscribe(
        (sortColumn: Sort) => {
          this.userService.listState.filter.sort =
            sortColumn.active;
          this.userService.listState.filter.direction =
            sortColumn.direction as ListFilter['direction'];
          this.userService.loadList();
        }
      );
  }

  get items(): MatTableDataSource<User> {
    return new MatTableDataSource<User>(
      this.userService.listState.response.items
    );
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.items.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows(): void {
    this.isAllSelected()
      ? this.selection.clear()
      : this.selection.select(...this.items.data);
  }

  async deleteUsers(): Promise<void> {
    for (const { id } of this.selection.selected) {
      await this.userService.delete(id);
    }
  }

  ngOnDestroy(): void {
    this.sortSubcription.unsubscribe();
  }
}
