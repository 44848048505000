<div class="container-html">
  <mat-toolbar
    [color]="(isHandset$ | async) ? 'primary' : undefined"
  >
    <div
      fxLayout="row"
      fxLayoutAlign="space-between center"
      class="container-content flex-1"
    >
      <div fxLayout="row" fxLayoutAlign="start center">
        <h1
          class="app-title"
          [class.app-title-mobile]="isHandset$ | async"
        >
          {{
            'title.default.application' | translate
          }}
        </h1>
      </div>
      <div>
        <app-i18n-button></app-i18n-button>
      </div>
    </div>
  </mat-toolbar>

  <div class="financed-by-eu">
    <img class="financed-by-eu-logo" src="/assets/img/mbr/EU_Emblem_ARF.jpg" [alt]="'mbr.financed-by-eu-alt' | translate">
  </div>

  <app-header-navbar
    [containsLinks]="false"
    [isAdmin]="false"
    *ngIf="(isHandset$ | async) === false"
  ></app-header-navbar>
  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav-content class="nav-content">
      <div>
        <div class="page-wrapper">
          <router-outlet></router-outlet>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <app-footer></app-footer>
</div>
