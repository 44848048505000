import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import {
  ExamCenterService,
  ExamLocationService,
} from '../services';

@Injectable({
  providedIn: 'root',
})
export class ResetExamCenterFormGuard
  implements CanActivate
{
  constructor(
    private service: ExamCenterService,
    private examLocationService: ExamLocationService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Promise<boolean>((resolve) => {
      this.service.formState.item =
        ExamCenterService.factoryItem();
      this.examLocationService
        .loadAllForSelect()
        .then(() => {
          resolve(true);
        });
    });
  }
}
