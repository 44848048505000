<mat-card>
  <mat-card-header>
    <mat-card-title>{{
      'label.exam-event-list' | translate
    }}</mat-card-title>
  </mat-card-header>
  <table
    mat-table
    matSort
    matSortDisableClear
    [dataSource]="items"
  >
    <ng-container matColumnDef="testeventTime">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'label.time' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        {{ getTimeLabel(row) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="testId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'label.exams.exam' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        <div class="test-name">{{ getTestName(row) }}</div>
        <img
          *ngIf="getTestLogo(row)"
          [src]="getTestLogo(row)"
          [alt]="getTestName(row)"
          class="test-logo"
        />
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="rowsConfig"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: rowsConfig"
      (click)="rowClicked(row)"
      class="hover-outline ripple clickable"
    ></tr>
  </table>
</mat-card>
<button
  (click)="createButtonClicked()"
  class="add-button"
  color="primary"
  mat-mini-fab
>
  <mat-icon>add</mat-icon>
</button>
