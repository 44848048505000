<mat-card>
  <mat-card-header>
    <mat-card-title>{{
      'label.personal-information' | translate
    }}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="flex-row flex-wrap mat-h3">
      <div class="flex-1">
        {{ 'label.firstname' | translate }}
      </div>
      <div class="info flex-1">
        {{ profile.user.firstname }}
      </div>
    </div>
    <div class="flex-row flex-wrap mat-h3">
      <div class="flex-1">
        {{ 'label.lastname' | translate }}
      </div>
      <div class="info flex-1">
        {{ profile.user.lastname }}
      </div>
    </div>
    <div class="flex-row flex-wrap mat-h3">
      <div class="flex-1">
        {{ 'label.birthname' | translate }}
      </div>
      <div class="info flex-1">
        {{ profile.user.birthName }}
      </div>
    </div>
    <div class="flex-row flex-wrap mat-h3">
      <div class="flex-1">
        {{ 'gender.label' | translate }}
      </div>
      <div class="info flex-1">
        {{ genderLabel | translate }}
      </div>
    </div>
    <div class="flex-row flex-wrap mat-h3">
      <div class="flex-1">
        {{ 'label.birthLocation' | translate }}
      </div>
      <div class="info flex-1">
        {{ profile.user.birthplace }}
      </div>
    </div>
    <div class="flex-row flex-wrap mat-h3">
      <div class="flex-1">
        {{ 'label.birthDate' | translate }}
      </div>
      <div class="info flex-1">
        {{ profile.user.birthday | localizedDate }}
      </div>
    </div>
    <div class="flex-row flex-wrap mat-h3">
      <div class="flex-1">
        {{ 'label.phone' | translate }}
      </div>
      <div class="info flex-1">
        {{ profile.user.phoneNumber }}
      </div>
    </div>
    <div class="flex-row flex-wrap mat-h3">
      <div class="flex-1">
        {{ 'label.email2' | translate }}
      </div>
      <div class="info flex-1">
        {{ profile.user.secondEmail }}
      </div>
    </div>
    <div class="flex-row flex-wrap mat-h3">
      <div class="flex-1">
        {{ 'label.street' | translate }}
      </div>
      <div class="info flex-1">
        {{ profile.user.street }}
      </div>
    </div>
    <div class="flex-row flex-wrap mat-h3">
      <div class="flex-1">
        {{ 'label.street-number' | translate }}
      </div>
      <div class="info flex-1">
        {{ profile.user.streetNumber }}
      </div>
    </div>
    <div class="flex-row flex-wrap mat-h3">
      <div class="flex-1">
        {{ 'label.addressAddition' | translate }}
      </div>
      <div class="info flex-1">
        {{ profile.user.additionalAddressInfo }}
      </div>
    </div>
    <div class="flex-row flex-wrap mat-h3">
      <div class="flex-1">
        {{ 'label.postalCode' | translate }}
      </div>
      <div class="info flex-1">
        {{ profile.user.zip }}
      </div>
    </div>
    <div class="flex-row flex-wrap mat-h3">
      <div class="flex-1">
        {{ 'label.city' | translate }}
      </div>
      <div class="info flex-1">
        {{ profile.user.city }}
      </div>
    </div>
    <div class="flex-row flex-wrap mat-h3">
      <div class="flex-1">
        {{ 'label.state' | translate }}
      </div>
      <div class="info flex-1">
        {{ profile.user.state }}
      </div>
    </div>
    <div class="flex-row flex-wrap mat-h3">
      <div class="flex-1">
        {{ 'label.country' | translate }}
      </div>
      <div class="info flex-1">
        {{ countryLabel | translate }}
      </div>
    </div>

    <ng-container
      *ngFor="let address of profile.user.parentalAddresses"
    >
      <mat-divider></mat-divider>

      <div class="flex-row flex-wrap mat-h3">
        <b class="flex-1">{{
          'userInfo.parental-addresses.title' | translate
        }}</b>
      </div>

      <div class="flex-row flex-wrap mat-h3">
        <div class="flex-1">
          {{ 'label.street' | translate }}
        </div>
        <div class="info flex-1">
          {{ address.street }}
        </div>
      </div>
      <div class="flex-row flex-wrap mat-h3">
        <div class="flex-1">
          {{ 'label.street-number' | translate }}
        </div>
        <div class="info flex-1">
          {{ address.streetNumber }}
        </div>
      </div>
      <div class="flex-row flex-wrap mat-h3">
        <div class="flex-1">
          {{ 'label.addressAddition' | translate }}
        </div>
        <div class="info flex-1">
          {{ address.additionalAddressInfo }}
        </div>
      </div>
      <div class="flex-row flex-wrap mat-h3">
        <div class="flex-1">
          {{ 'label.postalCode' | translate }}
        </div>
        <div class="info flex-1">
          {{ address.zip }}
        </div>
      </div>
      <div class="flex-row flex-wrap mat-h3">
        <div class="flex-1">
          {{ 'label.city' | translate }}
        </div>
        <div class="info flex-1">
          {{ address.city }}
        </div>
      </div>
      <div class="flex-row flex-wrap mat-h3">
        <div class="flex-1">
          {{ 'label.state' | translate }}
        </div>
        <div class="info flex-1">
          {{ address.state }}
        </div>
      </div>
      <div class="flex-row flex-wrap mat-h3">
        <div class="flex-1">
          {{ 'label.country' | translate }}
        </div>
        <div class="info flex-1">
          {{ countryLabel | translate }}
        </div>
      </div>
    </ng-container>
  </mat-card-content>
  <mat-card-actions>
    <button
      mat-raised-button
      color="accent"
      [routerLink]="['/profile/edit']"
    >
      {{ 'button.update' | translate }}
    </button>
  </mat-card-actions>
</mat-card>
