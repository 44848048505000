<mat-checkbox
    [formControl]="field"
    color="primary"
    [class.disabled]="disabled"
    [class.break-lines]="breakLines"
    [disabled]="disabled"
>{{
  checkBoxLabel | translate: translateParameters
}}</mat-checkbox>

<div
  *ngIf="hasHint"
  class="mat-form-field-subscript-wrapper"
>
  <mat-hint class="mat-hint">{{
    hint | translate
  }}</mat-hint>
</div>
<div
  *ngIf="showError"
  class="mat-form-field-subscript-wrapper"
>
  <mat-error class="mat-error">{{
    error | translate
  }}</mat-error>
</div>
