import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Params, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import {
  firstValueFrom,
  interval,
  Subscription,
} from 'rxjs';
import { environment } from '../../../environments/environment';
import { VersionResponse } from '../../types';
import { MbrUserStatus } from '../../types/MbrUserStatus';
import { AppService } from '../app';
import { CountryService } from '../country';
import { I18nService } from '../i18n';
import { ProfileService } from '../profile';

@Injectable({
  providedIn: 'root',
})
export class AppInitializerService implements OnDestroy {
  private readonly LOG_IN_STATUS_CHECK_INTERVAL = 1000;

  isLoggedIn = false;

  private currentUrl = '';
  private initPromise: Promise<void> | null = null;
  private mbrUserStatus?: MbrUserStatus;
  private unsubOnDestroy = new Subscription();
  private urlQueryParams!: Params;

  constructor(
    private app: AppService,
    private countryService: CountryService,
    private http: HttpClient,
    private i18n: I18nService,
    private keycloak: KeycloakService,
    private profileService: ProfileService,
    private router: Router
  ) {
    this.getQueryParams();
  }

  init(): Promise<void> {
    if (!this.initPromise) {
      this.initPromise = Promise.all([
        this.i18n.init(),
        this.countryService.init(),
        this.loadFrontendVersion(),
        this.loadBackendVersion(),
        this.resolveLoggedInStatus().then((status) =>
          status ? this.profileService.load() : null
        ),
      ]).then(() => {
        document.body.classList.add('app-initialized');
        this.checkLogInStatus();
      });
    }
    return this.initPromise;
  }

  private checkLogInStatus(): void {
    this.unsubOnDestroy = interval(
      this.LOG_IN_STATUS_CHECK_INTERVAL
    ).subscribe(() => {
      this.resolveLoggedInStatus();
    });
  }

  private getQueryParams(): void {
    const params = new URLSearchParams(
      window.location.search
    );

    this.urlQueryParams = {};
    params.forEach((value, key) => {
      const decodedValue =
        key === '' ? value : decodeURIComponent(value);
      this.urlQueryParams[key] = decodedValue;
    });
  }

  private isNotRedirectURL(): boolean {
    this.currentUrl = window.location.href;

    return (
      this.currentUrl.includes('landing') ||
      this.currentUrl.includes('register/') ||
      this.currentUrl.includes('reset-password/')
    );
  }

  private loadFrontendVersion(): Promise<void> {
    return Promise.resolve().then(() => {
      this.app.versionFrontend = environment.version;
    });
  }

  private loadBackendVersion(): Promise<void> {
    // The promise should resolve, even if the request fail
    return new Promise((resolve) => {
      firstValueFrom(
        this.http.get<VersionResponse>(
          '/api/system/version'
        )
      )
        .then((response) => {
          if (response) {
            this.app.versionBackend = response.version;
          }
        })
        .catch(
          () => (this.app.versionBackend = 'loading failed')
        )
        .finally(() => resolve());
    });
  }

  private resolveLoggedInStatus(): Promise<boolean | void> {
    return this.keycloak.isLoggedIn().then((status) => {
      this.isLoggedIn = status;

      if (!this.isLoggedIn) {
        this.mbrUserStatus = undefined;
      }

      if (!this.isLoggedIn && !this.isNotRedirectURL()) {
        this.router.navigate(['/landing'], {
          queryParams: this.urlQueryParams,
        });
        // TODO Do we have to logout here?
      } else if (
        this.isLoggedIn &&
        this.urlQueryParams['showPrivacy'] === 'true'
      ) {
        delete this.urlQueryParams['showPrivacy'];
        this.router.navigate(['home/privacy-terms'], {
          queryParams: this.urlQueryParams,
        });
      } else if (
        this.isLoggedIn &&
        this.isNotRedirectURL()
      ) {
        this.keycloak.logout().then(() => {
          this.router.navigate([this.currentUrl]);
          this.currentUrl = '';
        });
      }
    });
  }

  resolveMbrStatus(): Promise<MbrUserStatus> {
    // If we have already retrieved the mbr status in this session, and it's either not an MBR user or an MBR user who
    //  has completed the setup, we can return the status immediately.
    if (
      this.mbrUserStatus &&
      this.mbrUserStatus.setupCompleted
    ) {
      return Promise.resolve(this.mbrUserStatus);
    } else {
      // If it's an MBR user who has not yet confirmed GDPR, we need to retrieve the status.
      return firstValueFrom(
        this.http.get<MbrUserStatus>(
          `/api/user/mbrstatus`,
          {}
        )
      ).then((mbrUserStatus) => {
        this.mbrUserStatus = mbrUserStatus;
        if (
          this.mbrUserStatus.isMbrUser &&
          !this.mbrUserStatus.confirmedGDPR
        ) {
          // The user is an MBR user who has not yet confirmed the terms of service.
          this.router.navigate([
            'mbr-registration/privacy-terms',
          ]);
        } else if (
          this.mbrUserStatus.isMbrUser &&
          !this.mbrUserStatus.setupCompleted
        ) {
          // The user is an MBR user who has confirmed the terms of service but has not yet completed the setup.
          this.router.navigate([
            'mbr-registration/retrieve-personal-data',
          ]);
        }
        return Promise.resolve(mbrUserStatus);
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubOnDestroy.unsubscribe();
  }
}
