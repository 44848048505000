import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Duration } from 'luxon';

export interface MbrUserRegistrationTimeoutDialogData {
  onClose: () => Promise<void>;
}

@Component({
  selector: 'app-mbr-user-registration-timeout',
  templateUrl:
    './mbr-user-registration-timeout.component.html',
  styleUrls: [
    './mbr-user-registration-timeout.component.scss',
  ],
})
export class MbrUserRegistrationTimeoutComponent
  implements OnInit
{
  constructor(
    public dialogRef: MatDialogRef<MbrUserRegistrationTimeoutComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: MbrUserRegistrationTimeoutDialogData
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.dialogRef.close();
      this.data.onClose();
    }, Duration.fromObject({ minute: 1 }).toMillis());
  }
}
