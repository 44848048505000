<div class="plain-header">
  <div
    class="container-content flex-row flex-1 flex-ai-center flex-jc-space-between"
  >
    <div class="logo" routerLink="landing">
      <ng-container [ngSwitch]="tenant">
        <img
          *ngSwitchCase="'default'"
          class="itb"
          src="/assets/img/logos/ITB_-_Logo_Farbe.svg"
          [alt]="'title.default.logo' | translate"
        />
        <img
          *ngSwitchCase="'sdv'"
          class="sdv"
          src="/assets/img/logos/sdv-logo-sw.png"
          [alt]="'title.sdv.logo' | translate"
        />
      </ng-container>
    </div>
    <app-i18n-button
      *ngIf="tenant === 'default'"
    ></app-i18n-button>
  </div>
</div>

<div class="page-container flex-1">
  <router-outlet></router-outlet>
</div>
<app-footer></app-footer>
