import {
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { Subject } from 'rxjs';
import {
  BookingService,
  ExamEventService,
  NotificationService,
} from 'src/app/services';
import { EventBooking, ExamEvent } from 'src/app/types';
import { CancellationBookingRequest } from 'src/app/types/request/CancellationBookingRequest';
import { BookingCancellationInfoResponse } from 'src/app/types/response/BookingCancellationInfoResponse';

@Component({
  selector: 'app-cancel-booking-dialog',
  templateUrl: './cancel-booking-dialog.component.html',
  styleUrls: ['./cancel-booking-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CancelBookingDialogComponent
  implements OnInit
{
  bookingInfo$: Subject<BookingCancellationInfoResponse> =
    new Subject<BookingCancellationInfoResponse>();
  form!: UntypedFormGroup;
  isLoading!: boolean;
  isShowAddress!: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public bookingId: number,
    private bookingService: BookingService,
    private examEventService: ExamEventService,
    private formBuilder: UntypedFormBuilder,
    private logger: NGXLogger,
    private notification: NotificationService,
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<CancelBookingDialogComponent>
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      refundMethod: ['', [Validators.required]],
      reason: [''],
      refund: [{ amount: 0, currency: '' }],
    });

    this.isLoading = true;

    this.bookingService
      .cancellationInfo(this.bookingId)
      .then((info) => {
        this.isLoading = false;
        this.bookingInfo$.next(info);

        this.form.patchValue({
          refund: {
            amount: info.totalPaidAmount.amount,
            currency: info.totalPaidAmount.currency,
          },
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  async cancellationBooking() {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    try {
      this.isLoading = true;

      await this.bookingService.cancellationBooking(
        this.bookingId,
        this.cancellationRequestBody
      );

      this.translateService
        .get('message.booking-cancel-success')
        .subscribe((translation: string) => {
          this.isLoading = false;
          this.dialogRef.close();
          this.notification.success({
            message: translation,
          });
        });
    } catch (error) {
      this.isLoading = false;
      this.notification.error('error.general');
      this.logger.error(error);
    }
  }

  get eventBooking(): EventBooking {
    return this.bookingService.formState.item;
  }

  get examEvent(): ExamEvent {
    return this.examEventService.formState.item;
  }

  get refundMethod(): UntypedFormControl {
    return this.form.get(
      'refundMethod'
    ) as UntypedFormControl;
  }

  get reason(): UntypedFormControl {
    return this.form.get('reason') as UntypedFormControl;
  }

  get cancellationRequestBody(): CancellationBookingRequest {
    return this.form.value;
  }

  get currentLanguage(): string {
    return this.translateService.currentLang;
  }

  showAddress(): void {
    this.isShowAddress = !this.isShowAddress;
  }
}
