import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { ExamLocationService } from '../services';
import { ExamLocation } from '../types';

@Injectable({
  providedIn: 'root',
})
export class InitExamLocationFormGuard
  implements CanActivate
{
  constructor(private service: ExamLocationService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Promise<boolean>((resolve) => {
      const id = Number(route.paramMap.get('id'));
      this.service
        .loadById(id)
        .then((item: ExamLocation) => {
          this.service.formState.item = item;
          resolve(true);
        });
    });
  }
}
