<div class="step-content">
  <p>
    {{ 'booking.sdv.info-page.paragraph-1' | translate }}
  </p>

  <p>
    {{ 'booking.sdv.info-page.paragraph-2' | translate }}
  </p>

  <ol>
    <li>
      <p>
        <strong>
          {{
            'booking.sdv.info-page.paragraph-3.part-1'
              | translate
          }}</strong
        >
        {{
          'booking.sdv.info-page.paragraph-3.part-2'
            | translate
        }}
      </p>
    </li>

    <li>
      <p>
        <strong>
          {{
            'booking.sdv.info-page.paragraph-4.part-1'
              | translate
          }}
        </strong>

        {{
          'booking.sdv.info-page.paragraph-4.part-2'
            | translate
        }}
        <a
          href="{{
            'booking.sdv.info-page.paragraph-4.link-1.link'
              | translate
          }}"
          target="_blank"
          >{{
            'booking.sdv.info-page.paragraph-4.link-1.text'
              | translate
          }}</a
        >
        {{
          'booking.sdv.info-page.paragraph-4.part-3'
            | translate
        }}
        <a
          href="#"
          (click)="
            showInfoModal($event, 'consent-proctoring-sdv')
          "
          >{{
            'booking.sdv.info-page.paragraph-4.link-text-2'
              | translate
          }}</a
        >
        {{
          'booking.sdv.info-page.paragraph-4.part-4'
            | translate
        }}
        <a
          href="#"
          (click)="showInfoModal($event, 'privacy-terms')"
          >{{
            'booking.sdv.info-page.paragraph-4.link-text-3'
              | translate
          }}</a
        >
        {{
          'booking.sdv.info-page.paragraph-4.part-5'
            | translate
        }}
      </p>
    </li>
  </ol>
</div>

<mat-card-actions fxLayout fxLayoutAlign="space-between">
  <button
    mat-raised-button
    color="warn"
    (click)="cancelBooking()"
  >
    {{ 'button.cancel' | translate }}
  </button>
  <div>
    <button
      mat-raised-button
      matStepperPrevious
      type="button"
    >
      {{ 'button.back' | translate }}
    </button>
    <button
      mat-raised-button
      color="primary"
      matStepperNext
      type="button"
    >
      {{ 'button.next' | translate }}
    </button>
  </div>
</mat-card-actions>
