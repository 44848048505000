<div class="container-flex">
  <div
    class="col"
    *ngIf="currentLanguage === 'de'; else englishPersonal"
  >
    <div class="width100">
      <div class="section-bg-contrast">
        <span>{{
          'label.personal-information' | translate
        }}</span>
      </div>
      <p>
        <span>{{
          user.gender === 'm'
            ? 'Herr'
            : user.gender === 'f'
            ? 'Frau'
            : ''
        }}</span>
        {{ user.firstname }} {{ user.lastname }} <br />
        {{
          'userInfo.birth'
            | translate
              : {
                  birthday:
                    user.birthday
                    | localizedDate: 'mediumDate',
                  birthplace: user.birthplace
                }
        }}
      </p>
      <p>
        {{ user.email }} <br />
        Tel.: {{ user.phoneNumber }}
      </p>
      <p>
        {{ user.street }} {{ user.streetNumber }} <br />
        {{ user.zip }} {{ user.city }} <br />
        {{ user.country }}
      </p>
    </div>
  </div>
  <ng-template #englishPersonal>
    <div class="col">
      <div class="width100">
        <div class="section-bg-contrast">
          <span>{{
            'label.personal-information' | translate
          }}</span>
        </div>
        <p>
          <span>{{
            user.gender === 'm'
              ? 'Mr'
              : user.gender === 'f'
              ? 'Ms/Mrs'
              : ''
          }}</span>
          {{ user.firstname }} {{ user.lastname }} <br />
          {{
            'userInfo.birth'
              | translate
                : {
                    birthday:
                      user.birthday
                      | localizedDate: 'mediumDate',
                    birthplace: user.birthplace
                  }
          }}
        </p>
        <p>
          {{ user.email }} <br />
          Phone: {{ user.phoneNumber }}
        </p>
        <p>
          {{ user.street }} {{ user.streetNumber }} <br />
          {{ user.zip }} {{ user.city }}
          {{ user.country }}
        </p>
      </div>
    </div>
  </ng-template>

  <div class="col">
    <ng-container
      [ngSwitch]="(booking$ | async)?.test?.templateName"
    >
      <ng-container *ngSwitchCase="'PhaST'">
        <div class="question-block">
          <div class="section-bg-contrast">
            <span>{{
              'phast.question.university.text' | translate
            }}</span>
          </div>
          <ul
            *ngIf="
              userAnswers.universityIds &&
                userAnswers.universityIds.length > 0;
              else notSpecified
            "
          >
            <li
              *ngFor="let uni of userAnswers.universityIds"
            >
              {{ uni.translationKey | translate }}
            </li>
          </ul>
        </div>

        <div class="question-block">
          <div class="section-bg-contrast">
            <span>{{
              'phast.question.final-grade.text' | translate
            }}</span>
          </div>
          <span
            *ngIf="
              userAnswers.finalGrade;
              else notSpecified
            "
            class="plain-text-output"
          >
            {{ userAnswers.finalGrade | translate }}
          </span>

          <ng-template #notSpecified>
            <span class="plain-text-output">
              {{ 'label.not-specified' | translate }}
            </span>
          </ng-template>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'TM-WISO'">
        <div class="question-block">
          <div class="section-bg-contrast">
            <span>{{
              'tm-wiso.questions.language.text' | translate
            }}</span>
          </div>
          <div class="plain-text-output">
            <span>{{
              userAnswers.language
                ? (userAnswers.language | translate)
                : ('label.not-specified' | translate)
            }}</span>
          </div>
        </div>

        <div class="question-block">
          <div class="section-bg-contrast">
            <span>{{
              'tm-wiso.questions.apply-for-university.text'
                | translate
            }}</span>
          </div>
          <ul>
            <li
              *ngFor="let uni of userAnswers.universityIds"
            >
              {{ uni.translationKey | translate }}
            </li>
          </ul>
        </div>

        <div
          class="question-block"
          *ngIf="
            userAnswers.sendResultsToUniversityIds &&
            userAnswers.sendResultsToUniversityIds.length >
              0
          "
        >
          <div class="section-bg-contrast">
            <span>{{
              'tm-wiso.questions.send-results.text'
                | translate
            }}</span>
          </div>
          <ul>
            <li
              *ngFor="
                let uni of userAnswers.sendResultsToUniversityIds
              "
            >
              {{ uni.translationKey | translate }}
            </li>
          </ul>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'GSAT'">
        <div class="question-block">
          <div class="section-bg-contrast">
            <span>{{
              'gsat.questions.language.text' | translate
            }}</span>
          </div>
          <div class="plain-text-output">
            <span>{{
              userAnswers.language
                ? (userAnswers.language | translate)
                : ('label.not-specified' | translate)
            }}</span>
          </div>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'ITB-Business'">
        <div class="question-block">
          <div class="section-bg-contrast">
            <span>{{
              'itb-business.questions.language.text' | translate
              }}</span>
          </div>
          <div class="plain-text-output">
            <span>{{
              userAnswers.language
                ? (userAnswers.language | translate)
                : ('label.not-specified' | translate)
              }}</span>
          </div>
        </div>

        <div class="question-block">
          <div class="section-bg-contrast">
            <span>{{
              'itb-business.questions.apply-for-university.text'
                | translate
              }}</span>
          </div>
          <ul>
            <li
              *ngFor="let uni of userAnswers.universityIds"
            >
              {{ uni.translationKey | translate }}
            </li>
          </ul>
        </div>

        <div
          class="question-block"
          *ngIf="
            userAnswers.sendResultsToUniversityIds &&
            userAnswers.sendResultsToUniversityIds.length >
              0
          "
        >
          <div class="section-bg-contrast">
            <span>{{
                'itb-business.questions.send-results.text'
                  | translate
              }}</span>
          </div>
          <ul>
            <li
              *ngFor="
                let uni of userAnswers.sendResultsToUniversityIds
              "
            >
              {{ uni.translationKey | translate }}
            </li>
          </ul>
        </div>

        <div class="question-block">
          <div class="section-bg-contrast">
            <span>{{
              'itb-business.questions.final-grade.text' | translate
              }}</span>
          </div>
          <span
            *ngIf="
              userAnswers.finalGrade;
              else notSpecified
            "
            class="plain-text-output"
          >
            {{ userAnswers.finalGrade | translate }}
          </span>

          <ng-template #notSpecified>
            <span class="plain-text-output">
              {{ 'label.not-specified' | translate }}
            </span>
          </ng-template>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'ITB-Science'">
        <div class="question-block">
          <div class="section-bg-contrast">
            <span>{{
                'itb-science.questions.language.text' | translate
              }}</span>
          </div>
          <div class="plain-text-output">
            <span>{{
                userAnswers.language
                  ? (userAnswers.language | translate)
                  : ('label.not-specified' | translate)
              }}</span>
          </div>
        </div>

        <div class="question-block">
          <div class="section-bg-contrast">
            <span>{{
                'itb-science.questions.final-grade.text' | translate
              }}</span>
          </div>
          <span
            *ngIf="
              userAnswers.finalGrade;
              else notSpecified
            "
            class="plain-text-output"
          >
            {{ userAnswers.finalGrade | translate }}
          </span>

          <ng-template #notSpecified>
            <span class="plain-text-output">
              {{ 'label.not-specified' | translate }}
            </span>
          </ng-template>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'ITB-Technology'">
        <div class="question-block">
          <div class="section-bg-contrast">
            <span>{{
                'itb-technology.questions.language.text' | translate
              }}</span>
          </div>
          <div class="plain-text-output">
            <span>{{
                userAnswers.language
                  ? (userAnswers.language | translate)
                  : ('label.not-specified' | translate)
              }}</span>
          </div>
        </div>

        <div class="question-block">
          <div class="section-bg-contrast">
            <span>{{
                'itb-technology.questions.final-grade.text' | translate
              }}</span>
          </div>
          <span
            *ngIf="
              userAnswers.finalGrade;
              else notSpecified
            "
            class="plain-text-output"
          >
            {{ userAnswers.finalGrade | translate }}
          </span>

          <ng-template #notSpecified>
            <span class="plain-text-output">
              {{ 'label.not-specified' | translate }}
            </span>
          </ng-template>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <div class="col">
    <div
      *ngIf="booking$ | async as booking"
      class="width100"
    >
      <div class="section-bg-contrast">
        <span>{{
          'label.test-event-date' | translate
        }}</span>
      </div>
      <img
        *ngIf="booking.test.logoPath"
        [src]="booking.test.logoPath"
        [alt]="booking.test.name"
        class="logo"
      />
      <p>
        {{ booking.test.name }}
      </p>
      <p>
        <span>
          {{
            'booking.default.confirmationPage.format.dayOfWeek'
              | translate
                : {
                    timeSlot:
                      timeSlot | localizedDate: 'EEEE'
                  }
          }}
          <strong>{{
            'booking.default.confirmationPage.format.date'
              | translate
                : {
                    timeSlot:
                      timeSlot | localizedDate: 'mediumDate'
                  }
          }}</strong>
        </span>
        <br />
        <span>
          {{
            'booking.default.confirmationPage.format.area.prefix'
              | translate
          }}
          <strong>{{
            'booking.default.confirmationPage.format.area.area'
              | translate
                : {
                    testarea: examEvent
                      ? examEvent.testarea
                      : ''
                  }
          }}</strong>
          {{
            'booking.default.confirmationPage.format.area.suffix'
              | translate
          }}
        </span>
        <br />
        <span>
          {{
            'booking.default.confirmationPage.format.time.prefix'
              | translate
          }}
          <strong>{{
            timeSlot | localizedDate: 'shortTime'
          }}</strong>
          {{
            'booking.default.confirmationPage.format.time.suffix'
              | translate
          }}
        </span>
      </p>
      <p>
        {{ 'label.test-event-price' | translate }}:
        <strong>{{
          examEvent
            ? (examEvent.price.amount
              | currency
                : examEvent.price.currency
                : undefined
                : undefined
                : currentLanguage)
            : ''
        }}</strong>
      </p>
    </div>
    <app-form-field-checkbox-terms-of-service
      *ngIf="tenant === 'default'"
      class="spacerBottom"
      [field]="confirmedTermsOfServiceControl"
    >
    </app-form-field-checkbox-terms-of-service>
  </div>
</div>
