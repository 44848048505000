<mat-form-field
  [appearance]="appearance"
  [class.disabled]="disabled"
>
  <mat-label *ngIf="hasLabel">{{label|translate}}</mat-label>
  <input [formControl]="field"
         placeholder="{{placeholder|translate}}"
         [required]="required"
         [disabled]="disabled"
         currencyMask
         matInput
         [options]="options"
  />
  <mat-hint *ngIf="hasHint">{{hint|translate}}</mat-hint>
  <mat-hint *ngIf="isPending">{{'label.pending'|translate}}</mat-hint>
  <mat-error>{{error|translate}}</mat-error>
</mat-form-field>
